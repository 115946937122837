import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IMenuFixItem, IMenuItem } from '../../interfaces/sidebar'

export interface SidebarState {
  isOpen: boolean
  selectedMenu?: IMenuItem | IMenuFixItem | null
  menu?: IMenuItem[]
  home?: IMenuItem
  hoveredMenu?: any
  refetchMenu?: any
}

const initialState: SidebarState = {
  isOpen: true,
}

export const sidebarReducer = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    setSelectedMenu: (state, action: PayloadAction<IMenuItem | IMenuFixItem | null>) => {
      state.selectedMenu = action.payload
    },
    setMenu: (state, action: PayloadAction<IMenuItem[]>) => {
      state.menu = action.payload
    },
    setHome: (state, action: PayloadAction<IMenuItem>) => {
      state.home = action.payload
    },
    setHoveredMenu: (state, action: PayloadAction<any>) => {
      state.hoveredMenu = action.payload
    },
    setMenuSignal: (state, action: PayloadAction<any>) => {
      state.refetchMenu = action.payload
    },
  },
})

export const {
  setOpen,
  setSelectedMenu,
  setMenu,
  setHome,
  setHoveredMenu,
  setMenuSignal,
} = sidebarReducer.actions

export default sidebarReducer.reducer
