import SettingsLayout from '../../components/commons/Layouts/settingsPages/SettingsLayout'
import { Outlet, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

const Settings = () => {
  const [title, setTitle] = useState<string>('')
  const [path, setPath] = useState({
    parenPath: '',
    basePath: '',
    new: false,
  })
  const [fullTitle, setFullTitle] = useState<string | undefined>(undefined)
  const location = useLocation()

  useEffect(() => {
    setFullTitle(undefined)
    switch (true) {
      case location.pathname.includes('/settings/users'):
        setTitle('usuarios')
        setPath({
          parenPath: location.pathname,
          basePath: 'user',
          new: true,
        })
        break
      case location.pathname.includes('/settings/reports'):
        setTitle('informes')
        setPath({
          parenPath: location.pathname,
          basePath: 'report',
          new: true,
        })
        break
      case location.pathname.includes('/settings/companies'):
        setFullTitle('Configurar entidad')
        setPath({
          parenPath: location.pathname,
          basePath: 'company',
          new: true,
        })
        break
      case location.pathname.includes('/settings/roles'):
        setTitle('roles')
        setPath({
          parenPath: location.pathname,
          basePath: 'roles',
          new: true,
        })
        break
      case location.pathname.includes('/settings/cms'):
        setTitle('contenido')
        setPath({
          parenPath: location.pathname,
          basePath: 'page',
          new: true,
        })
        break
      case location.pathname.includes('/settings/workspaces'):
        setTitle('workspaces')
        setPath({
          parenPath: location.pathname,
          basePath: 'workspace',
          new: true,
        })
        break
      case location.pathname.includes('/settings/status'):
        setTitle('Estados')
        setPath({
          parenPath: location.pathname,
          basePath: 'status',
          new: false,
        })
        break
        case location.pathname.includes('/settings/navigation'):
        setTitle('Navegación')
        setPath({
          parenPath: location.pathname,
          basePath: 'navigation',
          new: false,
        })
        break
    }
  }, [location.pathname])

  return (
    <SettingsLayout
      title={title}
      path={path.new ? `/create-${path.basePath}` : path.basePath}
      fullTitle={fullTitle}
      showNewButton={path.new}
    >
      <Outlet />
    </SettingsLayout>
  )
}

export default Settings
