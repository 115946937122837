import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import i18next from 'i18next'
import { toast } from 'react-toastify'

const errorHandler = (message: string) => {
  const t = i18next.t
  // deactive all toast first
  toast.dismiss()
  toast.error(`${t('errors.' + message)}`, {
    position: 'top-center',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: 0,
    autoClose: 5000,
  })
}

const deleteHandler = () => {
  const t = i18next.t
  toast.success(`${t('messages.Registro eliminado')}`, {
    position: 'top-center',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: 0,
    autoClose: 5000,
  })
}

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (!config.headers) return config
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
)
let isRefreshing = false

api.interceptors.response.use(
  async (response: AxiosResponse) => {
    // if method is delete then return message
    if (
      response.config.method === 'delete' &&
      (response.status === 200 || response.status === 204)
    ) {
      deleteHandler()
    }

    return await response
  },
  async (error: AxiosError) => {
    let data: any = error.response?.data as {
      [key: string]: any
    }
    let originalConfig = error.config

    if (error.response?.status === 503) {
      if (window.location.pathname !== '/maintenance') {
        window.location.href = '/maintenance?logo=' + data.logo
        return Promise.reject(error)
      }
      return Promise.reject(error)
    }
    if (error?.code === 'ERR_NETWORK') {
      errorHandler('Network error')
      // abort all request
      // eslint-disable-next-line no-undef
      axios.CancelToken.source().cancel()
      return Promise.reject(error)
    }

    if (error.response?.status === 401) {
      if (
        originalConfig.url !== '/login' &&
        !isRefreshing &&
        localStorage.getItem('token')
      ) {
        isRefreshing = true
        const response = await api.get('/refresh-token')
        if (response.data.access_token) {
          localStorage.setItem('token', response.data.access_token)
          return api(originalConfig)
        }
      }

      localStorage.removeItem('token')

      if (window.location.pathname !== '/login') {
        errorHandler('Unauthorized')
        setTimeout(() => {
          window.location.href = '/login'
        }, 200)
        return Promise.reject(error)
      }
    } else if (error.response?.status === 403) {
      errorHandler(data.message || 'Forbidden')
    } else if (error.response?.status === 404) {
      errorHandler(data.message || 'Not Found')
    } else if (error.response?.status === 500) {
      errorHandler('Internal server error')
    } else if (error.response?.status === 422) {
      if (error.response.config.url?.indexOf('forgot-password') !== -1) {
        return Promise.reject(error)
      }
      if (error.response?.data) {
        let message = data['message']
        if (message) {
          errorHandler(message)
        }
      } else {
        errorHandler('Validation error' || 'Unprocessable Entity')
      }
    } else {
      errorHandler('Something went wrong')
    }

    return Promise.reject(error)
  }
)

// Path: src\infrastructure\services\api.ts
