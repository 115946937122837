import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logOut } from '../../../infrastructure/store/user/userSlice'
import {
  setEntityList,
  setSelectedEntity,
} from '../../../infrastructure/store/multitenant/multitenantSlice'
import Paragraphs from '../Paragraphs/Paragraphs'
import { AvatarContextMenuContainer } from './styles'
import { ICompanyItem } from '../../../infrastructure/interfaces/settings'
import { IUserItem, UserRoles } from '../../../infrastructure/interfaces/users'
import { useEffect, useState } from 'react'
import storageService from '../../../infrastructure/services/storageService'
import { settingsService } from '../../../infrastructure/services/settingsService'
import { checkPermissions } from '../../../infrastructure/utils/permissions'
import useListenerClose from '../../../infrastructure/hooks/useCloseListener'
import Logo from '../Logo'
import { authService } from '../../../infrastructure/services/authService'
import { icons } from 'eva-icons'

interface IAvatarContextMenuProps {
  onToggle: () => void
  width: number
  user: IUserItem
  entities: ICompanyItem[]
  selectedEntity: ICompanyItem
  bgColor: string
}

const AvatarContextMenu = ({
  onToggle,
  width,
  user,
  entities,
  selectedEntity,
  bgColor
}: IAvatarContextMenuProps) => {
  const { t } = useTranslation()
  const [ref] = useListenerClose(() => onToggle())
  const [userData, setUserData] = useState<IUserItem>()
  const [entityListing, setEntityListing] = useState<ICompanyItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onCloseSession = () => {
    setLoading(true)
    dispatch(logOut())
    authService.logout()
    navigate('/login')
  }

  const onChangeEntity = (entity: ICompanyItem) => {
    dispatch(setSelectedEntity(entity))
    // change url to entity domain
    if (window.location.hostname !== entity.domain) {
      window.location.href = `https://${entity.domain}`
    }
    onToggle()
  }

  useEffect(() => {
    if (user) {
      setUserData(user)
    } else {
      let user = storageService.getUser()
      if (user) {
        setUserData(user as IUserItem)
        if ((!entities && !entityListing) || !entityListing.length)
          getEntities()
        else setEntityListing(entities)
      }
    }
  }, [user])

  useEffect(() => {
    if (entities && entities.length && !entityListing.length)
      setEntityListing(entities)
    else getEntities()
  }, [entities])

  function getEntities() {
    if (
      checkPermissions(
        ['companies.*', 'companies.view', 'companies.list'],
        userData?.permissions || []
      ) ||
      (userData && userData.role.name === UserRoles.ADMIN)
    ) {
      settingsService
        .getCompanies({
          per_page: 500,
        })
        .then((res) => {
          setEntityListing(res.data.items)
          dispatch(setEntityList(res.data.items))
        })
    }
    return
  }

  return (
    <>
      {loading && (
        <div className="tw-flex tw-flex-col tw-absolute tw-top-0 tw-left-0 tw-bg-white tw-bg-opacity-100 tw-h-full tw-w-full tw-z-10 tw-items-center tw-justify-center">
          <Logo width={100} />
          <Paragraphs>{t('messages.Cargando')}</Paragraphs>
        </div>
      )}
      <div className="tw-relative tw-overflow-auto">
        <AvatarContextMenuContainer bgColor={bgColor} width={width} ref={ref}>
          <Paragraphs size="base" weight="bold" className='tw-px-4 tw-py-2 tw-cursor-default'>
            {t(`actions.Cambiar entidad`)}:
          </Paragraphs>
          {userData &&
            userData.role &&
            userData.role.name === UserRoles.ADMIN &&
            entityListing.map((entity: ICompanyItem, index) => (
              <div
                className={`tw-relative tw-px-4 tw-py-2 tw-cursor-pointer hover:tw-bg-white hover:tw-bg-opacity-[0.1] tw-transition-all tw-duration-200 ${selectedEntity
                  ? selectedEntity.id === entity.id
                    ? 'tw-bg-white tw-bg-opacity-[0.1]'
                    : ''
                  : entity.domain === window.location.hostname
                    ? 'tw-bg-white tw-bg-opacity-[0.1]'
                    : ''
                  }`}
                key={index}
                onClick={() => onChangeEntity(entity)}
              >

                <Paragraphs size="sm">{entity.name}</Paragraphs>
              </div>
            ))}
          <div
            onClick={onCloseSession}
            className="tw-border-t-2 tw-border-t-white tw-border-opacity-[0.1] tw-px-4 tw-py-2 tw-cursor-pointer tw-flex tw-items-center tw-justify-between hover:tw-bg-white hover:tw-bg-opacity-[0.1] tw-transition-all tw-duration-200"
          >
            <Paragraphs size="sm" weight="bold" uppercase>
              {t(`actions.Cerrar Sesión`)}
            </Paragraphs>
            <span
              className='tw-fill-white'
              dangerouslySetInnerHTML={{
                __html: icons['log-out-outline']?.toSvg({ width: 24, height: 24 }) || '',
              }}
            />
          </div>
        </AvatarContextMenuContainer>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
  entities: state.multitenant.entityList,
  selectedEntity: state.multitenant.entity,
})

export default connect(mapStateToProps)(AvatarContextMenu)
