import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { authService } from '../../../infrastructure/services/authService'
import { validateEmail } from '../../../infrastructure/utils/common'
import Button from '../../commons/Button'
import Paragraphs from '../../commons/Paragraphs/Paragraphs'
import TextInput from '../../commons/TextInput'

interface formData {
  email: string
}

const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false)
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onSubmit = async (data: formData) => {
    if (validateEmail(data.email)) {
      setLoading(true)
      let email = data.email.toLowerCase()
      authService
        .forgotPassword(email)
        .then((res: any) => {
          setLoading(false)
          navigate('/confirmation-email', { state: { email } })
        })
        .catch(() => {
          navigate('/confirmation-email', { state: { email } })
          setLoading(false)
        })
    } else {
      setError('email', { message: t('email error') }, { shouldFocus: true })
      setLoading(false)
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit as any)}
      className="tw-flex tw-flex-col tw-items-center tw-flex-1 tw-h-full tw-w-96"
    >
      <div className="tw-p-6 tw-w-full">
        <div className="tw-p-4 tw-text-center">
          <Paragraphs weight="bold" size="xl">
            {t('login.Recordar contraseña')}
          </Paragraphs>
        </div>
        <div>
          <TextInput
            type="text"
            id="email"
            label={t('login.Correo electronico')}
            register={register('email', {
              required: {
                value: true,
                message: 'Campo requerido',
              },
            })}
            error={errors['email']?.message}
          />
        </div>
      </div>
      <Button
        label={t('actions.Enviar')}
        uppercase
        type="submit"
        disabled={loading}
      />
    </form>
  )
}

export default ForgotPasswordForm
