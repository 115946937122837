import { IUserItem } from '../interfaces/users'

const LOCA_STORAGE_USER_KEY = 'user'

const getUser = (): IUserItem | Boolean => {
  let user = localStorage.getItem(LOCA_STORAGE_USER_KEY)
  if (user) {
    return JSON.parse(user)
  }
  return false
}

const logOut = () => {
  localStorage.removeItem(LOCA_STORAGE_USER_KEY)
  localStorage.removeItem('token')
  localStorage.removeItem('expires_at')
}

const setUser = (user: IUserItem) => {
  localStorage.setItem(LOCA_STORAGE_USER_KEY, JSON.stringify(user))
}

const set = (key: string, value: any) => {
  localStorage.setItem(key, value)
}

export default {
  getUser,
  logOut,
  setUser,
  set,
}
