import ForgotPasswordForm from '../../../components/Auth/FormForgotPassword'
import Layout from '../Layout'

const ForgotPassword = () => {
  return (
    <Layout>
      <ForgotPasswordForm />
    </Layout>
  )
}

export default ForgotPassword
