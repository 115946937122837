import FormLogin from '../../../components/Auth/FormLogin'
import Layout from '../Layout'

const Login = () => {
  return (
    <Layout>
      <FormLogin />
    </Layout>
  )
}

export default Login
