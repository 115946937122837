import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DropZone from '../../../../components/commons/DropZone/DropZone'
import Label from '../../../../components/commons/Label'
import { settingsService } from '../../../../infrastructure/services/settingsService'
import {
  getBase64,
  handleInputErrors,
} from '../../../../infrastructure/utils/common'
import { CommonContainer, Input } from '../../styles'
import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import StickySettingsBtnBottom from '../../../../components/commons/Layouts/settingsPages/StickySettingsBtnBottom'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedEntity } from '../../../../infrastructure/store/multitenant/multitenantSlice'
import { IRoleItem } from '../../../../infrastructure/interfaces/settings'

const CreateCompany = () => {
  const { t } = useTranslation()

  const entity = useSelector((state: any) => state.multitenant.entity)

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [image, setImage] = useState(null)
  const [data, setData] = useState<any>()
  const [type, setType] = useState<string>('create')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (location && location['state']) {
      const state: any = location['state']
      state && setData(state.data)
      setType('update')
      setLoading(false)
    }
  }, [location])

  useMemo(async () => {
    if (image && image[0]) {
      let b64Image = await getBase64(image[0])
      setData({ ...data, logo: b64Image })
    }
  }, [image])

  const onSave = () => {
    setLoading(true)
    if (type === 'update') {
      if (!image) {
        delete data.logo
      }

      Object.keys(data).forEach((key) => {
        if (data[key] === null || data[key] === 'None') {
          data[key] = ''
        }
      })

      settingsService
        .updateCompany(data)
        .then((res) => {
          if (res.status === 200) {
            toast(t('messages.el registro se ha guardado') as string, {
              type: 'success',
              className: 'toast-success tw-p-4',
              position: 'top-center',
            })
            if (
              (entity && res.data.id === entity.id) ||
              res.data.domain === window.location.hostname
            ) {
              dispatch(setSelectedEntity(res.data))
            }
            return navigate(-1)
          }
          throw new Error()
        })
        .catch((err) => {
          handleInputErrors(err)
        })
    } else {
      settingsService
        .createCompany(data)
        .then((res) => {
          if (res.status === 200) {
            toast(t('messages.el registro se ha guardado') as string, {
              type: 'success',
              className: 'toast-success tw-p-4',
              position: 'top-center',
            })
            navigate(-1)
          }
        })
        .catch((err) => {
          handleInputErrors(err)
        })
    }
    setLoading(false)
  }

  return (
    <div className="tw-py-8 tw-px-8 tw-h-full">
      <StickySettingsBtnBottom onSave={onSave} loading={loading}>
        <div className="tw-w-full">
          {/* top section inputs */}
          <CommonContainer className="tw-py-8 tw-px-8">
            <div className="tw-flex tw-w-full tw-h-full tw-pt-4">
              {/* left container */}
              <div className="tw-w-2/5">
                <div className="tw-container">
                  <div className="tw-w-full">
                    <div className="tw-w-full tw-mb-8">
                      <div className="tw-grid tw-grid-cols-3 tw-py-2">
                        <Label
                          label="Nombre"
                          className="tw-mr-2 tw-text-right"
                          align="right"
                          justify="end"
                        />
                        <div className="tw-block tw-w-full tw-col-span-2">
                          <Input
                            className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                            type="text"
                            name="name"
                            value={data && data.name}
                            onChange={(e: any) => {
                              if (
                                e.target.classList.contains('tw-border-red-500')
                              ) {
                                e.target.classList.remove('tw-border-red-500')
                              }
                              e.target.parentElement
                                ?.querySelector('.tw-text-red-500')
                                ?.remove()
                              setData({ ...data, name: e.target.value })
                            }}
                          />
                        </div>
                      </div>
                      <div className="tw-grid tw-grid-cols-3 tw-py-2">
                        <Label
                          label="labels.Cabecera"
                          className="tw-mr-2 tw-text-right"
                          align="right"
                          justify="end"
                        />
                        <input
                          type="color"
                          value={data && data.primary_color}
                          onChange={(e: any) =>
                            setData({ ...data, primary_color: e.target.value })
                          }
                        />
                      </div>
                      <div className="tw-grid tw-grid-cols-3 tw-py-2">
                        <Label
                          label="labels.Interfaz"
                          className="tw-mr-2 tw-text-right"
                          align="right"
                          justify="end"
                        />
                        <input
                          type="color"
                          value={data && data.secondary_color}
                          onChange={(e: any) =>
                            setData({
                              ...data,
                              secondary_color: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="tw-grid tw-grid-cols-3 tw-py-2">
                        <Label
                          label="labels.Barra lateral"
                          className="tw-mr-2 tw-text-right"
                          align="right"
                          justify="end"
                        />
                        <input
                          type="color"
                          value={data && data.tertiary_color}
                          onChange={(e: any) =>
                            setData({ ...data, tertiary_color: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* right container*/}
              <div className="tw-w-1/3">
                <div className="tw-w-full tw-mb-8">
                  <div className="tw-grid tw-grid-cols-4 tw-py-2">
                    <Label
                      label="Logotipo"
                      className="tw-mr-2 tw-text-right"
                      align="right"
                      justify="end"
                      flexAlign="start"
                    />
                    <DropZone
                      onUpload={setImage}
                      maxFiles={1}
                      url={data && data.logo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </CommonContainer>
          {/* mid section */}
          <div className="tw-grid-cols-1 tw-border-b-2 tw-border-t-2">
            <div className="tw-py-4 tw-px-8 tw-flex tw-justify-between tw-items-center">
              <Paragraphs size="xl" weight="bold">
                Configuración BI
              </Paragraphs>
            </div>
          </div>
          {/* content section */}
          <CommonContainer className="tw-py-8 tw-px-8">
            <div className="tw-flex tw-w-full tw-h-full tw-py-2">
              {/* left container */}
              <div className="tw-w-2/5">
                <div className="tw-container">
                  <div className="tw-w-full">
                    <div className="tw-w-full tw-mb-8">
                      <div className="tw-grid tw-grid-cols-3">
                        <Label
                          label="Dominio"
                          className="tw-mr-2 tw-text-right"
                          align="right"
                          justify="end"
                        />
                        <Input
                          className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                          type="text"
                          name="domain"
                          id="domain"
                          required
                          defaultValue={data && data.domain}
                          onChange={(e: any) => {
                            if (
                              e.target.classList.contains('tw-border-red-500')
                            ) {
                              e.target.classList.remove('tw-border-red-500')
                            }
                            e.target.parentElement
                              ?.querySelector('.tw-text-red-500')
                              ?.remove()
                            setData({ ...data, domain: e.target.value })
                          }}
                          onBlur={(e: any) => {
                            if (e.target.value === '') {
                              e.target.classList.add('tw-border-red-500')
                            }
                          }}
                          onKeyUp={(e: any) => {
                            if (e.target.value === '') {
                              e.target.classList.add('tw-border-red-500')
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="tw-w-full tw-mb-8">
                      <div className="tw-grid tw-grid-cols-3">
                        <Label
                          label="Api URL"
                          className="tw-mr-2 tw-text-right"
                          align="right"
                          justify="end"
                        />
                        <Input
                          className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                          type="text"
                          value={
                            data && data.domain_api === 'None'
                              ? ''
                              : data && data.domain_api
                          }
                          onChange={(e: any) =>
                            setData({
                              ...data,
                              domain_api:
                                e.target.value === 'None' ? '' : e.target.value,
                            })
                          }
                          onKeyUp={(e: any) => {
                            if (e.target.value === '') {
                              setData({ ...data, domain_api: 'None' })
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* right container*/}
              <div className="tw-w-2/5">
                <div className="tw-container tw-pl-4">
                  <div className="tw-w-full tw-mb-8">
                    <div className="tw-grid tw-grid-cols-3">
                      <Label
                        label="ID Cliente"
                        className="tw-mr-2 tw-text-right"
                        align="right"
                        justify="end"
                      />
                      <Input
                        className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                        type="text"
                        value={data && data.sso_client_id}
                        onChange={(e: any) =>
                          setData({ ...data, sso_client_id: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="tw-w-full tw-mb-8">
                    <div className="tw-grid tw-grid-cols-3">
                      <Label
                        label="ApplicationSecret"
                        className="tw-mr-2 tw-text-right"
                        align="right"
                        justify="end"
                      />
                      <Input
                        className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                        type="password"
                        value={data && data.sso_client_secret}
                        onChange={(e: any) =>
                          setData({
                            ...data,
                            sso_client_secret: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CommonContainer>
        </div>
      </StickySettingsBtnBottom>
    </div>
  )
}

export default CreateCompany
