import Paragraphs from '../Paragraphs/Paragraphs'
import { useTranslation } from 'react-i18next'
interface LabelProps {
  label: string
  color?: string
  size?: string
  className?: string
  required?: boolean
  truncate?: boolean
  translate?: boolean
  weight?: boolean
  align?: string
  justify?: 'end' | 'start' | 'between'
  flexAlign?: 'start' | 'end' | 'center' | 'baseline' | 'stretch'
}
const Label = ({
  label,
  size = 'md',
  className = '',
  required,
  color = 'black',
  truncate,
  weight = false,
  align,
  justify,
  flexAlign = 'center',
}: LabelProps) => {
  const { t } = useTranslation()
  return (
    <Paragraphs
      weight={`${weight ? 'bold' : 'regular'}`}
      size={size as string}
      color={color}
      className={`Label tw-flex tw-w-full tw-items-${flexAlign} ${
        align ? `tw-text-${align}` : ''
      } ${justify ? `tw-justify-${justify}` : ''}`}
    >
      <p className={`${truncate ? 'tw-truncate' : ''} ${className}`}>
        {t(`${label}`)}
      </p>
      {required && <span className="tw-text-alert"> *</span>}
    </Paragraphs>
  )
}

export default Label
