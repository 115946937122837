import React from 'react'
import ReactDOM from 'react-dom/client'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import './index.css'
import './infrastructure/localization/i18n'
import { BrowserRouter } from 'react-router-dom'
import { store } from './infrastructure/store'
import { Provider } from 'react-redux'
import 'material-icons/iconfont/material-icons.css'
import { LicenseManager } from 'ag-grid-enterprise'

const licenseKey =
  'Using_this_{AG_Grid}_Enterprise_key_{AG-051350}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SERTEC_FARMA_S.L.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1})_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{28_December_2024}____[v3]_[01]_MTczNTM0NDAwMDAwMA==635fe89392724cc31b6f8eb48107db61'
const cleanedLicenseKey =
  licenseKey && licenseKey.replace(/[\u200B-\u200D\uFEFF]/g, '')
LicenseManager.setLicenseKey(cleanedLicenseKey)

// MSAL configuration
import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'
const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSCLIENTID!!,
    authority: 'https://login.microsoftonline.com/common',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}
const pca = new PublicClientApplication(configuration)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <>
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <Provider store={store}>
      <BrowserRouter>
        <MsalProvider instance={pca}>
          <App />
        </MsalProvider>
      </BrowserRouter>
    </Provider>
  </>
)
