import React, { useEffect, useState } from 'react';
import LogoImgWhite from './assets/img/logo512.png';
import LogoImgBlack from './assets/img/fedefarma-logo-black.png';
import LogoImgBlackSmall from './assets/img/fedefarma-logo-black-sm.svg';

interface LogoProps {
  mode?: 'black' | 'white' | 'small';
  width?: number;
  entityLogo?: string;
}

const Logo = ({ mode, width, entityLogo }: LogoProps) => {
  const logoSrc = mode === 'small'
    ? LogoImgBlackSmall
    : entityLogo || (mode === 'black' ? LogoImgBlack : LogoImgWhite);

  return (
    <div className="logo" style={{ maxWidth: width }}>
      <img
        src={logoSrc}
        alt="logo"
        className={`${mode !== 'small' ? 'h-[41.09px] w-[170px]' : 'h-[42px] w-[42px]'
          }`}
      />
    </div>
  );
};

export default Logo;
