import styled from 'styled-components'

const sizes: any = {
  mini: {
    height: '30px',
    with: '100px',
    full: '100%',
  },
  small: {
    height: '44px',
    with: '100px',
  },
  medium: {
    height: '44px',
    with: '200px',
  },
  large: {
    height: '44px',
    with: '300px',
  },
  full: {
    height: '44px',
    with: '100%',
  },
}

export const Container = styled.button<any>`
  max-width: ${(props: any) => sizes[props.size].with};
  width: ${(props: any) => props.width} !important;
  height: ${(props: any) => sizes[props.size].height};
  outline: none !important;
  background-color: ${(props: any) => props.backgroundColor || '#787878'};
  color: ${(props: any) => props.textColor};
  &.expansible:hover .label {
    text-decoration: underline;
  }
`
