import styled from 'styled-components'
export const ContainerContent = styled.div`
  margin-left: 0px;
  padding-top: 0px;
`
export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
`
export const SearchBarWrapper = styled.div`
  display: block;
  width: 100%;
`

export const Col = styled.div`
  width: 33%;
  cursor: pointer;
`
export const HeaderWrapper = styled.div<{
  bgColor?: string
}>`
  display: flex;
  background-color: ${(props) => props.bgColor || 'rgba(44, 44, 44, 1)'};
`
