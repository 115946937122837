import { Fragment, useState } from 'react'
import { TagContainer } from '../../../modules/Settings/styles'
import { HastagContainer } from '../AgGrid/styles'
import { icons } from 'eva-icons'

interface ITagInputProps {
  tags: string[]
  setTags: (tags: string[]) => void
  onDeletedTag: (tags: string[]) => void
  showLabel?: boolean
  loading?: boolean
}

const TagInput = ({
  tags,
  setTags,
  onDeletedTag,
  showLabel = true,
  loading = false,
}: ITagInputProps) => {
  const [tag, setTag] = useState<string>('')

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    const trimmedInput = tag.trim()

    if (
      (key === 'Enter' || key === 'Tab' || key === ',') &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault()
      setTags([...tags, trimmedInput])
      setTag('')
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTag(e.target.value)
  }

  const onDelete = (index: number) => {
    const newTags = [...tags]
    newTags.splice(index, 1)
    setTags(newTags)
    onDeletedTag(newTags)
  }

  return (
    <Fragment>
      {showLabel ? (
        <label className="tw-mr-2 tw-text-right ">Tags:</label>
      ) : null}
      <TagContainer className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2">
        {tags?.map((tag: string, index: number) => (
          <HastagContainer
            style={{ marginLeft: 0, paddingRight: '10px' }}
            className="tw-mr-2 tw-mb-2"
            key={index}
          >
            {tag}{' '}
            <span
              className="tw-ml-2 tw-cursor-pointer"
              style={{ fontSize: '16px' }}
              onClick={() => onDelete(index)}
              dangerouslySetInnerHTML={{
                __html:
                  icons['close-outline']?.toSvg({ width: 24, height: 24 }) ||
                  '',
              }}
            />
          </HastagContainer>
        ))}
        <input
          value={tag}
          onKeyDown={(e) => onKeyDown(e)}
          onChange={(e) => onChange(e)}
          readOnly={loading}
          className="tw-appearance-none tw-border-0 focus:tw-outline-none focus:tw-bg-white focus-visible:outline-none"
        />
      </TagContainer>
    </Fragment>
  )
}

export default TagInput
