export const navReportsColumns = [
  {
    headerName: 'Nombre',
    field: 'label',
    sortable: true,
    filter: true,
    rowDrag: true,
    editable: (params: any) => {
      console.log(params)
      return params.data.type !== 'folder'
    },
  },
  {
    headerName: 'Tipo',
    field: 'type',
    sortable: true,
    filter: true,
    valueGetter: (params: any) => {
      // si es report devolvemos informe y si es page devolvemos cms
      return params?.data?.type === 'report'
        ? 'Informe'
        : params?.data?.type === 'page'
        ? 'CMS'
        : 'Carpeta'
    },
  },
  {
    headerName: 'Descripción',
    field: 'description',
    valueGetter: (params: any) => {
      return params?.data?.type === 'report'
        ? params?.data?.report?.name
        : params?.data?.type === 'page'
        ? params?.data?.page?.title
        : '-'
    },
    sortable: true,
    filter: true,
  },
  {
    headerName: 'Icono',
    field: 'icon',
  },
]
