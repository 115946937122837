// SettingsContext.tsx
import React, {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react'

interface SettingsContextProps {
  currentEntity: string
  setCurrentEntity: Dispatch<SetStateAction<string>>
}

export const SettingsContext = createContext<SettingsContextProps | undefined>(
  undefined
)

interface SettingsProviderProps {
  children: ReactNode
}

export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children,
}) => {
  const [currentEntity, setCurrentEntity] = useState<string>('')

  return (
    <SettingsContext.Provider value={{ currentEntity, setCurrentEntity }}>
      {children}
    </SettingsContext.Provider>
  )
}
