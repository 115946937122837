import type { ActionType } from '@rc-component/trigger/lib/interface';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import React, { useEffect } from 'react';
import colors from '../../../infrastructure/styles/colors';
import './styles.css'
import styled from 'styled-components';
import { icons } from 'eva-icons';
interface CustomTooltipProps {
  ref?: React.RefObject<any>
  text: string;
  content: React.ReactElement;
  trigger?: ActionType[];
  visible?: boolean;
}
const Arrow = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0;
  border-color: #fff transparent transparent transparent !important;
  position: absolute;
  left: -6px; /* Ajusta la posición horizontal de la flecha */
  top: 50%;
  transform: translateY(-50%);
`;

// Luego en tooltipProps
const CustomTooltip: React.FC<CustomTooltipProps> = ({ ref, visible, text, content, trigger = ['hover'] }) => {
  const tooltipProps: {
    placement: string;
    overlay: string;
    arrowContent: React.ReactElement;
    trigger: ActionType[];
    overlayStyle: React.CSSProperties;
    overlayInnerStyle: React.CSSProperties;
    visible?: boolean;
  } = {
    placement: 'right',
    overlay: text,
    arrowContent: <div className="custom-tooltip-arrow">
      <span
        className='tw-fill-white'
        dangerouslySetInnerHTML={{
          __html: icons['log-out-outline']?.toSvg({ width: 24, height: 24 }) || '',
        }}
      />
    </div>,
    trigger: trigger,
    overlayStyle: {
      fontFamily: '"Aeonik", sans-serif',
      fontWeight: 'regular',
      color: colors.brandMedium,//color arrow
      opacity: 1
    },
    overlayInnerStyle: {
      background: colors.brandMedium,//color content
      color: '#ffffff',
      borderRadius: '6px',
      padding: '4px, 8px, 4px, 8px',
      alignContent: 'center',
      marginLeft: '-5px',
      opacity: 1
    },
  };


  useEffect(() => {
    if (visible) {
      tooltipProps.visible = visible;
    }
  }, [visible]);

  if (!visible) return <>{content}</>

  return <Tooltip ref={ref} {...tooltipProps}>{content}</Tooltip>;
};

export default CustomTooltip;
