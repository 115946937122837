import { Transition } from '@headlessui/react'
import { icons } from 'eva-icons'
import React, { MouseEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  IMenuFixItem,
  IMenuItem,
} from '../../../../infrastructure/interfaces/sidebar'
import { setSelectedMenu } from '../../../../infrastructure/store/sidebar/sidebarSlice'
import CustomTooltip from '../../CustomTooltip/CustomTooltip'
import Paragraphs from '../../Paragraphs/Paragraphs'
import SubMenu from './SubMenu'
import { IPositionSubMenu } from '../Sidebar'

interface MenuItemProps {
  item: IMenuItem | IMenuFixItem
  isOpen: boolean
  selectedMenu: IMenuItem | IMenuFixItem | null | undefined
  positionSubMenu: IPositionSubMenu | null
  selectMenu: (item: IMenuItem | IMenuFixItem) => void
  calculateRelativePosition: (
    event: MouseEvent<HTMLElement> | null,
    submenu: IMenuItem[] | IMenuFixItem[] | null | undefined
  ) => void
}

const MenuItem: React.FC<MenuItemProps> = ({
  item,
  isOpen,
  selectedMenu,
  positionSubMenu,
  calculateRelativePosition,
  selectMenu,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    let isLinkActive = false
    if (item.submenu && item.submenu.length > 0) {
      item.submenu.map((subitem) => {
        if (
          subitem.slug === selectedMenu?.slug &&
          selectedMenu?.parentId === item.id
        ) {
          isLinkActive = true
        }
      })
    } else {
      if (selectedMenu) {
        if (item.slug === selectedMenu?.slug && !selectedMenu.parentId) {
          isLinkActive = true
        }
      } else {
        if (item.slug === location.pathname) {
          isLinkActive = true
        }
      }
    }
    setCollapsed(!isLinkActive)

    setIsActive(isLinkActive)
  }, [location.pathname, selectedMenu])

  const handleMenuClick = () => {
    dispatch(setSelectedMenu(item))
    selectMenu(item)
    if (item.submenu && item.submenu.length > 0) {
      setCollapsed(!collapsed)
    }
  }

  const toggleSubMenu = (e: MouseEvent<HTMLElement> | null) => {
    e?.stopPropagation()
    const value = !collapsed
    setCollapsed(value)
    if (value || !e) {
      calculateRelativePosition(null, null)
    } else {
      calculateRelativePosition(e, item.submenu)
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setCollapsed(true)
      calculateRelativePosition(null, null)
    }
  }, [isOpen, selectedMenu])

  useEffect(() => {
    if (!positionSubMenu) return

    if (positionSubMenu.submenu !== item.submenu) setCollapsed(true)
  }, [positionSubMenu, item.submenu])

  return (
    <CustomTooltip
      text={item.label}
      visible={isOpen || (!isOpen && !collapsed) ? false : true}
      trigger={isOpen ? [] : ['hover']}
      content={
        <div className="tw-relative tw-w-full tw-cursor-pointer tw-font-normal">
          <div
            className={`tw-flex tw-items-center tw-justify-between tw-h-[60px] tw-bg-white
          ${
            isOpen
              ? 'tw-w-[275px] tw-p-[20px] tw-pl-[37px]'
              : `tw-w-[80px] tw-p-4 ${
                  isActive
                    ? 'tw-border-l-[6px] tw-border-l-brand tw-pl-[18px]'
                    : 'tw-pl-[24px] '
                }`
          }
          ${
            isActive
              ? 'tw-bg-brandSoft tw-text-brandMedium'
              : ' tw-text-darkGrey hover:tw-text-brandMedium hover:tw-bg-[#EEEEF0]'
          }`}
            onClick={(e) =>
              item.submenu && item.submenu.length > 0
                ? toggleSubMenu(e)
                : handleMenuClick()
            }
          >
            <div className={`tw-flex tw-items-center tw-gap-[14px]`}>
              <div className="tw-w-6 tw-h-6 tw-flex tw-justify-center tw-items-center">
                <span
                  className="tw-fill-current"
                  dangerouslySetInnerHTML={{
                    __html:
                      icons[item.icon ?? 'home-outline']?.toSvg({
                        width: 24,
                        height: 24,
                      }) || '',
                  }}
                />
              </div>
              <Paragraphs
                size="base"
                className={`tw-ml-2 tw-mt-0.5 ${
                  isActive ? ' tw-font-semibold' : ''
                }`}
              >
                {isOpen ? item.label : ''}
              </Paragraphs>
            </div>
            {isOpen && item.submenu && item.submenu.length > 0 && (
              <div
                className="tw-w-6 tw-h-6 tw-flex tw-justify-center tw-items-center"
                onClick={toggleSubMenu}
              >
                <span
                  className="tw-align-end tw-mr-1 tw-fill-current"
                  dangerouslySetInnerHTML={{
                    __html:
                      icons[
                        collapsed
                          ? 'arrow-ios-downward-outline'
                          : 'arrow-ios-upward-outline'
                      ]?.toSvg({ width: 24, height: 24 }) || '',
                  }}
                />
              </div>
            )}
          </div>
          {isOpen && item.submenu && item.submenu.length > 0 && (
            <Transition
              show={!collapsed}
              enter="tw-transition-all tw-duration-300"
              enterFrom="tw-max-h-0"
              enterTo="tw-max-h-full"
              leave="tw-transition-all tw-duration-300"
              leaveFrom="tw-max-h-screen"
              leaveTo="tw-max-h-0"
            >
              <SubMenu
                submenu={item.submenu}
                isOpen={isOpen}
                selectedMenu={selectedMenu}
                toggleSubMenu={toggleSubMenu}
                selectMenu={selectMenu}
                dispatch={dispatch}
                parentId={item.id!}
              />
            </Transition>
          )}
        </div>
      }
    />
  )
}

export default MenuItem
