import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../Paragraphs/Paragraphs'
import { reportsFilterOptions } from '../../../infrastructure/constant/columns/userCreateOptions'
import { Input, UserOptionsTableHeader } from '../../../modules/Settings/styles'
import TableTagInput from '../TableTagsInput/TableTaginput'
import { IFilterItem } from '../../../infrastructure/interfaces/filters'
import { IReportItem } from '../../../infrastructure/interfaces/settings'

interface OptionsProps {
  data: any
  handleReportFilter: (report_id: number, filter: IFilterItem | null) => void
  children?: React.ReactNode
  selectedReportFilters: IReportItem | any
  divRef: React.RefObject<HTMLDivElement>
}
const UserFilterReport = ({
  data,
  handleReportFilter,
  selectedReportFilters,
  divRef,
}: OptionsProps) => {
  const { t } = useTranslation()
  const [filterOptions, setFilterOptions] = useState<any>({})

  useEffect(() => {
    setFilterOptions(data)
  }, [data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterOptions({
      ...filterOptions,
      filter: {
        ...filterOptions?.filter,
        [e.target.name]: e.target.value,
      },
    })
  }

  const handleTagChange = (values: string[]) => {
    setFilterOptions({
      ...filterOptions,
      filter: {
        ...filterOptions?.filter,
        values,
      },
    })
  }

  const onDeletedTag = (newValues: string[]) => {
    setFilterOptions({
      ...filterOptions,
      filter: {
        ...filterOptions.filter,
        values: newValues,
      },
    })
  }

  const handleSaveFilter = () => {
    handleReportFilter(selectedReportFilters.id, filterOptions.filter)

    reportsFilterOptions.forEach((item: any) => {
      ;(document.getElementsByName(item.name)[0] as HTMLInputElement).value = ''
    })
  }

  return (
    <div className="tw-relative tw-h-full tw-flex tw-flex-col">
      <Paragraphs size="md">{t('titles.Tabla de filtros')}</Paragraphs>
      <div className="tw-flex tw-flex-col tw-relative">
        <div
          className="tw-pb-2 tw-inline-block tw-min-w-full tw-overflow-hidden"
          ref={divRef}
        >
          <table className="tw-text-left tw-border-2 ">
            <UserOptionsTableHeader className="tw-border-b tw-bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="tw-font-medium tw-text-gray-900 tw-p-2"
                  style={{ width: '20%' }}
                >
                  Filtro
                </th>

                <th
                  scope="col"
                  className="tw-font-medium tw-text-gray-900 tw-p-2"
                  colSpan={2}
                >
                  Valor
                </th>
                <th
                  scope="col"
                  className="tw-font-medium tw-text-gray-900 tw-p-4"
                ></th>
                <th
                  scope="col"
                  className="tw-font-medium tw-text-gray-900 tw-p-4"
                ></th>
                <th
                  scope="col"
                  className="tw-font-medium tw-text-gray-900 tw-p-4"
                ></th>
                <th
                  scope="col"
                  className="tw-font-medium tw-text-gray-900 tw-p-4"
                ></th>
              </tr>
            </UserOptionsTableHeader>
            <tbody>
              {reportsFilterOptions.map((e: any, i: number) => (
                <tr className="tw-bg-white tw-border-b" key={i}>
                  <td className="tw-p-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900 tw-border-r-2 ">
                    <span className="tw-pr-4 tw-py-2">
                      {t('labels.' + e.label)}
                    </span>
                  </td>
                  <td colSpan={10}>
                    <Input
                      type="text"
                      name={e.name}
                      className="tw-h-12 tw-w-full tw-pl-2"
                      value={
                        selectedReportFilters.filter &&
                        selectedReportFilters?.filter[e.name]
                          ? selectedReportFilters?.filter[e.name]
                          : filterOptions &&
                            filterOptions.filter &&
                            filterOptions.filter[e.name]
                          ? filterOptions.filter[e.name]
                          : ''
                      }
                      disabled={
                        selectedReportFilters.filter &&
                        selectedReportFilters?.filter[e.name]
                      }
                      onChange={handleFilterChange}
                    />
                  </td>
                </tr>
              ))}
              <tr className="tw-bg-white tw-border-b">
                <td className="tw-p-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900 tw-border-r-2 ">
                  <span className="tw-pr-4 tw-py-2">{t('labels.Valores')}</span>
                </td>
                <td colSpan={10}>
                  <TableTagInput
                    setTags={handleTagChange}
                    tags={
                      filterOptions &&
                      filterOptions.filter &&
                      filterOptions.filter.values
                        ? filterOptions.filter.values
                        : []
                    }
                    onDeletedTag={onDeletedTag}
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td className="tw-p-4 tw-text-sm " colSpan={6}>
                  <a
                    href="#"
                    className="tw-text-dark tw-underline tw-mt-2"
                    onClick={() => {
                      handleReportFilter(selectedReportFilters.id, null)
                    }}
                  >
                    {t('actions.Eliminar filtro')}
                  </a>
                </td>

                <td className="tw-p-4 tw-text-right tw-text-sm">
                  {Boolean(
                    filterOptions &&
                      filterOptions.filter &&
                      filterOptions.filter.values &&
                      filterOptions.filter.column &&
                      filterOptions.filter.table
                  ) && (
                    <a
                      href="#"
                      className="tw-text-dark tw-underline tw-mt-2"
                      onClick={(e) => {
                        e.preventDefault()
                        handleSaveFilter()
                      }}
                    >
                      {t('actions.Aplicar filtro')}
                    </a>
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  )
}

export default UserFilterReport
