import React, { useEffect, useState } from 'react';
import lottie from 'lottie-web';

interface LottieOverlayProps {
  animationData: any;
  map: google.maps.Map | null;
  overlayPosition: google.maps.LatLngLiteral;
}

const LottieOverlay: React.FC<LottieOverlayProps> = ({ animationData, map, overlayPosition }) => {
  const [position, setPosition] = useState<{ top: number; left: number } | null>(null);

  useEffect(() => {
    if (!map) return;

    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.width = '60px';
    container.style.height = '60px';
    container.style.transform = 'translate(-50%, -50%)';
    container.style.pointerEvents = 'none'; // Evitar que el contenedor interfiera con los eventos del mapa

    map.getDiv().appendChild(container);

    const animation = lottie.loadAnimation({
      container,
      renderer: 'canvas',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    const updatePosition = () => {
      if (map) {
        const overlayLatLng = new google.maps.LatLng(overlayPosition.lat, overlayPosition.lng);

        // Convierte las coordenadas geográficas en píxeles usando la proyección del mapa
        const overlayPixel = map.getProjection()?.fromLatLngToPoint(overlayLatLng);

        if (overlayPixel) {
          // Calcula la posición en píxeles dentro del mapa
          const scale = Math.pow(2, map.getZoom() ?? 5);

          const centerLatLng = map.getCenter();
          if (centerLatLng) {
            const worldCoordinate = map.getProjection()?.fromLatLngToPoint(centerLatLng);

            if (worldCoordinate) {
              const mapPixel = new google.maps.Point(
                Math.floor((overlayPixel.x - worldCoordinate.x) * scale + map.getDiv().offsetWidth / 2),
                Math.floor((overlayPixel.y - worldCoordinate.y) * scale + map.getDiv().offsetHeight / 2)
              );

              container.style.top = `${mapPixel.y - 25}px`;
              container.style.left = `${mapPixel.x}px`;

              setPosition({ top: mapPixel.y, left: mapPixel.x });
            }
          }
        }
      }
    };

    const handleIdle = () => {
      updatePosition();
    };

    map.addListener('idle', handleIdle);

    // Centra inicialmente el marcador
    updatePosition();

    return () => {
      container.remove();
      // animation.destroy();
      // google.maps.event.removeListener(handleIdle as any);
    };
  }, [animationData, map, overlayPosition]);

  return position ? (
    null
  ) : null;
};

export default LottieOverlay;
