type PaginatorProps = {
  currentPage: number
  totalPages?: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  handleFetchNextPage: () => void
  handleFetchPreviousPage: () => void
  isLoading: boolean
  handleFetchPage: (page: number) => void
}

export function Paginator({
  currentPage,
  totalPages,
  hasNextPage,
  hasPreviousPage,
  handleFetchNextPage,
  handleFetchPreviousPage,
  isLoading,
  handleFetchPage,
}: PaginatorProps) {
  const renderPageNumbers = () => {
    const pageNumbers = []
    const maxPageButtons = 7 // Adjust this to control the number of visible page buttons.

    if (totalPages) {
      if (totalPages <= maxPageButtons) {
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i)
        }
      } else {
        const leftOffset = Math.floor(maxPageButtons / 2)
        const rightOffset = totalPages - leftOffset

        if (currentPage <= leftOffset) {
          for (let i = 1; i <= maxPageButtons - 2; i++) {
            pageNumbers.push(i)
          }
          pageNumbers.push('...')
          pageNumbers.push(totalPages)
        } else if (currentPage >= rightOffset) {
          pageNumbers.push(1)
          pageNumbers.push('...')
          for (let i = totalPages - maxPageButtons + 3; i <= totalPages; i++) {
            pageNumbers.push(i)
          }
        } else {
          pageNumbers.push(1)
          pageNumbers.push('...')
          for (let i = currentPage - 1; i <= currentPage + 1; i++) {
            pageNumbers.push(i)
          }
          pageNumbers.push('...')
          pageNumbers.push(totalPages)
        }
      }
    } else {
      pageNumbers.push(1)
    }

    return pageNumbers.map((page, index) => (
      <div
        key={index}
        onClick={() => {
          if (!isLoading && page !== '...') {
            handleFetchPage(typeof page === 'number' ? page : currentPage)
          }
        }}
        className={`${
          currentPage === page
            ? 'tw-bg-brand tw-text-white'
            : 'tw-cursor-pointer tw-text-mediumGrey'
        } tw-mx-1 tw-flex tw-items-center tw-justify-center tw-rounded-full ${
          Number(page) < 100 ? 'tw-h-4 tw-w-4' : 'tw-h-6 tw-w-6'
        }`}
      >
        {page}
      </div>
    ))
  }

  return (
    <>
      {isLoading ? (
        <div className="tw-mx-1 tw-h-4 tw-w-4 tw-animate-pulse" />
      ) : (
        <div
          className={
            'tw-flex tw-w-full tw-items-center tw-justify-center tw-text-xs'
          }
        >
          <div
            onClick={() =>
              !isLoading && hasPreviousPage && handleFetchPreviousPage()
            }
            className={`${
              !isLoading && hasPreviousPage && 'tw-cursor-pointer'
            }`}
            style={{ width: 15, height: 15 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#B1B1B1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
          {renderPageNumbers()}
          <div
            onClick={() => !isLoading && hasNextPage && handleFetchNextPage()}
            className={`${!isLoading && hasNextPage && 'tw-cursor-pointer'}`}
            style={{ width: 15, height: 15 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#B1B1B1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
      )}
    </>
  )
}
