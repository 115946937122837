import React from 'react'
import Paragraphs from '../Paragraphs/Paragraphs'
import { useBtn } from './hooks/useBtn'
import { Container } from './styles'

interface ButtonProps {
  form?: string
  loading?: boolean
  type?: 'submit' | 'button' | 'link'
  size?: 'mini' | 'small' | 'medium' | 'large' | 'full'
  mode?: 'primary' | 'secondary' | 'transparent' | 'expansible' | 'custom'
  width?: string
  label: string
  shortcut?: string
  icon?: Node
  rounded?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  uppercase?: boolean
  backgroundColor?: string
  textColor?: string
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  styles?: any
  labelLeft?: boolean
  customParams?: any
  className?: string
}

const Button = ({
  form,
  loading = false,
  type = 'button',
  size = 'medium',
  mode = 'primary',
  width = '100%',
  label = '',
  shortcut = '',
  icon,
  rounded = false,
  onClick = () => null,
  disabled = false,
  uppercase = false,
  backgroundColor = '',
  textColor,
  iconLeft,
  iconRight,
  styles,
  labelLeft,
  customParams,
  className = '',
}: ButtonProps) => {
  const { classFromMode } = useBtn(mode, rounded, uppercase, customParams)

  return (
    <Container
      form={form}
      backgroundColor={backgroundColor}
      textColor={textColor}
      size={size}
      width={width}
      styles={{ ...styles }}
      className={`Button tw-bg-gray-100 tw-rounded-sm ${classFromMode(
        disabled
      )} ${className} ${loading ? 'tw-cursor-not-allowed tw-disabled' : ''}`}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
    >
      <div
        className={`tw-flex tw-w-full tw-items-center ${
          shortcut || iconRight ? 'tw-justify-between' : 'tw-justify-center'
        }`}
      >
        {iconLeft && <div className="tw-flex">{iconLeft}</div>}
        <div>
          <Paragraphs
            className={`label ${labelLeft && 'tw-leading-3 tw-text-left'}`}
            weight="regular"
            size="sm"
          >
            <span
              style={{
                letterSpacing: '0.05em',
                lineHeight: '1.5em',
              }}
            >
              {label}
            </span>
          </Paragraphs>
        </div>
        <div className="tw-ml-2 tw-opacity-70">
          <Paragraphs size="xxs">{shortcut}</Paragraphs>
        </div>
        {iconRight && <div className="tw-flex">{iconRight}</div>}
      </div>
    </Container>
  )
}

export default Button
