import { useRef } from 'react'
import { ICompanyItem } from '../../../../infrastructure/interfaces/settings'
import { IUserItem } from '../../../../infrastructure/interfaces/users'
import Avatar from '../../Avatar/Avatar'
import colors from '../../styles/colors'
import { HeaderWrapper } from '../styles'
import Paragraphs from '../../Paragraphs/Paragraphs'

interface IHeaderProps {
  user: IUserItem
  entity?: ICompanyItem
}

const Header = ({ user, entity }: IHeaderProps) => {
  const ref = useRef<any>(null)
  const getWidthFromRef = () => {
    if (ref && ref.current) {
      const width = ref.current.offsetWidth
      return width + 15
    }
    return 250
  }

  return (
    <HeaderWrapper
      className="header tw-sticky tw-top-0 tw-z-20 tw-flex tw-w-full tw-grid-cols-3 tw-justify-between tw-items-center tw-px-4"
      style={{ height: '48px' }}
      bgColor={entity?.primary_color || colors.header}
    >
      <div
        className="tw-flex tw-flex-1 tw-items-end tw-justify-start tw-pr-4"
        ref={ref}
      >
        <Paragraphs
          size="sm"
          color="white"
          weight="bold"
          className="tw-ml-[48px]"
        >
          {entity?.name}
        </Paragraphs>
      </div>
      <div
        className="tw-flex tw-flex-1 tw-items-end tw-justify-end tw-pr-4"
        ref={ref}
      >
        <Avatar
          bgColor={colors.header}
          user={user}
          getWidthFromRef={getWidthFromRef}
        />
      </div>
    </HeaderWrapper>
  )
}
export default Header
