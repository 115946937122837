import { useTranslation } from 'react-i18next'
import Paragraphs from '../../commons/Paragraphs/Paragraphs'
import microsoftIcon from '../../../infrastructure/assets/svg/microsoft.svg'

interface IMicrosoftBtnProps {
  onClick: () => void
}

const MicrosoftBtn = (props: IMicrosoftBtnProps) => {
  const { onClick } = props
  const { t } = useTranslation()
  return (
    <div style={{
      border: '2px solid #7d185c',
    }} className="tw-p-3 tw-w-300 tw-my-3 tw-border-2 tw-rounded-sm tw-cursor-pointer tw-flex tw-items-center tw-justify-center" onClick={onClick}>
      <div className="tw-flex tw-justify-start tw-items-center">
        <img src={microsoftIcon} alt="Microsoft Sign in" />

        <div className="tw-ml-2">
          <Paragraphs weight="bold" size="l">
            {t('login.Identifícate con Microsoft')}
          </Paragraphs>
        </div>
      </div>
    </div>
  )
}

export default MicrosoftBtn
