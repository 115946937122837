import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { navigationColumns } from '../../../infrastructure/constant/columns/navigationColumns'
import AgList from '../../../components/commons/AgGrid/AgList'
import { actionContextMenu } from '../../../infrastructure/utils/common'
import DeleteModal from '../../../components/commons/modals/DeleteModal/DeleteModal'
import useNavigationList from './hooks/useNavigationList'
import { useContext } from 'react'
import { SettingsContext } from '../context/SettingsContext'

const NavigationList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    gridApi,
    onGridReady,
    deleteModal,
    setDeleteModal,
    getContextMenu,
    handleDelete,
    loadedData,
    perPage,
    currentPage,
    totalPages,
    // setPerPage,
    // setCurrentPage,
    // setTotalPages,
  } = useNavigationList()
  const settingsContext = useContext(SettingsContext)

  if (!settingsContext) {
    throw new Error('SettingsContext must be used within a SettingsProvider')
  }

  const { setCurrentEntity } = settingsContext
  return (
    <>
      <AgList
        columns={navigationColumns}
        loading={loadedData}
        gridApi={gridApi}
        currentPage={currentPage}
        totalPages={totalPages}
        onGridReady={onGridReady}
        getContextMenu={getContextMenu}
        pagination={true}
        paginationPageSize={perPage}
        cacheBlockSize={perPage}
        onRowDoubleClicked={(row) => {
          setCurrentEntity(row?.data?.name)
          actionContextMenu(
            'EDIT',
            row,
            null,
            location.pathname + '/menu/' + row.data.id,
            navigate
          )
        }}
      />
      <DeleteModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        description={t(
          'messages.¿Está seguro que desea eliminar este registro?'
        )}
        onConfirm={handleDelete}
        title={t('titles.Eliminar informe')}
      />
    </>
  )
}

export default NavigationList
