import React from 'react';
import { ArrowLeft, ArrowRight, Elipse } from './styles';

interface ToggleButtonProps {
  isOpen: boolean;
  handleToggle: () => void;
  t: (key: string) => string;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ isOpen, handleToggle, t }) => (
  <Elipse onClick={handleToggle} isOpen={isOpen} title={isOpen ? t('actions.Cerrar menu') : t('actions.Abrir menu')}>
    {isOpen ? <ArrowRight /> : <ArrowLeft />}
  </Elipse>
);

export default ToggleButton;
