import React from 'react'
import Label from '../Label'
import { BoxFix, Error, Input, InputContent, Textarea } from './styles'
import Paragraphs from '../Paragraphs/Paragraphs'
import IcSearch from '../../icons/IcSearch'
import { useInput } from '../../../infrastructure/hooks/useInput'
import { useTranslation } from 'react-i18next'

interface TextInputProps {
  error?: any | undefined
  inputStyles?: object | undefined
  name: string
  description?: string | undefined
  type:
    | 'text'
    | 'password'
    | 'hidden'
    | 'date'
    | 'time'
    | 'phone'
    | 'number'
    | 'searchLeft'
    | 'searchRight'
    | 'search'
    | string
    | undefined

  weight?:
    | 'thin'
    | 'extralight'
    | 'light'
    | 'normal'
    | 'medium'
    | 'semibold'
    | 'bold'
    | 'extrabold'
    | 'black'
    | string
    | undefined

  size?: 'small' | 'large' | undefined
  label: string | undefined
  transparent?: boolean | undefined
  autoFocus?: boolean
  placeholder?: string | undefined
  required?: boolean | undefined
  register?: any | undefined
  disabled?: boolean | undefined
  value: any
  hideError?: boolean | undefined
  textAlign?: 'center' | 'left' | 'right' | undefined
  labelAlign?: 'center' | 'left' | 'right' | undefined
  mode?: 'success' | 'warning' | 'alert' | 'normal' | 'clickable' | undefined
  row?: boolean | undefined
  containerStyles?: string | undefined
  labelStyles?: string | undefined
  classNameInput?: string | undefined
  readOnly?: boolean | undefined
  onChange: (e: any) => void | undefined
  noMarginBottom?: boolean | undefined
  uppercase?: boolean | undefined
  backgroundColorInput?: string | undefined
  prefix?: string | undefined
  sufix?: string | undefined
  flex?: 'row' | 'column' | 'between' | undefined
  id?: number | string | undefined
  color?: string | undefined
  customClassName?: string | undefined
  reference: (e: any) => void | undefined
  rows?: number | undefined
  translate?: boolean | undefined
  truncate?: boolean | undefined
  searchIconsize?: number | undefined
  iconLeft?: React.ReactNode | undefined
  iconRight?: React.ReactNode | undefined
  iconRightPlain?: boolean | undefined
  width?: string | undefined
  colorIcSearch?: string | undefined
  children?: React.ReactNode | undefined
  labelSize?: string | undefined
}
const TextInput = (props: TextInputProps | any) => {
  const {
    rows,
    error,
    name,
    label,
    placeholder,
    type,
    autoFocus,
    disabled,
    required,
    hideError,
    textAlign = 'left',
    color,
    readOnly,
    description,
    uppercase = false,
    backgroundColorInput = 'transparent',
    prefix,
    sufix,
    customClassName,
    labelAlign,
    reference,
    mode,
    onChange,
    register,
    flex = 'row',
    id,
    value,
    translate,
    weight,
    width,
    searchIconsize,
    iconLeft,
    iconRight,
    iconRightPlain,
    truncate,
    colorIcSearch,
    size = 'large',
    children,
    labelSize,
  } = props
  const { t } = useTranslation()
  const { classSelection, setGenericClass, modeFlex } = useInput(
    disabled,
    readOnly,
    backgroundColorInput,
    flex
  )

  const isDisabled = mode === 'clickable' ? true : disabled
  return (
    <div className="TextInput">
      <div className={`${modeFlex()} tw-pb-3`}>
        <div
          className={` tw-m-0 tw-text-${labelAlign} ${
            flex && flex !== 'column' && 'tw-mr-2 tw-mt-auto tw-mb-auto'
          } ${flex && flex === 'column' && 'tw-pb-2'}`}
        >
          {label && (
            <Label
              label={label}
              required={required}
              truncate={truncate}
              size={labelSize}
            />
          )}
        </div>
        <div className={`tw-w-${width || 'full'} tw-relative`}>
          <InputContent
            error={error}
            className={`${classSelection(customClassName)} tw-w-full ${
              rows === null ? 'tw-h-textinput' : 'tw-h-auto'
            } tw-border`}
            backgroundColor={backgroundColorInput}
          >
            {type === 'searchLeft' && (
              <div className="tw-h-full tw-flex tw-items-center tw-justify-center tw-pl-2">
                <IcSearch size={searchIconsize} color={colorIcSearch ?? ''} />
              </div>
            )}
            {prefix && (
              <BoxFix className="tw-flex tw-items-center tw-justify-center tw-px-2">
                <Label label={prefix} translate={translate} />
              </BoxFix>
            )}
            {iconLeft && (
              <div className="tw-flex tw-items-center tw-justify-center tw-px-2">
                {iconLeft}
              </div>
            )}
            {rows === null ? (
              <Input
                autocomplete={type === 'search' ? 'off' : type}
                id={id}
                color={color}
                autoSave="off"
                autoFocus={autoFocus}
                name={name}
                type={type === 'search' ? 'text' : type}
                autoComplete={'off'}
                ref={reference}
                placeholder={placeholder}
                className={`tw-outline-none tw-h-full tw-flex-1 tw-px-2 tw-bg-transparent tw-w-${
                  width || 'full'
                } ${setGenericClass({ uppercase, textAlign, mode, weight })} ${
                  size === 'large'
                    ? 'tw-text-large'
                    : `${
                        size === 'small' ? 'tw-text-small' : `tw-text-${size}`
                      }`
                }`}
                disabled={isDisabled}
                readOnly={readOnly}
                onChange={onChange}
                {...register}
                value={value}
              />
            ) : (
              <Textarea
                id={id}
                color={color}
                autoSave="off"
                autoFocus={autoFocus}
                name={name}
                // rows={rows}
                autoComplete={'off'}
                ref={reference}
                placeholder={placeholder}
                className={`tw-outline-none tw-h-full tw-w-${
                  width || 'full'
                } tw-flex-1 tw-px-2 tw-bg-transparent ${
                  disabled ? 'tw-text-gray-700' : ''
                } ${setGenericClass({
                  uppercase,
                  textAlign,
                  mode,
                })} ${rows === null ? 'tw-h-textinput' : 'tw-h-auto'}`}
                disabled={disabled}
                readOnly={readOnly}
                onChange={onChange}
                {...register}
                value={value}
              />
            )}
            {iconRight && !iconRightPlain ? (
              <div className="tw-flex tw-items-center tw-justify-center tw-px-2 tw-absolute tw-right-1 tw-top-2">
                {iconRight}
              </div>
            ) : (
              iconRight
            )}
            {sufix && (
              <BoxFix className="tw-flex tw-items-center tw-justify-center tw-px-2">
                <Label label={sufix} translate={translate} />
              </BoxFix>
            )}
            {type === 'searchRight' && (
              <div className="tw-h-full tw-flex tw-items-center tw-justify-center tw-px-2">
                <IcSearch size={searchIconsize} color={colorIcSearch ?? ''} />
              </div>
            )}
            {children}
          </InputContent>
          {error ? (
            <Error
              className={`tw-text-red-600 tw-font-Atkinson-bold tw-absolute ${
                hideError && 'tw-hidden'
              }`}
            >
              {error && t(`errors.${error}`, { field: '' })}
            </Error>
          ) : (
            description && (
              <Paragraphs
                italic
                size="xxs"
                className="tw-text-gray-700 tw-absolute"
              >
                {description}
              </Paragraphs>
            )
          )}
        </div>
      </div>
    </div>
  )
}

TextInput.defaultProps = {
  autoFocus: false,
  uppercase: false,
  label: '',
  backgroundColorInput: null,
  placeholder: '',
  type: 'text',
  description: null,
  register: {},
  required: false,
  disabled: false,
  textAlign: 'left',
  labelAlign: 'left',
  hideError: false,
  error: '',
  mode: 'normal',
  onChange: null,
  readOnly: false,
  flex: 'column',
  id: 'textinput',
  name: 'text-input',
  weight: 'normal',
  rows: null,
  translate: true,
  truncate: false,
  searchIconsize: 30,
  colorIcSearch: '#9a9a9a',
  size: 'small',
}

export default TextInput
