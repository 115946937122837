import { useSelector } from 'react-redux'
import { RootState } from '../../infrastructure/store'
import MenuCard from './MenuCard'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSelectedMenu } from '../../infrastructure/store/sidebar/sidebarSlice'
import { IMenuItem } from '../../infrastructure/interfaces/sidebar'

const MenuList: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { menu } = useSelector((state: RootState) => state.sidebar)

  const selectMenu = (link: IMenuItem, parent?: IMenuItem) => {
    if (link.type === 'folder' && link.submenu.length === 0) return

    if (link.page) {
      if (!link.page.has_widgets && link.submenu.length) {
        let firstSubmenu = link.submenu[0]
        dispatch(setSelectedMenu(firstSubmenu))
        if (firstSubmenu.page) {
          return navigate('/pages/' + firstSubmenu.page.slug)
        }
        if (firstSubmenu.report) {
          return navigate('/reports/' + firstSubmenu.report.slug)
        }
      }
      dispatch(
        setSelectedMenu({ ...link, ...(parent ? { parentId: parent.id } : {}) })
      )
      return navigate('/pages/' + link.page.slug)
    } else if (link.report) {
      dispatch(
        setSelectedMenu({ ...link, ...(parent ? { parentId: parent.id } : {}) })
      )
      return navigate('/reports/' + link.report.slug)
    } else {
      navigate('/home')
      return dispatch(setSelectedMenu(link))
    }
  }

  return (
    <div className="relative tw-h-full tw-w-full">
      <span className="tw-text-2xl">
        Business Intelligence - <strong>fedefarma</strong>{' '}
      </span>
      <div className="tw-flex tw-flex-wrap tw-flex-row tw-gap-3 tw-mt-4 tw-w-full">
        {/* MenuCard component */}
        {menu?.map((item, index) => (
          <MenuCard
            key={`menu-dashboard-${index}`}
            parent={item}
            items={item.submenu}
            selectMenu={selectMenu}
          />
        ))}
      </div>
    </div>
  )
}

export default MenuList
