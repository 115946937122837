module.exports = {
  gray: { 100: '#E8F4F5' },
  coins: {
    euro: '#E2E2E2',
    cent: '#F5D016',
    cent2: '#C66E1D',
  },
  green: {
    200: '#f6e8f1',
    300: '#d38ebc',
    600: '#22949B',
    800: '#961b6d',
    900: '#7D185C',
  },
  red: {
    200: '#FECACA',
    300: '#FCA5A5',
    600: '#DC2626',
    800: '#7F1D1D',
    900: '#7F1D1D',
  },
  blue: {
    200: '#BFDBFE',
    300: '#93C5FD',
    600: '#2563EB',
    800: '#1E3A8A',
    900: '#1E3A8A',
  },
  purple: {
    900: '#6A51C7',
    800: '#6A51C7',
  },
  alert: '#D8004D',
  primary: '#AF1685',
  secondary: '#751E52',
  tertiary: '#333333',
  quaternary: '#F7E8F3',
  quinary: '#FFFFFF',
  header: 'rgba(54,55,64,1)',
  headerMedium: 'rgba(160,15,75,1)',
  // searchInput: '#f6e8f1',
  // activeMenu: '#530f3d',
  // titleFolders: '#d38ebc',
  success: '#3DBF42',
  warning: '#FFB500',
  // footer: '#F1F8F9',
}
