import { FC, ReactNode, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../PrimaryButton/PrimaryButton'
import { TertiaryButton } from '../../TertiaryButton/TertiaryButton'
import { NewComponentBottom } from './styles'

interface Props {
  children: ReactNode
  onSave?: () => void
  loading?: boolean
}

const StickySettingsBtnBottom: FC<Props> = ({ children, onSave, loading }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [replace, setReplace] = useState<boolean | string>(false)

  const goBack = () => {
    if (location.pathname.includes('edit')) {
      return navigate(-1)
    } else {
      const path = location.pathname.split('/')
      path.pop()
      setReplace(path.join('/'))
    }
  }

  if (typeof replace === 'boolean') {
    return (
      <div className="new-layout tw-w-full tw-h-full">
        {children}
        <NewComponentBottom>
          <div className="tw-w-[145px] tw-mr-2">
            <TertiaryButton text="Atrás" type="button" onClick={goBack} disabled={loading} />
          </div>
          <div className="tw-w-[145px]">
            <PrimaryButton text="Guardar" type="button" onClick={onSave} disabled={loading} />
          </div>
        </NewComponentBottom>
      </div>
    )
  } else {
    return <Navigate to={replace} />
  }
}

export default StickySettingsBtnBottom
