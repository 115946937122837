import { IRoutesData } from "../../infrastructure/interfaces/routes";

export const mockData: IRoutesData = {
  250033: {
    name: '250033 - ruta1',
    stops: [
      {
        partnerCode: 'C800122',
        partnerName: 'TRIVES PRADA ENRIQUE',
        city: 'ALICANTE',
        order: 25,
        prepared: '0h 19m',
        created: '03:36 PM',
        departure: '03:36 PM',
        arrival: '06:03 PM',
        duration: '02:27 AM',
        stops: 5,
        buckets: 1,
        units: 2,
        location: {
          lat: 38.3604259,
          lng: -0.4801433,
        },
      },
      {
        partnerCode: 'C800126',
        partnerName: 'DEL RIO AYALA MARIA ELENA',
        city: 'ALICANTE',
        order: 25,
        prepared: '0h 21m',
        created: '03:38 PM',
        departure: '03:38 PM',
        arrival: '05:39 PM',
        duration: '02:01 AM',
        stops: 10,
        buckets: 1,
        units: 4,
        location: {
          lat: 38.3621664,
          lng: -0.4894395,
        },
      },
      {
        partnerCode: 'C800151',
        partnerName: 'DEL POZO SANCHEZ FRANCISCO',
        city: 'ALICANTE',
        order: 25,
        prepared: '0h 21m',
        created: '03:36 PM',
        departure: '03:36 PM',
        arrival: '05:16 PM',
        duration: '01:40 AM',
        stops: 6,
        buckets: 1,
        units: 8,
        location: {
          lat: 38.3660965,
          lng: -0.4834888,
        },
      },
      {
        partnerCode: 'S00074',
        partnerName: 'ORTIZ ALEMANY FRANCISCO',
        city: 'MUTXAMEL',
        order: 15,
        prepared: '0h 19m',
        created: '03:35 PM',
        departure: '03:35 PM',
        arrival: '04:27 PM',
        duration: '12:51 AM',
        stops: 14,
        buckets: 1,
        units: 20,
        location: {
          lat: 38.4116339,
          lng: -0.4451402,
        },
      },
      {
        partnerCode: 'S01384',
        partnerName: 'MOYANO CHICOY MARIA DOLORES',
        city: 'ALICANTE',
        order: 100,
        prepared: '0h 19m',
        created: '03:35 PM',
        departure: '03:35 PM',
        arrival: '05:41 PM',
        duration: '02:06 AM',
        stops: 14,
        buckets: 4,
        units: 128,
        location: {
          lat: 38.3616642,
          lng: -0.4924948,
        },
      },
    ],
    data: {
      totalStops: 49,
      fill: 86.61,
      totalBuckets: 8,
      totalUnits: 162,
      departure: '15:30h',
      realTimeDuration: '3:50h',
      realTimeEstimatedDuration: '2:57h',
      kmBBDD: 42,
      kmGoogle: 42
    },
    startLocation: {
      lat: 38.35912,
      lng: -0.47931
    },
    edited: false
  },
  250263: {
    name: '250263 - ruta2',
    stops: [
      {
        partnerCode: 'B02176-99',
        partnerName: 'CRESPO ANDRES MARIA ASUNCION',
        city: 'TORREVIEJA',
        order: 35,
        prepared: '0h 17m',
        created: '02:46 PM',
        departure: '02:46 PM',
        arrival: '05:36 PM',
        duration: '02:50 AM',
        stops: 11,
        buckets: 2,
        units: 72,
        location: {
          lat: 37.94925,
          lng: -0.71576
        }
      },
      {
        partnerCode: 'C800161',
        partnerName: 'MUÑOZ MARTINEZ IGNACIO',
        city: 'TORREVIEJA',
        order: 35,
        prepared: '0h 16m',
        created: '02:53 PM',
        departure: '02:53 PM',
        arrival: '05:36 PM',
        duration: '02:42 AM',
        stops: 2,
        buckets: 1,
        units: 10,
        location: {
          lat: 37.9836735,
          lng: -0.6618373
        }
      },
      {
        partnerCode: 'C800165',
        partnerName: 'MARTINEZ PACHECO ALBORADA',
        city: 'BENEJUZAR',
        order: 35,
        prepared: '0h 14m',
        created: '02:46 PM',
        departure: '02:46 PM',
        arrival: '04:52 PM',
        duration: '02:06 AM',
        stops: 2,
        buckets: 1,
        units: 6,
        location: {
          lat: 38.0779303,
          lng: -0.841341
        }
      }],
    data: {
      totalStops: 49,
      fill: 30.61,
      totalBuckets: 8,
      totalUnits: 162,
      departure: '15:30h',
      realTimeDuration: '3h 50min',
      realTimeEstimatedDuration: '2h 57min',
      kmBBDD: 42,
      kmGoogle: 42
    },
    startLocation: {
      lat: 37.94954,
      lng: -0.71797
    },
    edited: false
  },
  250163: {
    name: '250163 - ruta3',
    stops: [
      {
        partnerCode: 'C800123',
        partnerName: 'GOMEZ PINA JESUS',
        city: 'ELCHE',
        order: 35,
        prepared: '0h 14m',
        created: '03:02 PM',
        departure: '03:02 PM',
        arrival: '04:30 PM',
        duration: '01:28 AM',
        stops: 10,
        buckets: 1,
        units: 6,
        location: {
          lat: 38.2614296,
          lng: -0.7121145
        }
      },
      {
        partnerCode: 'C800133',
        partnerName: 'FARMACIA QUESADA-ALCARAZ CB',
        city: 'ELCHE',
        order: 35,
        prepared: '0h 17m',
        created: '03:10 PM',
        departure: '03:10 PM',
        arrival: '04:48 PM',
        duration: '01:38 AM',
        stops: 9,
        buckets: 1,
        units: 3,
        location: {
          lat: 38.2737064,
          lng: -0.713719
        }
      },
      {
        partnerCode: 'C800136',
        partnerName: 'RUIZ MARTINEZ FRANCISCO',
        city: 'ELCHE',
        order: 35,
        prepared: '0h 21m',
        created: '03:07 PM',
        departure: '03:07 PM',
        arrival: '04:27 PM',
        duration: '01:20 AM',
        stops: 4,
        buckets: 1,
        units: 4,
        location: {
          lat: 38.262954,
          lng: -0.7044783
        }
      }
    ],
    data: {
      totalStops: 49,
      fill: 30.61,
      totalBuckets: 8,
      totalUnits: 162,
      departure: '15:30h',
      realTimeDuration: '3h 50min',
      realTimeEstimatedDuration: '2h 57min',
      kmBBDD: 42,
      kmGoogle: 42
    },
    startLocation: {
      lat: 38.26154,
      lng: -0.71534
    },
    edited: false
  }
};