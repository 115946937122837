import { CustomMarker, ProcessDirections, RouteData, Waypoint } from "../../../infrastructure/interfaces/routes";
import markerImage from '../../../infrastructure/assets/images/icons/marker.png';

export const createDirectionsRenderer = async (
  route: RouteData,
  waypoints: Waypoint[],
  map: google.maps.Map,
  directionsService: google.maps.DirectionsService,
  newDirections: google.maps.DirectionsRenderer[],
  newMarkers: CustomMarker[],
  routeIndex: number,
  type: string
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    directionsService.route(
      {
        origin: waypoints.shift()!.location,
        destination: route.destinations[route.destinations.length - 1].location,
        waypoints,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
        region: 'ES',
        language: 'es-ES',
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          const { borderPolyline, routePolyline, lottieMarker } = processDirectionsResult(
            result,
            route,
            map,
            type
          );

          const directionsRenderer = createDirectionsRendererInstance(result, map);
          newDirections.push(directionsRenderer);

          const originMarker = createOriginMarker(route, map);
          newMarkers.push({ routeIndex, circle: originMarker });

          if (type === 'create') {
            createLottieMarker(route.destinations[0]?.location, map);
          }

          const createIconPromise = waypoints.map((leg, legIndex) => {
            return createIconWithMarkerPromise(
              leg.location,
              legIndex,
              route.destinations[legIndex]?.partner,
              route.color,
              map,
              borderPolyline,
              routePolyline,
              lottieMarker,
              newMarkers,
              routeIndex
            );
          });

          Promise.all(createIconPromise).then(() => {
            resolve();
          });
        } else {
          console.error(`Error getting directions for route ${routeIndex + 1}:`, status);
          reject();
        }
      }
    );
  });
}
export const processDirectionsResult = (
  result: google.maps.DirectionsResult,
  route: RouteData,
  map: google.maps.Map,
  type: string
): ProcessDirections => {
  let borderPolyline: google.maps.Polyline | null = null;
  let routePolyline: google.maps.Polyline | null = null;
  let lottieMarker: google.maps.Marker | null = null;

  if (result?.routes[0]?.overview_polyline?.toString()) {
    const polyline = result.routes[0].overview_polyline.toString();
    const decodedPath = google.maps.geometry.encoding.decodePath(polyline);

    borderPolyline = createPolyline(decodedPath, 'black', 8, map);
    routePolyline = createPolyline(decodedPath, route.color, 5, map);

    lottieMarker = type === 'create' ? createLottieMarker(route.destinations[0]?.location, map) : null;
  } else {
    console.error('No polyline string found in the route.');
  }

  return { borderPolyline, routePolyline, lottieMarker };
}

export const createPolyline = (path: google.maps.LatLng[], color: string, weight: number, map: google.maps.Map): google.maps.Polyline => {
  return new google.maps.Polyline({
    path,
    strokeColor: color,
    strokeWeight: weight,
    map
  });
}

export const createDirectionsRendererInstance = (
  result: google.maps.DirectionsResult,
  map: google.maps.Map,
): google.maps.DirectionsRenderer => {
  return new google.maps.DirectionsRenderer({
    map,
    directions: result,
    suppressMarkers: true,
    polylineOptions: {
      strokeOpacity: 0
    },
  });
};


export const createOriginMarker = (route: RouteData, map: google.maps.Map): google.maps.Marker => {
  return new google.maps.Marker({
    position: route.origin,
    map,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: '#ffffff',
      fillOpacity: 1,
      strokeColor: route.color,
      strokeWeight: 3,
      strokeOpacity: 0.8,
      scale: 7,
    },
  });
}

export const createLottieMarker = (location: google.maps.LatLngLiteral, map: google.maps.Map): google.maps.Marker => {
  return new google.maps.Marker({
    position: location,
    map,
    icon: {
      url: 'URL_DEL_MARCADOR_DE_LOTTIE',
      scaledSize: new google.maps.Size(40, 40),
    },
  });
}

export const createIconWithMarkerPromise = async (
  location: google.maps.LatLng,
  legIndex: number,
  partner: string | undefined,
  color: string,
  map: google.maps.Map,
  borderPolyline: google.maps.Polyline | null,
  routePolyline: google.maps.Polyline | null,
  lottieMarker: google.maps.Marker | null,
  newMarkers: CustomMarker[],
  routeIndex: number
): Promise<void> => {
  return new Promise<void>((resolveIcon) => {
    const marker = createIconMarker(location, '#ffffff', '#000000', 7, map);
    const middlePointMarker = createIconMarker(location, '#000000', '#ffffff', 3, map);

    createIconWithMarker(location, (legIndex + 1).toString() ?? '-', partner ?? '', color, map).then((icon: google.maps.Marker) => {
      newMarkers.push({
        routeIndex,
        circle: marker,
        circleSmall: middlePointMarker,
        iconMarker: icon,
        borderPolyline,
        routePolyline,
        lottieMarker,
      });
      resolveIcon();
    });
  });
}

export const createIconMarker = (
  position: google.maps.LatLng,
  fillColor: string,
  strokeColor: string,
  scale: number,
  map: google.maps.Map
): google.maps.Marker => {
  return new google.maps.Marker({
    position,
    map,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor,
      fillOpacity: 1,
      strokeColor,
      strokeWeight: 1,
      strokeOpacity: 1,
      scale,
    },
  });
}

export const createCanvas = (width: number, height: number) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
};

export const drawMarker = (img: HTMLImageElement, color: string, canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, text: string) => {
  context.drawImage(img, 0.5, 0, canvas.width, canvas.height);
  // apply marker color of the image
  context.globalCompositeOperation = 'source-in';
  context.fillStyle = color;
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.globalCompositeOperation = 'source-over';

  // draw the white circle in the center of the canvas
  context.beginPath();
  context.arc(20.5, 13, 10, 0, 2 * Math.PI);
  context.fillStyle = '#ffffff';
  context.fill();

  // draw the text in the center of the circle
  context.font = '14px "Atkinson Hyperlegible", sans-serif'; // Usar la fuente Atkinson Hyperlegible
  context.fillStyle = '#000000';
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.fillText(text, 20.5, 14, canvas.height); // Mover el texto un píxel a la derecha
};

export const createIcon = (text: string, color: string, callback: (iconUrl: string) => void) => {
  const canvas = createCanvas(40, 40);
  const context = canvas.getContext('2d');
  if (!context) return;

  const img = new Image();
  img.src = markerImage;
  img.onload = () => {
    drawMarker(img, color, canvas, context, text);

    // create a custom icon with the canvas URL
    const iconUrl = canvas.toDataURL();
    callback(iconUrl);
  };
};

export const createInfoBox = (text: string) => {
  const infoWindow = new google.maps.InfoWindow({
    content: `<span style="display: flex; justify-content: center; align-items: center;">${text}</span>`, // Centra el texto
    pixelOffset: new google.maps.Size(0, -10),
    minWidth: 100,
  });
  return infoWindow;
};


export const createIconWithMarker = (
  location: google.maps.LatLng,
  smalltext: string,
  text: string,
  color: string,
  map: google.maps.Map
): Promise<google.maps.Marker> => {
  return new Promise((resolve) => {
    createIcon(smalltext, color, (iconUrl) => {
      // create a marker with the custom icon and text
      const marker = new google.maps.Marker({
        position: location,
        map,
        icon: {
          url: iconUrl,
          scaledSize: new google.maps.Size(40, 40),
        },
      });
      const infoWindow = createInfoBox(text);

      // open the InfoWindow when the marker is clicked
      google.maps.event.addListener(marker, 'mouseover', () => {
        infoWindow.open(map, marker);
      });

      // close the InfoWindow when the mouse leaves the marker
      google.maps.event.addListener(marker, 'mouseout', () => {
        infoWindow.close();
      });

      // resolve promise with marker
      resolve(marker);
    });
  });
};

