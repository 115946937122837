import { AxiosResponse } from 'axios'
import { api } from './api'

interface LoginInterface {
  email: string
  password: string
  remember_me?: boolean
}

const login = async (login: LoginInterface): Promise<AxiosResponse> => {
  return await api.post('/login', login)
}

const forgotPassword = async (email: string): Promise<AxiosResponse> => {
  return await api.post('/forgot-password', { email })
}

const resetPassword = async (
  token: string,
  password: any
): Promise<AxiosResponse> => {
  return await api.post('/reset-password', {
    token,
    password,
  })
}

const logout = (): void => {
  api
    .post('/logout')
    .then(() => {
      localStorage.removeItem('powerBiFilter')
      localStorage.clear()
    })
    .then(() => {
      if (window.location.pathname !== '/login') {
        window.location.href = '/login'
      }
    })
}

const getProfile = async (): Promise<AxiosResponse> => {
  return await api.get('/me')
}

// const checkTokenExpiration = (): void => {
//   let expiresAt = localStorage.getItem('expires_at')

//   if (!expiresAt) {
//     return logout()
//   }
//   if (new Date().getTime() < parseInt(expiresAt)) {
//     return
//   } else {
//     localStorage.removeItem('token')
//     localStorage.removeItem('expires_at')
//     return logout()
//   }
// }

const checkTokenExpiration = (token: string, expiresAt: string): void => {
  if (!token) {
    return logout()
  }
  const currentTime = new Date().getTime();
  const expiresTime = new Date(expiresAt).getTime();
  if (currentTime < expiresTime) {
    return
  } else {
    localStorage.removeItem('token')
    localStorage.removeItem('expires_at')
    return logout()
  }
}

export const authService = {
  login,
  forgotPassword,
  resetPassword,
  logout,
  getProfile,
  checkTokenExpiration,
}
