import { Transition } from '@headlessui/react';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { mockData } from '../../../modules/RoutePlanner/mocks';
import SearchIcon from '@material-ui/icons/Search';
import './datepicker.css';
import { icons } from 'eva-icons';
interface CodeFilterProps {
  selectedCodes: string[];
  setSelectedCodes: (codes: string[]) => void;
}

const CodeFilter: React.FC<CodeFilterProps> = ({ selectedCodes, setSelectedCodes }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const addTag = (name: string) => {
    if (selectedCodes.length < 4) {
      setSelectedCodes([...selectedCodes, name]);
      setSearchTerm('');
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setSearchTerm('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredSuggestions = suggestions.filter((suggestion: string) =>
    suggestion.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const transformedSuggestions = Object.keys(mockData).map((code: any) => mockData[code].name);
    setSuggestions(transformedSuggestions);
  }, []);

  return (
    <div className='tw-flex xl:tw-items-end xl:tw-justify-end tw-space-x-2 xl:tw-px-2 tw-py-4 xl:tw-py-0 tw-w-full' ref={containerRef}>

      <div className="tw-relative tw-flex tw-items-start">
        <label htmlFor="search" className='tw-absolute -tw-top-4 tw-left-1'>
          Código de ruta
        </label>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="tw-border tw-border-gray-300 tw-rounded-md tw-p-2 focus:tw-outline-none tw-w-36 tw-h-8 tw-mt-2"
        />
        <Transition
          as="div"
          show={searchTerm.length > 0 && filteredSuggestions.length > 0}
          enter="tw-transition tw-ease-out tw-duration-100"
          enterFrom="tw-transform tw-opacity-0 tw-scale-95"
          enterTo="tw-transform tw-opacity-100 tw-scale-100"
          leave="tw-transition tw-ease-in tw-duration-75"
          leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
          leaveTo="tw-transform tw-opacity-0 tw-scale-95"
          ref={dropdownRef}
          className="tw-absolute tw-z-10 tw-top-11 tw-bg-white tw-border tw-border-gray-300 tw-border-opacity-50 tw-rounded-md tw-max-h-40 tw-overflow-y-auto"
          style={{ width: `720px` }}
        >
          <div className="tw-py-1">
            {filteredSuggestions.map((suggestion) => (
              <div
                key={suggestion}
                onClick={() => addTag(suggestion)}
                className="tw-flex tw-justify-start tw-px-2 tw-py-2 tw-cursor-pointer tw-bg-transparent hover:tw-bg-gray-50 tw-text-black"
              >
                <SearchIcon className='tw-w-4 tw-h-4 tw-mr-2 tw-text-gray-300' />
                <span>{suggestion}</span>
              </div>
            ))}
          </div>
        </Transition>
      </div>

      <div className="tw-relative tw-flex tw-items-start">
        <label htmlFor="search" className='tw-absolute -tw-top-4 tw-left-1'>
          Fecha
        </label>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MM/yy"
          placeholderText="mes/año"
          className="tw-border tw-border-gray-300 tw-rounded-md tw-p-2 focus:tw-outline-none tw-w-36 tw-h-8 tw-mt-2"
          showMonthYearPicker
        />
        <span
          className="tw-absolute tw-top-4 tw-right-2 tw-fill-[#76AFAF]"
          dangerouslySetInnerHTML={{
            __html: icons['calendar-outline']?.toSvg({ width: 16, height: 18 }) || '',
          }}
        />
      </div>

      <div className="tw-relative tw-flex tw-items-start">
        <label htmlFor="route" className='tw-absolute -tw-top-4 tw-left-1'>
          Ruta
        </label>
        <select
          id="route"
          onChange={(e) => { }}
          className="tw-border tw-border-gray-300 tw-rounded-md tw-px-2 focus:tw-outline-none tw-w-36 tw-h-8 tw-mt-2"
        >
          <option value="" disabled selected>
            --
          </option>
          <option value="ruta1">R1</option>
          <option value="ruta2">R2</option>
          <option value="ruta3">R3</option>
          <option value="ruta4">R4</option>
        </select>
      </div>

      <div className="tw-relative tw-flex tw-items-start">
        <label htmlFor="codePharmacy" className='tw-absolute -tw-top-4 tw-left-0'>
          Código de farmacia
        </label>
        <input
          id='codePharmacy'
          type="text"
          className="tw-border tw-border-gray-300 tw-rounded-md tw-p-2 focus:tw-outline-none tw-w-36 tw-h-8 tw-mt-2"
        />
      </div>

      <div className="tw-relative tw-flex tw-items-start">
        <label htmlFor="routeType" className='tw-absolute -tw-top-4 tw-left-1'>
          Tipo de ruta
        </label>
        <select
          id="routeType"
          className="tw-border tw-border-gray-300 tw-rounded-md tw-px-2 focus:tw-outline-none tw-w-36 tw-h-8 tw-mt-2"
        >
          <option value="" disabled selected>
            --
          </option>
          <option value="daily">Diario</option>
          <option value="saturday">Sábados</option>
          <option value="notwork">Festivos</option>
        </select>
      </div>
    </div>
  );
};


export default CodeFilter;