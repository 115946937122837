import { Libraries, LoadScript } from '@react-google-maps/api';
import { useState } from 'react';
import { IRoutes, RouteData } from '../../../infrastructure/interfaces/routes';
import GoogleMaps from './GoogleMaps';
import Paragraphs from '../../commons/Paragraphs/Paragraphs';
import { useTranslation } from 'react-i18next';

const libraries: Libraries = ['places', 'geometry'];
interface IProps {
  data: IRoutes[];
  routesData: RouteData[];
  routesOriginData: RouteData[];
  showBtnRecalcMap: boolean;
  setShowBtnRecalcMap: (show: boolean) => void;
}
const LoadGoogleMaps = (props: IProps) => {
  const { data, routesData, routesOriginData, showBtnRecalcMap, setShowBtnRecalcMap } = props
  const [load, setLoad] = useState<boolean>(false)
  const [showOriginalMap, setShowOriginalMap] = useState<boolean>(false);
  const { t } = useTranslation()


  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string ?? ''}
      libraries={libraries}
      onLoad={() => setLoad(true)}
    >
      {routesData.length > 0 && load ?
        <div className='tw-w-full'>
          <div className='tw-h-full tw-w-full'>
            <div className={`${showOriginalMap ? 'tw-h-1/2' : 'tw-h-full'} tw-w-full tw-space-y-1`}>
              {showOriginalMap &&
                <GoogleMaps
                  data={data}
                  routesData={routesOriginData}
                  showBtnLeft={false}
                  type='origin'
                  load={load}
                  showOriginalMap={showOriginalMap}
                  setShowBtnLeft={() => { }}
                  setShowOriginalMap={() => { }}
                />}
              <GoogleMaps
                data={data}
                routesData={routesData}
                showBtnLeft={showBtnRecalcMap}
                type='simulator'
                load={load}
                showOriginalMap={showOriginalMap}
                setShowBtnLeft={setShowBtnRecalcMap}
                setShowOriginalMap={setShowOriginalMap}
              />
            </div>
          </div>
        </div>
        : <div className='tw-flex tw-items-center tw-justify-center tw-bg-white'>
          <div className='tw-text-center'>
            <Paragraphs size="2xl" weight="bold">
              {t('Sin datos')}
            </Paragraphs>
            <Paragraphs size="lg" weight="regular">
              {t('Utiliza el buscador en la parte superior de la pantalla para encontrar la ruta que buscas. Puedes añadir hasta un máximo de 3 rutas por vista.')}
            </Paragraphs>
          </div>
        </div>}
    </LoadScript>
  )
}

export default LoadGoogleMaps