import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core'

export default forwardRef((props: IFilterParams, ref) => {
  const [filterText, setFilterText] = useState<string | undefined>(undefined)

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params: IDoesFilterPassParams) {
        const { api, colDef, column, columnApi, context } = props
        const { node } = params

        // make sure each word passes separately, ie search for firstname, lastname
        let passed = true
        if (filterText) {
          filterText
            .toLowerCase()
            .split(' ')
            .forEach((filterWord) => {
              const value = props.valueGetter(node)

              if (value.toString().toLowerCase().indexOf(filterWord) < 0) {
                passed = false
              }
            })
        }

        return passed
      },

      isFilterActive() {
        return filterText != null && filterText !== ''
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null
        }

        return { value: filterText }
      },

      setModel(model: any) {
        setFilterText(model == null ? null : model.value)
      },
    }
  })

  const onChange = (event: any) => {
    setFilterText(event.target.value)
  }

  useEffect(() => {
    props.filterChangedCallback()
  }, [filterText])

  return (
    <div style={{ padding: 4, width: 200 }}>
      <div style={{ fontWeight: 'bold' }}>Custom Athlete Filter</div>
      <div>
        <input
          style={{ margin: '4 0 4 0' }}
          type="text"
          value={filterText}
          onChange={onChange}
          placeholder="Full name search..."
        />
      </div>
    </div>
  )
})
