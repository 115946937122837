import { ICellRendererParams, ValueGetterParams } from 'ag-grid-community'

export const userColumns = [
  {
    field: 'name',
    headerName: 'Nombre',
    filter: 'agTextColumnFilter',
    sortable: true,
    width: 250,
    maxWidth: 250,
  },
  {
    field: 'lastname',
    headerName: 'Apellidos',
    filter: 'agTextColumnFilter',
    sortable: true,
    width: 250,
    maxWidth: 250,
  },
  {
    field: 'email',
    headerName: 'Correo electrónico',
    filter: 'agTextColumnFilter',
    sortable: true,
    flex: 3,
    maxWidth: 250,
  },
  {
    colId: 'companies',
    field: 'companies',
    headerName: 'Entidad',
    valueFormatter: (params: ValueGetterParams) => {
      const { data } = params
      return data && data.companies
        ? data.companies.map((company: any) => company.name).join(', ')
        : ''
    },
    filter: 'agTextColumnFilter',
    floatingFilterComponent: 'customCompaniesFilter',
    sortable: false,
    width: 250,
    maxWidth: 250,
  },
  {
    field: 'tags',
    colId: 'tags',
    headerName: 'Tags',
    filter: 'agTextColumnFilter',
    cellRenderer: 'customTags',
    valueFormatter: (params: ValueGetterParams) => {
      const { data } = params
      return data && data.tags
        ? data.tags.map((tag: any) => tag.name).join(', ')
        : ''
    },
    floatingFilterComponent: 'customTagFilter',
    sortable: false,
    width: 250,
    maxWidth: 250,
  },
  {
    field: 'roles',
    headerName: 'Rol',
    sortable: true,
    filter: 'agTextColumnFilter',
    cellRenderer: (params: ICellRendererParams) => {
      const { data } = params
      return data && data.role ? data.role.name || '' : ''
    },
    floatingFilterComponent: 'customSelectFilter',
    width: 250,
    maxWidth: 250,
  },
  {
    field: 'permissions',
    headerName: 'Permisos',
    filter: 'agTextColumnFilter',
    sortable: false,
    cellRenderer: 'customTags',
    floatingFilterComponent: 'customSelectFilter',
    width: 250,
    maxWidth: 250,
  },
]
