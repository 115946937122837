import { GoogleMap } from '@react-google-maps/api';
import { memo, useEffect, useState } from 'react';
import { CustomMarker, Destination, IRoutes, LatLng, ProcessDirections, RouteData, Waypoint } from '../../../infrastructure/interfaces/routes';
import MapButtons from './MapButtons';
import { createDirectionsRenderer } from '../utils/MapUtils';
import ubicacionFarmaAnimation from "../DataTable/ubicacionFarma.json";
import LottieOverlay from './LottieOverlay';

type GoogleMapsType = 'origin' | 'simulator' | 'create'
interface IProps {
  data: IRoutes[];
  routesData: RouteData[];
  showBtnLeft: boolean;
  type: GoogleMapsType;
  load: boolean;
  showOriginalMap: boolean;
  setRoutesData?: (routesData: RouteData[]) => void;
  setShowBtnLeft: (show: boolean) => void;
  setShowOriginalMap: (show: boolean) => void;
  setLoad?: (load: boolean) => void;
  onClose?: () => void;
}

const GoogleMaps = (props: IProps): React.ReactElement => {
  const {
    data,
    load,
    routesData,
    showBtnLeft,
    type,
    showOriginalMap,
    setShowBtnLeft,
    setShowOriginalMap,
    setLoad,
    setRoutesData,
    onClose = () => { }
  } = props
  const [directionsRenderers, setDirectionsRenderers] = useState<google.maps.DirectionsRenderer[]>([]);
  const [markers, setMarkers] = useState<CustomMarker[]>([]);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState<boolean>(true);
  const [calculatedCenter, setCalculatedCenter] = useState<LatLng>({
    lat: 41.29482,
    lng: 2.01729,
  });
  const [zoomLevel, setZoomLevel] = useState<number>(8);

  const handleCenterMap = () => {
    if (map && routesData.length > 0) {
      fitMapToRoutes()
    }
  };

  const handleRecalcMap = () => {
    if (map && routesData.length > 0) {
      clearMap();
      traceRoutes()
      setShowBtnLeft(false)
    }
  };

  const traceRoutes = async (): Promise<void> => {
    if (map && routesData.length > 0) {
      const directionsService = new google.maps.DirectionsService();
      let newDirections: google.maps.DirectionsRenderer[] = [];
      let newMarkers: CustomMarker[] = [];

      const createMarkersPromises: Promise<void>[] = routesData.map(async (route, routeIndex) => {
        if (route.destinations.length === 0) {
          return;
        }

        const waypoints = route.destinations.map((destination) => ({
          location: new google.maps.LatLng(destination.location.lat, destination.location.lng),
          stopover: true,
        }));

        // Añade el origen como primer waypoint
        waypoints.unshift({
          location: new google.maps.LatLng(route.origin.lat, route.origin.lng),
          stopover: true,
        });

        return createDirectionsRenderer(
          route,
          waypoints,
          map,
          directionsService,
          newDirections,
          newMarkers,
          routeIndex,
          type
        );
      });

      try {
        await Promise.all(createMarkersPromises);
        setMarkers(newMarkers);
        setDirectionsRenderers(newDirections);
        fitMapToRoutes(newDirections);
      } catch (error) {
        console.error('Error tracing routes:', error);
      }
    }
  };

  //función para borrar las rutas almacenadas
  const clearMap = () => {
    // remove DirectionsRenderers
    directionsRenderers.forEach((directionsRenderer) => {
      directionsRenderer.setMap(null);
    });

    // remove markers by parts
    markers.forEach((marker) => {
      marker.circle.setMap(null);
      marker.circleSmall?.setMap(null);
      marker.iconMarker?.setMap(null);
      marker.borderPolyline?.setMap(null);
      marker.routePolyline?.setMap(null);
    });

    // Limpia los arrays
    setDirectionsRenderers([])
    setMarkers([])
  };

  const fitMapToRoutes = (directions?: google.maps.DirectionsRenderer[]) => {
    const currentDirections = directions ? directions : directionsRenderers;
    if (load && map && currentDirections.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      currentDirections.forEach((direction) => {
        direction.getDirections()?.routes[0].legs.forEach((leg) => {
          bounds.extend(leg.start_location);
          bounds.extend(leg.end_location);
        });
      });

      // Centra el mapa y ajusta el zoom para mostrar todas las rutas
      map.fitBounds(bounds);

      // Limita el nivel de zoom para evitar un zoom excesivo
      const maxZoom = 15;
      const zoom = map.getZoom() ?? 10;
      if (zoom > maxZoom) {
        map.setZoom(maxZoom);
      }

      // Almacena las coordenadas y el nivel de zoom calculados en el estado
      setCalculatedCenter({ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() });
      setZoomLevel(zoom);
      setCenter(true);
    }
  };

  useEffect(() => {
    if (!load || routesData.length === 0 || routesData.length === directionsRenderers.length || showBtnLeft) return;
    clearMap();
    traceRoutes()
  }, [load, map, routesData]);

  useEffect(() => {
    if (!load) return;
    const moveEndListener = map?.addListener('dragend', () => {
      // se activa cuando el usuario deja de mover el mapa
      setCenter(false);
    });

    return () => {
      moveEndListener && google?.maps.event.removeListener(moveEndListener);
    };
  }, [map]);

  return <GoogleMap
    mapContainerStyle={{ width: '100%', height: `calc(100% - ${type === 'origin' ? '1' : '0'}%)`, position: 'relative' }}
    zoom={zoomLevel}
    center={routesData[0]?.origin ?? calculatedCenter}
    options={{
      disableDefaultUI: true,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
    }
    }
    onLoad={(map) => {
      setMap(map)
      setLoad && setLoad(true)
    }}
  >
    {type === 'create' && routesData.length > 0 && <LottieOverlay animationData={ubicacionFarmaAnimation} map={map} overlayPosition={routesData[0].origin} />}

    <MapButtons
      data={data}
      type={type}
      handleBtn={handleRecalcMap}
      showOriginalMap={showOriginalMap}
      setShowOriginalMap={setShowOriginalMap}
      center={center}
      handleCenterMap={handleCenterMap}
      showBtnLeft={showBtnLeft}
      setShowBtnLeft={setShowBtnLeft}
      setRoutesData={setRoutesData}
      onClose={onClose}
    />
  </GoogleMap >
};

export default memo(GoogleMaps);
