import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { settingsService } from '../../../../infrastructure/services/settingsService'
import Pagination from '../../Pagination/Pagination'
import Modal from '../Modal/Modal'
import { set } from 'react-hook-form'

const AssignReportsModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm: (reports: any[], entities: any[]) => void
}) => {
  const { t } = useTranslation()
  const [reports, setReports] = useState<any[]>([])
  const [entities, setEntities] = useState<any[]>([])
  const [selectedReports, setSelectedReports] = useState<any[]>([])
  const [selectedEntities, setSelectedEntities] = useState<any[]>([])
  const [selectedPages, setSelectedPages] = useState<any[]>([])
  const [pageReport, setPageReport] = useState<number>(1)
  const [perPageReport] = useState<number>(10)
  const [searchReport, setSearchReport] = useState<string>('')
  const [pageEntity, setPageEntity] = useState<number>(1)
  const [perPageEntity] = useState<number>(10)
  const [searchEntity, setSearchEntity] = useState<string>('')
  const [paginationReports, setPaginationReports] = useState<any>({
    current_page: 1,
    per_page: 14,
    total: 0,
    to: 0,
    from: 0,
    last_page: 0,
  })
  const [paginationEntities, setPaginationEntities] = useState<any>({
    current_page: 1,
    per_page: 14,
    total: 0,
    to: 0,
    from: 0,
    last_page: 0,
  })

  const handleSelectReports = (report: any) => {
    if (selectedReports.find((r) => r.id === report.id)) {
      setSelectedReports(selectedReports.filter((r) => r.id !== report.id))
    } else {
      setSelectedReports([...selectedReports, report])
    }
  }

  const handleSelectEntities = (entity: any) => {
    let entitiesReports: any[] = []
    if (selectedEntities.find((e) => e.id === entity.id)) {
      entitiesReports = selectedEntities.filter((r) => r.id !== entity.id)
    } else {
      entitiesReports = [...selectedEntities, entity]
    }
    setSelectedEntities(entitiesReports)
  }

  const getReports = async () => {
    try {
      const response = await settingsService.getReports({
        page: pageReport,
        per_page: perPageReport,
        order_field: 'name',
        order_direction: 'asc',
        ...(searchReport && { name: searchReport }),
        ...(selectedEntities.length > 0 && {
          companies: selectedEntities.map((e) => e.id).join(','),
        }),
      })
      setReports(response.data.items)
      setPaginationReports(response.data.meta)
    } catch (error) {
      toast(
        t(
          'messages.No tiene suficientes permisos, consulte con el administrador'
        ) as string,
        {
          type: 'error',
          className: 'toast-error tw-p-4',
          position: 'top-center',
        }
      )
    }
  }

  const getEntities = async () => {
    try {
      const response = await settingsService.getCompanies({
        page: pageEntity,
        per_page: perPageEntity,
        order_field: 'name',
        order_direction: 'asc',
        ...(searchEntity && { name: searchEntity }),
      })
      setEntities(response.data.items)
      setPaginationEntities(response.data.meta)
    } catch (error) {
      toast(
        t(
          'messages.No tiene suficientes permisos, consulte con el administrador'
        ) as string,
        {
          type: 'error',
          className: 'toast-error tw-p-4',
          position: 'top-center',
        }
      )
    }
  }

  useEffect(() => {
    getReports()
  }, [pageReport, perPageReport, selectedEntities])

  useEffect(() => {
    getEntities()
  }, [pageEntity, perPageEntity])

  useEffect(() => {
    let timer = setTimeout(() => {
      getReports()
    }, 500)
    return () => clearTimeout(timer)
  }, [searchReport])

  useEffect(() => {
    let timer = setTimeout(() => {
      getEntities()
    }, 500)
    return () => clearTimeout(timer)
  }, [searchEntity])

  useEffect(() => {
    if (isOpen) {
      setSelectedReports([])
      setSelectedEntities([])
      setSelectedPages([])
      setSearchReport('')
      setSearchEntity('')
    }
  }, [isOpen])

  const onBeforeConfirm = () => {
    if (selectedReports.length > 0 && selectedEntities.length > 0) {
      onConfirm(selectedReports, selectedEntities)
    } else if (selectedEntities.length === 0) {
      toast(t('messages.Seleccione al menos una entidad') as string, {
        type: 'error',
        className: 'toast-error tw-p-4',
        position: 'top-center',
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('titles.Asignar informes')}
      onConfirm={() => onBeforeConfirm()}
    >
      <div className="tw-flex tw-justify-between tw-h-full tw-w-full">
        {/* ENTITIES */}
        <div className="tw-flex tw-flex-col tw-overflow-y-auto tw-p-4 tw-h-full tw-w-full">
          <h4
            className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
            id="modal-title"
          >
            {t('titles.Entidades')}
          </h4>
          <div className="tw-flex tw-items-center tw-justify-between tw-mt-2">
            <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
              <input
                type="text"
                className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-outline-none focus:tw-border-blue-500"
                placeholder={t('placeholders.Buscar entidad')}
                value={searchEntity}
                onChange={(e) => {
                  setSearchEntity(e.target.value)
                }}
              />
            </div>
          </div>
          {entities.map((entity) => (
            <div
              key={entity.id}
              className="tw-flex tw-items-center tw-justify-between tw-p-2 tw-border-b tw-border-gray-200 tw-cursor-pointer hover:tw-bg-gray-100"
              onClick={() => handleSelectEntities(entity)}
            >
              <div className="tw-flex tw-items-center">
                <input
                  type="checkbox"
                  className="tw-mr-2"
                  checked={
                    selectedEntities.filter((e) => e.id === entity.id).length >
                    0
                  }
                  readOnly
                />
                <span className="tw-text-gray-500">{entity.name}</span>
              </div>
            </div>
          ))}

          {/* pagination */}
          <Pagination
            {...paginationEntities}
            page={pageEntity}
            nextPage={(page) => setPageEntity(page)}
            prevPage={(page) => setPageEntity(page)}
          />
        </div>

        {/* REPORTS */}
        <div className="tw-flex tw-flex-col tw-h-full tw-overflow-y-auto tw-p-4 tw-w-full">
          <h4
            className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
            id="modal-title"
          >
            Informes
          </h4>
          <div className="tw-flex tw-items-center tw-justify-between tw-mt-2">
            <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
              <input
                type="text"
                className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-outline-none focus:tw-border-blue-500"
                placeholder={t('placeholders.Buscar informe')}
                value={searchReport}
                onChange={(e) => {
                  setSearchReport(e.target.value)
                }}
              />
            </div>
          </div>

          {reports.map((report) => (
            <div
              key={report.id}
              className="tw-flex tw-items-center tw-justify-between tw-p-2 tw-border-b tw-border-gray-200 tw-cursor-pointer hover:tw-bg-gray-100"
              onClick={() => handleSelectReports(report)}
            >
              <div className="tw-flex tw-items-center">
                <input
                  type="checkbox"
                  className="tw-mr-2"
                  checked={
                    selectedReports.filter((r) => r.id === report.id).length > 0
                  }
                  readOnly
                />
                <span className="tw-text-gray-500">{report.name}</span>
              </div>
            </div>
          ))}
          {/* pagination */}
          <Pagination
            {...paginationReports}
            page={pageReport}
            nextPage={(page) => setPageReport(page)}
            prevPage={(page) => setPageReport(page)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default AssignReportsModal
