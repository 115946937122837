import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 92vh;
  overflow: auto;
`
export const HastagContainer = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  border: 1px solid #a5a5a5;
  border-radius: 10px;
  padding: 0 15px;
  margin-left: 5px;
  font-size: 0.8rem;
  height: 28px;
  background-color: #f9fafb;
  &:hover {
    background-color: #a5a5a5;
    color: #fff;
    transition: all 0.2s ease-in-out;
  }
`
export const AgGridContainer = styled.div`
  --tw-border-opacity: 0.5;
  .ag-root-wrapper {
    border-radius: 8px;
    border: none;
  }

  .ag-header {
    background-color: #fff;
    border-bottom-color: rgba(169, 169, 169, 0.5) !important;
  }

  .ag-header-row-column {
    border: none;
  }
`
export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  overflow-y: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a5a5a5;
    border-radius: 10px; /* Bordes redondeados */
  }
`
