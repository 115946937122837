import { AxiosResponse } from 'axios'
import {
  ICompany,
  ICompanyItem,
  IMenuItem,
  IReport,
  IReportItem,
  IStatus,
  IWorkspace,
} from '../interfaces/settings'
import { IUserItem, IUserItemInput } from '../interfaces/users'
import { api } from './api'
interface IPagination {
  [key: string]: any
}

interface IPaginationResponse {
  items: any[]
  meta: {
    per_page: number
    page: number
    total: number
    last_page: number
    from: number
    to: number
    current_page: number
  }
}

/**
 * settings.user
 */

const getUsers = async (
  elements: IPagination,
  company?: string
): Promise<AxiosResponse<IPaginationResponse>> => {
  return await api.get('/users', { params: { ...elements } })
}

const getUser = async (id: number): Promise<AxiosResponse<IUserItem>> => {
  return await api.get(`/users/${id}`)
}

const updateUser = async (data: IUserItem): Promise<AxiosResponse> => {
  return await api.put('/users/' + data.id, data)
}

const setUserReports = async (
  id: number,
  data: {
    reports: number[]
  }
): Promise<AxiosResponse> => {
  return await api.post(`/users/${id}/reports`, data)
}
const setUserPages = async (
  id: number,
  data: {
    pages: number[]
  }
): Promise<AxiosResponse> => {
  return await api.post(`/users/${id}/pages`, data)
}

const setUserRoles = async (
  userId: number,
  roleId: number
): Promise<AxiosResponse> => {
  return await api.put(`/users/${userId}/role/${roleId}`)
}

const deleteUser = async (id: number): Promise<AxiosResponse> => {
  return await api.delete('/users/' + id)
}

const createUser = async (data: IUserItemInput): Promise<AxiosResponse> => {
  return await api.post('/users', data)
}

const userMassUpdate = async (
  users: number[],
  reports: number[],
  companies: number[],
  pages?: number[]
): Promise<AxiosResponse> => {
  return await api.post('/users/mass-update', {
    users,
    reports,
    companies,
  })
}

/* permissions list */

const getPermissions = async (): Promise<AxiosResponse> => {
  return await api.get('/permissions')
}

/* createRole */
const createRole = async (data: any): Promise<AxiosResponse> => {
  return await api.post('/roles', data)
}

/* getRoles*/
const getRoles = async (
  elements: IPagination
): Promise<AxiosResponse<IPagination>> => {
  return await api.get('/roles', { params: { ...elements } })
}

/* updateRole*/
const updateRole = async (id: Number, data: any): Promise<AxiosResponse> => {
  return await api.put('/roles/' + id, data)
}

/* deleteRole */
const deleteRole = async (id: number): Promise<AxiosResponse> => {
  return await api.delete(`/roles/${id}`)
}

/**
 * settings.company
 */

const getCompanies = async (
  elements: IPagination
): Promise<AxiosResponse<IPagination>> => {
  return await api.get('/companies', { params: { ...elements } })
}

const getCompany = async (id: number): Promise<AxiosResponse> => {
  return await api.get(`/companies/${id}`)
}

const deleteCompany = async (id: number): Promise<AxiosResponse> => {
  return await api.delete(`/companies/${id}`)
}

const updateCompany = async (data: ICompanyItem): Promise<AxiosResponse> => {
  return await api.put(`/companies/${data.id}`, data)
}

const createCompany = async (data: ICompanyItem): Promise<AxiosResponse> => {
  return await api.post('/companies', data)
}

/**
 * settings.reports
 */

const getReports = async (
  elements: IPagination
): Promise<AxiosResponse<IPagination>> => {
  return await api.get('/reports', { params: { ...elements } })
}

const deleteReport = async (id: number): Promise<AxiosResponse> => {
  return await api.delete(`/reports/${id}`)
}

const updateReport = async (data: IReportItem): Promise<AxiosResponse> => {
  return await api.put(`/reports/${data.id}`, data)
}

const createReport = async (data: IReportItem): Promise<AxiosResponse> => {
  return await api.post('/reports', data)
}

/**
 *  settings.CMS
 */

const getPages = async (
  elements: IPagination
): Promise<AxiosResponse<IPagination>> => {
  return await api.get('/pages', { params: { ...elements } })
}

const getPage = async (id: number | string): Promise<AxiosResponse> => {
  return await api.get(`/pages/${id}`)
}

const updatePage = async (data: any): Promise<AxiosResponse> => {
  return await api.put(`/pages/${data.id}`, data)
}

const createPage = async (data: any): Promise<AxiosResponse> => {
  return await api.post('/pages', data)
}

const deletePage = async (id: number): Promise<AxiosResponse> => {
  return await api.delete(`/pages/${id}`)
}

/**
 * Widgets
 */

const getWidgets = async (): Promise<AxiosResponse> => {
  return await api.get('/widgets')
}

const getWidget = async (id: number): Promise<AxiosResponse> => {
  return await api.get(`/widgets/${id}`)
}

const updateWidget = async (data: any): Promise<AxiosResponse> => {
  return await api.put(`/widgets/${data.id}`, data)
}

const createWidget = async (data: any): Promise<AxiosResponse> => {
  return await api.post('/widgets', data)
}

const deleteWidget = async (id: number): Promise<AxiosResponse> => {
  return await api.delete(`/widgets/${id}`)
}
interface IReorderWidgets {
  page_id: number
  widgets: [
    {
      id: number
      position: number
    }
  ]
}
const reorderWidgets = async (data: any): Promise<AxiosResponse> => {
  return await api.post('/widgets/reorder', data)
}

const uploadWidgetImage = async (
  widgetId: number,
  image: string
): Promise<AxiosResponse> => {
  return await api.post(`/widgets/${widgetId}/upload-image`, { image })
}

/**
 * settings.Workspaces
 */

const getWorkspaces = async (elements: IPagination): Promise<AxiosResponse> => {
  return await api.get('/workspaces', { params: { ...elements } })
}

const getWorkspace = async (id: number): Promise<AxiosResponse<IWorkspace>> => {
  return await api.get(`/workspaces/${id}`)
}

const updateWorkspace = async (
  data: IWorkspace
): Promise<AxiosResponse<IWorkspace>> => {
  return await api.put(`/workspaces/${data.id}`, data)
}

const createWorkspace = async (
  data: IWorkspace
): Promise<AxiosResponse<IWorkspace>> => {
  return await api.post('/workspaces', data)
}

const deleteWorkspace = async (id: number): Promise<AxiosResponse> => {
  return await api.delete(`/workspaces/${id}`)
}

/**
 * settings.Status
 */

const getStatusList = async (elements: IPagination): Promise<AxiosResponse> => {
  return await api.get('/etls', { params: { ...elements } })
}

/**
 * settings.tags
 */

const getTags = async (elements: IPagination): Promise<AxiosResponse> => {
  return await api.get('/tags', { params: { ...elements } })
}

const getTag = async (id: number): Promise<AxiosResponse> => {
  return await api.get(`/tags/${id}`)
}

/**
 * settings.menu
 */
const getMenuItems = async ({ id }: { id: string }): Promise<AxiosResponse> => {
  return await api.get(`/menu/${id}`)
}
const postMenuItems = async (data: IMenuItem): Promise<AxiosResponse> => {
  return await api.post('/menu', data)
}
const putMenuItems = async (
  id: string | number | undefined,
  data: IMenuItem
): Promise<AxiosResponse> => {
  console.log('data', data)
  return await api.put(`/menu/${id}`, data)
}

const deleteMenuItem = async (id: number): Promise<AxiosResponse> => {
  return await api.delete(`/menu/${id}`)
}

const reorderMenuItems = async (data: any): Promise<AxiosResponse> => {
  return await api.post('/menu/reorder', {
    menus: data,
  })
}

export const settingsService = {
  getUsers,
  getUser,
  getPermissions,
  updateUser,
  userMassUpdate,
  setUserReports,
  setUserPages,
  setUserRoles,
  deleteUser,
  createUser,
  getCompanies,
  getCompany,
  deleteCompany,
  updateCompany,
  createCompany,
  getReports,
  deleteReport,
  updateReport,
  createReport,
  createRole,
  getRoles,
  updateRole,
  deleteRole,
  getPages,
  getPage,
  updatePage,
  createPage,
  deletePage,
  getWidgets,
  getWidget,
  updateWidget,
  createWidget,
  deleteWidget,
  reorderWidgets,
  uploadWidgetImage,
  getWorkspaces,
  getWorkspace,
  updateWorkspace,
  createWorkspace,
  deleteWorkspace,
  getStatusList,
  getTags,
  getTag,
  getMenuItems,
  postMenuItems,
  putMenuItems,
  deleteMenuItem,
  reorderMenuItems,
}
