import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsParams,
  MenuItemDef,
} from 'ag-grid-community'
import AgList from '../../../components/commons/AgGrid/AgList'
import DeleteModal from '../../../components/commons/modals/DeleteModal/DeleteModal'
import { setMenu } from '../../../infrastructure/store/sidebar/sidebarSlice'

import { cmsColumns } from '../../../infrastructure/constant/columns/cmsColumns'
import { settingsService } from '../../../infrastructure/services/settingsService'
import {
  actionContextMenu,
  formattedFilters,
} from '../../../infrastructure/utils/common'
import storageService from '../../../infrastructure/services/storageService'
import { checkPermissions } from '../../../infrastructure/utils/permissions'
import { UserRoles } from '../../../infrastructure/interfaces/users'
import { useTranslation } from 'react-i18next'

const CMSList = () => {
  const { t } = useTranslation()
  const [gridApi, setGridApi] = useState<GridApi>()
  const [deleteModal, setDeleteModal] = useState<any>(false)
  const [perPage, setPerPage] = useState<number>(14)
  const [page, setPage] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const menu = useSelector((state: any) => state.sidebar.menu)
  const user =
    useSelector((state: any) => state.auth) || storageService.getUser()

  const getContextMenu = (row: GetContextMenuItemsParams) => {
    if (!row.value) return []
    const items: MenuItemDef[] = []

    let currentUser = user?.user || storageService.getUser()

    if (
      checkPermissions(['pages.*', 'pages.create'], currentUser?.permissions) ||
      currentUser?.role?.name === UserRoles.ADMIN
    ) {
      items.push({
        name: 'Editar',
        action: () =>
          actionContextMenu(
            'EDIT',
            row,
            null,
            location.pathname + '/create-page',
            navigate
          ),
        cssClasses: ['tw-cursor-pointer'],
      })
    }
    if (
      checkPermissions(['pages.*', 'pages.delete'], currentUser?.permissions) ||
      currentUser?.role?.name === UserRoles.ADMIN
    ) {
      items.push({
        name: 'Eliminar',
        action: () => actionContextMenu('DELETE', row, setDeleteModal),
        cssClasses: ['tw-text-alert', 'tw-cursor-pointer'],
      })
    }
    return items
  }

  const handleDelete = () => {
    settingsService
      .deletePage(deleteModal.id)
      .then((res) => {
        setDeleteModal(false)
        gridApi?.refreshServerSide({ purge: true })
        if (menu && menu.length) {
          dispatch(
            setMenu(menu.filter((item: any) => item.slug !== deleteModal.slug))
          )
        }
      })
      .catch((err) => {
        setDeleteModal(false)
      })
  }

  const handleDoubleClick = (row: any) => {
    let currentUser = user?.user || storageService.getUser()
    if (
      checkPermissions(['pages.*', 'pages.edit'], currentUser.permissions) ||
      currentUser.role?.name === UserRoles.ADMIN
    ) {
      actionContextMenu(
        'EDIT',
        row,
        null,
        location.pathname + '/create-page',
        navigate
      )
    }
  }

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
    const updateData = (data: any) => {
      let idSequence = 1
      data?.items?.forEach(function (item: any) {
        item.id = idSequence++
      })

      const datasource = {
        getRows(params: IServerSideGetRowsParams) {
          let filters: any = null
          if (
            params.request.filterModel &&
            Object.keys(params.request.filterModel).length > 0
          ) {
            filters = formattedFilters(params.request.filterModel)
          }

          if (params.request.sortModel.length > 0) {
            filters = {
              ...filters,
              order_field: params.request.sortModel[0].colId,
              order_direction: params.request.sortModel[0].sort,
            }
          }

          settingsService
            .getPages({
              page: params.api.paginationGetCurrentPage() + 1,
              per_page: perPage,
              ...filters,
            })
            .then((response) => {
              params.success({
                rowData: response.data.items,
                rowCount: response.data.meta.total,
              })
              setCurrentPage(response.data.meta.current_page)
              setTotalPages(response.data.meta.last_page)
            })
            .catch((error) => {
              params.fail()
            })
        },
      }
      params.api.setServerSideDatasource(datasource)
    }

    updateData(params.api)
  }
  return (
    <>
      <AgList
        gridApi={gridApi}
        columns={cmsColumns}
        currentPage={currentPage}
        totalPages={totalPages}
        onGridReady={onGridReady}
        getContextMenu={getContextMenu}
        pagination={true}
        paginationPageSize={perPage}
        onRowDoubleClicked={handleDoubleClick}
        cacheBlockSize={perPage}
      />
      <DeleteModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        description={'¿Está seguro que desea eliminar este registro?'}
        onConfirm={handleDelete}
        title={t('titles.Eliminar Página')}
      />
    </>
  )
}

export default CMSList
