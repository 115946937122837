import EmailConfirmation from '../../../components/Auth/EmailConfirmation'
import Layout from '../Layout'

const ConfirmationEmail = () => {
  return (
    <Layout>
      <EmailConfirmation />
    </Layout>
  )
}

export default ConfirmationEmail
