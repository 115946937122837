import styled from 'styled-components'
import colors from '../../../infrastructure/styles/colors'

export const SidebarContainer = styled.div<{
  isOpen: boolean
}>`
  position: relative;
  min-width: ${({ isOpen }) => (isOpen ? '275px' : '75px')};
  max-width: 275px;
  height: 92vh;
  transition: width 0.3s ease-in-out;
`

export const SidebarInnerContainer = styled.div<{
  color: any
  isOpen: boolean;
}>`
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding-bottom: 1.2rem;
  background-color: ${({ color }) =>
    color ? color : 'white'};
`

export const SidebarBtnMenu = styled.div`
  height: 50px;
`

export const SidebarOutterContaier = styled.div`
  left: 50px;
  & .sidebar-outter-container-content {
    width: 254px;
  }
`

export const SidebarContentHeader = styled.div`
  height: 100px;
`

export const IconWrapper = styled.div<{
  single?: boolean,
  active?: boolean,
  width?: number,
  height?: number,
}>`
  width: ${({ width }) => `${width ?? 20}px`};
  height: ${({ height }) => `${height ?? 20}px`};
  display: flex;
  color: #000;
  justify-content: center;
  align-items: center;
  fill: ${({ active }) => (active ? colors.brandMedium : '#737373')};

  &:hover {
    cursor: ${({ single }) => (single ? 'default' : 'pointer')};
  }
  @media (max-width: 1024px) {
    width: 35px;
  }
`
export const Elipse = styled.div<{
  isOpen: boolean
}>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  bottom: 50px;
  left: 100%;
  transform: ${({ isOpen }) => isOpen ? 'translate(-50%, -50%)' : 'translate(-125%, -50%)'};
  cursor: pointer;
  background: rgba(51, 51, 51, 1);
`

export const ArrowRight = styled.i`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(133deg);
  -webkit-transform: rotate(133deg);
  transition: all 0.3s ease-in-out;
`

export const ArrowLeft = styled.i`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(317deg);
  -webkit-transform: rotate(317deg);
  transition: all 0.3s ease-in-out;
`

export const SidebarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  margin-top: 1rem;
`
export const MenuLinkWrapper = styled.div<{
  active?: boolean
  parent?: boolean
  opened?: boolean
}>`
  position: relative;
  width: 100%;
  height: ${({ parent }) => (parent ? '32px' : '60px')};
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: ${({ active }) => (active ? colors.brandMedium : '#737373')};
  background-color: ${({ active }) => (active ? colors.brandSoft : 'white')};
 `

export const MenuParentLinkWrapper = styled.div<{
  active: boolean
  parent?: boolean
  isOpen?: boolean
  paddingLeft?: boolean
}>`
  width: ${({ isOpen }) => isOpen ? '275px' : '80px'};
  height: ${({ parent }) => (parent ? '32px' : '60px')};
  padding: ${({ isOpen, paddingLeft }) => isOpen ? `20px 37px 20px ${paddingLeft ? '37px' : '0'}` : '0'};
  gap: 4px;
  justify: space-between;

  &:hover {
    background-color: ${colors.brandSoft};
    color: ${colors.brandMedium};
    transition: all 0.2s ease-in-out;
  }
`
export const MenuChildLinkWrapper = styled.div<{
  active?: boolean
}>`
  position: relative;
  background-color: ${({ active }) => (active ? 'rgba(245, 245, 245, 1)' : 'white')};
`

export const MenuDivider = styled.div`
  margin: 0 auto;
  width: 85%;
  height: 2px;
  background-color: #c4c4c4;
`

export const MenuFixLinkWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
`

export const MenuSubLinkWrapper = styled.div<{
  active: boolean,
  fixed?: boolean,
  isOpen: boolean,
}>`
  position: relative;
  width: 275px;
  height: 32px;
  gap: 24px;
  opacity: 0px;
  display: flex;
  justify-content: start;
  align-items: center;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  padding: 10px 0 10px 25px;
  color: ${({ active }) => (active ? colors.brandMedium : '#737373')};
  background-color: ${({ active }) => (active ? colors.brandSoft : 'white')};
  padding: ${({ isOpen }) => isOpen ? '20px 37px 20px 37px' : '0 17px 0 17px'};
  &:hover {
    background-color: ${colors.brandSoft};
    color: ${colors.brandMedium};
    transition: all 0.2s ease-in-out;
  }

  @media (max-width: 1248px) {
    padding-left: 1.2rem;
  }

  @media (max-width: 1024px) {
    padding-left: 0.5rem;
    font-size: 0.85rem;
  }
`

export const LogoWrapper = styled.div<{
  isOpen: boolean;
}>`
  width: ${({ isOpen }) => isOpen ? '275px' : '75px'};
  padding: ${({ isOpen }) => isOpen ? '20px 37px 20px 37px' : '12px 12px 0 12px'};
  height: ${({ isOpen }) => isOpen ? '125px' : '75px'};
  gap: 4px;
  display: flex;
  align-items: start;
  justify-content: center;
  position: sticky;
  top: 0;
  cursor: pointer;
`;
