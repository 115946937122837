import styled from 'styled-components'

export const Input = styled.input<{
  type?: string
  backgroundColor?: string
  borderColor?: string
}>`
  &::placeholder {
    font-style: italic;
  }
  ${({ type }) =>
    type && type === 'checkbox'
      ? `
        width: 20px; 
        height: 20px;
        &:checked {
          background: #000000 !important;
          accent-color: #000000 !important;
        }
    `
      : ''}
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor};` : ''}
  ${({ borderColor }) =>
    borderColor ? `border: 1px solid ${borderColor};` : ''}
`

export const UserOptionsTableHeader = styled.thead`
  background-color: #e5e7eb;
  padding: 0.5rem;
`
export const CommonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  padding-top: 20px;
  overflow: visible;
`

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding-left: 14px;
  background-color: #fff;
  input {
    width: 100%;
    min-width: 50%;
    border: none;
    border-radius: 5px;
    padding: 14px;
    padding-left: 14px;
  }
`

export const Tag = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem;
  border: 1px solid orange;
  border-radius: 5px;
  background-color: orange;
  white-space: nowrap;
  color: white;
  button {
    display: flex;
    padding: 6px;
    border: none;
    background-color: unset;
    cursor: pointer;
    color: white;
  }
`
export const ContentBox = styled.div`
  width: 100%;
  min-height: 500px;
  height: 100%;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
`
