import React, { useEffect } from 'react'
import MaintenanceImage from '../../assets/images/maintenance.png'
import FedeLogo from '../../assets/images/fedefarma.png'

const Maintenance: React.FC = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload()
    }, 180000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="tw-flex tw-size-full tw-items-center tw-justify-center tw-flex-col tw-p-10">
      <img
        src={FedeLogo}
        alt="service_mode_on"
        className="tw-w-[150px] tw-mb-10"
      />
      <img
        src={MaintenanceImage}
        alt="service_mode_on"
        className="md:tw-w-[40%]"
      />
      <h2 className="tw-font-medium tw-text-[30px] tw-mt-5 tw-text-center">
        Pagina en mantenimiento
      </h2>
      <div className="tw-py-5 tw-text-center">
        <p className="tw-text-[#035659d5] tw-text-[13px] tw-font-medium">
          El acceso a la página está temporalmente no disponible para brindarte
          una experiencia más eficiente.
        </p>
        <p className="tw-text-[13px] tw-font-normal tw-text-[#B1B1B1]">
          Si necesitas asistencia inmediata o tienes preguntas, no dudes en
          contactarnos
        </p>
      </div>
      <div className="tw-flex tw-gap-5 tw-items-center tw-text-center tw-justify-center">
        <a
          href="mailto:sas@fedefarma.com"
          className="tw-text-[#035659] tw-underline tw-text-[13px] tw-font-bold"
        >
          Envíanos un email
        </a>
        <a
          href="tel:937060371"
          className="tw-text-[#035659] tw-underline tw-text-[13px] tw-font-bold"
        >
          Llama a atención al cliente
        </a>
      </div>
    </div>
  )
}

export default Maintenance
