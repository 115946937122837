import React, { MouseEvent } from 'react';
import MenuItem from './MenuItem';
import { IMenuFixItem, IMenuItem } from '../../../../infrastructure/interfaces/sidebar';
import { IPositionSubMenu } from '../Sidebar';

interface MenuProps {
  menu: IMenuItem[];
  selectedMenu: IMenuItem | IMenuFixItem | null;
  isOpen: boolean;
  positionSubMenu: IPositionSubMenu | null;
  selectMenu: (item: IMenuItem | IMenuFixItem) => void;
  calculateRelativePosition: (event: MouseEvent<HTMLElement> | null, submenu: IMenuItem[] | IMenuFixItem[] | null | undefined) => void
}

const Menu: React.FC<MenuProps> = ({ menu, selectedMenu, isOpen, positionSubMenu, calculateRelativePosition, selectMenu }) => {
  return (
    <div className="tw-opacity-100 tw-pointer-events-auto">
      {menu.map((item) => (
        <MenuItem
          key={item.id}
          item={item}
          isOpen={isOpen}
          selectedMenu={selectedMenu}
          positionSubMenu={positionSubMenu}
          selectMenu={selectMenu}
          calculateRelativePosition={calculateRelativePosition}
        />
      ))}
    </div>
  );
};

export default Menu;