export const companyColumns = [
  {
    field: 'name',
    headerName: 'Entidad',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'domain',
    headerName: 'Dominio',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'count_menus',
    headerName: 'Nº de informes',
    sortable: true,
    cellRenderer: ({ value }: any) => {
      return value
    },
  },
]
