import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUserItem } from '../../interfaces/users'

// Basic user slice interfaces
export interface AuthState {
  isAuthenticated: boolean
  user: IUserItem | null
  token: string
  expires_at: string
}

const initialState: AuthState = {
  isAuthenticated: localStorage.getItem('token') ? true : false,
  user: null,
  token: localStorage.getItem('token') || '',
  expires_at: '',
}

export const authReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    setUser: (state, action: PayloadAction<IUserItem>) => {
      state.user = action.payload
      state.isAuthenticated = true
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setExpiresAt: (state, action: PayloadAction<string>) => {
      state.expires_at = action.payload
    },
    logOut: () => {
      return initialState
    },
  },
})

export const { setAuthenticated, setUser, setToken, logOut, setExpiresAt } =
  authReducer.actions

export default authReducer.reducer
