export interface EventData {
  [key: string]: any;
}

interface EventBus {
  listeners: { [key: string]: ((data: EventData) => void)[] };
  subscribe: (eventName: string, callback: (data: EventData) => void) => Subscription;
  publish: (eventName: string, data: EventData) => void;
}

export interface Subscription {
  unsubscribe: () => void;
}

const EventBusService: EventBus = {
  listeners: {},
  subscribe: (eventName, callback) => {
    if (!EventBusService.listeners[eventName]) {
      EventBusService.listeners[eventName] = [];
    }

    const unsubscribe = () => {
      EventBusService.listeners[eventName] = EventBusService.listeners[eventName].filter(listener => listener !== callback);
    };

    const subscription: Subscription = {
      unsubscribe,
    };

    EventBusService.listeners[eventName].push(callback);

    return subscription;
  },
  publish: (eventName, data) => {
    if (EventBusService.listeners[eventName]) {
      EventBusService.listeners[eventName].forEach((callback) => {
        callback(data);
      });
    }
  }
};

export default EventBusService;
