export const folderColumns = [
  {
    field: 'label',
    headerName: 'Nombre',
    editable: true,
    rowDrag: true,
  },
  {
    field: 'type',
    headerName: 'Tipo',
    valueGetter: (params: any) => {
      return params?.data?.type === 'report' ? 'Informe' : 'CMS'
    },
  },
  {
    field: 'description',
    headerName: 'Descripción',
    valueGetter: (params: any) => {
      return params?.data?.type === 'report'
        ? params?.data?.report?.name
        : params?.data?.page?.title
    },
  },
]
