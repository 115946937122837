const Pagination = ({
  page,
  current_page,
  total,
  last_page,
  from,
  to,
  nextPage,
  prevPage,
}: {
  page: number
  current_page: number
  total: number
  last_page: number
  from: number
  to: number
  nextPage: (page: number) => void
  prevPage: (page: number) => void
}) => {
  return (
    <div className="tw-flex tw-justify-end tw-p-4">
      <div className="tw-flex tw-items-center tw-justify-center tw-text-gray-500 tw-text-sm">
        <span className="tw-mr-2">
          {from} - {to}
        </span>
        <span className="tw-mr-2">de</span>
        <span className="tw-mr-2">{total}</span>
        <button
          className="tw-mr-2 hover:tw-bg-gray-100 tw-rounded-full tw-p-1 tw-text-gray-500 tw-transition tw-duration-150 tw-ease-in-out"
          onClick={() => {
            if (current_page > 1) nextPage(page - 1)
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-4 tw-w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          className="tw-mr-2 hover:tw-bg-gray-100 tw-rounded-full tw-p-1 tw-text-gray-500 tw-transition tw-duration-150 tw-ease-in-out"
          onClick={() => {
            if (current_page < last_page) prevPage(page + 1)
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-4 tw-w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default Pagination
