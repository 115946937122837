export const fedefarmaOptions = [
  {
    type: 'checkbox',
    name: 'informe_mercado',
    className: 'tw-mr-4',
    label: 'Informes de mercado',
    id: 1,
  },
  {
    type: 'checkbox',
    name: 'scoring_farmacia',
    className: 'tw-mr-4',
    label: 'Scoring de farmacia',
    id: 2,
  },
  {
    type: 'checkbox',
    name: 'ventas_cfo',
    className: 'tw-mr-4',
    label: 'Ventas CFO',
    id: 3,
  },
]

export const acofarOptions = [
  {
    type: 'checkbox',
    name: 'finanzas_principal',
    className: 'tw-mr-4',
    label: 'Finanzas principal',
  },
  {
    type: 'checkbox',
    name: 'finanzas_principal',
    className: 'tw-mr-8',
    label: 'Finanzas sub-item',
  },
  {
    type: 'checkbox',
    name: 'finanzas_principal',
    className: 'tw-mr-8',
    label: 'Finanzas sub-item',
  },
  {
    type: 'checkbox',
    name: 'finanzas_principal',
    className: 'tw-mr-8',
    label: 'Finanzas sub-item',
  },
]

export const biFarmaciasOptions = [
  {
    type: 'checkbox',
    name: 'ventas',
    className: 'tw-mr-4',
    label: 'Ventas',
  },
  {
    type: 'checkbox',
    name: 'mercado',
    className: 'tw-mr-4',
    label: 'Mercado',
  },
  {
    type: 'checkbox',
    name: 'ventas_cfo',
    className: 'tw-mr-4',
    label: 'Stocks',
  },
]

export const filterOptions = [
  {
    type: 'checkbox',
    name: 'table_to_filter',
    className: 'tw-ml-2 tw-mr-4',
    label: 'Table_to_filter',
  },
  {
    type: 'checkbox',
    name: 'table_to_filter',
    className: 'tw-ml-2 tw-mr-4',
    label: 'Table_to_filter',
  },
  {
    type: 'checkbox',
    name: 'table_to_filter',
    className: 'tw-ml-2 tw-mr-4',
    label: 'Table_to_filter',
  },
]

export const reportsFilterOptions = [
  {
    name: 'table',
    className: 'tw-ml-2 tw-mr-4',
    label: 'Tabla',
  },
  {
    name: 'column',
    className: 'tw-ml-2 tw-mr-4',
    label: 'Columna',
  },
]
