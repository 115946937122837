import styled from 'styled-components'

export const AvatarImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
`
export const AvatarContextMenuContainer = styled.div<{
  width: number
  bgColor: string
}>`
  position: fixed;
  top: 5rem;
  right: 0.5rem;
  background-color: ${({ bgColor }) => bgColor};
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #c4c4c4;
  width: ${({ width }) => width}px;
  z-index: 150;
  overflow-y: auto;
  max-height: 89%;
`
