import React from 'react'
import { Text } from './styles'

interface PProps {
  children: React.ReactNode
  italic?: boolean
  size?:
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'base'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | string

  color?: string
  uppercase?: boolean
  family?: 'atkinson' | 'gotham' | 'Atkinson Hyperlegible Regular' | 'Aeonik'
  className?: string
  weight?: 'light' | 'medium' | 'bold' | 'book' | 'regular'
}

const Paragraphs = ({
  children = '',
  italic = false,
  size = 'sm',
  color,
  uppercase,
  family = 'Aeonik',
  className = '',
  weight = 'regular',
}: PProps) => (
  <p
    className={`Paragraphs tw-text-${color} tw-font-${weight} tw-text-${size} ${italic ? 'tw-italic' : ''
      } ${uppercase && 'tw-uppercase'}  ${className}`}
  // family={family}
  >
    {children}
  </p>
)

export default Paragraphs
