import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '../../SecondaryButton/SecondaryButton'
import { PrimaryButton } from '../../PrimaryButton/PrimaryButton'
import { TertiaryButton } from '../../TertiaryButton/TertiaryButton'

const Modal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  children,
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  children: React.ReactNode
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={`tw-z-10 ${
        isOpen ? 'tw-relative' : 'tw-hidden'
      } tw-min-h-[75%]`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="tw-fixed  tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"></div>

      <div className="tw-fixed tw-top-20 tw-z-10 tw-inset-0 tw-overflow-y-auto">
        <div className="tw-flex sm:tw-items-center tw-justify-center tw-min-h-full tw-p-4 tw-text-center sm:tw-p-0">
          <div className="tw-relative tw-bg-white  tw-text-left  tw-shadow-xl tw-transform tw-transition-all tw-w-2/4">
            <div className="tw-bg-white  tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4">
              <div className="sm:tw-flex sm:tw-items-start">
                <div className="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left tw-w-full">
                  <h3
                    className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
                    id="modal-title"
                  >
                    {title}
                  </h3>
                  <div className="tw-mt-2">{children}</div>
                </div>
              </div>
              <div className=" tw-flex tw-gap-5 tw-px-3 tw-py-5  tw-w-1/3">
                <TertiaryButton
                  text="actions.Cancelar"
                  type="button"
                  onClick={onClose}
                />
                <PrimaryButton
                  className=""
                  text="labels.Guardar"
                  type="button"
                  onClick={onConfirm}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
