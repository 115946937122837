import { useState } from 'react'
import AgList from '../../../components/commons/AgGrid/AgList'
import { settingsService } from '../../../infrastructure/services/settingsService'
import { statusColumns } from '../../../infrastructure/constant/columns/statusColumns'
import { GridReadyEvent, IServerSideGetRowsParams } from 'ag-grid-community'
import { formattedFilters } from '../../../infrastructure/utils/common'

const StatusList = () => {
  const [gridApi, setGridApi] = useState<any>()
  const [perPage, setPerPage] = useState<number>(14)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
    const updateData = (data: any) => {
      let idSequence = 1
      data?.items?.forEach(function (item: any) {
        item.id = idSequence++
      })

      const datasource = {
        getRows(params: IServerSideGetRowsParams) {
          let filters: any = null
          if (
            params.request.filterModel &&
            Object.keys(params.request.filterModel).length > 0
          ) {
            filters = formattedFilters(params.request.filterModel)
          }

          if (params.request.sortModel.length > 0) {
            filters = {
              ...filters,
              order_field: params.request.sortModel[0].colId,
              order_direction: params.request.sortModel[0].sort,
            }
          }

          settingsService
            .getStatusList({
              page: params.api.paginationGetCurrentPage() + 1,
              per_page: perPage,
              ...filters,
            })
            .then((response) => {
              params.success({
                rowData: response.data.items,
                rowCount: response.data.meta.total,
              })
              setTotalPages(response.data.meta.last_page)
              setCurrentPage(response.data.meta.current_page)
              // Manejo de storeInfo separadamente
              const storeInfo = {
                page: response.data.meta.page,
                perPage: response.data.meta.per_page,
              }
            })
            .catch((error) => {
              params.fail()
            })
        },
      }
      params.api.setServerSideDatasource(datasource)
    }

    updateData(params.api)
  }

  return (
    <AgList
      gridApi={gridApi}
      columns={statusColumns}
      currentPage={currentPage}
      totalPages={totalPages}
      onGridReady={onGridReady}
      paginationPageSize={perPage}
      pagination
      cacheBlockSize={perPage}
    />
  )
}
export default StatusList
