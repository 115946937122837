import styled from 'styled-components'

export const Badge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 3px 5px;
  background-color: #f9fafb;
  font-size: 0.6rem;
  margin-left: 5px;
`
