import { formatDate } from '../../utils/common'

export const cmsColumns = [
  {
    field: 'title',
    headerName: 'Nombre de página',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'visible',
    headerName: 'Mostrar en menú',
    floatingFilter: false,
    cellRenderer: ({ value }: any) => {
      return value ? 'Si' : 'No'
    },
  },
  {
    field: 'updated_at',
    headerName: 'Fecha últ. modificación',
    floatingFilter: false,
    cellRenderer: ({ value }: any) => {
      return value ? formatDate(value) : ''
    },
  },
]
