import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'

import authReducer, {
  setToken,
  logOut,
  setExpiresAt,
  setUser,
} from './user/userSlice'
import storageService from '../services/storageService'
import sidebarReducer, { setHome, setMenu } from './sidebar/sidebarSlice'
import multiTenantReducer, {
  setSelectedEntity,
} from './multitenant/multitenantSlice'
import { api } from '../services/api'
import tableFiltersReducer from './tableFilters/tableFiltersSlice'

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: setUser,
  effect: async (action, listenerApi) => {
    const user = action.payload
    if (user) {
      await storageService.set('user', JSON.stringify(user))
    }
  },
})

listenerMiddleware.startListening({
  actionCreator: setToken,
  effect: async (action, listenerApi) => {
    const token = action.payload
    if (token !== '') {
      storageService.set('token', token)
    } else {
      localStorage.removeItem('token')
    }
  },
})

listenerMiddleware.startListening({
  actionCreator: logOut,
  effect: async (action, listenerApi) => {
    api.defaults.baseURL = process.env.REACT_APP_API_URL
    listenerApi.dispatch(setSelectedEntity(null))
    storageService.logOut()
    window.location.reload()
  },
})

listenerMiddleware.startListening({
  actionCreator: setExpiresAt,
  effect: async (action, listenerApi) => {
    const expiresAt = action.payload
    if (expiresAt !== '') {
      storageService.set('expires_at', expiresAt)
    } else {
      localStorage.removeItem('expires_at')
    }
  },
})

listenerMiddleware.startListening({
  actionCreator: setSelectedEntity,
  effect: async (action, listenerApi) => {
    const entity = action.payload
    if (entity !== null) {
      if (entity.domain_api) {
        let domain = action.payload!.domain_api
        api.defaults.baseURL = domain?.startsWith('http')
          ? domain
          : `https://${domain}`
      } else {
        api.defaults.baseURL = process.env.REACT_APP_API_URL
      }
      //change url to entity domain no refresh
      /**
       * EXPERIMENTAL
       */
      let domain = entity.domain
      if (domain && !window.location.href.includes('localhost')) {
        domain = domain.startsWith('http') ? domain : `https://${domain}`
        // window.location.href = domain
      }
    } else {
      api.defaults.baseURL = process.env.REACT_APP_API_URL
    }
  },
})

/*
 * Listen to menu changes and change the home menu
 */
listenerMiddleware.startListening({
  actionCreator: setMenu,
  effect: async (action, listenerApi) => {
    const menu = action.payload
    if (menu && menu.length > 0) {
      const home = menu.find((item) => item.page?.is_home)
      if (home) {
        listenerApi.dispatch(setHome(home))
      } else {
        listenerApi.dispatch(setHome(null as any))
      }
    }
  },
})

export const store = configureStore({
  reducer: {
    auth: authReducer,
    sidebar: sidebarReducer,
    multitenant: multiTenantReducer,
    tableFilters: tableFiltersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
