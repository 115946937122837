import { IPagination } from './common'
import { IFilterItem } from './filters'
import { ICompanyItem } from './settings'

export interface IUser {
  items: IUserItem[]
  meta?: IPagination
}

export interface IUserItem {
  id: number
  email: string
  name: string
  lastname: string
  role: UserRole
  permissions: string[]
  company?: {
    id: number
    name: string
  }
  companies: ICompanyItem[]
  tags?: ITags[]
  report_filters: IFilterItem[]
}

interface ITags {
  id: number
  name: string
}

interface UserRole {
  id: number
  name: string
}

export interface IUserItemInput {
  email: string
  name: string
  lastname: string
  role: UserRole
  permissions?: string[]
  companies?: [
    {
      id: number
      name: string
    }
  ]
}

export enum UserRoles {
  ADMIN = 'super-admin',
  MODERATOR = 'moderator',
  USER = 'user',
}
