import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import {
  ICompanyItem,
  IReportItem,
} from '../../../infrastructure/interfaces/settings'
import { IUserItem, UserRoles } from '../../../infrastructure/interfaces/users'
import { RootState } from '../../../infrastructure/store'
import { userTypeGuard } from '../../../infrastructure/utils/common'
import Button from '../Button'
import { icons } from 'eva-icons'

interface IPowerBiFilterInput {
  onFilterChange: (filter: string) => void
  entity: ICompanyItem | null
  user: IUserItem | null
  report: IReportItem
  downloadReportPDF: () => void
}

const PowerBiFilterInput = ({
  onFilterChange,
  entity,
  user,
  report,
  downloadReportPDF,
}: IPowerBiFilterInput) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')

  useEffect(() => {
    const filterValue = localStorage.getItem('powerBiFilter')
    if (filterValue) {
      setValue(filterValue)
    }
  }, [])

  const onSubmitFilter = (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault()
    let inputValue: any = (e.target as any)[0].value || ''
    if (inputValue) {
      localStorage.setItem('powerBiFilter', inputValue)
    } else {
      localStorage.removeItem('powerBiFilter')
    }
    onFilterChange(inputValue)
  }

  const onChangeSelect = (value: string) => {
    setValue(value)
    localStorage.setItem('powerBiFilter', value)
    onFilterChange(value)
  }

  if (user?.role?.name === UserRoles.USER) return null

  return (
    <div
      className="powerbi-filter-input tw-border-t tw-border-white tw-pr-2 "
      style={{
        backgroundColor: 'rgba(44,44,44,1)',
      }}
    >
      <form
        className="tw-w-full tw-flex tw-flex-row tw-justify-end tw-items-center tw-py-2"
        onSubmit={(e: React.SyntheticEvent) => onSubmitFilter(e)}
      >
        {/* <button
          className="tw-bg-white tw-rounded tw-px-2 tw-text-sm tw-font-bold tw-text-black tw-ml-2"
          onClick={downloadReportPDF}
        >
          {t('actions.Exportar')} PDF
        </button> */}

        {(Boolean(userTypeGuard(user) && user?.role.name === UserRoles.ADMIN) ||
          Boolean(
            user?.role.name === UserRoles.MODERATOR &&
            report.filter?.values.indexOf('*') !== -1
          )) && (
            <div className="tw-flex tw-flex-row tw-align-center">
              <input
                className="tw-text-black tw-bg-white tw-border-none tw-text-center tw-px-2 tw-text-sm tw-rounded-l"
                type="text"
                placeholder="P000XX"
                onChange={(e) => setValue(e.target.value)}
                value={value}
              />
              <button
                className="tw-bg-white tw-rounded-r-1"
                type="submit"
                title={t('actions.Aplicar filtro')}
                dangerouslySetInnerHTML={{
                  __html: icons['funnel-outline']?.toSvg({ width: 24, height: 24 }) || '',
                }}
              />
            </div>
          )}
        {Boolean(
          user &&
          userTypeGuard(user) &&
          user?.role.name !== UserRoles.ADMIN &&
          report.filter &&
          !Array.isArray(report.filter) &&
          report.filter.values &&
          report.filter.values.length > 0 &&
          report.filter.values.indexOf('*') === -1
        ) && (
            <Select
              className="tw-text-white tw-border-none tw-text-center tw-px-2 tw-text-sm tw-rounded-l focus:tw-border-b focus:tw-border-white tw-w-48"
              options={report!.filter!.values!.map((v) => ({
                value: v,
                label: v,
              }))}
              onChange={(e) => onChangeSelect(e?.value || '')}
              value={report!
                .filter!.values!.map((v) => ({
                  value: v,
                  label: v,
                }))
                .find((v: any) => v.value === value)}
              placeholder={t('actions.Filtrar por')}
              isSearchable={true}
              isClearable={true}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'rgba(44,44,44,1)',
                }),
                option: (provided) => ({
                  ...provided,
                  backgroundColor: 'rgba(44,44,44,1)',
                  color: 'white',
                }),
                input: (provided) => ({
                  ...provided,
                  color: 'white',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: 'white',
                }),
              }}
            />
          )}
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  entity: state.multitenant.entity,
  user: state.auth.user,
})

export default connect(mapStateToProps)(PowerBiFilterInput)
