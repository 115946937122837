import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IFilterItem } from '../interfaces/filters'
import { RootState } from '../store'
import {
  setFilters as setStoreFilters,
  deleteFilter,
} from '../store/tableFilters/tableFiltersSlice'

export function useUserFilters() {
  const [filters, setFilters] = useState<any[]>([])
  const storeFilters: IFilterItem[] = useSelector(
    (state: RootState) => state.tableFilters.filters
  )
  const dispatch = useDispatch()

  useEffect(() => {
    setFilters(storeFilters)
  }, [storeFilters])

  const addFilters = (filter: IFilterItem) => {
    let temp = [...filters]
    temp.push(filter)
    setFilters(temp)
    dispatch(setStoreFilters(temp))
  }

  const deleteAFilter = (filterId: number) => {
    const newFilters = filters.filter(
      (filter: IFilterItem) => filter.id !== filterId
    )
    setFilters(newFilters)
    dispatch(deleteFilter(filterId))
  }

  return { filters, addFilters, deleteAFilter, setFilters }
}
