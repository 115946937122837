import { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HastagContainer } from '../AgGrid/styles'
import { icons } from 'eva-icons'

interface ITagInputProps {
  tags: string[]
  setTags: (tags: string[]) => void
  onDeletedTag: (tags: string[]) => void
  loading?: boolean
}

const TagInput = ({
  tags,
  setTags,
  onDeletedTag,
  loading = false,
}: ITagInputProps) => {
  const { t } = useTranslation()
  const [copyTags, setCopyTags] = useState<string[]>([])
  const [tag, setTag] = useState<string>('')
  const [from, setFrom] = useState<number>(0)
  const [to, setTo] = useState<number>(12)
  const [filter, setFilter] = useState<string>('')

  useEffect(() => {
    if (!tags || !Array.isArray(tags) || tags.indexOf('[native code]') > -1)
      return

    if (tags) setCopyTags(tags)
  }, [tags])

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    const trimmedInput = tag.trim()

    if (
      (key === 'Enter' || key === 'Tab' || key === ',') &&
      trimmedInput.length &&
      !copyTags.includes(trimmedInput)
    ) {
      e.preventDefault()
      setTags([...copyTags, trimmedInput])
      setTag('')
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTag(e.target.value)
  }

  const onDelete = (tag: string) => {
    const index = tags.indexOf(tag)
    const newTags = [...tags]
    newTags.splice(index, 1)
    setTags(newTags)
    onDeletedTag(newTags)
  }

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value)
    if (e.target.value === '') {
      setCopyTags(tags)
      setFrom(0)
      setTo(12)
    } else {
      const newTags = tags.filter((tag) => tag.includes(e.target.value))
      setFrom(0)
      setTo(12)
      setCopyTags(newTags)
    }
  }

  return (
    <Fragment>
      <div className="tw-appearance-none tw-w-full tw-p-2 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-bg-white focus:tw-border-gray-800 tw-col-span-2">
        <input
          value={filter}
          onChange={(e) => onFilterChange(e)}
          readOnly={loading}
          className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-p-2 tw-mb-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800"
          placeholder={t('placeholders.Buscar')}
          title={t('placeholders.Buscar')}
        />
        <div>
          <div className="tw-flex tw-flex-wrap tw-overflow-hidden tw-justify-evently">
            {copyTags?.slice(from, to).map((tag: string, index: number) => (
              <HastagContainer
                style={{ marginLeft: 0, paddingRight: '10px' }}
                className="tw-m-1.5"
                key={index}
              >
                {tag}{' '}
                <span
                  className="tw-ml-2 tw-cursor-pointer"
                  style={{ fontSize: '16px' }}
                  onClick={() => onDelete(tag)}
                  dangerouslySetInnerHTML={{
                    __html: icons['close-outline']?.toSvg({ width: 24, height: 24 }) || '',
                  }}
                />
              </HastagContainer>
            ))}
          </div>
          {/* pagination */}
          <div className="tw-flex tw-justify-between tw-items-center tw-mt-4">
            <div className="tw-flex tw-items-center">
              <span className="tw-text-sm tw-text-gray-700 tw-mr-2">
                {t('messages.Mostrando de x a x entradas', {
                  from: from + 1,
                  to,
                  total: copyTags.length,
                })}
              </span>
            </div>
            <div className="tw-flex tw-items-center">
              <button
                className="tw-text-sm tw-text-gray-700 tw-mr-2"
                onClick={() => {
                  if (from > 0) {
                    setFrom(from - 12)
                    setTo(to - 12)
                  }
                }}
              >
                Anterior
              </button>
              <button
                className="tw-text-sm tw-text-gray-700 tw-mr-2"
                onClick={() => {
                  if (to < tags.length) {
                    setFrom(from + 12)
                    setTo(to + 12)
                  }
                }}
              >
                {t('labels.Siguiente')}
              </button>
            </div>
          </div>
        </div>
        <input
          value={tag}
          onKeyDown={(e) => onKeyDown(e)}
          onChange={(e) => onChange(e)}
          readOnly={loading}
          className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-p-2 tw-mt-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800"
          placeholder={'Varlor1, Valor2'}
          title={t(
            'placeholders.Escribe los valores separados por comas o presiona enter'
          )}
        />
      </div>
    </Fragment>
  )
}

export default TagInput
