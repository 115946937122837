import React, { MouseEvent, useState } from 'react'
import {
  IMenuFixItem,
  IMenuItem,
} from '../../../../infrastructure/interfaces/sidebar'
import { setSelectedMenu } from '../../../../infrastructure/store/sidebar/sidebarSlice'
import Paragraphs from '../../Paragraphs/Paragraphs'

interface SubMenuProps {
  submenu: IMenuItem[] | IMenuFixItem[]
  selectedMenu: IMenuItem | IMenuFixItem | null | undefined
  selectMenu: (item: IMenuItem | IMenuFixItem) => void
  isOpen: boolean
  dispatch: React.Dispatch<any>
  toggleSubMenu: (e: MouseEvent<HTMLElement> | null) => void
  parentId: number
}

const SubMenu: React.FC<SubMenuProps> = ({
  submenu,
  isOpen,
  selectedMenu,
  selectMenu,
  dispatch,
  toggleSubMenu,
  parentId,
}) => {
  const [hover, setHover] = useState<string>('')

  const handleSubmenuClick = (item: IMenuItem | IMenuFixItem) => {
    dispatch(setSelectedMenu({ ...item, parentId }))
    selectMenu(item)
    if (!isOpen) {
      toggleSubMenu(null)
    }
  }

  if (!isOpen) {
    return (
      <div className="tw-space-y-1 tw-rounded-[4px] tw-p-1">
        {submenu.map((subitem) => (
          <div
            className={`tw-transition-all tw-ease-in-out tw-flex tw-text-start tw-items-center tw-px-[20px] tw-py-[8px] tw-cursor-pointer tw-min-h-[40px] tw-rounded-[4px] 
          ${
            selectedMenu?.slug === subitem.slug
              ? 'tw-bg-brandSoft tw-text-brandMedium'
              : 'tw-text-darkGrey hover:tw-bg-brandSoft hover:tw-text-brandMedium'
          } 
          `}
            onClick={() => handleSubmenuClick(subitem)}
          >
            <Paragraphs className=" tw-text-[13px]">{subitem.label}</Paragraphs>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="tw-flex tw-flex-col tw-pl-[45px] tw-py-2">
      {submenu.map((subitem) => (
        <div
          key={subitem.slug}
          onMouseEnter={() => setHover(subitem.slug)}
          onMouseLeave={() => setHover('')}
          onClick={(e) => handleSubmenuClick(subitem)}
          className={`tw-transition-all tw-ease-in-out tw-relative tw-flex tw-items-center tw-py-2 tw-cursor-pointer tw-bg-inherit
         ${
           selectedMenu?.slug === subitem.slug &&
           selectedMenu?.parentId === parentId
             ? 'tw-text-brandMedium'
             : 'tw-text-darkGrey hover:tw-text-brandMedium'
         } 
          ${isOpen ? 'tw-pl-8' : 'tw-pl-2'}`}
        >
          <div className="tw-absolute tw-left-0 tw-top-0 tw-bottom-0 tw-w-[2px] tw-h-full tw-bg-softGrey" />
          <div
            className={`tw-absolute tw-left-0 tw-top-0 tw-bottom-0 tw-rounded-lg tw-w-[2px] !tw-bg-brand tw-transition-all tw-ease-in-out ${
              selectedMenu?.slug === subitem.slug &&
              selectedMenu?.parentId === parentId
                ? 'tw-h-[40px]'
                : `${hover === subitem.slug ? 'tw-h-full' : 'tw-h-0'}`
            } `}
          />
          <Paragraphs
            className={`tw-text-[13px] tw-w-full 
          ${
            selectedMenu?.slug === subitem.slug &&
            selectedMenu?.parentId === parentId
              ? 'tw-font-semibold'
              : ''
          } 
          ${isOpen ? 'tw-ml-2' : 'tw-ml-1'}`}
          >
            {subitem.label}
          </Paragraphs>
        </div>
      ))}
    </div>
  )
}

export default SubMenu
