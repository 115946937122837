import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AgList from '../../../../components/commons/AgGrid/AgList'
import DeleteModal from '../../../../components/commons/modals/DeleteModal/DeleteModal'
import { actionContextMenu } from '../../../../infrastructure/utils/common'
import Modal from '../../../../components/commons/modals/Modal/Modal'
import Select from 'react-select'
import IconPicker from '../../../../components/commons/IconPicker/IconPicker'
import useNavReportList from './hooks/useNavReportList'

const NavReportList = () => {
  const { t } = useTranslation()
  const { companyId } = useParams()
  const navigate = useNavigate()
  const {
    gridApi,
    onGridReady,
    deleteModal,
    setDeleteModal,
    loadedData,
    perPage,
    currentPage,
    totalPages,
    rowData,
    onRowDragEnd,
    showAddReportModal,
    getContextMenu,
    reportsAvailable,
    selectedOption,
    handleSelect,
    handleNameChangeReport,
    onAddNewReport,
    cmsAvaillables,
    currentEntity,
    handleCloseModal,
    handleFormFolder,
    onDeleteMenuItem,
    formFolder,
    menuColumns,
    onCellEditingStopped,
    handleFolderAction,
  } = useNavReportList(companyId as string)

  return (
    <>
      <AgList
        title={currentEntity}
        onCellEditingStopped={onCellEditingStopped}
        columns={menuColumns}
        rowModelType={'clientSide'}
        loading={loadedData}
        gridApi={gridApi}
        currentPage={currentPage}
        getContextMenu={getContextMenu}
        totalPages={totalPages}
        onGridReady={onGridReady}
        pagination={false}
        paginationPageSize={perPage}
        onRowDragEnd={onRowDragEnd}
        rowData={rowData} // Pasar el estado de las filas al componente AgList
        rowDragManaged={true}
        onRowDoubleClicked={(row) => {
          if (row.data.type === 'folder') {
            actionContextMenu(
              'EDIT',
              row,
              null,
              location.pathname + '/folder/' + row.data.id,
              navigate
            )
          }
        }}
      />
      <DeleteModal
        isOpen={deleteModal.open}
        onClose={() => setDeleteModal({ open: false, id: 0 })}
        description={t('¿Está seguro que desea eliminar este menu-item?')}
        onConfirm={() => onDeleteMenuItem(deleteModal.id as number)}
        title={t('titles.eliminar-menu-item')}
      />
      <Modal
        onConfirm={() => {
          if (showAddReportModal.type === 'folder') {
            if (showAddReportModal.action === 'EDIT') {
              handleFolderAction('EDIT')
            } else {
              handleFolderAction('CREATE')
            }
          } else {
            onAddNewReport({ type: showAddReportModal.type })
          }
        }}
        onClose={handleCloseModal}
        title={showAddReportModal.title}
        isOpen={showAddReportModal.open}
      >
        {showAddReportModal.type !== 'folder' && (
          <div className="tw-py-5 tw-flex tw-flex-col tw-gap-2">
            <Select
              value={selectedOption && selectedOption.value}
              onChange={(selectedOption) =>
                handleSelect({
                  selected: selectedOption,
                  type: showAddReportModal.type,
                })
              }
              options={
                showAddReportModal.type === 'report'
                  ? reportsAvailable
                  : cmsAvaillables
              }
              placeholder={
                showAddReportModal.type === 'report'
                  ? t('messages.Selecciona un informe')
                  : t('messages.Selecciona un CMS')
              }
            />
            {selectedOption && (
              <div className="tw-flex tw-py-5">
                <input
                  onChange={handleNameChangeReport}
                  type="text"
                  placeholder={t('Nombre')}
                  className="tw-input tw-outline-none tw-rounded-md  tw-p-2 tw-w-full tw-border tw-border-gray-300"
                />
              </div>
            )}
          </div>
        )}
        {showAddReportModal.type === 'folder' && (
          <div className="tw-py-5 tw-flex tw-flex-col tw-gap-2">
            <input
              onChange={(e) => handleFormFolder('label', e.target.value)}
              value={formFolder.label}
              type="text"
              placeholder={t('Nombre')}
              className="tw-input tw-outline-none tw-rounded-md  tw-p-2 tw-w-full tw-border tw-border-gray-300"
            />
            <IconPicker
              selectedIcon={formFolder.icon}
              onSelect={(iconName) => handleFormFolder('icon', iconName)}
            />
          </div>
        )}
      </Modal>
    </>
  )
}

export default NavReportList
