import styled from 'styled-components'

export const Spinner = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;

  &::after {
    content: ' ';
    display: block;
    width: 44px;
    height: 44px;
    margin: 6px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff rgba(44, 44, 44, 1) #fff rgba(44, 44, 44, 1);
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
