import React from 'react';
import RoomIcon from '@mui/icons-material/Room';
import addIcon from '../../../infrastructure/assets/svg/add.svg';

interface AutocompleteItem {
  place_id: string;
  description: string;
  // Agrega aquí todas las propiedades necesarias de AutocompletePrediction
}

interface Props {
  suggestions: AutocompleteItem[] | string[];
  onAdd?: (index: number) => void;
}

const AutocompleteSuggestions: React.FC<Props> = ({ suggestions, onAdd }) => {
  const [hoveredSuggestion, setHoveredSuggestion] = React.useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredSuggestion(index);
  };

  const handleMouseLeave = () => {
    setHoveredSuggestion(null);
  };

  return (
    <div>
      {suggestions.map((suggestion: AutocompleteItem | string, index: number) => (
        <div
          key={index} // Usa el índice como clave si suggestions es un array de strings
          className="tw-flex tw-justify-start tw-items-center tw-space-x-2 tw-px-2 tw-py-2 tw-cursor-pointer tw-bg-transparent hover:tw-bg-gray-50 tw-text-black"
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          onClick={() => onAdd && onAdd(index)}
        >
          <RoomIcon sx={{ stroke: 'gray', color: 'transparent' }} />
          <span className="tw-w-full">
            {typeof suggestion === 'string' ? suggestion : suggestion.description}
          </span>

          <div className="tw-flex tw-justify-end">
            {hoveredSuggestion === index && onAdd && (
              <img
                src={addIcon}
                alt="Añadir parada"
                className="tw-mr-2 tw-w-4 tw-h-4 tw-transition-all tw-animate-pulse"
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AutocompleteSuggestions;
