module.exports = {
  gray: {
    100: '#F0F0F0',
    200: '#2C2C2C',
  },
  coins: {
    euro: '#E2E2E2',
    cent: '#F5D016',
    cent2: '#C66E1D',
  },
  grey: {
    300: '#F8F8FB',
    400: '#6B6B6B',
    600: '#2C2C2C',
    700: '#E8F4F5',
    800: '#BCDFE1',
    900: '#A2A2A2',
  },
  green: {
    200: '#f6e8f1',
    300: '#d38ebc',
    600: '#22949B',
    800: '#961b6d',
    900: '#7D185C',
  },
  red: {
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#d8004d',
    600: '#DC2626',
    800: '#7F1D1D',
    900: '#7F1D1D',
  },
  blue: {
    200: '#BFDBFE',
    300: '#93C5FD',
    600: '#2563EB',
    800: '#1E3A8A',
    900: '#1E3A8A',
  },
  purple: {
    900: '#6A51C7',
    800: '#6A51C7',
  },
  alert: '#D8004D',
  primary: '#AF1685',
  secondary: '#03777B',
  tertiary: '#FC7C66',
  success: '#71D875',
  warning: '#FFB500',
  brand: '#549b9b',
  brandDark: '#336866',
  brandMedium: '#82c7cd',
  brandSoft: '#cde8e8',
  brandLight: '#8fccce',
  brand2: '#751E52',
  brand3: '#333333',
  brand4: '#F7E8F3',
  brand5: '#FFFFFF',
  header: '#363740',
  lightGrey: '#FAFAFA',
  borderGrey: '#EEEEF0',
  softGrey: '#E2E2E2',
  mediumGrey: '#B1B1B1',
  darkGrey: '#737373',
  blackGrey: '#333333',
  offer: '#FFED00',
  contrast: '#F9B119',
  highContrast: '#E30513',
  error: '#E72D51',
  errorLight: '#F9A0B1',
  correct: '#75B84E',
  gold: '#B6A260',
}