export const capabilitiesColumns = [
  {
    field: 'id',
    headerName: 'Id',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'name',
    headerName: 'Titulo',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'permissions',
    headerName: 'Permisos',
    filter: 'agTextColumnFilter',
    cellRenderer: 'customTags',
    sortable: false,
  },
]
