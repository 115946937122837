import { forwardRef } from 'react'
import { HastagContainer, NavigationContainer } from './styles'

const TagsCellRenderer = forwardRef((props: any, ref: any) => {
  // check if props.value is an array of objects
  if (
    Array.isArray(props.value) &&
    props.value.length > 0 &&
    typeof props.value[0] === 'object'
  ) {
    return (
      <NavigationContainer>
        {props.value.map((item: any, index: number) => {
          return <HastagContainer key={index}>{item.name}</HastagContainer>
        })}
      </NavigationContainer>
    )
  }
  return (
    <NavigationContainer>
      {props.value
        .filter(
          (item: string, index: number, self: any) =>
            self.indexOf(item) === index
        )
        .map((tag: string, index: number) => (
          <HastagContainer key={index}>{tag}</HastagContainer>
        ))}

    </NavigationContainer>
  )
})

export default TagsCellRenderer
