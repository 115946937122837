import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Label from '../../../../components/commons/Label'
import StickySettingsBtnBottom from '../../../../components/commons/Layouts/settingsPages/StickySettingsBtnBottom'
import useCustomToast from '../../../../infrastructure/hooks/useCustomToast'
import { IWorkspace } from '../../../../infrastructure/interfaces/settings'
import { settingsService } from '../../../../infrastructure/services/settingsService'
import { handleInputErrors } from '../../../../infrastructure/utils/common'
import { Input } from '../../styles'

const CreateWorkspace = () => {
  const [data, setData] = useState<IWorkspace>({
    name: '',
    workspace_id: '',
    application_id: '',
    application_secret: '',
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [type, setType] = useState<string>('create')

  const { responseOK } = useCustomToast()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location && location['state']) {
      setType('update')
      const state: any = location['state']
      state && setData(state.data as IWorkspace)
    }
    setLoading(false)
  }, [location])

  const onSave = async () => {
    setLoading(true)

    if (type === 'create') {
      settingsService
        .createWorkspace(data)
        .then((res) => {
          if (res.status === 200) {
            responseOK()
            setLoading(false)
            setTimeout(() => {
              navigate(-1)
            }, 300)
          }
        })
        .catch((err) => {
          handleInputErrors(err)
        })
    } else {
      settingsService
        .updateWorkspace(data)
        .then((res) => {
          if (res.status === 200) {
            responseOK()
            setLoading(false)
            setTimeout(() => {
              navigate(-1)
            }, 300)
          }
        })
        .catch((err) => {
          handleInputErrors(err)
        })
    }
    setLoading(false)
  }

  return (
    <StickySettingsBtnBottom onSave={onSave} loading={loading}>
      <div className="tw-p-8">
        <div className="user-form tw-w-full tw-mt-4 tw-flex">
          <div className="tw-flex tw-w-full tw-gap-4 lg:tw-flex-row tw-flex-col">
            {/* Left col */}
            <div className="tw-flex tw-flex-col tw-w-full lg:tw-w-9/12 2xl:tw-w-5/12 xl:tw-w-7/12">
              <div className="tw-flex tw-items-center tw-flex-row 2xl:tw-w-10/12 xl:tw-w-full tw-mb-2">
                <div className="tw-w-7/12 lg:tw-w-5/12">
                  <Label
                    label="Nombre"
                    translate
                    className="tw-mb-1 md:tw-mb-0 tw-whitespace-nowrap tw-mr-2 tw-block"
                  />
                </div>
                <div className="tw-block tw-w-full">
                  <Input
                    className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800"
                    type="text"
                    tabIndex={1}
                    value={data?.name}
                    required
                    name="name"
                    onChange={(e) => {
                      if (e.target.classList.contains('tw-border-red-500')) {
                        e.target.classList.remove('tw-border-red-500')
                      }
                      e.target.parentElement
                        ?.querySelector('.tw-text-red-500')
                        ?.remove()
                      setData({ ...data, name: e.target.value })
                    }}
                  />
                </div>
              </div>

              <div className="tw-flex tw-items-center tw-flex-row 2xl:tw-w-10/12 xl:tw-w-full tw-mb-2">
                <div className="tw-w-7/12 lg:tw-w-5/12">
                  <Label
                    label="Workspace ID"
                    translate
                    className="tw-mb-1 tw-md:mb-0 tw-whitespace-nowrap tw-mr-2 tw-block"
                  />
                </div>
                <div className="tw-block tw-w-full">
                  <Input
                    className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800"
                    type="text"
                    name="workspace_id"
                    tabIndex={3}
                    required
                    value={data?.workspace_id}
                    onChange={(e) => {
                      if (e.target.classList.contains('tw-border-red-500')) {
                        e.target.classList.remove('tw-border-red-500')
                      }
                      e.target.parentElement
                        ?.querySelector('.tw-text-red-500')
                        ?.remove()
                      setData({ ...data, workspace_id: e.target.value })
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Right col */}
            <div className="tw-flex tw-flex-col tw-w-full lg:tw-w-10/12 xl:tw-w-6/12 2xl:tw-w-5/12">
              <div className="tw-flex tw-items-center tw-flex-row 2xl:tw-w-10/12 lg:tw-w-full tw-mb-2">
                <div className="tw-w-7/12 lg:tw-w-6/12">
                  <Label
                    label="Application ID"
                    translate
                    className="tw-mb-1 tw-md:mb-0 tw-whitespace-nowrap tw-mr-2"
                  />
                </div>
                <div className="tw-block tw-w-full">
                  <Input
                    className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800"
                    type="text"
                    name="application_id"
                    required
                    tabIndex={2}
                    value={data?.application_id}
                    onChange={(e) => {
                      if (e.target.classList.contains('tw-border-red-500')) {
                        e.target.classList.remove('tw-border-red-500')
                      }
                      e.target.parentElement
                        ?.querySelector('.tw-text-red-500')
                        ?.remove()
                      setData({ ...data, application_id: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div className="tw-flex tw-items-center tw-flex-row 2xl:tw-w-10/12 lg:tw-w-full tw-mb-2">
                <div className="tw-w-7/12 lg:tw-w-6/12">
                  <Label
                    label="Application Secret"
                    translate
                    className="tw-mb-1 tw-md:mb-0 tw-whitespace-nowrap tw-mr-2"
                  />
                </div>
                <div className="tw-block tw-w-full">
                  <Input
                    className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800"
                    type="password"
                    name="application_secret"
                    required
                    tabIndex={4}
                    value={data?.application_secret}
                    onChange={(e) => {
                      if (e.target.classList.contains('tw-border-red-500')) {
                        e.target.classList.remove('tw-border-red-500')
                      }
                      e.target.parentElement
                        ?.querySelector('.tw-text-red-500')
                        ?.remove()
                      setData({ ...data, application_secret: e.target.value })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StickySettingsBtnBottom>
  )
}

export default CreateWorkspace
