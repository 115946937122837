import { formatDate } from '../../utils/common'

export const statusColumns = [
  {
    field: 'name',
    headerName: 'Nombre',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'start_date',
    headerName: 'Fecha de inicio',
    filter: 'agDateColumnFilter',
    cellRenderer: ({ value }: any) => {
      return value ? formatDate(value) : '-'
    },
  },
  {
    field: 'end_date',
    headerName: 'Fecha fin',
    filter: 'agDateColumnFilter',
    cellRenderer: ({ value }: any) => {
      return value ? formatDate(value) : '-'
    },
  },
  {
    field: 'next_update_date',
    headerName: 'Fecha prox. actualización',
    filter: 'agDateColumnFilter',
    cellRenderer: ({ value }: any) => {
      return value ? formatDate(value) : '-'
    },
  },
  {
    field: 'status',
    headerName: 'Estado',
    filter: 'agTextColumnFilter',
  },
]
