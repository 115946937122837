import { useParams } from 'react-router-dom'
import AgList from '../../../../../components/commons/AgGrid/AgList'
import { useTranslation } from 'react-i18next'
import DeleteModal from '../../../../../components/commons/modals/DeleteModal/DeleteModal'
import Modal from '../../../../../components/commons/modals/Modal/Modal'
import Select from 'react-select'
import useFolderList from './hooks/useFolderList'
const FolderList = () => {
  // debemos coger tanto el id de la carpeta  como el de la compañía que esta en la url

  const { companyId: companyIdParam, folderId: folderIdParam } = useParams<{
    companyId: string
    folderId: string
  }>()
  const companyId = parseInt(companyIdParam as string)
  const folderId = parseInt(folderIdParam as string)
  const {
    rowData,
    columns,
    onGridReady,
    gridApi,
    deleteModal,
    setDeleteModal,
    getContextMenu,
    onDeleteMenuItem,
    handleCloseModal,
    showAddReportModal,
    handleSelect,
    reportsAvailable,
    selectedOption,
    handleNameChangeReport,
    cmsAvailable,
    onAddReport,
    onRowDragEnd,
    onCellEditingStopped,
  } = useFolderList({
    companyId,
    folderId,
  } as any)
  const { t } = useTranslation()

  return (
    <>
      <AgList
        rowModelType={'clientSide'}
        onRowDragEnd={onRowDragEnd}
        rowDragManaged={true}
        onCellEditingStopped={onCellEditingStopped}
        columns={columns}
        gridApi={gridApi}
        rowData={rowData}
        onGridReady={onGridReady}
        getContextMenu={getContextMenu}
        onRowDoubleClicked={(row) => console.log('row', row)}
      />
      <DeleteModal
        isOpen={deleteModal.open}
        onClose={() => setDeleteModal({ open: false, id: 0 })}
        description={t(
          'messages.¿Está seguro que desea eliminar este registro?'
        )}
        onConfirm={() => onDeleteMenuItem(deleteModal.id)}
        title={t('titles.Eliminar informe')}
      />
      <Modal
        onConfirm={() => onAddReport({ type: showAddReportModal.type })}
        onClose={handleCloseModal}
        title={showAddReportModal.title}
        isOpen={showAddReportModal.open}
      >
        <div className="tw-py-5 tw-flex tw-flex-col tw-gap-2">
          <Select
            value={selectedOption && selectedOption.value}
            onChange={(selectedOption) =>
              handleSelect({
                selected: selectedOption,
                type: showAddReportModal.type,
              })
            }
            options={
              showAddReportModal.type === 'report'
                ? reportsAvailable
                : cmsAvailable
            }
            placeholder={
              showAddReportModal.type === 'report'
                ? t('messages.Selecciona un informe')
                : t('messages.Selecciona un CMS')
            }
          />
          {selectedOption && (
            <div className="tw-flex tw-py-5">
              <input
                onChange={handleNameChangeReport}
                type="text"
                placeholder={t('Nombre')}
                className="tw-input tw-outline-none tw-rounded-md  tw-p-2 tw-w-full tw-border tw-border-gray-300"
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default FolderList
