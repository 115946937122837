import {
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsParams,
  MenuItemDef,
} from 'ag-grid-community'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AgList from '../../../components/commons/AgGrid/AgList'
import DeleteModal from '../../../components/commons/modals/DeleteModal/DeleteModal'
import { workspaceColumns } from '../../../infrastructure/constant/columns/workspaceColumns'
import { UserRoles } from '../../../infrastructure/interfaces/users'
import { settingsService } from '../../../infrastructure/services/settingsService'
import storageService from '../../../infrastructure/services/storageService'
import {
  actionContextMenu,
  formattedFilters,
} from '../../../infrastructure/utils/common'
import { checkPermissions } from '../../../infrastructure/utils/permissions'

interface IWorkspaceListProps {}
const WorkspaceList = (props: IWorkspaceListProps) => {
  const { t } = useTranslation()
  const [gridApi, setGridApi] = useState<GridApi>()
  const [deleteModal, setDeleteModal] = useState<any>(false)
  const [perPage, setPerPage] = useState<number>(14)
  const [page, setPage] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const navigate = useNavigate()

  const user =
    useSelector((state: any) => state.auth) || storageService.getUser()

  const getContextMenu = (row: GetContextMenuItemsParams) => {
    if (!row.value) return []
    const items: MenuItemDef[] = []

    let currentUser = user?.user || storageService.getUser()

    if (
      checkPermissions(
        ['workspaces.*', 'workspaces.edit'],
        currentUser?.permissions
      ) ||
      currentUser?.role?.name === UserRoles.ADMIN
    ) {
      items.push({
        name: 'Editar',
        action: () =>
          actionContextMenu(
            'EDIT',
            row,
            null,
            location.pathname + '/create-workspace',
            navigate
          ),
        cssClasses: ['tw-cursor-pointer'],
      })
    }
    if (
      checkPermissions(
        ['workspaces.*', 'workspaces.delete'],
        currentUser?.permissions
      ) ||
      currentUser?.role?.name === UserRoles.ADMIN
    ) {
      items.push({
        name: 'Eliminar',
        action: () => actionContextMenu('DELETE', row, setDeleteModal),
        cssClasses: ['tw-text-alert', 'tw-cursor-pointer'],
      })
    }
    return items
  }

  const handleDelete = () => {
    settingsService.deleteWorkspace(deleteModal.id).then((res) => {
      setDeleteModal(false)
      gridApi?.refreshServerSide({ purge: true })
    })
  }

  const handleDoubleClick = (row: any) => {
    let currentUser = user?.user || storageService.getUser()
    if (
      checkPermissions(
        ['workspaces.*', 'workspaces.edit'],
        currentUser.permissions
      ) ||
      currentUser.role?.name === UserRoles.ADMIN
    ) {
      actionContextMenu(
        'EDIT',
        row,
        null,
        location.pathname + '/create-workspace',
        navigate
      )
    }
  }

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
    const updateData = (data: any) => {
      let idSequence = 1
      data?.items?.forEach(function (item: any) {
        item.id = idSequence++
      })

      const datasource = {
        getRows(params: IServerSideGetRowsParams) {
          let filters: any = null
          if (
            params.request.filterModel &&
            Object.keys(params.request.filterModel).length > 0
          ) {
            filters = formattedFilters(params.request.filterModel)
          }

          if (params.request.sortModel.length > 0) {
            filters = {
              ...filters,
              order_field: params.request.sortModel[0].colId,
              order_direction: params.request.sortModel[0].sort,
            }
          }

          settingsService
            .getWorkspaces({
              page: params.api.paginationGetCurrentPage() + 1,
              per_page: perPage,
              from: params.request.endRow,
              ...filters,
            })
            .then((response) => {
              params.success({
                rowData: response.data.items,
                rowCount: response.data.meta.total,
              })
            })
            .catch((error) => {
              params.fail()
            })
        },
      }
      params.api.setServerSideDatasource(datasource)
    }

    updateData(params.api)
  }

  return (
    <>
      <AgList
        gridApi={gridApi}
        currentPage={currentPage}
        totalPages={totalPages}
        columns={workspaceColumns}
        getContextMenu={getContextMenu}
        onGridReady={onGridReady}
        onRowDoubleClicked={handleDoubleClick}
        pagination={true}
        paginationPageSize={perPage}
        cacheBlockSize={perPage}
      />

      <DeleteModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        description={'¿Está seguro que desea eliminar este registro?'}
        onConfirm={handleDelete}
        title={t('titles.Eliminar workspace')}
      />
    </>
  )
}

export default WorkspaceList
