import { icons } from 'eva-icons'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Label from '../../../../components/commons/Label'
import StickySettingsBtnBottom from '../../../../components/commons/Layouts/settingsPages/StickySettingsBtnBottom'
import WidgetList from '../../../../components/commons/Pages/Widgets/WidgetList'
import CMSModal from '../../../../components/commons/modals/CMSModal/CMSModal'
import { Spinner } from '../../../../components/commons/styles/loaders'
import useCustomToast from '../../../../infrastructure/hooks/useCustomToast'
import {
  ICMS,
  ICompanyItem,
} from '../../../../infrastructure/interfaces/settings'
import { UserRoles } from '../../../../infrastructure/interfaces/users'
import { settingsService } from '../../../../infrastructure/services/settingsService'
import storageService from '../../../../infrastructure/services/storageService'
import { RootState } from '../../../../infrastructure/store'
import { setMenuSignal } from '../../../../infrastructure/store/sidebar/sidebarSlice'
import {
  handleInputErrors,
  slugify,
  userTypeGuard,
} from '../../../../infrastructure/utils/common'
import { checkPermissions } from '../../../../infrastructure/utils/permissions'
import { CommonContainer, ContentBox, Input } from '../../styles'
export interface OptionType {
  value: string
  label: string
}

const CreatePage = () => {
  const { t } = useTranslation()
  const { noPermissionsToast } = useCustomToast()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user =
    useSelector((state: RootState) => state.auth.user) ||
    storageService.getUser()

  const [data, setData] = useState<any>(null)
  const [type, setType] = useState<string>('create')
  const [selectedIcon, setSelectedIcon] = useState<string | null>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selection, setSelection] = useState<string>('')
  const [widgetList, setWidgetList] = useState<any>({})
  const [widgetCopy, setWidgetCopy] = useState<any>({})
  const [reportList, setReportList] = useState<any>([])
  const [companies, setCompanies] = useState<ICompanyItem[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const handleIconChange = (selectedOption: OptionType | null) => {
    setData((prevData: ICMS) => ({
      ...prevData,
      icon: selectedOption ? selectedOption.value : null,
    }))
    setSelectedIcon(selectedOption ? selectedOption.value : null)
    document.getElementById('icon')?.classList.remove('tw-border-red-500')
  }

  const evaIconOptions = Object.keys(icons).map((icon) => ({
    label: icon,
    value: icon,
  }))

  useEffect(() => {
    if (
      !(
        typeof user !== 'boolean' &&
        userTypeGuard(user) &&
        (checkPermissions(
          ['pages.*', 'pages.view', 'pages.edit'],
          user.permissions
        ) ||
          user.role.name === UserRoles.ADMIN)
      )
    ) {
      navigate(-1)
    }
  }, [user])

  let callingAfter = false
  useEffect(() => {
    if (!callingAfter) {
      getData()
      callingAfter = true
    }
  }, [])

  const getData = useCallback(() => {
    if (
      typeof user !== 'boolean' &&
      userTypeGuard(user) &&
      (checkPermissions(
        ['reports.*', 'reports.view', 'reports.list'],
        user.permissions
      ) ||
        user.role.name === UserRoles.ADMIN)
    ) {
      settingsService
        .getReports({
          per_page: 500,
        })
        .then((res) => {
          setReportList(res.data.items)
        })
    } else noPermissionsToast()

    if (
      typeof user !== 'boolean' &&
      userTypeGuard(user) &&
      (checkPermissions(
        ['companies.*', 'companies.view', 'companies.list'],
        user.permissions
      ) ||
        user.role.name === UserRoles.ADMIN)
    ) {
      settingsService.getCompanies({ per_page: 500 }).then((res) => {
        setCompanies(res.data.items)
        setLoading(false)
      })
    } else {
      noPermissionsToast()
      setLoading(false)
    }
  }, [])

  let calling = false
  useEffect(() => {
    if (location && location['state'] && !calling) {
      const state: any = location['state']
      state && getPageData(state.data.id)
      setType('update')
    }
  }, [location])

  useEffect(() => {
    if (selection && String(selection).length > 0) {
      createWidget()
    }
  }, [selection])

  const createWidget = async () => {
    setLoading(true)
    let id = data?.id || null

    if (!data || !data.title || !data.title.length) {
      document.getElementById('title')?.classList.add('tw-border-red-500')
      return
    }

    if (!data.id || type !== 'update') {
      if (!data['visible']) data['visible'] = false
      if (!data['is_home']) data['is_home'] = false

      await settingsService
        .createPage(data as ICMS)
        .then((response) => {
          if (response) {
            setData(response.data)
            id = response.data.id
            setType('update')
          }
          document
            .getElementById('title')
            ?.classList.remove('tw-border-red-500')
        })
        .catch((error) => {
          setLoading(false)
          return toast(t('errors.' + error.response.data.message) as string, {
            type: 'error',
            className: 'toast-error tw-p-4',
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          })
        })
    }
    if (!id) return
    await settingsService
      .createWidget({
        type: selection,
        page_id: id,
        position: data.widgets?.length + 1 || 0,
        name: 'Nuevo bloque HTML',
        content: '<p>Contenido</p>',
      })
      .then((res) => {
        if (res.data) {
          setWidgetList({
            ...widgetList,
            children: [
              ...(widgetList.children ?? []),
              {
                ...res.data,
                children: [],
              },
            ],
          })
          setWidgetCopy({
            ...widgetCopy,
            children: [
              ...(widgetCopy.children ?? []),
              {
                ...res.data,
                children: [],
              },
            ],
          })
          setSelection('')
          setLoading(false)
        }
      })
      .catch((err) => {
        toast(t('errors.' + err.response.data.message) as string, {
          type: 'error',
          position: 'top-center',
          autoClose: 5000,
        })
        setLoading(false)
      })
  }

  const getPageData = async (id: number) => {
    calling = true
    await settingsService
      .getPage(id)
      .then((res) => {
        setData(res.data)
        setSelectedIcon(res.data.icon)
        let orderedWigets = res.data.widgets || []
        if (orderedWigets) {
          orderedWigets = orderedWigets.sort((a: any, b: any) => {
            return a.position - b.position
          })
        }

        setWidgetList({
          label: 'menu',
          leaf: false,
          children: orderedWigets
            ? orderedWigets.map((item: any) => {
                return {
                  ...item,
                }
              })
            : [],
        })
        setWidgetCopy({
          label: 'menu',
          leaf: false,
          children: orderedWigets
            ? orderedWigets.map((item: any) => {
                return {
                  ...item,
                }
              })
            : [],
        })
      })
      .catch((err) => {
        console.log(err)
      })
    setLoading(false)
  }

  const onSave = () => {
    if (data && !data.visible) {
      data.visible = false
    }
    if (data && !data['is_home']) data['is_home'] = false

    if (type === 'update') {
      if (
        typeof user !== 'boolean' &&
        userTypeGuard(user) &&
        (checkPermissions(['pages.*', 'pages.edit'], user.permissions) ||
          user.role.name === UserRoles.ADMIN)
      ) {
        let company = data.company_id || data.company.id

        if (data.company) delete data.company
        if (data.slug.indexOf(' ') > -1) {
          data.slug = slugify(data.slug)
        }
        data.is_home = true
        settingsService
          .updatePage(data as ICMS)
          .then((res) => {
            if (res.status === 200) {
              toast(t('messages.el registro se ha guardado') as string, {
                type: 'success',
                className: 'toast-success tw-p-4',
                position: 'top-center',
              })
              setMenuVisible(res)
              navigate('/settings/cms', { replace: true })
            }
          })
          .catch((err) => {
            handleInputErrors(err)
            setData({ ...data, company_id: company })
          })
      } else {
        noPermissionsToast()
      }
    } else {
      if (
        typeof user !== 'boolean' &&
        userTypeGuard(user) &&
        (checkPermissions(['pages.*', 'pages.create'], user.permissions) ||
          user.role.name === UserRoles.ADMIN)
      ) {
        settingsService
          .createPage(data as ICMS)
          .then((res) => {
            if (res.status === 200) {
              toast(t('messages.el registro se ha guardado') as string, {
                type: 'success',
                className: 'toast-success tw-p-4',
                position: 'top-center',
              })
              setData(res.data)
              setMenuVisible(res)
              setType('update')
            }
          })
          .catch((err) => {
            handleInputErrors(err)
          })
      } else {
        noPermissionsToast()
      }
    }
  }

  function setMenuVisible(res: any) {
    setTimeout(() => {
      dispatch(setMenuSignal(true))
    }, 1000)
  }

  return (
    <StickySettingsBtnBottom onSave={onSave} loading={loading}>
      <div className="tw-w-full tw-relative">
        {/* top section inputs */}
        <CommonContainer className="tw-pt-6">
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-h-full tw-pt-4 tw-align-center lg:tw-justify-evenly tw-justify-center">
            <div className="lg:tw-w-2/5 tw-w-full">
              <div className="tw-container">
                <div className="tw-w-full tw-mb-8">
                  <div className="tw-grid tw-grid-cols-3 tw-py-2">
                    <Label
                      label="Título de la página"
                      className="tw-mr-2 tw-text-right"
                      align="right"
                      justify="end"
                    />
                    <div className="tw-col-span-2">
                      <Input
                        className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                        type="text"
                        required
                        id="title"
                        name="title"
                        onChange={(e) => {
                          if (
                            e.target.classList.contains('tw-border-red-500')
                          ) {
                            e.target.classList.remove('tw-border-red-500')
                          }

                          e.target.parentElement
                            ?.querySelector('.tw-text-red-500')
                            ?.remove()

                          setData({
                            ...data,
                            title: e.target.value,
                          })
                        }}
                        value={data?.title || ''}
                      />
                    </div>
                    <Label
                      label="Slug de la página"
                      className="tw-mr-2 tw-text-right tw-mt-4"
                      align="right"
                      justify="end"
                    />
                    <div className="tw-col-span-2">
                      <Input
                        className="tw-appearance-none tw-border-2 tw-mt-4 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                        type="text"
                        id="slug"
                        name="slug"
                        onChange={(e) => {
                          if (
                            e.target.classList.contains('tw-border-red-500')
                          ) {
                            e.target.classList.remove('tw-border-red-500')
                          }
                          e.target.parentElement
                            ?.querySelector('.tw-text-red-500')
                            ?.remove()
                          setData({
                            ...data,
                            slug: e.target.value,
                          })
                        }}
                        value={data?.slug || ''}
                      />
                    </div>
                    {slugify(data?.title || '') !== data?.slug && (
                      <div className="tw-col-span-3 tw-text-right tw-mt-2">
                        <span className="tw-text-xs tw-text-gray-500">
                          {t('messages.el slug se generará automáticamente')}
                        </span>

                        <button
                          className="tw-ml-1 tw-text-xs tw-text-gray-500 tw-underline"
                          onClick={() => {
                            setData({
                              ...data,
                              slug: slugify(data?.title || ''),
                            })
                          }}
                        >
                          {t('actions.generar slug')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:tw-w-1/3 tw-w-full">
              <div className="tw-container">
                <div className="tw-w-full tw-mb-8">
                  <div className="tw-grid tw-grid-cols-3 tw-py-2">
                    <Label
                      label="Icono"
                      className="tw-mr-2 tw-text-right"
                      align="right"
                      justify="end"
                    />
                    <div className="tw-col-span-2">
                      <Select
                        className="tw-grid tw-z-[50] tw-appearance-none tw-w-full tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2 tw-italic tw-cursor-pointer"
                        value={
                          selectedIcon
                            ? { value: selectedIcon, label: selectedIcon }
                            : null
                        }
                        onChange={(selectedOption) =>
                          handleIconChange(selectedOption as OptionType | null)
                        }
                        options={evaIconOptions}
                        id="icon"
                        name="icon"
                        components={{
                          Option: (props: any) => {
                            const iconName = props?.data?.value
                            return (
                              <div
                                {...props.innerProps}
                                className="tw-p-1 material-icons hover:tw-bg-secondary hover:tw-bg-opacity-30"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: icons[iconName]?.toSvg({
                                      width: 24,
                                      height: 24,
                                    }),
                                  }}
                                />
                              </div>
                            )
                          },
                          SingleValue: (props: any) => {
                            const iconName = props?.data?.value
                            return (
                              <div
                                className="tw-flex tw-items-center tw-cursor-text"
                                title="Empezar a buscar"
                              >
                                {iconName && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: icons[iconName]?.toSvg({
                                        width: 24,
                                        height: 24,
                                      }),
                                    }}
                                  />
                                )}
                                <span className="tw-ml-2">
                                  {props.data.label}
                                </span>
                              </div>
                            )
                          },
                        }}
                        styles={{
                          control: (base: any) => ({
                            ...base,
                            display: 'flex',
                            flexWrap: 'wrap',
                          }),
                          valueContainer: (base: any) => ({
                            ...base,
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            flexWrap: 'wrap',
                          }),
                          option: (base: any) => ({
                            ...base,
                            display: 'flex',
                            alignItems: 'center',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="tw-grid tw-grid-cols-3 tw-py-2">
                    <Label
                      label="Asociar a empresa"
                      className="tw-mr-2 tw-text-right"
                      align="right"
                      justify="end"
                    />
                    <div className="tw-col-span-2">
                      {typeof user !== 'boolean' &&
                      userTypeGuard(user) &&
                      (checkPermissions(
                        ['companies.*', 'companies.view', 'companies.list'],
                        user.permissions
                      ) ||
                        user.role!.name === UserRoles.ADMIN) ? (
                        <Select
                          value={
                            data && companies.length && 'company_id' in data
                              ? {
                                  value: data.company_id,
                                  label: companies.find(
                                    (company) => company.id === data.company_id
                                  )?.name,
                                }
                              : data && companies.length && data.company
                              ? {
                                  value: data.company.id,
                                  label: data.company.name,
                                }
                              : null
                          }
                          className="tw-appearance-none tw-w-full tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2 tw-italic tw-cursor-pointer"
                          onChange={(e: any) => {
                            if (
                              document
                                .getElementById('company')
                                ?.classList.contains('tw-border-red-500')
                            ) {
                              document
                                .getElementById('company')
                                ?.classList.remove('tw-border-red-500')
                            }
                            setData({
                              ...data,
                              company_id: e.value,
                            })
                          }}
                          placeholder="Seleccione una empresa"
                          options={companies.map((company) => ({
                            label: company.name,
                            value: company.id,
                          }))}
                          id="company"
                          name="company"
                        />
                      ) : (
                        <Input
                          className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                          type="text"
                          required
                          readOnly
                          disabled
                          value={data && data.company?.name}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonContainer>
        {/* content section */}
        <CommonContainer className="tw-py-8 tw-px-8">
          <div className="tw-flex tw-w-full tw-h-full tw-align-center tw-relative">
            <div className="tw-w-2/5">
              <div className="tw-container tw-w-full">
                <div className="tw-grid tw-grid-cols-3">
                  <Label
                    label="Contenidos"
                    className="tw-text-left"
                    align="center"
                    justify="start"
                  />
                </div>
              </div>
            </div>
          </div>

          <ContentBox>
            {/* componente widgets */}
            {loading ? (
              <div className="tw-flex tw-flex-wrap tw-justify-center tw-items-center tw-mt-4">
                <Spinner />
              </div>
            ) : (
              <Fragment>
                <WidgetList
                  widgets={widgetList}
                  setWidgets={setWidgetList}
                  reportList={reportList}
                  widgetsCopy={widgetCopy}
                  pageId={data?.id}
                  data={data}
                />

                {Boolean(data && data.title) && (
                  <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-align-center tw-h-full tw-relative">
                    {widgetList &&
                    'children' in widgetList &&
                    widgetList.children.length <= 2 ? (
                      <button
                        className={`tw-bg-white hover:tw-bg-gray-100 tw-text-gray-800 tw-py-2 tw-px-6 tw-border-2 tw-border-gray-400 tw-rounded-lg tw-shadow-lg tw-absolute tw-z-20`}
                        style={{ top: '50%' }}
                        type="button"
                        onClick={(e) => {
                          e.preventDefault()
                          setOpenModal(true)
                        }}
                      >
                        Añadir bloque{' '}
                      </button>
                    ) : widgetList && 'children' in widgetList ? (
                      <button
                        className={`tw-bg-white hover:tw-bg-gray-100 tw-text-gray-800 tw-py-2 tw-px-6 tw-border-2 tw-border-gray-400 tw-rounded-lg tw-shadow-lg tw-relative tw-z-20`}
                        type="button"
                        onClick={(e) => {
                          e.preventDefault()
                          setOpenModal(true)
                        }}
                      >
                        Añadir bloque{' '}
                      </button>
                    ) : (
                      <button
                        className={`tw-bg-white hover:tw-bg-gray-100 tw-text-gray-800 tw-py-2 tw-px-6 tw-border-2 tw-border-gray-400 tw-rounded-lg tw-shadow-lg tw-absolute tw-z-20`}
                        style={{ top: '50%' }}
                        type="button"
                        onClick={(e) => {
                          e.preventDefault()
                          setOpenModal(true)
                        }}
                      >
                        Añadir bloque{' '}
                      </button>
                    )}
                  </div>
                )}
              </Fragment>
            )}
          </ContentBox>
        </CommonContainer>

        {Boolean(openModal) && (
          <CMSModal
            onClick={(selection: string) => {
              setSelection(selection)
              setOpenModal(false)
            }}
            onClose={() => setOpenModal(false)}
          />
        )}
      </div>
    </StickySettingsBtnBottom>
  )
}

export default CreatePage
