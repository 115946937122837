import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import Logo from './components/commons/Logo'
import Paragraphs from './components/commons/Paragraphs/Paragraphs'
import { ICompanyItem } from './infrastructure/interfaces/settings'
import ProtectedRoutes from './infrastructure/routes/ProtectedRoutes'
import SettingsRoutes from './infrastructure/routes/SettingsRoutes'
import { api } from './infrastructure/services/api'
import { RootState } from './infrastructure/store'
import { setSelectedEntity } from './infrastructure/store/multitenant/multitenantSlice'
import ConfirmationEmail from './modules/Auth/ConfirmationEmailScreen'
import CreatePasswordScreen from './modules/Auth/CreatePasswordScreen'
import ForgotPassword from './modules/Auth/ForgotPassword'
import Login from './modules/Auth/LoginScreen'
import ResetPassword from './modules/Auth/ResetPasswordScreen'
import Dashboard from './modules/Dashboard/Dashboard'
import Maintenance from './modules/Maintenance/Maintenance'
import { useTranslation } from 'react-i18next'
import { UserRoles } from './infrastructure/interfaces/users'
import PublicRoutes from './infrastructure/routes/PublicRoutes'

function App() {
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true'
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth = useSelector((state: RootState) => state?.auth)
  const [loading, setLoading] = useState(true)
  const path = window?.location.pathname
  // TENANT configuration
  let stop = false
  useEffect(() => {
    if (!stop) {
      setLoading(true)
      api
        .get('/branding')
        .then((res) => {
          const entity: ICompanyItem = res.data
          if (entity) {
            dispatch(setSelectedEntity(entity))
          }
          if (path === '/maintenance') {
            window.location.href = '/'
          }
          return setLoading(false)
        })
        .catch((err) => {
          return setLoading(false)
        })
    }
    stop = true

    return () => {
      setLoading(false)
      null
    }
  }, [])

  if (loading) {
    return (
      <div className="tw-flex tw-flex-col tw-absolute tw-top-0 tw-left-0 tw-bg-white tw-bg-opacity-80 tw-h-full tw-w-full tw-z-10 tw-items-center tw-justify-center">
        <Logo width={100} />
        <Paragraphs>{t('messages.Cargando')}</Paragraphs>
      </div>
    )
  }

  if (isMaintenanceMode) {
    return (
      <Routes>
        <Route path="*" element={<Maintenance />} />
      </Routes>
    )
  }

  return (
    <>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/confirmation-email" element={<ConfirmationEmail />} />
          <Route
            caseSensitive
            path="/create-password/"
            element={<CreatePasswordScreen />}
          />
          <Route
            path="/email-confirmation-sent"
            element={<ConfirmationEmail />}
          />
        </Route>
        <Route element={<ProtectedRoutes />}>
          <Route path="/home" element={<Dashboard />} />
          <Route path="/reports/:slug" element={<Dashboard />} />
          <Route path="/pages/:slug" element={<Dashboard />} />
          <Route
            path="settings/*"
            element={
              auth.user?.role.name === UserRoles.USER ? (
                <Navigate to="/home" />
              ) : (
                <SettingsRoutes />
              )
            }
          />
        </Route>
        <Route
          path="*"
          element={
            auth.isAuthenticated ? (
              <Navigate to="/home" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/maintenance" element={<Maintenance />} />
      </Routes>
    </>
  )
}

export default App
