import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { toast } from 'react-toastify'
import { DropDownZoneImgPlaceHolder } from './styles'
import dropDownImage from '../../../infrastructure/assets/logos/logo.png'
import { icons } from 'eva-icons'

function DropZone(props: any) {
  const { t } = useTranslation()
  const [files, setFiles] = useState([])
  const [url, setUrl] = useState()

  useEffect(() => {
    if (props.url) setUrl(props.url)
  }, [props])

  const maxSize = 8848576
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      let uploadedfiles = acceptedFiles.map((file: any) => {
        if (file.size < maxSize) {
          setUrl(undefined)
          return file
        } else {
          toast(t('errors.imagen demasiado grande') as string, {
            type: 'error',
            className: 'toast-error tw-p-4',
            position: 'top-center',
          })
          return
        }
      })
      if (uploadedfiles[0]) {
        props.onUpload && props.onUpload(uploadedfiles)
        if (props.multiple) setFiles(files.concat(uploadedfiles))
        else setFiles(uploadedfiles)
      }
    },
    [files]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: props.maxFiles,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': [],
      'image/jpg': [],
      'image/webp': [],
    },
  })

  function imageLoaded(index: number) {
    let imagePreview = document.querySelectorAll('.dz-preview')[0]
    imagePreview.classList.add('dz-success', 'dz-complete')
  }

  function removeFile(e: any, id: any) {
    e.preventDefault()
    e.stopPropagation()
    setFiles([])
    setUrl(undefined)
    props.onRemove && props.onRemove(id)
  }

  useEffect(() => {
    props && props.defaultValue && defaultValue(props.defaultValue)
  }, [props])

  function defaultValue(data: []) {
    if (Array.isArray(data)) {
      data.forEach((file) => {
        setFiles(files.concat(data))
      })
    }

    if (typeof data === 'string') {
      setUrl(data)
    }
  }

  //unmount component
  useEffect(() => {
    return () => {
      setFiles([])
      setUrl(undefined)
    }
  }, [])

  return (
    <>
      <div
        className={`tw-cursor-pointer tw-border tw-border-gray-200 tw-rounded tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2 tw-italic tw-relative  ${!files[0]
          ? 'tw-w-40 tw-h-20'
          : 'tw-w-full tw-h-full tw-max-w-96 tw-max-h-96'
          } `}
        {...getRootProps()}
        title={t('titles.seleccionar imagen')}
      >
        <input {...getInputProps()} />
        {!url && !files?.length && (
          <div className="tw-p-2 tw-w-full tw-h-full">
            <DropDownZoneImgPlaceHolder imageUrl={dropDownImage} />
          </div>
        )}
        {files[0] &&
          files.map((file: any, index: number) => (
            <div
              className="tw-w-full tw-h-full dz-preview "
              key={`preview-${index}`}
            >
              <div className="tw-w-full">
                <LazyLoadImage
                  src={URL.createObjectURL(file)}
                  alt={t(file.name || 'new imagen')}
                  afterLoad={() => imageLoaded(index)}
                />
              </div>
              <div className="dz-details">
                <div className="dz-size">
                  <span>{(file.size / (1024 * 1024)).toFixed(2)}</span> MB
                </div>
              </div>
              <a
                className="dz-remove tw-absolute tw-right-0 tw-top-1 tw-text-white tw-text-sm tw-cursor-pointer"
                href="#remove"
                onClick={(e: any) => removeFile(e, file.id)}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: icons['trash-2-outline']?.toSvg({ width: 24, height: 24 }) || '',
                  }}
                />
              </a>
            </div>
          ))}

        {url && !files[0] && (
          <div className="dz-image-preview dz-processing" key={`preview-${0}`}>
            <div className="dz-image">
              <LazyLoadImage src={url} />
            </div>
            <div className="dz-progress">
              <span className="dz-upload"></span>
            </div>
            <a
              className="dz-remove tw-absolute tw-right-0 tw-top-1 tw-text-white tw-text-sm tw-cursor-pointer"
              href="#remove"
              onClick={(e: any) => setUrl(undefined)}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: icons['trash-2-outline']?.toSvg({ width: 24, height: 24 }) || '',
                }}
              />
            </a>
            {/* cambiar imagen */}
            <span className="tw-absolute tw-top-8 tw-left-4 tw-text-gray-500 tw-font-medium">
              {t('labels.cambiar imagen')}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export default DropZone
