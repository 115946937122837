import Paragraphs from "../../commons/Paragraphs/Paragraphs";

export interface AltModalProps {
  top: number;
  left: number;
  text: string;
}

const AltModal: React.FC<AltModalProps> = ({ top, left, text }) => {
  return (
    <div
      className="tw-absolute tw-bg-gray-50 tw-shadow-[0_3px_10px_rgb(0,0,0,0.2)] tw-rounded-md tw-z-[40]"
      style={{ top: `calc(${top}px + 40px)`, left: `${left}px` }}
    >
      <Paragraphs
        size="xs"
        weight="regular"
        color='black'
        className='tw-flex tw-flex-1 tw-items-center tw-mt-1 tw-p-1 '
      >
        {text}
      </Paragraphs>
    </div>
  );
};

export default AltModal;
