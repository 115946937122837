// IconPicker.tsx
import React, { useState } from 'react'
import { icons } from 'eva-icons'

interface IconPickerProps {
  selectedIcon: string
  onSelect: (icon: string) => void
}

const IconPicker = ({ selectedIcon, onSelect }: IconPickerProps) => {
  const [searchTerm, setSearchTerm] = useState('')

  const handleSelectIcon = (iconName: string) => {
    onSelect(iconName)
  }

  const filteredIcons = Object.keys(icons).filter((iconName) =>
    iconName.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <input
        type="text"
        placeholder="Buscar íconos"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="tw-p-2 tw-border tw-border-gray-300 tw-rounded-md"
      />
      <div className="tw-flex tw-flex-wrap tw-gap-2 tw-overflow-y-auto tw-h-64">
        {filteredIcons.map((iconName) => (
          <div
            style={{
              border: selectedIcon === iconName ? '3px solid #549b9b' : '',
            }}
            key={iconName}
            className={`tw-flex tw-bg-[#eaeaea] tw-shadow-md tw-justify-center tw-h-8 tw-w-8 tw-items-center tw-p-2 tw-border tw-border-transparent tw-rounded-md tw-cursor-pointer ${
              selectedIcon === iconName ? 'tw-border-brandDark tw-border-4' : ''
            }`}
            onClick={() => handleSelectIcon(iconName)}
            title={iconName}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: icons[iconName].toSvg({ width: 24, height: 24 }),
              }}
              className="tw-flex-shrink-0"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default IconPicker
