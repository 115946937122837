import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ButtonsRoutes from '../../components/RoutePlanner/ButtonsRoutes/ButtonsRoutes'
import CodeFilter from '../../components/RoutePlanner/CodeFilter/CodeFilter'
import DynamicTables from '../../components/RoutePlanner/DataTable/DynamicTables'
import LoadGoogleMaps from '../../components/RoutePlanner/GoogleMaps/LoadGoogleMaps'
import Paragraphs from '../../components/commons/Paragraphs/Paragraphs'
import {
  IRoute,
  IRoutes,
  RouteData,
} from '../../infrastructure/interfaces/routes'
import { Container } from '../Dashboard/styles'
import { mockData } from './mocks'
import HeaderRoutes from '../../components/RoutePlanner/HeaderRoutes/HeaderRoutes'

const RoutePlanner: React.FC = () => {
  const { t } = useTranslation()
  const [routeData, setRouteData] = useState<RouteData[]>([]) //formateado para google maps
  const [routesOriginData, setRouteOriginData] = useState<RouteData[]>([]) //formateado para google maps
  const [selectedCodes, setSelectedCodes] = useState<string[]>([])
  const [data, setData] = useState<IRoutes[]>([]) //formateado para tabla
  const [showBtnRecalcMap, setShowBtnRecalcMap] = useState<boolean>(false)
  const [heightHeader, setHeightHeader] = useState<number>(0)
  const headerRef = useRef<HTMLDivElement>(null)
  const colorsRoutes = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00'] // Lista de colores para las rutas

  const removeRoute = (codrut: string) => {
    setSelectedCodes(
      selectedCodes.filter((selectedCode: string) => selectedCode !== codrut)
    )
  }

  const resetRoute = (codrut: string) => {
    // Encuentra la ruta correspondiente en los datos de prueba originales
    const originalRoute: IRoutes = Object.values(mockData).find(
      (route) => route.name === codrut
    )
    const updateRoutesData: RouteData[] = [...routeData]

    if (!originalRoute) {
      console.error(`No se encontró la ruta con el código: ${codrut}`)
      return
    }

    // Actualiza los datos de la ruta seleccionada para restablecerlos
    const updatedData = data.map((route, index) => {
      if (route.name === codrut) {
        const stops = originalRoute.stops.map((stop) => {
          return {
            ...stop,
            updated: false,
          }
        })
        const destinations = stops.map((stop: IRoute) => {
          return {
            location: {
              lat: stop.location.lat,
              lng: stop.location.lng,
            },
            partner: stop.partnerCode,
          }
        })

        updateRoutesData[index] = {
          origin: route.startLocation,
          destinations: destinations,
          color: route.color ?? '',
        }

        return {
          ...originalRoute,
          stops,
          color: route.color, // Conserva el color si es necesario
        }
      }
      return route
    })

    // Actualiza el estado de los datos
    setData(updatedData)
    setRouteData(updateRoutesData)
    setShowBtnRecalcMap(true)
  }

  useEffect(() => {
    // Obtener datos de las rutas según los códigos seleccionados
    const routesData: RouteData[] = []
    const newData: IRoutes[] = []

    selectedCodes.map((code, index) => {
      const route: IRoutes = Object.values(mockData).find(
        (route) => route.name === code
      )
      if (!route) return
      const color = colorsRoutes[index % colorsRoutes.length]
      const stops = route.stops.map((stop) => {
        return {
          ...stop,
          updated: false,
        }
      })

      const destinations = route.stops.map((stop: IRoute) => {
        return {
          location: {
            lat: stop.location.lat,
            lng: stop.location.lng,
          },
          partner: stop.partnerCode,
        }
      })

      routesData.push({
        origin: route.startLocation,
        destinations: destinations,
        color,
      })

      newData.push({
        ...route,
        stops,
        color,
      })
    })

    setRouteOriginData(routesData)
    setRouteData(routesData)
    setData(newData)
  }, [selectedCodes])

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        const resize = (headerRef.current?.clientHeight ?? 0) + 95
        setHeightHeader(resize)
      }, 100)
    }

    window.addEventListener('resize', handleResize) // Agrega el listener al evento 'resize'
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize) // Remueve el listener al desmontar el componente
    }
  }, [])

  return (
    <Container className="tw-block tw-w-screen tw-h-screen tw-overflow-auto">
      <HeaderRoutes
        headerRef={headerRef}
        t={t}
        data={data}
        selectedCodes={selectedCodes}
        setSelectedCodes={setSelectedCodes}
      />
      <div className="tw-p-4 tw-pt-0">
        <div className="tw-border tw-border-gray-300 tw-border-opacity-50">
          <div
            id="dynamic-tables"
            className={`dynamic-tables tw-pb-auto tw-h-screen tw-w-full tw-flex 
          ${
            routeData.length > 0
              ? 'tw-justify-between tw-bg-[#F0F0F2]'
              : 'tw-justify-center tw-bg-white'
          }
           `}
            style={{ height: `calc(100vh - ${heightHeader}px)` }}
          >
            {routeData.length > 0 && (
              <div className="tw-p-1 tw-w-[650px] tw-pr-2">
                <div className="tw-w-full tw-h-full tw-mb-2 tw-overflow-y-auto">
                  <DynamicTables
                    data={data}
                    setData={setData}
                    routeData={routeData}
                    setRouteData={setRouteData}
                    showBtnRecalcMap={showBtnRecalcMap}
                    setShowBtnRecalcMap={setShowBtnRecalcMap}
                    removeRoute={removeRoute}
                    resetRoute={resetRoute}
                  />
                </div>
              </div>
            )}

            <LoadGoogleMaps
              data={data}
              routesData={routeData}
              routesOriginData={routesOriginData}
              showBtnRecalcMap={showBtnRecalcMap}
              setShowBtnRecalcMap={setShowBtnRecalcMap}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default RoutePlanner
