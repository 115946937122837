import styled from 'styled-components'
export const LogoHeader = styled.div`
  height: 200px;
`
export const LinkWrapper = styled.div`
  a,
  div {
    color: #7d185c;
    text-decoration: underline;
  }
`

export const ErrorBox = styled.div`
  color: #ff0000;
`
export const MsWrapper = styled.div`
  background-color: white;
  border-color: #7d185c;
`
