import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Paragraphs from '../../commons/Paragraphs/Paragraphs';
import ButtonsRoutes from '../ButtonsRoutes/ButtonsRoutes';
import CodeFilter from '../CodeFilter/CodeFilter';

interface HeaderRoutesProps {
  t: (key: string) => string;
  selectedCodes: string[];
  setSelectedCodes: (codes: string[]) => void;
  data: any;
  headerRef: React.RefObject<HTMLDivElement>
}

const HeaderRoutes: React.FC<HeaderRoutesProps> = ({ t, selectedCodes, setSelectedCodes, data, headerRef }) => {
  return (
    <div
      ref={headerRef}
      className="header-routes tw-flex tw-flex-col xl:tw-flex-row tw-w-full tw-py-4 tw-space-y-4 xl:tw-space-y-0 xl:tw-space-x-4 tw-items-start xl:tw-items-end"
    >
      {/* left */}
      <div className='tw-px-4'>
        <Paragraphs weight="bold" size='2xl'>
          {t('titles.Rutas de Transporte')}
        </Paragraphs>
        <Paragraphs size="sm" weight="regular">
          <Link to='/home' style={{ color: 'rgb(118, 175, 175)' }}>
            {t('Inicio')}
          </Link>
          <span className='tw-text-black'>
            {' / '}
            {t('titles.Rutas de Transporte')}
          </span>
        </Paragraphs>
      </div>
      <div className="tw-flex tw-w-full xl:tw-justify-end tw-items-end tw-flex-1 tw-px-2">

        {/* center */}
        <div className="tw-w-full xl:tw-w-auto xl:tw-flex-grow xl:tw-flex xl:tw-justify-center">
          <ButtonsRoutes data={data} />

          {/* right */}
          <div className="tw-w-full xl:tw-w-auto xl:tw-flex-grow tw-flex tw-justify-end">
            <CodeFilter selectedCodes={selectedCodes} setSelectedCodes={setSelectedCodes} />
          </div>
        </div>
      </div>

    </div>
  );
};
export default HeaderRoutes;
