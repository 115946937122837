export const checkPermissions = (
  permissions: string[],
  userPermissions: string[]
) => {
  if (!permissions || !userPermissions) {
    return false
  }

  return permissions.some((permission) => userPermissions.includes(permission))
}
