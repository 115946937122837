// ButtonsRoutes.tsx
import { icons } from 'eva-icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import arrowAltCircleDown from '../../../infrastructure/assets/svg/arrow-alt-circle-down.svg';
import arrowAltCircleEnd from '../../../infrastructure/assets/svg/arrow-alt-circle-end.svg';
import arrowAltCircleStart from '../../../infrastructure/assets/svg/arrow-alt-circle-start.svg';
import arrowAltCircleUp from '../../../infrastructure/assets/svg/arrow-alt-circle-up.svg';
import { IRoutes } from '../../../infrastructure/interfaces/routes';
import EventBusService from '../../../infrastructure/services/events';
import CreateStopModal from '../DataTable/CreateStopModal';
interface ButtonsRoutesProps {
  data: IRoutes[];
}

const ButtonsRoutes: React.FC<ButtonsRoutesProps> = ({ data }) => {
  const { t } = useTranslation();
  const [selectRow, setSelectRow] = useState<string | null>(null);
  const [btnRowUpEnabled, setBtnRowUpEnabled] = useState<boolean>(false);
  const [btnRowDownEnabled, setBtnRowDownEnabled] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const buttons = [
    { icon: arrowAltCircleStart, alt: 'Mover al principio', action: 'moveToStart' },
    { icon: arrowAltCircleUp, alt: 'Mover hacia arriba', action: 'moveUp' },
    { icon: arrowAltCircleDown, alt: 'Mover hacia abajo', action: 'moveDown' },
    { icon: arrowAltCircleEnd, alt: 'Mover al final', action: 'moveToEnd' },
  ];

  const disableButton = (alt: string) => {
    if (selectRow === null) return true;
    if (alt === 'Mover al principio' || alt === 'Mover hacia arriba') return btnRowUpEnabled;
    if (alt === 'Mover al final' || alt === 'Mover hacia abajo') return btnRowDownEnabled;
    return false;
  };

  const publishRowAction = (action: string) => {
    EventBusService.publish('rowAction', {
      row: selectRow, action
    })
  };

  useEffect(() => {
    const eventSelectRow = EventBusService.subscribe('tableSelectRow',
      ({ selectRow, disabledUp, disabledDown }) => {
        if (selectRow === null) {
          setBtnRowUpEnabled(false)
          setBtnRowDownEnabled(false)
        } else {
          setBtnRowUpEnabled(disabledUp)
          setBtnRowDownEnabled(disabledDown)
        }
        setSelectRow(selectRow)
      })

    return () => eventSelectRow.unsubscribe()
  }, []);

  return (
    <div className="tw-flex xl:tw-items-end xl:tw-justify-end tw-space-x-2 xl:tw-px-2 tw-py-4 xl:tw-py-0 tw-w-full">
      <div className='tw-block tw-items-center tw-space-x-4 tw-w-auto tw-h-8 tw-border-r tw-border-gray-600 tw-border-opacity-10 tw-p-2 xl:tw-space-x-4'>
        {buttons.map((button, index) => (
          <button
            key={index}
            disabled={disableButton(button.alt)}
            onClick={() => publishRowAction(button.action)}
            className={`${disableButton(button.alt) ? 'tw-opacity-50' : ''}`}
          >
            <img src={button.icon} alt={button.alt} />
          </button>
        ))}
      </div>
      <div className='tw-flex tw-items-end tw-px-2 tw-space-x-2 tw-flex-row  tw-border-r tw-border-gray-600 tw-border-opacity-10'>
        <button className='tw-flex tw-space-x-2 tw-items-center tw-border tw-border-gray-300 tw-rounded-lg xl:tw-px-2 tw-py-1 tw-bg-[#E7F1F1]'>
          <span
            className=" tw-fill-[#004753]"
            dangerouslySetInnerHTML={{
              __html: icons['download-outline']?.toSvg({ width: 16, height: 17 }) || '',
            }}
          />
          <span>{t('Descargar rutas')}</span>
        </button>
        <button
          className='tw-flex tw-items-center tw-border tw-border-gray-300 tw-rounded-lg tw-px-2 xl:tw-px-6 tw-py-1 tw-bg-[#004753] tw-text-white'
          onClick={() => setModalIsOpen(true)}
        >
          <span>{t('Añadir parada')}</span>
        </button>
      </div>

      {modalIsOpen && <CreateStopModal
        data={data}
        onSave={() => { }}
        onClose={() => setModalIsOpen(false)}
      />}
    </div>
  );
}

export default ButtonsRoutes;
