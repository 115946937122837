import { useTranslation } from "react-i18next"

interface ButtonProps {
  text: string
  onClick?: () => void
  disabled?: boolean
  small?: boolean
  className?: string
  type?: 'button' | 'submit' | 'reset'
}

export function TertiaryButton({
  text,
  onClick = () => null,
  disabled,
  small,
  className,
  type = 'button',
}: ButtonProps): JSX.Element {
  const { t } = useTranslation()
  return (
    <button
      className={`tw-flex tw-w-full tw-h-10 tw-items-center tw-justify-center tw-rounded-sm tw-px-[35px] tw-py-[12px] tw-font-medium tw-text-brand tw-focus:tw-outline-none tw-transition-all tw-ease-in-out tw-bg-white tw-border ${!disabled ? ' tw-border-brand hover:tw-bg-brand hover:tw-text-white' : 'tw-bg-softGrey'
        } ${!small ? 'tw-h-10' : 'tw-h-8'} ${className}
        `}
      disabled={disabled}
      onClick={!disabled ? onClick : () => null}
      type={type}
    >
      <h6 style={{ fontWeight: 500 }}>{t(text as string)}</h6>
    </button>
  )
}
