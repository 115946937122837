import React from 'react'
import { connect } from 'react-redux'
import Logo from '../../components/commons/Logo'
import { ICompanyItem } from '../../infrastructure/interfaces/settings'
import { LogoHeader } from './styles'
import colors from '../../infrastructure/styles/colors'

interface Props {
  children: React.ReactNode
  entity: ICompanyItem
}

const Layout: React.FunctionComponent<Props> = ({ children, entity }) => {
  return (
    <div className="login-screen tw-h-screen tw-bg-[#e5e7eb] tw-overflow-y-auto">
      <LogoHeader
        className="login-screen-header tw-w-full tw-flex tw-items-center tw-justify-center tw-bg-grey-600"
        bgColor={'rgba(44, 44, 44, 1)'}
      >
        <div className="login-screen-header-logo tw-w-80">
          <Logo entityLogo={entity ? entity.logo : undefined} />
        </div>
      </LogoHeader>
      <div className="login-screen-content tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full">
        {children}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  entity: state.multitenant.entity,
})

export default connect(mapStateToProps)(Layout)
