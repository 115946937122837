import { useState } from 'react'
import { AvatarImage } from './styles'

import { IUserItem } from '../../../infrastructure/interfaces/users'
import Paragraphs from '../Paragraphs/Paragraphs'
import AvatarContextMenu from './AvatarContextMenu'

interface AvatarProps {
  user?: IUserItem
  getWidthFromRef: () => number
  bgColor: string
}
const Avatar = ({ user, getWidthFromRef, bgColor }: AvatarProps) => {
  const [openContextMenu, setOpenContextMenu] = useState<Boolean>(false)

  const onToggle = () => {
    setOpenContextMenu(!openContextMenu)
  }

  return (
    <div className="avatar tw-cursor-pointer tw-relative"
      onMouseEnter={() => setOpenContextMenu(true)}
      onMouseLeave={() => setOpenContextMenu(false)}
    >
      <div className="avatar-content tw-flex"
      // onClick={() => onToggle()}
      >
        <AvatarImage className="avatar__img tw-bg-gray-100 tw-text-black tw-flex tw-items-center tw-justify-center">
          <Paragraphs size="sm" weight="bold" uppercase>
            {user?.name?.charAt(0)}
            {user?.name?.charAt(1)}
          </Paragraphs>
        </AvatarImage>
        {openContextMenu && <div className='tw-absolute tw-left-[-58px] tw-top-[25px] tw-h-20 tw-w-32 tw-cursor-default' />}
      </div>

      {openContextMenu && (
        <div className="tw-relative">
          <AvatarContextMenu bgColor={bgColor} onToggle={onToggle} width={300} />
        </div>
      )}
    </div>
  )
}

export default Avatar
