import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import { Spinner } from '../../../../components/commons/styles/loaders'

import {
  ICMS,
  ICompanyItem,
  IReportItem,
} from '../../../../infrastructure/interfaces/settings'
import { UserRoles } from '../../../../infrastructure/interfaces/users'
import { Input } from '../../styles'
import './style.css'
import { Badge } from './styles'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../infrastructure/store'
import { icons } from 'eva-icons'

interface OptionsProps {
  userData: any
  setUserData: (userData: any) => void
  loading: boolean
  setUserPages: (userPages: any) => void
  setUserReports: (userReports: any) => void
  userPermissions: string[]
  userRole: string
  entityData: any
  setEntityData: (entity: any) => void
  setSelectedReportFilters: (report: IReportItem) => void
  selectedReportFilters: IReportItem | null
}

const UserTableInformesOptions = ({
  userData,
  setUserData,
  loading,
  setUserPages,
  setUserReports,
  entityData,
  setSelectedReportFilters,
  selectedReportFilters,
}: OptionsProps) => {
  const { t } = useTranslation()
  const menu = useSelector((state: RootState) => state.sidebar.menu)
  /**
   * Returns the entities that are in the menu and the ones that are not
   * @returns {inMenu: any[], notInMenu: any[]} entitiesData
   */
  const entitiesData = useMemo(() => {
    if (!entityData?.length || !menu?.length) {
      return {
        inMenu: [],
        notInMenu: [],
      }
    }

    const menuReports: any[] = []
    menu?.forEach((item) => {
      if (item.report) {
        let tenant = entityData?.find((entity: ICompanyItem) => {
          return entity.reports?.find(
            (report: IReportItem) => report.id === item.report?.id
          )
        })
        menuReports.push({
          ...item.report,
          submenu: item.submenu,
          tenantName: tenant?.name,
          tenantId: tenant?.id,
        })
      }
    })
    const menuPages: any[] = []
    menu?.map((item) => {
      if (item.page) {
        let tenant = entityData?.find((entity: ICompanyItem) => {
          return entity.pages?.find((page: ICMS) => page.id === item.page?.id)
        })

        menuPages.push({
          ...item.page,
          submenu: item.submenu,
          tenantName: tenant?.name,
          tenantId: tenant?.id,
        })
      }
    })

    const notInMenu: any[] = []
    entityData?.forEach((entity: ICompanyItem) => {
      let reports = entity.reports?.filter((report: IReportItem) => {
        // not in menuReport and neither in menuReport submenu

        return (
          !menuReports.find((item: any) => item.id === report.id) &&
          !menuReports.find((item: any) =>
            item.submenu?.find((sub: any) => sub.report?.id === report?.id)
          )
        )
      })
      let pages = entity.pages?.filter((page: ICMS) => {
        // not in menuReport and neither in menuReport submenu
        return (
          !menuPages.find((item: any) => item.id === page.id) &&
          !menuPages.find((item: any) =>
            item.submenu?.find((sub: any) => sub.id === page.id)
          )
        )
      })

      return notInMenu.push({
        ...entity,
        reports,
        pages,
      })
    })

    return {
      inMenu: menuReports.concat(menuPages),
      notInMenu,
    }
  }, [entityData, menu])

  /**
   * This function handles the selection of a report
   * @param report report to be selected
   * @param companyId company id
   * @return void
   */
  function handleSelectReport(report: any, companyId: number) {
    let temp = userData.reports || []
    let found = temp.find(
      (item: any) => item.id === (report.report?.id || report.id)
    )
    if (found) {
      temp = temp.filter(
        (item: any) => item.id !== (report.report?.id || report.id)
      )
    } else {
      const reportData = report.report ? report.report : report
      temp.push(reportData)
    }
    setUserData({ ...userData, reports: temp })
    setUserReports(temp.map((item: any) => item.id))
  }

  /**
   * This function handles the selection of a page
   * @param page page to be selected
   * @param companyId company id
   * @return void
   */
  function handleSelectPage(page: any, companyId: number) {
    let temp = userData.pages || []
    let found = temp.find((item: any) => item.id === page.id)
    if (found) {
      temp = temp.filter((item: any) => item.id !== page.id)
    } else {
      temp.push(page)
    }
    setUserData({ ...userData, pages: temp })
    setUserPages(temp.map((item: any) => item.id))
  }

  /**
   * This function handles the checkbox value of a report or page
   * @param report report to be selected
   * @param companyId company id
   * @param type <'reports' | 'pages'>
   * @returns
   */
  const handleCheck = (report: any, companyId: number, type: string) => {
    let temp = userData[type] || []
    if (type === 'reports')
      return Boolean(
        temp.find((item: any) => item.id === (report.report?.id || report.id))
      )

    if (type === 'pages')
      return Boolean(
        temp.find((item: any) => item.id === (report.report?.id || report.id))
      )

    return false
  }

  /**
   * Checks if the report has a filter applied by the user
   * @param report
   * @returns boolean
   */
  const hasFilterApplied = (report: any) => {
    return userData.report_filters?.find(
      (item: any) => item.report_id === report.id && !item.delete
    )
  }

  return (
    <div className="tw-relative">
      <Paragraphs size="md">
        {t('messages.Seleccionar informes de menu')}
      </Paragraphs>
      {!!loading && <Spinner />}
      {entitiesData.notInMenu ? (
        <>
          {entitiesData.inMenu
            ?.filter((item) => item.name)
            .map((menu: any) => (
              <Fragment key={menu.label}>
                <div
                  className={`tw-flex tw-mt-2 tw-items-center ${selectedReportFilters?.id === menu.id
                    ? 'tw-bg-gray-100'
                    : ''
                    }`}
                  key={`${menu.id}_${menu.name}_main`}
                >
                  <Input
                    className="tw-mr-4"
                    type="checkbox"
                    id={String(menu.id)}
                    name={menu.name}
                    checked={handleCheck(menu, menu.tenantId, 'reports')}
                    onChange={(e) => {
                      if (!e) return
                      handleSelectReport(menu, menu.tenantId)
                    }}
                    disabled={!menu.tenantName}
                  />
                  {menu.name}{' '}
                  <Badge>{menu.tenantName || t('labels.Restringido')}</Badge>
                  {handleCheck(menu, menu.tenantId, 'reports') &&
                    userData &&
                    userData.role !== UserRoles.ADMIN &&
                    menu.tenantName && (
                      <span
                        className="material-icons tw-cursor-pointer"
                        onClick={() => {
                          if (
                            selectedReportFilters &&
                            selectedReportFilters.id === menu.id
                          )
                            setSelectedReportFilters(undefined!)
                          else setSelectedReportFilters(menu)
                        }}
                        dangerouslySetInnerHTML={{
                          __html: icons[hasFilterApplied(menu)
                            ? 'funnel-outline'
                            : 'funnel-outline'
                          ]?.toSvg({ width: 24, height: 24 }) || '',
                        }}
                      />
                    )}
                </div>
                <div className="tw-pl-2">
                  {menu.submenu?.map((sub: any) => (
                    <div
                      className={`tw-flex tw-mt-2 tw-items-center ${selectedReportFilters?.id === sub.id
                        ? 'tw-bg-gray-100'
                        : ''
                        }`}
                      key={`${sub.id}_${sub.name}_sub`}
                    >
                      {' '}
                      <span className="tw-mb-2 tw-mr-1 tw-self-start">
                        <svg
                          width="21"
                          height="13"
                          viewBox="0 0 21 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="0.5"
                            y1="2.18557e-08"
                            x2="0.499999"
                            y2="13"
                            stroke="#787878"
                            strokeDasharray="1 2 1 2"
                          />
                          <line
                            y1="12.5"
                            x2="21"
                            y2="12.5"
                            stroke="#787878"
                            strokeDasharray="1 2 1 2"
                          />
                        </svg>
                      </span>
                      <Input
                        className="tw-mr-4"
                        type="checkbox"
                        id={String(sub.report?.id)}
                        name={sub.report?.name}
                        checked={handleCheck(sub, menu.tenantId, 'reports')}
                        onChange={(e) => {
                          if (!e) return
                          handleSelectReport(sub, menu.tenantId)
                        }}
                        disabled={!menu.tenantName}
                      />
                      {sub.report?.name}{' '}
                      <Badge>
                        {menu.tenantName || t('labels.Restringido')}
                      </Badge>
                      {handleCheck(sub, menu.tenantId, 'reports') &&
                        userData &&
                        userData.role !== UserRoles.ADMIN && (
                          <span
                            className="material-icons tw-cursor-pointer"
                            onClick={() => {
                              if (
                                selectedReportFilters &&
                                selectedReportFilters.id === sub.id
                              )
                                setSelectedReportFilters(undefined!)
                              else setSelectedReportFilters(sub)
                            }}
                            dangerouslySetInnerHTML={{
                              __html: icons[hasFilterApplied(sub)
                                ? 'funnel-outline'
                                : 'funnel-outline'
                              ]?.toSvg({ width: 24, height: 24 }) || '',
                            }}
                          />
                        )}
                    </div>
                  ))}
                </div>
              </Fragment>
            ))}
          <div className="tw-mt-8">
            <hr />
            <Paragraphs size="md" className="tw-mt-3">
              {t('messages.Seleccionar informes fuera de menu')}
            </Paragraphs>
            {!!loading && <Spinner />}
            {entitiesData.notInMenu.map((entity: ICompanyItem) => (
              <div className="tw-mt-2" key={`${entity.id}_${entity.name}`}>
                <div className="table-user-options">
                  <div
                    className="tw-w-full tw-flex tw-pr-4 show hide"
                    id={`toggle${entity.id}`}
                  >
                    {entity?.reports?.map((report: IReportItem, i: number) => (
                      <div
                        className={`tw-flex tw-mt-2 tw-items-center ${selectedReportFilters?.id === report.id
                          ? 'tw-bg-gray-100'
                          : ''
                          }`}
                        key={`${report.id}_${report.name}_${i}`}
                      >
                        <Input
                          className="tw-mr-4"
                          type="checkbox"
                          id={String(report.id)}
                          name={report.name}
                          checked={handleCheck(report, entity.id, 'reports')}
                          onChange={(e) => {
                            if (!e) return
                            handleSelectReport(report, entity.id)
                          }}
                          disabled={!entity.name}
                        />
                        {report.name} <Badge>{entity.name}</Badge>
                        {handleCheck(report, entity.id, 'reports') &&
                          userData &&
                          userData.role !== UserRoles.ADMIN && (
                            <span
                              className="material-icons tw-cursor-pointer"
                              onClick={() => {
                                if (
                                  selectedReportFilters &&
                                  selectedReportFilters.id === report.id
                                )
                                  setSelectedReportFilters(undefined!)
                                else setSelectedReportFilters(report)
                              }}
                              dangerouslySetInnerHTML={{
                                __html: icons[hasFilterApplied(report)
                                  ? 'funnel-outline'
                                  : 'funnel-outline'
                                ]?.toSvg({ width: 24, height: 24 }) || '',
                              }}
                            />
                          )}
                      </div>
                    ))}
                    {entity?.pages?.map((page: ICMS, i: number) => (
                      <div className="tw-flex tw-mt-2" key={`${page.id}_${i}`}>
                        {userData && userData.role !== UserRoles.ADMIN && (
                          <Input
                            className="tw-mr-4"
                            type="checkbox"
                            id={String(page.id)}
                            name={page.title}
                            checked={handleCheck(page, entity.id, 'pages')}
                            onChange={(e) => {
                              if (!e) return
                              handleSelectPage(page, entity.id)
                            }}
                          />
                        )}
                        {page.title} <Badge>{entity.name}</Badge>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <Fragment>
          {!entitiesData ? null : (
            <Fragment>
              <Paragraphs size="md">{t('messages.Cargando')}</Paragraphs>
              <Spinner />
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default UserTableInformesOptions
