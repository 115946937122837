import { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { ICompanyItem } from '../../../infrastructure/interfaces/settings'
import { IUserItem } from '../../../infrastructure/interfaces/users'
import storageService from '../../../infrastructure/services/storageService'
import { RootState } from '../../../infrastructure/store'
import { setUser } from '../../../infrastructure/store/user/userSlice'
import Header from './Header'
import { ContainerContent } from './styles'

interface IProps {
  user: any
  entity: ICompanyItem
  children: any
}
const Layout = ({ children, entity, user }: IProps) => {
  const dispatch = useDispatch()
  const [userData, setUserData] = useState<IUserItem>(user)

  useEffect(() => {
    if (user) {
      setUserData(user)
    } else {
      let user = storageService.getUser()
      if (user) {
        setUserData(user as IUserItem)
        dispatch(setUser(user as IUserItem))
      }
    }
  }, [user])

  return (
    <div className="layout tw-h-[100vh] tw-overflow-hidden tw-flex tw-w-full tw-relative tw-bg-lightGrey">
      <ContainerContent className="layout-content tw-h-full tw-relative tw-w-full">
        <Header user={userData} entity={entity} />
        <div
          className="tw-flex tw-h-full"
          style={{
            backgroundColor: entity?.secondary_color || '#fff',
          }}
        >
          {children}
        </div>
      </ContainerContent>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
  entity: state.multitenant.entity,
})

export default connect(mapStateToProps)(Layout)
