import { AxiosResponse } from 'axios'
import { IFilters, IFilterItem } from '../interfaces/filters'
import { api } from './api'

interface IParams {
  [key: string]: any
}

const createFilter = async (
  filter: IFilterItem
): Promise<AxiosResponse<IFilterItem>> => {
  return await api.post('/filters', filter)
}

const getFilters = async (
  params: IParams
): Promise<AxiosResponse<IFilters>> => {
  return await api.get('/filters', { params })
}

const deleteFilter = async (id: string): Promise<AxiosResponse> => {
  return await api.delete(`/filters/${id}`)
}

export const filterService = {
  createFilter,
  getFilters,
  deleteFilter,
}
