export const workspaceColumns = [
  {
    field: 'name',
    headerName: 'Nombre',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'workspace_id',
    headerName: 'Workspace ID',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'application_id',
    headerName: 'Application ID',
    filter: 'agTextColumnFilter',
  },
]
