import { useTranslation } from 'react-i18next'
import Paragraphs from '../Paragraphs/Paragraphs'
import { reportsFilterOptions } from '../../../infrastructure/constant/columns/userCreateOptions'
import { Input, UserOptionsTableHeader } from '../../../modules/Settings/styles'
import TableTagInput from '../TableTagsInput/TableTaginput'

interface OptionsProps {
  data: any
  setData: (userData: any) => void
  children?: React.ReactNode
}
const TableFilters = ({ data, setData, children }: OptionsProps) => {
  const { t } = useTranslation()

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newData = {
      ...data,
      filter: {
        ...data.filter,
        [e.target.name]: e.target.value,
      },
    }
    setData(newData)
  }

  const handleTagChange = (values: string[]) => {
    const newData = {
      ...data,
      filter: {
        ...data.filter,
        values,
      },
    }
    setData(newData)
  }

  const onDeletedTag = (newValues: string[]) => {
    const newData = {
      ...data,
      filter: {
        ...data.filter,
        values: newValues,
      },
    }
    setData(newData)
  }

  return (
    <>
      <Paragraphs size="md">{t('titles.Tabla de filtros')}</Paragraphs>
      <div className="tw-flex tw-flex-col">
        <div className="tw-py-2 tw-inline-block tw-min-w-full">
          <div className="tw-overflow-hidden">
            <table className="tw-text-left tw-border-2 ">
              <UserOptionsTableHeader className="tw-border-b tw-bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="tw-font-medium tw-text-gray-900 tw-p-2"
                    style={{ width: '20%' }}
                  >
                    Filtro
                  </th>

                  <th
                    scope="col"
                    className="tw-font-medium tw-text-gray-900 tw-p-2"
                    colSpan={2}
                  >
                    Valor
                  </th>
                  <th
                    scope="col"
                    className="tw-font-medium tw-text-gray-900 tw-p-4"
                  ></th>
                  <th
                    scope="col"
                    className="tw-font-medium tw-text-gray-900 tw-p-4"
                  ></th>
                  <th
                    scope="col"
                    className="tw-font-medium tw-text-gray-900 tw-p-4"
                  ></th>
                  <th
                    scope="col"
                    className="tw-font-medium tw-text-gray-900 tw-p-4"
                  ></th>
                </tr>
              </UserOptionsTableHeader>
              <tbody>
                {reportsFilterOptions.map((e: any, i: number) => (
                  <tr className="tw-bg-white tw-border-b" key={i}>
                    <td className="tw-p-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900 tw-border-r-2 ">
                      <span className="tw-pr-4 tw-py-2">
                        {t('labels.' + e.label)}
                      </span>
                    </td>
                    <td colSpan={10}>
                      <Input
                        type="text"
                        name={e.name}
                        className="tw-h-12 tw-w-full tw-pl-2"
                        value={data && data.filter && data.filter[e.name]}
                        onChange={handleFilterChange}
                      />
                    </td>
                  </tr>
                ))}
                <tr className="tw-bg-white tw-border-b">
                  <td className="tw-p-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900 tw-border-r-2 ">
                    <span className="tw-pr-4 tw-py-2">
                      {t('labels.Valores')}
                    </span>
                  </td>
                  <td colSpan={10}>
                    <TableTagInput
                      setTags={handleTagChange}
                      tags={
                        data && data.filter && data.filter.values
                          ? data.filter.values
                          : []
                      }
                      onDeletedTag={onDeletedTag}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default TableFilters
