import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Select from 'react-select'
import Label from '../../../../components/commons/Label'
import StickySettingsBtnBottom from '../../../../components/commons/Layouts/settingsPages/StickySettingsBtnBottom'
import {
  ICompanyItem,
  IReportItem,
  IRoleItem,
} from '../../../../infrastructure/interfaces/settings'
import {
  IUserItem,
  IUserItemInput,
  UserRoles,
} from '../../../../infrastructure/interfaces/users'
import { settingsService } from '../../../../infrastructure/services/settingsService'
import { Input, CommonContainer } from '../../styles'
import UserTableInformesOptions from './UserTableInformesOptions'
import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import { checkPermissions } from '../../../../infrastructure/utils/permissions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../infrastructure/store'
import storageService from '../../../../infrastructure/services/storageService'
import { handleInputErrors } from '../../../../infrastructure/utils/common'
import TagInput from '../../../../components/commons/TagInput/TagInput'
import { IFilterItem } from '../../../../infrastructure/interfaces/filters'
import UserFilterReport from '../../../../components/commons/UseFilterReport/UserFilterReport'
import PermissionsSelect from './PermissionSelect'

const CreateUser = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const filterTableRef = useRef<HTMLDivElement>(null)

  const type: any = (params && params.id) || 'create'

  const [userData, setUserData] = useState<IUserItem | IUserItemInput | any>(
    null
  )
  const [roles, setRoles] = useState<IRoleItem[]>([])
  const [companies, setCompanies] = useState<ICompanyItem[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [roleError, setRoleError] = useState<boolean>(false)
  const [companyError, setCompanyError] = useState<boolean>(false)
  const [selectedRole, setSelectedRole] = useState<{
    label: string
    value: string
  }>()
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([])
  const [userReports, setUserReports] = useState<number[]>([])
  const [userPages, setUserPages] = useState<number[]>([])
  const [defaultRole, setDefaultRole] = useState<any>(null)
  const [entityData, setEntityData] = useState<ICompanyItem[]>([])
  const [selectedReportFilters, setSelectedReportFilters] =
    useState<IReportItem>()
  const [selectedReportFiltersData, setSelectedReportFiltersData] =
    useState<any>([])

  const loggedInUser: any =
    useSelector((state: RootState) => state.auth.user) ||
    storageService.getUser()

  useEffect(() => {
    if (params.id) {
      getUserData(Number(params.id))
    }
  }, [params])

  // add scroll listener
  useEffect(() => {
    const layout = document.getElementsByClassName('ag-container-layout')
    if (layout.length) {
      layout[0].addEventListener('scroll', handleScroll)
    }
    handleScroll()

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [selectedReportFilters])

  /**
   * This function is used to handle the scroll event
   * @returns void
   */
  const handleScroll = () => {
    if (filterTableRef.current) {
      // get the table top position
      const tableTop = filterTableRef.current.getBoundingClientRect().top
      const parentBottom =
        filterTableRef.current.parentElement?.getBoundingClientRect().bottom
      if (tableTop < 20) {
        filterTableRef.current.style.position = 'fixed'
        filterTableRef.current.style.top = '100px'
      } else if (parentBottom && parentBottom > 100) {
        filterTableRef.current.style.position = 'relative'
        filterTableRef.current.style.top = '0px'
      }
    }
  }

  let callingApi = false
  /**
   * This function is used to get the user data by id
   * @async
   * @param id
   * @returns  void
   */
  const getUserData = async (id: number) => {
    if (callingApi) return
    callingApi = true
    await settingsService.getUser(id).then((res) => {
      setUserData({
        ...res.data,
        role: res.data.role.name,
        tags: res.data.tags?.map((tag) => tag.name),
      })
      setSelectedRole({
        label: res.data.role.name,
        value: String(res.data.role.id),
      })
      setSelectedCompanies(res.data.companies)
    })
    callingApi = false
  }

  let calling = false
  useEffect(() => {
    if (!calling) {
      let permissions =
        typeof loggedInUser !== 'boolean' ? loggedInUser.permissions : []

      if (
        checkPermissions(
          ['roles.*', 'roles.view', 'roles.list'],
          permissions
        ) ||
        (loggedInUser && loggedInUser.role.name === UserRoles.ADMIN)
      ) {
        getRoles(type)
      } else {
        setSelectedRole({
          label: 'user',
          value: '1',
        })
        setUserData({
          ...userData,
          role: 'user',
        })
        setLoading(false)
      }

      if (!companies.length) {
        if (
          checkPermissions(
            ['companies.*', 'companies.view', 'companies.list'],
            permissions
          ) ||
          (loggedInUser && loggedInUser.role.name === UserRoles.ADMIN)
        ) {
          getCompanies()
        } else {
          setCompanies(loggedInUser.companies)
          setLoading(false)
        }
      }
      calling = true
    }
  }, [params])

  /**
   * This function is used to get the roles list, and set the default role
   * @async
   * @returns void
   */
  const getRoles = async (type: string) => {
    await settingsService.getRoles({ per_page: 500 }).then((res) => {
      setRoles(res.data.items)
      if (!params) {
        res.data.items.map((role: IRoleItem) => {
          if (role.default) {
            setDefaultRole({
              value: role.id,
              label: role.name,
            })
            if (!userData?.role) {
              setSelectedRole({
                value: String(role.id),
                label: role.name,
              })
              setUserData({
                ...userData,
                role: role.name,
              })
            }
            setLoading(false)
          }
        })
      }
    })
  }

  /**
   * This function is used to get the companies list, it will call the api and set the companies list
   * @async
   * @returns void
   */
  const getCompanies = async () => {
    setLoading(true)
    await settingsService
      .getCompanies({ per_page: 500 })
      .then((res) => {
        setCompanies(res.data.items)
      })
      .finally(() => setLoading(false))
  }

  /**
   * This function is used to handle the save button click, it will validate the form and call the api
   * @returns void
   */
  const onSave = () => {
    setLoading(true)

    if (selectedRole && selectedRole.label !== UserRoles.ADMIN) {
      if (!userData.companies && !params && !selectedCompanies.length) {
        setCompanyError(true)
      }
    }

    if (selectedRole && selectedRole.label === UserRoles.ADMIN) {
      delete userData.companies
      setUserReports([])
      setUserPages([])
    }

    if (selectedRole && selectedRole.label === UserRoles.USER) {
      if (!userData.companies && !selectedCompanies.length) {
        setCompanyError(true)
      }
    }

    if (userData?.role === UserRoles.USER) {
      if (!userData.companies_id && !selectedCompanies.length) {
        setCompanyError(true)
      }
    }

    if (!selectedRole && defaultRole && userData && !userData.role) {
      setSelectedRole(defaultRole)
      userData.role = defaultRole.label
    }

    if (selectedReportFiltersData) {
      userData.filters = selectedReportFiltersData
    }

    if (params && params.id) {
      if (selectedRole && typeof selectedRole === 'object') {
        userData.role = selectedRole.label
      }

      settingsService
        .updateUser(userData as IUserItem)
        .then((res) => {
          if (res.status === 200) {
            // if report is a menu get the report id, else get the id
            const reportsToUpdate: number[] = userData.reports.map(
              (item: any) => (item.report ? item.report.id : item.id)
            )

            setReports(res.data.id, reportsToUpdate)
            setPages(res.data.id, userData.pages.map((p: any) => p.id) || [])

            if (
              selectedRole &&
              res.data.role.id !== parseInt(selectedRole.value)
            ) {
              setRole(res.data.id, selectedRole)
            }

            responseOk()
          }
        })
        .catch((err) => {
          let errors = handleInputErrors(err)
          handleSelectErrors(errors)
        })
    } else {
      settingsService
        .createUser(userData as IUserItemInput)
        .then((res) => {
          if (res.status === 200) {
            if (userReports && userReports.length) {
              setReports(res.data.id, userReports)
            }

            if (userPages && userPages.length) {
              setPages(res.data.id, userPages)
            }

            responseOk()
          }
        })
        .catch((err) => {
          let errors = handleInputErrors(err)
          handleSelectErrors(errors)
        })
    }
  }

  /**
   * This function sets a user's reports based on the given id and reports. It uses the settingsService to set the user reports, and if the response status is 200, it returns true.
   * @param id
   * @param reports
   * @returns boolean
   */
  const setReports = (id: number, reports: any[]) => {
    settingsService.setUserReports(id, { reports }).then((res) => {
      if (res.status === 200) {
        return true
      } else {
        let errors = handleInputErrors(res)
        handleSelectErrors(errors)
      }
    })
  }

  /**
   * This function sets a user's pages based on the given id and pages. It uses the settingsService to set the user pages, and if the response status is 200, it returns true.
   * @param id
   * @param pages
   * @returns boolean
   */
  const setPages = (id: number, pages: any) => {
    if (pages.length === 0) return
    settingsService.setUserPages(id, { pages }).then((res) => {
      if (res.status === 200) {
        return true
      }
    })
  }

  /**
   * This function sets a user's role based on the given id and role. It uses the settingsService to set the user roles, and if the response status is 200, it returns true.
   * @param id
   * @param role
   * @returns boolean
   */
  const setRole = (id: number, role: any) => {
    settingsService.setUserRoles(id, role.value).then((res) => {
      if (res.status === 200) {
        return true
      }
    })
  }

  /**
   * This code is a function called responseOk that displays a success message using the toast library and then navigates to the '/settings/users' page after 200 milliseconds.
   * @returns void
   */
  const responseOk = () => {
    toast(t('messages.el registro se ha guardado') as string, {
      type: 'success',
      className: 'toast-success tw-p-4',
      position: 'top-center',
    })

    setTimeout(() => {
      navigate('/settings/users')
    }, 500)
  }

  /**
   * This function is used to handle the selection of a company from a dropdown menu. It takes in an event (e) as an argument, and uses the target's value to set the selected company.
   * @param {any} e company select value
   * @returns void
   */
  const handleSelectCompany = async (e: any) => {
    if (!e.length) return setSelectedCompanies([])
    // async setSelectedCompanies(e)
    setSelectedCompanies((prev: any) => e)

    setCompanyError(false)
    let companies = e.map((item: any) => item.value)
    let userCompanies = userData.companies_id || []

    setUserData({
      ...userData,
      companies_id: [...userCompanies, ...companies],
    })

    getCompanyData(e)
  }

  useEffect(() => {
    let permissions =
      typeof loggedInUser !== 'boolean' ? loggedInUser.permissions : []
    if (
      selectedCompanies.length &&
      (checkPermissions(
        ['companies.*', 'companies.view', 'companies.list'],
        permissions
      ) ||
        loggedInUser.role.name === UserRoles.ADMIN)
    ) {
      getCompanyData()
    }
  }, [selectedCompanies])

  /**
   * getCompanyData
   *
   * Retrieves company data from a database.
   * @async
   * @returns void
   */
  const getCompanyData = async (currentSelected?: any) => {
    let entities: any[] = []
    let selected = currentSelected || selectedCompanies

    selected.forEach(async (company: any) => {
      let id = Number(company.id || company.value)
      setLoading(true)
      if (!entityData.find((item: any) => item.id === id)) {
        await settingsService
          .getCompany(id)
          .then((response) => {
            entities.push(response.data)
          })
          .then(() => {
            if (entities.length) {
              setEntityData([...entityData, ...entities])
            }
          })
        setLoading(false)
      } else {
        // not in the list
        const newEntities = entityData.filter((item: any) =>
          selected.find((company: any) => company.id === item.id)
        )
        setEntityData(newEntities)
        setLoading(false)
      }
    })
  }

  /**
   * handleSelectErrors is a function that takes in an array of errors as an argument and logs a message to the console for each error of type 'select'.
   * @param {any[]} errors - An array of errors to be handled.
   * @returns void
   */
  const handleSelectErrors = (errors: any[]) => {
    //convert array of object to object with key as name
    let errorObject: any = {}
    errors.forEach((error) => {
      Object.keys(error)?.forEach((key) => {
        errorObject[key] = error[key]
      })
    })

    if (errorObject.role) {
      document
        .getElementsByClassName('role__control')[0]
        ?.setAttribute('style', 'border: 2px solid #e53e3e')
    }
    if (errorObject.companies_id) {
      document
        .getElementsByClassName('companies__control')[0]
        ?.setAttribute('style', 'border: 2px solid #e53e3e')
    }
    setLoading(false)
  }

  /**
   * getUserTags
   * This function takes in a user object and returns an array of tags associated with the user.
   * @returns {Array} An array of tags associated with the user.
   * @example
   * const tags = getUserTags()
   * // ['tag1', 'tag2']
   */
  const getUserTags = () => {
    if (userData && userData.tags?.length) {
      if (typeof userData.tags[0] === 'object') {
        return userData.tags.map((tag: any) => tag.name)
      } else {
        return userData.tags
      }
    }
    return []
  }

  /**
   * Sets to a report a individual filter
   * @param report_id: number
   * @param filter: IFilterItem
   * @returns void
   */

  const handleReportFilter = (
    report_id: number,
    filter: IFilterItem | null
  ): void => {
    let { report_filters } = userData
    if (userData.role === UserRoles.ADMIN) return
    if (!report_filters) {
      report_filters = []
    }
    const index = report_filters.findIndex(
      (item: any) => item.report_id === report_id
    )
    if (index === -1) {
      report_filters.push({ report_id, ...filter })
    } else {
      if (filter === null) {
        report_filters[index] = {
          report_id,
          filter: {
            column: '',
            table: '',
          },
          delete: true,
        }
      } else {
        report_filters[index].filter = { ...filter }
        report_filters[index].delete = false
      }
    }

    const userFiltersReports = selectedReportFiltersData
    const filterindex = userFiltersReports.findIndex(
      (item: any) => item.report_id === report_id
    )
    if (filterindex === -1) {
      if (filter === null) {
        userFiltersReports.push({ report_id, delete: true })
      } else {
        userFiltersReports.push({ report_id, ...filter })
      }
    } else {
      if (filter === null) {
        userFiltersReports[filterindex].filter = null
      } else {
        userFiltersReports[filterindex].filter = { ...filter }
        userFiltersReports[filterindex].delete = false
      }
    }
    setSelectedReportFiltersData(userFiltersReports)
    setSelectedReportFilters(undefined)
    setUserData({ ...userData, report_filters })
  }

  return (
    <StickySettingsBtnBottom onSave={onSave} loading={loading}>
      <div className="tw-p-8 tw-relative">
        <div className="user-form tw-w-full tw-mt-4 tw-flex">
          <div className="tw-flex tw-w-full tw-gap-4 lg:tw-flex-row tw-flex-col">
            {/* Left col */}
            <div className="tw-flex tw-flex-col tw-w-full lg:tw-w-full 2xl:tw-w-6/12 xl:tw-w-7/12">
              <div className="tw-flex tw-items-center tw-flex-row 2xl:tw-w-10/12 xl:tw-w-full tw-mb-2">
                <div className="tw-w-7/12">
                  <Label
                    label="Nombre"
                    translate
                    className="tw-mb-1 md:tw-mb-0 tw-whitespace-nowrap tw-mr-2 tw-block"
                  />
                </div>
                <div className="tw-w-full">
                  <Input
                    className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800"
                    type="text"
                    placeholder="Nombre"
                    tabIndex={1}
                    value={userData?.name || ''}
                    required
                    name="name"
                    onChange={(e) => {
                      if (e.target.classList.contains('tw-border-red-500')) {
                        e.target.classList.remove('tw-border-red-500')
                      }
                      e.target.parentElement
                        ?.querySelector('.tw-text-red-500')
                        ?.remove()
                      setUserData({ ...userData, name: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div className="tw-flex tw-items-center tw-flex-row 2xl:tw-w-10/12 xl:tw-w-full tw-mb-2">
                <div className="tw-w-7/12">
                  <Label
                    label="Correo electrónico"
                    translate
                    className="tw-mb-1 tw-md:mb-0 tw-whitespace-nowrap tw-mr-2 tw-block"
                  />
                </div>
                <div className="tw-w-full">
                  <Input
                    className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800"
                    id="email"
                    type="email"
                    name="email"
                    placeholder="user@fedefarma.com"
                    tabIndex={3}
                    required
                    value={userData?.email || ''}
                    onChange={(e) => {
                      if (e.target.classList.contains('tw-border-red-500')) {
                        e.target.classList.remove('tw-border-red-500')
                      }
                      e.target.parentElement
                        ?.querySelector('.tw-text-red-500')
                        ?.remove()
                      setUserData({ ...userData, email: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div className="tw-flex tw-items-center tw-flex-row 2xl:tw-w-10/12 xl:tw-w-full tw-mb-2">
                <div className="tw-w-7/12">
                  <Label
                    label="Tags"
                    translate
                    className="tw-mb-1 tw-md:mb-0 tw-whitespace-nowrap tw-mr-2 tw-block"
                  />
                </div>
                <div className="tw-w-full">
                  <TagInput
                    tags={getUserTags()}
                    setTags={(tags: string[]) =>
                      setUserData({ ...userData, tags: tags })
                    }
                    onDeletedTag={(tags: string[]) => {
                      setUserData({ ...userData, tags: tags })
                    }}
                    showLabel={false}
                    loading={loading}
                  />
                </div>
              </div>
            </div>

            {/* Right col */}
            <div className="tw-flex tw-flex-col tw-w-full lg:tw-w-7/12 xl:tw-w-6/12 2xl:tw-w-4/12">
              <div className="tw-flex tw-items-center tw-flex-row 2xl:tw-w-10/12 lg:tw-w-full tw-mb-2">
                <div className="tw-w-7/12 lg:tw-w-4/12">
                  <Label
                    label="Apellidos"
                    translate
                    className="tw-mb-1 tw-md:mb-0 tw-whitespace-nowrap tw-mr-2"
                  />
                </div>
                <div className="tw-w-full">
                  <Input
                    className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800"
                    type="text"
                    name="lastname"
                    placeholder="Apellidos"
                    required
                    tabIndex={2}
                    value={userData?.lastname || ''}
                    onChange={(e) => {
                      if (e.target.classList.contains('tw-border-red-500')) {
                        e.target.classList.remove('tw-border-red-500')
                      }
                      e.target.parentElement
                        ?.querySelector('.tw-text-red-500')
                        ?.remove()
                      setUserData({ ...userData, lastname: e.target.value })
                    }}
                  />
                </div>
              </div>
              {loggedInUser &&
                (checkPermissions(
                  ['users.*', 'users.edit'],
                  loggedInUser.permissions
                ) ||
                  loggedInUser.role.name === UserRoles.ADMIN) && (
                  <>
                    <div className="tw-flex tw-items-center tw-flex-row 2xl:tw-w-10/12 xl:tw-w-full tw-mb-2">
                      <div className="tw-w-7/12 lg:tw-w-4/12">
                        <Label
                          label="Roles"
                          translate
                          className="ctw-mb-1 tw-md:mb-0 tw-whitespace-nowrap tw-mr-2"
                        />
                      </div>
                      <Select
                        name="role"
                        options={roles.map((role) => {
                          return { value: role.id, label: role.name }
                        })}
                        className="basic-multi-select tw-w-full"
                        classNamePrefix="role"
                        tabIndex={4}
                        id="role"
                        onChange={(e) => {
                          if (roleError || e.label) setRoleError(false)
                          document
                            .getElementById('role')
                            ?.classList.remove('tw-border-red-500')
                          document
                            .getElementById('role')
                            ?.parentElement?.querySelector('.tw-text-red-500')
                            ?.remove()
                          document
                            .getElementsByClassName('role__control')[0]
                            .removeAttribute('style')

                          if (e.label === UserRoles.USER) {
                            setUserData({ ...userData, role: e.label })
                            setSelectedRole(e)
                          } else {
                            setUserData({
                              ...userData,
                              report_filters: [],
                              role: e.label,
                            })
                            setSelectedRole(e)
                            setSelectedReportFiltersData([])
                          }
                        }}
                        value={
                          userData && userData.role && roles.length > 0
                            ? roles.map((role) => {
                                if (
                                  role.name === userData.role ||
                                  role.id === userData.role.id
                                ) {
                                  return { value: role.id, label: role.name }
                                }
                              })
                            : defaultRole || selectedRole
                        }
                        isLoading={loading}
                      />
                    </div>
                    {Boolean(selectedRole?.label === UserRoles.MODERATOR) &&
                      Boolean(loggedInUser.role.name === UserRoles.ADMIN) && (
                        <PermissionsSelect
                          onChange={(e) => {
                            setUserData({ ...userData, permissions: e })
                          }}
                          blockedPermissions={
                            roles.find(
                              (role) => role.name === UserRoles.MODERATOR
                            )?.permissions || []
                          }
                          userPermissions={userData?.permissions || []}
                        />
                      )}
                  </>
                )}

              {Boolean(
                selectedRole?.label !== UserRoles.ADMIN ||
                  (userData?.role.name !== UserRoles.ADMIN &&
                    typeof userData?.role === 'string' &&
                    userData?.role !== UserRoles.ADMIN)
              ) && (
                <div className="tw-flex tw-items-center tw-flex-row 2xl:tw-w-10/12 lg:tw-w-full tw-mb-2">
                  <div className="tw-w-7/12 lg:tw-w-4/12">
                    <Label
                      label="Entidad"
                      translate
                      className="tw-mb-1 tw-md:mb-0 tw-whitespace-nowrap tw-mr-2"
                    />
                  </div>
                  <div className="tw-w-full">
                    <Select
                      name="companies_id"
                      options={companies.map((company) => {
                        return { value: company.id, label: company.name }
                      })}
                      className="basic-multi-select tw-w-full"
                      classNamePrefix="companies"
                      tabIndex={4}
                      id="companies_id"
                      onChange={(e: any) => {
                        if (companyError || e.label) setCompanyError(false)
                        handleSelectCompany(e)

                        document
                          .getElementById('companies_id')
                          ?.classList.remove('tw-border-red-500')
                        document
                          .getElementById('companies_id')
                          ?.parentElement?.querySelector('.tw-text-red-500')
                          ?.remove()
                        document
                          .getElementsByClassName('companies__control')[0]
                          .removeAttribute('style')
                      }}
                      isMulti
                      value={
                        selectedCompanies && selectedCompanies.length > 0
                          ? selectedCompanies.map((company) => {
                              return {
                                value: company.id || company.value,
                                label: company.name || company.label,
                              }
                            })
                          : []
                      }
                      isLoading={loading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Company Reports/Pages */}
        <CommonContainer className="tw-mb-12 lg:tw-mb-8">
          <div className="tw-flex tw-w-full tw-h-full tw-pt-4 tw-gap-4 tw-flex-col lg:tw-flex-row xl:tw-mb-10">
            {loggedInUser &&
              (checkPermissions(
                ['users.*', 'users.edit'],
                loggedInUser.permissions
              ) ||
                loggedInUser.role.name === UserRoles.ADMIN) && (
                <Fragment>
                  {Boolean(selectedRole?.label !== UserRoles.ADMIN) && (
                    <div className="tw-w-full xl:tw-w-1/2">
                      {selectedCompanies?.length ? (
                        <UserTableInformesOptions
                          userData={userData}
                          setUserData={setUserData}
                          loading={loading}
                          setUserPages={setUserPages}
                          setUserReports={setUserReports}
                          userPermissions={loggedInUser.permissions || []}
                          userRole={loggedInUser.role.name}
                          entityData={entityData}
                          setEntityData={setEntityData}
                          setSelectedReportFilters={setSelectedReportFilters}
                          selectedReportFilters={selectedReportFilters || null}
                        />
                      ) : (
                        <Paragraphs size="md">
                          {t(
                            'messages.Seleccione una entidad para ver las opciones de informes'
                          )}
                        </Paragraphs>
                      )}
                    </div>
                  )}
                </Fragment>
              )}

            <div className="tw-ml-2 tw-w-full xl:tw-w-1/2 tw-mt-2">
              {selectedRole &&
              selectedRole.label !== UserRoles.ADMIN &&
              !!selectedReportFilters ? (
                <UserFilterReport
                  handleReportFilter={handleReportFilter}
                  data={
                    userData?.report_filters?.find(
                      (f: IFilterItem) =>
                        f.report_id === selectedReportFilters.id
                    ) || selectedReportFilters
                  }
                  selectedReportFilters={selectedReportFilters}
                  divRef={filterTableRef}
                />
              ) : selectedReportFiltersData.length > 0 ? (
                <Paragraphs size="md">
                  {t('messages.Recuerde guardar los cambios')}
                </Paragraphs>
              ) : null}
            </div>
          </div>
        </CommonContainer>
      </div>
    </StickySettingsBtnBottom>
  )
}

export default CreateUser
