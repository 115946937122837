import { AxiosResponse } from 'axios'
import { IReportItem } from '../interfaces/settings'
import { api } from './api'

const getReportBITokens = async (
  id: string
): Promise<AxiosResponse<IReportItem>> => {
  return await api.get('/reports/' + id)
}

export const biService = {
  getReportBITokens,
}
