import * as React from 'react'
import Label from '../../../../components/commons/Label'
import StickySettingsBtnBottom from '../../../../components/commons/Layouts/settingsPages/StickySettingsBtnBottom'
import { Input } from '../../styles'
import { settingsService } from '../../../../infrastructure/services/settingsService'
import { useEffect } from 'react'
import Select, { MultiValue } from 'react-select'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { handleInputErrors } from '../../../../infrastructure/utils/common'

interface Option {
  label: string
  value: string
}

const CreateCapability = () => {
  const { t } = useTranslation()
  const [permissions, setPermissions] = React.useState<any[]>([])
  const [name, setName] = React.useState<string>('')
  const [selectPermissions, setSelectPermissions] = React.useState<Option[]>([])
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [id, setId] = React.useState<Number>(0)
  const [loading, setLoading] = React.useState<boolean>(false)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location && location['state']) {
      const state: any = location['state']
      setName(state?.data?.name)
      setId(state?.data?.id)
      setEditMode(true)
      setSelectPermissions(
        state?.data?.permissions?.map((item: any) => ({
          label: item,
          value: item,
        }))
      )
      setLoading(false)
    }
  }, [location])

  const getPermissions = async () => {
    await settingsService.getPermissions().then((res) => {
      setPermissions(
        res.data.map((permission: Object) => {
          return {
            label: permission,
            value: permission,
          }
        })
      )
    })
    setLoading(false)
  }

  let calling = false
  useEffect(() => {
    if (!calling) {
      getPermissions()
      calling = true
    }
  }, [])

  const onChange = (newValue: MultiValue<Option>) => {
    setSelectPermissions(Object.values(newValue))
    if (document.getElementById('permissions')) {
      document.getElementById('permissions')!.classList.remove('error')
      document
        .getElementById('permissions')
        ?.parentElement?.querySelector('.tw-text-red-500')
        ?.remove()
    }
    if (document.getElementsByClassName('select__control').length > 0) {
      document
        .getElementsByClassName('select__control')[0]
        .removeAttribute('style')
    }
  }

  const onSave = async () => {
    setLoading(true)
    if (editMode) {
      await settingsService
        .updateRole(id, {
          permissions:
            selectPermissions.length > 0
              ? selectPermissions.map((permission: any) => permission.value)
              : null,
        })
        .then(() => {
          toast(t('messages.Rol actualizado correctamente') as string, {
            type: 'success',
            className: 'toast-success tw-p-4',
            position: 'top-center',
          })
          navigate('/settings/roles')
        })
        .catch(() => {
          toast.error(t('messages.Error al actualizar el rol') as string)
        })
      setSelectPermissions([])
      setName('')
      setLoading(false)
      return
    }

    await settingsService
      .createRole({
        name,
        permissions: selectPermissions.map(
          (permission: any) => permission.value
        ),
      })
      .then((res) => {
        if (res.status === 200) {
          toast(t('messages.el registro se ha guardado') as string, {
            type: 'success',
            className: 'toast-success tw-p-4',
            position: 'top-center',
          })
          setSelectPermissions([])
          setName('')
          navigate('/settings/roles')
        }
      })
      .catch((err) => {
        let errors = handleInputErrors(err)
        if (errors.find((error) => error['permissions'])) {
          document
            .getElementsByClassName('select__control')[0]
            ?.setAttribute(
              'style',
              'border-color: #e53e3e !important; boder-width: 2px !important;'
            )
        }
      })

    setLoading(false)
  }

  return (
    <div className="tw-h-full tw-overflow-hidden tw-py-8 tw-px-8">
      <StickySettingsBtnBottom onSave={onSave} loading={loading}>
        <div className="tw-w-1/2 tw-mx-auto tw-h-full tw-mt-4">
          <div className=" tw-flex tw-flex-col tw-gap-3">
            <div className="tw-flex  tw-items-center">
              <Label
                label="Título"
                translate
                className="ctw-mb-1  tw-md:mb-0 tw-whitespace-nowrap tw-mr-2"
              />
            </div>
            <div>
              <Input
                className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800"
                type="text"
                placeholder="Nombre"
                value={name}
                disabled={editMode}
                name="name"
                onChange={(e) => {
                  setName(e.target.value)
                  if (
                    e.target.parentElement?.querySelector('.tw-text-red-500')
                  ) {
                    e.target.parentElement
                      .querySelector('.tw-text-red-500')
                      ?.remove()
                    e.target.classList.remove('tw-border-red-500')
                  }
                }}
              />
            </div>
            <div className="tw-flex tw-items-center"></div>
            <div className="tw-flex tw-items-center">
              <Label
                label="Permisos"
                translate
                className="ctw-mb-1 tw-md:mb-0 tw-whitespace-nowrap tw-mr-2"
              />
            </div>
            <div>
              <Select
                isMulti
                name="permissions"
                options={permissions}
                className="basic-multi-select tw-w-full"
                classNamePrefix="select"
                onChange={onChange}
                value={selectPermissions}
                id="permissions"
              />
            </div>
          </div>
        </div>
      </StickySettingsBtnBottom>
    </div>
  )
}

export default CreateCapability
