import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Paragraphs from '../../commons/Paragraphs/Paragraphs';

Modal.setAppElement('#root');

interface Props {
  header: string;
  field: string;
  value: string;
  onSave: (newValue: string) => void;
  onClose: () => void;
}

const ModifyDepeatureModal: React.FC<Props> = ({ header, value, onSave, onClose }) => {
  const [hour, setHour] = useState(value.split(':')[0] ?? '00');
  const [minute, setMinute] = useState(value.split(':')[1] ?? '00');
  const { t } = useTranslation();

  const handleSave = () => {
    const newValue = `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}h`;
    onSave(newValue);
    onClose();
  };

  const getCustomStyles = () => ({
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 'fit-content', // Ajusta el ancho del modal según su contenido
      maxWidth: '80%', // Establece un ancho máximo para el modal
      padding: '20px', // Añade un poco de relleno al contenido del modal
    },
  });


  const customStyles = getCustomStyles();

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Modificar Campo"
      style={customStyles}
    // Other props for the Modal
    >
      <label htmlFor="fieldInput" className="tw-block tw-mb-2 tw-text-center">
        <Paragraphs size='md' weight='bold' className='tw-text-[#004753]'
        >
          Modificar {header}
        </Paragraphs>
      </label>
      <div className="tw-flex tw-text-center tw-justify-center">
        <input
          type="number"
          id="hourInput"
          min="0"
          max="23"
          value={hour}
          onChange={(e) => setHour(e.target.value)}
          className="tw-border tw-border-gray-300 tw-outline-none tw-rounded-md tw-py-1 tw-px-2 tw-mb-4"
          style={{ width: 'auto' }}
        />
        <span className="tw-mx-2 tw-my-1">:</span>
        <input
          type="number"
          id="minuteInput"
          min="0"
          max="59"
          value={minute}
          onChange={(e) => setMinute(e.target.value)}
          className="tw-border tw-border-gray-300 tw-outline-none tw-rounded-md tw-py-1 tw-px-2 tw-mb-4"
          style={{ width: 'auto' }}
        />
      </div>

      <div className="tw-flex tw-space-x-2 tw-w-full">

        <button
          onClick={handleSave}
          className='tw-flex tw-items-center tw-border tw-border-gray-300 tw-rounded-lg tw-px-2 xl:tw-px-6 tw-py-1 tw-bg-[#004753] tw-text-white'
        >
          <Paragraphs>
            {t('Guardar')}
          </Paragraphs>
        </button>
        <button
          onClick={handleSave}
          className='tw-flex tw-items-center tw-border tw-border-gray-300 tw-rounded-lg tw-px-2 xl:tw-px-6 tw-py-1 tw-bg-red-500 tw-text-white'
        >
          <Paragraphs>
            {t('Cancelar')}
          </Paragraphs>
        </button>
      </div>
    </Modal>
  );
};

export default ModifyDepeatureModal;
