import styled from 'styled-components'

export const DropDownZoneImgPlaceHolder = styled.div<{
  imageUrl: string
}>`
  width: 100%;
  height: 46px;
  margin: 0 auto;
  background: url(${({ imageUrl }) => imageUrl}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`
