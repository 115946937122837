import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ICompanyItem } from '../../interfaces/settings'

export interface MultitenantState {
  entity: ICompanyItem | null
  entityList: ICompanyItem[]
}

const initialState: MultitenantState = {
  entity: null,
  entityList: [],
}

export const multiTenantReducer = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSelectedEntity: (state, action: PayloadAction<ICompanyItem | null>) => {
      state.entity = action.payload
    },
    setEntityList: (state, action: PayloadAction<ICompanyItem[]>) => {
      state.entityList = action.payload
    },
  },
})

export const { setSelectedEntity, setEntityList } = multiTenantReducer.actions

export default multiTenantReducer.reducer
