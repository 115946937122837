import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setMenu } from '../../../../infrastructure/store/sidebar/sidebarSlice'
import { checkPermissions } from '../../../../infrastructure/utils/permissions'
import { UserRoles } from '../../../../infrastructure/interfaces/users'
import storageService from '../../../../infrastructure/services/storageService'
import { settingsService } from '../../../../infrastructure/services/settingsService'
import {
  actionContextMenu,
  formattedFilters,
} from '../../../../infrastructure/utils/common'
import {
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { GetContextMenuItemsParams } from 'ag-grid-community'
import { MenuItemDef } from 'ag-grid-community'

const useNavigationList = () => {
  const dispatch = useDispatch()
  const menu = useSelector((state: any) => state.sidebar.menu)
  const user =
    useSelector((state: any) => state.auth) || storageService.getUser()
  const [gridApi, setGridApi] = useState<GridApi>()
  const [deleteModal, setDeleteModal] = useState<any>(false)
  const [loadedData, setLoadedData] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(14)
  const navigate = useNavigate()

  const getContextMenu = (row: GetContextMenuItemsParams) => {
    if (!row.value) return []
    const items: MenuItemDef[] = []

    let currentUser = user?.user || storageService.getUser()

    if (
      checkPermissions(
        ['reports.*', 'reports.edit'],
        currentUser?.permissions
      ) ||
      currentUser?.role?.name === UserRoles.ADMIN
    ) {
      items.push({
        name: 'Editar',
        action: () =>
          actionContextMenu(
            'EDIT',
            row,
            null,
            location.pathname + '/menu/' + row.node!.data.id,
            navigate
          ),
        cssClasses: ['tw-cursor-pointer'],
      })
    }
    if (
      checkPermissions(
        ['reports.*', 'reports.delete'],
        currentUser?.permissions
      ) ||
      currentUser?.role?.name === UserRoles.ADMIN
    ) {
      items.push({
        name: 'Eliminar',
        action: () => actionContextMenu('DELETE', row, setDeleteModal),
        cssClasses: ['tw-text-alert', 'tw-cursor-pointer'],
      })
    }
    return items
  }

  const handleDelete = () => {
    settingsService
      .deleteReport(deleteModal.id)
      .then((res) => {
        if (menu && menu.length) {
          dispatch(
            setMenu(menu.filter((item: any) => item.slug !== deleteModal.slug))
          )
        }
        setDeleteModal(false)
        gridApi?.refreshServerSide({ purge: true })
      })
      .catch((err) => {
        setDeleteModal(false)
      })
  }
  const onGridReady = async (params: GridReadyEvent) => {
    setGridApi(params.api)
    const updateData = (data: any) => {
      let idSequence = 1
      data?.items?.forEach(function (item: any) {
        item.id = idSequence++
      })

      const datasource = {
        async getRows(params: IServerSideGetRowsParams) {
          let filters: any = null
          if (
            params.request.filterModel &&
            Object.keys(params.request.filterModel).length > 0
          ) {
            filters = formattedFilters(params.request.filterModel)
          }

          if (params.request.sortModel.length > 0) {
            filters = {
              ...filters,
              order_field: params.request.sortModel[0].colId,
              order_direction: params.request.sortModel[0].sort,
            }
          }

          try {
            const response = await settingsService.getCompanies({
              page: params.api.paginationGetCurrentPage() + 1,
              per_page: perPage,
              from: params.request.endRow,
              ...filters,
            })

            if (response) {
              params.success({
                rowData: response.data.items,
                rowCount: response.data.meta.total,
              })
              setTotalPages(response.data.meta.last_page)
              setCurrentPage(response.data.meta.current_page)
            } else {
              params.fail()
            }
          } catch (error) {
            params.fail()
          }
        },
      }
      params.api.setServerSideDatasource(datasource)
    }

    updateData(params.api)
  }

  return {
    gridApi,
    deleteModal,
    setDeleteModal,
    loadedData,
    currentPage,
    totalPages,
    perPage,
    onGridReady,
    getContextMenu,
    handleDelete,
  }
}

export default useNavigationList
