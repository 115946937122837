import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFilterItem } from '../../interfaces/filters'
import { IRoleItem } from '../../interfaces/settings'

export interface TableFiltersState {
  filters: IFilterItem[]
  tags: string[]
  companies: string[]
  roles: IRoleItem[]
  permissions: string[]
}

const initialState: TableFiltersState = {
  filters: [],
  tags: [],
  companies: [],
  roles: [],
  permissions: [],
}

export const tableFiltersReducer = createSlice({
  name: 'tableFilters',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<IFilterItem[]>) => {
      state.filters = action.payload
    },
    deleteFilter: (state, action: PayloadAction<number>) => {
      state.filters = state.filters.filter(
        (filter) => filter.id !== action.payload
      )
    },
    setTags: (state, action: PayloadAction<string[]>) => {
      state.tags = action.payload
    },
    setCompanies: (state, action: PayloadAction<string[]>) => {
      state.companies = action.payload
    },
    setRoles: (state, action: PayloadAction<IRoleItem[]>) => {
      state.roles = action.payload
    },
    setPermissions: (state, action: PayloadAction<string[]>) => {
      state.permissions = action.payload
    },
  },
})

export const {
  setFilters,
  deleteFilter,
  setTags,
  setCompanies,
  setRoles,
  setPermissions,
} = tableFiltersReducer.actions

export default tableFiltersReducer.reducer
