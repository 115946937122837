import {
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsParams,
  MenuItemDef,
  RowSelectedEvent,
} from 'ag-grid-community'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import AgList from '../../../components/commons/AgGrid/AgList'
import Button from '../../../components/commons/Button'
import AssignReportsModal from '../../../components/commons/modals/AssignReportsModal/AssignReportsModal'
import DeleteModal from '../../../components/commons/modals/DeleteModal/DeleteModal'
import { userColumns } from '../../../infrastructure/constant/columns/userColumns'
import {
  ICompanyItem,
  IReportItem,
} from '../../../infrastructure/interfaces/settings'
import { IUserItem, UserRoles } from '../../../infrastructure/interfaces/users'
import { settingsService } from '../../../infrastructure/services/settingsService'
import storageService from '../../../infrastructure/services/storageService'
import { RootState } from '../../../infrastructure/store'
import {
  actionContextMenu,
  formattedFilters,
} from '../../../infrastructure/utils/common'
import { checkPermissions } from '../../../infrastructure/utils/permissions'

const UserList = () => {
  const { t } = useTranslation()
  const [gridApi, setGridApi] = useState<GridApi>()
  const [deleteModal, setDeleteModal] = useState<any>(false)
  const [perPage, setPerPage] = useState<number>(14)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [selectedUsers, setSelectedUsers] = useState<IUserItem[]>([])
  const [loadedData, setLoadedData] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)

  const navigate = useNavigate()

  // store
  const user: any = useSelector((state: RootState) => state.auth)
  const companies = useSelector(
    (state: RootState) => state.tableFilters.companies
  )
  const tags = useSelector((state: RootState) => state.tableFilters.tags)
  const roles = useSelector((state: RootState) => state.tableFilters.roles)
  const permissions = useSelector(
    (state: RootState) => state.tableFilters.permissions
  )

  useEffect(() => {
    localStorage.setItem('tags', JSON.stringify(tags))
  }, [tags]),
    useEffect(() => {
      localStorage.setItem('companies', JSON.stringify(companies))
    }, [companies]),
    useEffect(() => {
      localStorage.setItem('roles', JSON.stringify(roles))
    }, [roles]),
    useEffect(() => {
      localStorage.setItem('permissions', JSON.stringify(permissions))
    }, [permissions])

  const getContextMenu = (row: GetContextMenuItemsParams) => {
    if (!row.value) return []
    const items: MenuItemDef[] = []

    let currentUser = user?.user || storageService.getUser()

    if (
      checkPermissions(['users.*', 'users.edit'], currentUser?.permissions) ||
      currentUser?.role?.name === UserRoles.ADMIN
    ) {
      items.push({
        name: 'Editar',
        action: () =>
          actionContextMenu(
            'EDIT',
            row,
            null,
            location.pathname + '/edit-user/' + row.node!.data.id,
            navigate
          ),
        cssClasses: ['tw-cursor-pointer'],
      })
    }
    if (
      checkPermissions(['users.*', 'users.delete'], currentUser?.permissions) ||
      currentUser?.role?.name === UserRoles.ADMIN
    ) {
      items.push({
        name: 'Eliminar',
        action: () => actionContextMenu('DELETE', row, setDeleteModal),
        cssClasses: ['tw-text-alert', 'tw-cursor-pointer'],
      })
    }
    return items
  }

  const handleDelete = () => {
    settingsService.deleteUser(deleteModal.id).then((res) => {
      setDeleteModal(false)
      gridApi?.refreshServerSide({ purge: true })
    })
  }

  const handleDoubleClick = (row: any) => {
    let currentUser = user?.user
    if (!row.data) {
      return
    }
    if (
      checkPermissions(['users.*', 'users.edit'], currentUser.permissions) ||
      currentUser.role?.name === UserRoles.ADMIN
    ) {
      actionContextMenu(
        'EDIT',
        row,
        null,
        location.pathname + '/edit-user/' + row.data.id,
        navigate
      )
    }
  }

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
    // adjust columns
    params.api.sizeColumnsToFit()
    params.api.addEventListener('filterOpened', ({ column }: any) => {
      if (column.colId === 'tags' || column.colId === 'companies') {
        params.api.openToolPanel('filters')
      }
    })

    const updateData = (data: any) => {
      let idSequence = 1
      data?.items?.forEach(function (item: any) {
        item.id = idSequence++
      })

      const datasource = {
        async getRows(params: IServerSideGetRowsParams) {
          let filters: any = null
          if (
            params.request.filterModel &&
            Object.keys(params.request.filterModel).length > 0
          ) {
            filters = formattedFilters(params.request.filterModel)
          }

          if (params.request.sortModel.length > 0) {
            filters = {
              ...filters,
              order_field: params.request.sortModel[0].colId,
              order_direction: params.request.sortModel[0].sort,
            }
          } else {
            filters = {
              ...filters,
              order_field: 'name',
              order_direction: 'asc',
            }
          }

          await getData(params, filters)
        },
      }

      params.api.setServerSideDatasource(datasource)
    }
    updateData(params.api)
  }

  const sideBarUsers = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: t('aggrid.filters'),
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'userFilterToolPanel',
        },
        {
          id: 'saved_filters',
          labelDefault: t('aggrid.filtros guardados'),
          labelKey: 'saved_filters',
          iconKey: 'menu',
          toolPanel: 'userSavedFiltersToolPanel',
        },
      ],
    }
  }, [loadedData])

  async function getData(params: any, filters: any) {
    let tags = localStorage.getItem('tags')
    let companies = localStorage.getItem('companies')
    let roles = localStorage.getItem('roles')
    let permissions = localStorage.getItem('permissions')
    let companiesPromise

    if (tags && tags.length > 0) {
      let tagsArray = JSON.parse(tags)
      if (tagsArray.length > 0) {
        filters = {
          ...filters,
          tags: tagsArray.map((tag: any) => tag).join(','),
        }
      }
    }

    if (companies) {
      let companiesArray = JSON.parse(companies)
      if (companiesArray.length > 0) {
        // Gets companies id from API
        let companiesNames = companiesArray
          .map((company: any) => company)
          .join(',')
        companiesPromise = settingsService.getCompanies({
          names: companiesNames,
        })
        filters.companies = (await companiesPromise).data.items
          .map((item: any) => item.id)
          .join(',')
      }
    }

    if (roles) {
      let rolesArray = JSON.parse(roles)
      if (rolesArray.length > 0) {
        filters.role = rolesArray.map((role: any) => role).join(',')
      }
    }

    if (permissions) {
      let permissionsArray = JSON.parse(permissions)
      if (permissionsArray.length > 0) {
        // Gets permissions id from API
        filters.permissions = permissionsArray
          .map((permission: string) => permission)
          .join(',')
      }
    }

    let [usersResponse] = await Promise.all([
      settingsService.getUsers({
        page: params.api.paginationGetCurrentPage() + 1,
        per_page: perPage,
        ...filters,
      }),
    ])

    setLoadedData(true)
    setCurrentPage(usersResponse.data.meta.page)
    setTotalPages(usersResponse.data.meta.last_page)
    return params.success({
      rowData: usersResponse.data.items || null,
      rowCount: usersResponse.data.meta.total,
      storeInfo: {
        page: usersResponse.data.meta.page,
        perPage: usersResponse.data.meta.per_page,
      },
    })
  }

  const onRowSelected = (event: RowSelectedEvent) => {
    setSelectedUsers(event.api.getSelectedRows() as IUserItem[])
  }

  const handleAssignReports = () => {
    setOpenModal(true)
  }

  const handleAssignMassive = (
    selectedReports: IReportItem[],
    selectedEntities: ICompanyItem[]
  ) => {
    setOpenModal(false)
    let users = selectedUsers.map((user: IUserItem) => user.id) || []
    let reports = selectedReports.map((report: IReportItem) => report.id) || []
    let companies =
      selectedEntities.map((company: ICompanyItem) => company.id) || []
    settingsService
      .userMassUpdate(users, reports, companies)
      .then((res) => {
        toast(t('messages.el registro se ha guardado') as string, {
          type: 'success',
          className: 'toast-success tw-p-4',
          position: 'top-center',
        })
        gridApi?.forEachNode((node) => node.setSelected(false))
        setSelectAll(false)
        gridApi?.onFilterChanged()
      })
      .catch((error) => {
        toast(t('messages.error al guardar el registro') as string, {
          type: 'error',
          className: 'toast-error tw-p-4',
          position: 'top-center',
        })
      })
  }

  return (
    <>
      {gridApi &&
        (user.user?.role?.name === UserRoles.ADMIN ||
          checkPermissions(
            ['users.edit', 'users.*', 'reports.list'],
            user.user?.permissions || ['']
          )) && (
          <div className="tw-relative tw-flex tw-justify-between tw-text-sm tw-pt-2 tw-pl-8 tw-h-16">
            {/* select all */}
            <div
              className="tw-flex tw-items-center tw-cursor-pointer"
              onClick={() => {
                if (selectAll) {
                  gridApi.forEachNode((node) => node.setSelected(false))
                } else {
                  gridApi.forEachNode((node) => node.setSelected(true))
                }
                setSelectAll(!selectAll)
              }}
            >
              <input
                type="checkbox"
                className="tw-mr-2"
                defaultChecked={selectAll}
                disabled={loadedData ? false : true}
                id="select-all"
              />
              <label
                htmlFor="select-all"
                className="tw-cursor-pointer tw-text-gray-500 tw-mt-0.5"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                {t('labels.Seleccionar todos')}
              </label>
            </div>
            {selectedUsers.length >= 1 && (
              <div className="tw-relative tw-flex tw-justify-end tw-text-sm tw-pr-8">
                <div className="tw-flex tw-items-center">
                  <Button
                    label="Asignar informes"
                    onClick={handleAssignReports}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      <AgList
        height="calc(100vh - 200px)"
        loading={!loadedData}
        sideBar={sideBarUsers}
        currentPage={currentPage}
        totalPages={totalPages}
        columns={userColumns}
        gridApi={gridApi}
        getContextMenu={getContextMenu}
        onGridReady={onGridReady}
        onRowDoubleClicked={handleDoubleClick}
        pagination={true}
        paginationPageSize={perPage}
        cacheBlockSize={perPage}
        rowSelection="multiple"
        onRowSelected={onRowSelected}
      />
      {/* <BiffTable header={userHeaders} rows={rowData} onRowClick={() => {}} /> */}

      <DeleteModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        description={'¿Está seguro que desea eliminar este registro?'}
        onConfirm={handleDelete}
        title={t('titles.Eliminar usuario')}
      />

      <AssignReportsModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={handleAssignMassive}
      />
    </>
  )
}

export default UserList
