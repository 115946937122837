import { IToolPanelParams } from 'ag-grid-community'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../Button'
import Label from '../../Label'
import Paragraphs from '../../Paragraphs/Paragraphs'
import AsyncSelect from 'react-select/async'
import { ICompanyItem } from '../../../../infrastructure/interfaces/settings'
import { settingsService } from '../../../../infrastructure/services/settingsService'
import { filterService } from '../../../../infrastructure/services/filterServices'
import {
  debounce,
  handleInputErrors,
  userTypeGuard,
} from '../../../../infrastructure/utils/common'
import { toast } from 'react-toastify'
import i18next from 'i18next'
import { useUserFilters } from '../../../../infrastructure/hooks/useUserFilters'
import { useDispatch, useSelector } from 'react-redux'
import {
  setTags as setStoreTags,
  setCompanies as setStoreCompanies,
  setRoles as setStoreRoles,
  setPermissions as setStorePermissions,
} from '../../../../infrastructure/store/tableFilters/tableFiltersSlice'
import { RootState } from '../../../../infrastructure/store'
import { checkPermissions } from '../../../../infrastructure/utils/permissions'
import { UserRoles } from '../../../../infrastructure/interfaces/users'
import { SecondaryButton } from '../../SecondaryButton/SecondaryButton'
import { PrimaryButton } from '../../PrimaryButton/PrimaryButton'
import { TertiaryButton } from '../../TertiaryButton/TertiaryButton'

export default (props: IToolPanelParams) => {
  const { t } = useTranslation()
  const { addFilters } = useUserFilters()
  const { api } = props as IToolPanelParams
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.auth.user)
  const columns = api.getColumns()
  const [filtersColumns, setFiltersColumns] = useState<any[]>([])
  const [newFilterName, setNewFilterName] = useState<any>('')
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([])
  const [selectedTags, setSelectedTags] = useState<any[]>([])
  const [selectedRoles, setSelectedRoles] = useState<any[]>([])
  const [selectedPermissions, setSelectedPermissions] = useState<any[]>([])
  const [inputsValues, setInputsValues] = useState<any>({})
  const [companies, setCompanies] = useState<ICompanyItem[]>([])
  const [tags, setTags] = useState<any[]>([])
  const [roles, setRoles] = useState<any[]>([])
  const [permissions, setPermissions] = useState<any[]>([])

  useEffect(() => {
    if (columns && columns.length > 0 && filtersColumns.length === 0) {
      const filters = columns
        .filter((column) => column.getColDef().filter)
        .map((column) => {
          return {
            field: column.getColDef().field,
            filter:
              column.getColDef().field === 'companies' ||
                column.getColDef().field === 'tags'
                ? 'custom'
                : column.getColDef().filter,
            headerName: column.getColDef().headerName,
          }
        })
      setFiltersColumns(filters)
    }
  }, [])

  api.addEventListener('filterChanged', (e: any) => {
    const filters = api.getFilterModel()
    let tagsStorage = localStorage.getItem('tags')
    let companiesStorage = localStorage.getItem('companies')
    let rolesStorage = localStorage.getItem('roles')
    let permissionsStorage = localStorage.getItem('permissions')
    if (companiesStorage) {
      let companiesNames = JSON.parse(companiesStorage)
      setSelectedCompanies(
        companiesNames.map((company: any) => {
          return { name: company }
        })
      )
    }

    if (tagsStorage) {
      let tagsNames = JSON.parse(tagsStorage)
      setSelectedTags(
        tagsNames.map((tag: any) => {
          return { name: tag }
        })
      )
    }

    if (rolesStorage) {
      let rolesNames = JSON.parse(rolesStorage)
      setSelectedRoles(
        rolesNames.map((role: any) => {
          return { name: role }
        })
      )
    }

    if (permissionsStorage) {
      let permissionsNames = JSON.parse(permissionsStorage)
      setSelectedPermissions(
        permissionsNames.map((permission: any) => {
          return { name: permission }
        })
      )
    }

    setInputsValues(filters)
  })

  const onFilterChange = (field: string, value: any) => {
    let filtersModel = api.getFilterModel()
    if (value) {
      if (typeof value === 'string') {
        filtersModel[field] = {
          type: 'contains',
          filter: value,
        }
      }
      if (Array.isArray(value) && value.length > 0) {
        filtersModel[field] = {
          filterType: 'set',
          values: value.map((item: any) => String(item)),
        }
        if (field === 'tags') {
          dispatch(setStoreTags(value))
        }
        if (field === 'companies') {
          dispatch(setStoreCompanies(value))
        }
        if (field === 'roles') {
          dispatch(setStoreRoles(value))
        }
        if (field === 'permissions') {
          dispatch(setStorePermissions(value))
        }
      } else if (Array.isArray(value) && value.length === 0) {
        if (field === 'tags') {
          dispatch(setStoreTags([]))
        }
        if (field === 'companies') {
          dispatch(setStoreCompanies([]))
        }
        if (field === 'roles') {
          dispatch(setStoreRoles([]))
        }
        if (field === 'permissions') {
          dispatch(setStorePermissions([]))
        }

        delete filtersModel[field]
      }
    } else {
      delete filtersModel[field]
    }

    setInputsValues({
      ...inputsValues,
      [field]: {
        filter: value,
      },
    })

    api.setFilterModel(filtersModel)
    debounce(api.onFilterChanged, 500)
  }

  const onClearFilters = () => {
    api.setFilterModel(null)
    clearInputs()
  }

  const onSaveFilters = () => {
    const filtersModel = api.getFilterModel()
    if (filtersModel) {
      if (selectedTags.length > 0) {
        filtersModel.tags = {
          filterType: 'set',
          values: selectedTags.map((item: any) => String(item.name)),
        }
      }
      if (selectedCompanies.length > 0) {
        filtersModel.companies = {
          filterType: 'set',
          values: selectedCompanies.map((item: any) => String(item.name)),
        }
      }
      if (selectedRoles.length > 0) {
        filtersModel.roles = {
          filterType: 'set',
          values: selectedRoles.map((item: any) => String(item.name)),
        }
      }
      if (selectedPermissions.length > 0) {
        filtersModel.permissions = {
          filterType: 'set',
          values: selectedPermissions.map((item: any) => String(item.name)),
        }
      }

      if (
        (userTypeGuard(user) &&
          checkPermissions(
            ['filters.*', 'filters.create'],
            user?.permissions
          )) ||
        user?.role.name === UserRoles.ADMIN
      ) {
        let response = saveFilter(newFilterName, filtersModel)
        response.then((res) => {
          if (typeof res !== 'boolean') {
            addFilters(res)
            setNewFilterName('')
            api.refreshToolPanel()
          }
        })
      }
    }
  }

  const clearInputs = () => {
    document.getElementsByName('filter').forEach((item: any) => {
      item['value'] = ''
      item.classList.remove('tw-border-red-500')
      item.parentElement?.querySelector('.tw-text-red-500')?.remove()
    })
    document
      .getElementById('filter_name')
      ?.classList.remove('tw-border-red-500')
    document
      .getElementById('filter_name')
      ?.parentElement?.querySelector('.tw-text-red-500')
      ?.remove()

    setSelectedCompanies([])
    setSelectedTags([])
    setSelectedRoles([])
    setSelectedPermissions([])
    dispatch(setStoreRoles([]))
    dispatch(setStorePermissions([]))
    dispatch(setStoreTags([]))
    dispatch(setStoreCompanies([]))
    setNewFilterName('')
    localStorage.removeItem('tags')
    localStorage.removeItem('companies')
    localStorage.removeItem('roles')
    localStorage.removeItem('permissions')
    api.onFilterChanged()
  }

  return (
    <div className="ag-filter-tool-panel tw-p-2 tw-w-full tw-h-full tw-overflow-auto">
      <div className="ag-filter-tool-panel__filters tw-flex tw-flex-col tw-w-100">
        <Paragraphs className="tw-text-sm tw-font-bold tw-mb-2 tw-text-center tw-mt-2">
          {t('titles.Filtros')}
        </Paragraphs>
        {filtersColumns.map((filter) => (
          <Filter
            key={filter.field}
            filter={filter}
            onFilterChange={onFilterChange}
            setSelectValue={setSelectedCompanies}
            selectValue={selectedCompanies}
            inputsValues={inputsValues}
            setCompanies={setCompanies}
            setSelectedTags={setSelectedTags}
            selectedTags={selectedTags}
            setTags={setTags}
            setSelectedRoles={setSelectedRoles}
            selectedRoles={selectedRoles}
            setRoles={setRoles}
            roles={roles}
            setSelectedPermissions={setSelectedPermissions}
            selectedPermissions={selectedPermissions}
            setPermissions={setPermissions}
            permissions={permissions}
            t={t}
            user={user}
          />
        ))}
        <div className="tw-flex tw-flex-col tw-gap-2 tw-mb-2 tw-relative">
          <Label label="Nombre del filtro" className="tw-text-sm" />
          <div className="tw-flex tw-flex-col">
            <input
              type="text"
              name="name"
              id="filter_name"
              className="tw-w-full tw-p-1 tw-border-2 tw-border-gray-300 tw-rounded focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-300 focus:tw-border-blue-300"
              placeholder={t('placeholders.Nombre del filtro')}
              onChange={(e) => {
                if (e.target.classList.contains('tw-border-red-500')) {
                  e.target.classList.remove('tw-border-red-500')
                }
                e.target.parentElement
                  ?.querySelector('.tw-text-red-500')
                  ?.remove()
                setNewFilterName(e.target.value)
              }}
              value={newFilterName}
            />
          </div>
        </div>
        <div className="tw-relative tw-block">
          <div className="ag-filter-tool-panel__actio
          ns tw-w-full tw-mt-2">
            <div className="tw-flex tw-flex-row tw-gap-4 tw-justify-between">
              <div className="tw-w-[100px] tw-h-[44px]">
                <PrimaryButton text="Guardar" type="button" onClick={onSaveFilters} />
              </div>
              <div className="tw-w-[100px] tw-h-[44px]">
                <TertiaryButton text="Limpiar" type="button" onClick={onClearFilters} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Filter(props: any) {
  const {
    filter,
    onFilterChange,
    companies = [],
    setSelectValue,
    selectValue,
    inputsValues,
    setCompanies,
    setSelectedTags,
    selectedTags,
    setTags,
    setSelectedRoles,
    selectedRoles,
    setRoles,
    roles,
    setSelectedPermissions,
    selectedPermissions,
    setPermissions,
    permissions,
    t,
    user,
  } = props

  const onFilterChangeHandler = (value: any) => {
    if (Array.isArray(value)) {
      onFilterChange(
        filter.field,
        value.map((item: any) => item.name)
      )
      if (filter.field === 'companies') {
        setSelectValue(value)
      }
      if (filter.field === 'tags') {
        setSelectedTags(value)
        localStorage.setItem(
          'tags',
          JSON.stringify(value.map((item: any) => item.name))
        )
      }
      if (filter.field === 'roles') {
        setSelectedRoles(value)
        localStorage.setItem(
          'roles',
          JSON.stringify(value.map((item: any) => item.name))
        )
      }
      if (filter.field === 'permissions') {
        setSelectedPermissions(value)
        localStorage.setItem(
          'permissions',
          JSON.stringify(value.map((item: any) => item.name))
        )
      }
    } else {
      onFilterChange(filter.field, value)
    }
  }

  let callingCompanies = false
  const getCompanies = (inputValue: string) => {
    if (!callingCompanies && inputValue === '') {
      callingCompanies = true
      return settingsService
        .getCompanies({ name: inputValue, per_page: 50 })
        .then((response: any) => {
          setCompanies(response.data.items)
          return response.data.items
        })
    } else if (inputValue !== '' && !callingCompanies) {
      callingCompanies = true
      return settingsService
        .getCompanies({ name: inputValue, per_page: 50 })
        .then((response: any) => {
          setCompanies(response.data.items)
          return response.data.items
        })
    }
  }

  let callingTags = false
  const getTags = (inputValue: string) => {
    if (!callingTags && inputValue === '') {
      callingTags = true
      return settingsService
        .getTags({ name: inputValue, per_page: 50 })
        .then((response: any) => {
          setTags(response.data.items)
          return response.data.items
        })
    } else if (inputValue !== '') {
      return settingsService
        .getTags({ name: inputValue, per_page: 50 })
        .then((response: any) => {
          setTags(response.data.items)
          return response.data.items
        })
    }
  }

  let callingRoles = false
  const getRoles = (inputValue: string) => {
    if (!callingRoles) {
      callingRoles = true
      return settingsService
        .getRoles({ name: inputValue, per_page: 50 })
        .then((response: any) => {
          setRoles(response.data.items)
          return response.data.items
        })
    }
  }

  let callingPermissions = false
  const getPermissions = (inputValue: string) => {
    if (!callingPermissions) {
      callingPermissions = true
      return settingsService.getPermissions().then((response: any) => {
        setPermissions(response.data)
        return response.data?.map((item: any) => ({
          name: item,
          label: item,
        }))
      })
    }
  }

  let timerCompanies: any = null
  const promiseOptions = (inputValue: string) =>
    new Promise<ICompanyItem[]>((resolve) => {
      clearTimeout(timerCompanies)
      timerCompanies = setTimeout(() => {
        resolve(getCompanies(inputValue) || [])
      }, 500)
    })

  let timer: any = null
  const promiseTags = (inputValue: string) =>
    new Promise<any>((resolve) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resolve(getTags(inputValue) || [])
      }, 500)
    })

  let timerRoles: NodeJS.Timeout = setTimeout(() => { }, 0)
  const promiseRoles = (inputValue: string) =>
    new Promise<any>((resolve) => {
      clearTimeout(timerRoles)
      timerRoles = setTimeout(() => {
        resolve(getRoles(inputValue) || [])
      }, 500)
    })

  let timerPermissions: NodeJS.Timeout = setTimeout(() => { }, 0)
  const promisePermissions = (inputValue: string) =>
    new Promise<any>((resolve) => {
      clearTimeout(timerPermissions)
      timerPermissions = setTimeout(() => {
        resolve(getPermissions(inputValue) || [])
      }, 500)
    })

  const canUserList = (permissions: string[]) => {
    return (
      (userTypeGuard(user) &&
        checkPermissions(permissions, user?.permissions)) ||
      user?.role.name === UserRoles.ADMIN
    )
  }

  return (
    <div className="ag-filter-tool-panel__filter tw-mb-2">
      <Label label={filter.headerName} className="tw-text-sm" />
      {filter.filter === 'agTextColumnFilter' && (
        <input
          className="tw-w-full tw-p-1 tw-border-2 tw-border-gray-300 tw-rounded focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-300 focus:tw-border-blue-300"
          type="text"
          name="filter"
          onChange={(e) => {
            if (e.target.classList.contains('tw-border-red-500')) {
              e.target.classList.remove('tw-border-red-500')
            }
            e.target.parentElement?.querySelector('.tw-text-red-500')?.remove()
            onFilterChangeHandler(e.target.value)
          }}
          value={inputsValues[filter.field]?.filter || ''}
        />
      )}
      {filter.filter === 'custom' &&
        filter.field === 'tags' &&
        canUserList(['tags.*', 'tags.view', 'tags.list']) && (
          <AsyncSelect
            isMulti
            cacheOptions
            defaultOptions
            loadOptions={promiseTags}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option) => String(option.name)}
            onChange={onFilterChangeHandler}
            value={selectedTags}
            className="tw-appearance-none tw-w-full tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2 tw-italic tw-cursor-pointer"
            styles={{
              control: (provided: any) => ({
                ...provided,
                minHeight: 32,
                border: '2px solid #d1d5db !important',
              }),
              valueContainer: (provided: any) => ({
                ...provided,
                minHeight: 32,
                padding: '2 0',
              }),
            }}
            placeholder={t('labels.Tags')}
          />
        )}
      {filter.filter === 'custom' &&
        filter.field === 'companies' &&
        canUserList(['companies.*', 'companies.view', 'companies.list']) && (
          <AsyncSelect
            isMulti
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option) => String(option.name)}
            onChange={onFilterChangeHandler}
            value={selectValue}
            className="tw-appearance-none tw-w-full tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2 tw-italic tw-cursor-pointer"
            styles={{
              control: (provided: any) => ({
                ...provided,
                minHeight: 32,
                border: '2px solid #d1d5db !important',
              }),
              valueContainer: (provided: any) => ({
                ...provided,
                minHeight: 32,
                padding: '2 0',
              }),
            }}
            placeholder={t('labels.Companies')}
          />
        )}
      {filter.filter === 'custom' &&
        filter.field === 'roles' &&
        canUserList(['role.*', 'role.view', 'role.list']) && (
          <AsyncSelect
            isMulti
            cacheOptions
            defaultOptions
            loadOptions={promiseRoles}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option) => String(option.name)}
            onChange={onFilterChangeHandler}
            value={selectedRoles}
            className="tw-appearance-none tw-w-full tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2 tw-italic tw-cursor-pointer"
            styles={{
              control: (provided: any) => ({
                ...provided,
                minHeight: 32,
                border: '2px solid #d1d5db !important',
              }),
              valueContainer: (provided: any) => ({
                ...provided,
                minHeight: 32,
                padding: '2 0',
              }),
            }}
            placeholder={t('labels.Roles')}
          />
        )}
      {filter.filter === 'custom' &&
        filter.field === 'permissions' &&
        canUserList([
          'permissions.*',
          'permissions.view',
          'permissions.list',
        ]) && (
          <AsyncSelect
            isMulti
            cacheOptions
            defaultOptions
            loadOptions={promisePermissions}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option) => String(option.name)}
            onChange={onFilterChangeHandler}
            value={selectedPermissions}
            className="tw-appearance-none tw-w-full tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2 tw-italic tw-cursor-pointer"
            styles={{
              control: (provided: any) => ({
                ...provided,
                minHeight: 32,
                border: '2px solid #d1d5db !important',
              }),
              valueContainer: (provided: any) => ({
                ...provided,
                minHeight: 32,
                padding: '2 0',
              }),
            }}
            placeholder={t('labels.Permisos')}
          />
        )}
    </div>
  )
}

async function saveFilter(name: string, filters: any) {
  const t = i18next.t
  return await filterService
    .createFilter({
      name,
      table: 'users',
      filter: filters,
    })
    .then((res) => {
      toast.success(`${t('messages.Filtro guardado')}`, {
        position: 'top-center',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        autoClose: 5000,
      })
      return res.data
    })
    .catch((err) => {
      handleInputErrors(err)
      return false
    })
}
