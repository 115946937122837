import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Column, useTable } from 'react-table';
import ContextMenu from './ContextMenu';
import AltModal, { AltModalProps } from './AltModal';
import { ActionRow, IRoutes, TableRow } from '../../../infrastructure/interfaces/routes';

// Representa la posición con valores top (arriba) y left (izquierda).
interface Position {
  top: number; // Valor de posición superior
  left: number; // Valor de posición izquierda
}
export interface DataTableProps {
  columns: Column<TableRow>[];
  data: IRoutes[];
  tableData: TableRow[];
  droppableId: string;
  onDragEnd: (result: any) => void;
  selectRow: string | null;
  setSelectRow: (result: string | null) => void;
  actionRows: (props: ActionRow) => void;

}

const DataTable = (props: DataTableProps) => {
  const {
    data,
    columns,
    tableData,
    droppableId,
    selectRow,
    setSelectRow,
    actionRows
  } = props

  const [contextMenuPosition, setContextMenuPosition] = useState<Position>({ top: 0, left: 0 });
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [altModalPosition, setAltModalPosition] = useState<AltModalProps>({ top: 0, left: 0, text: '' });
  const [showAltModal, setShowAltModal] = useState(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tableData,
  });

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const calculateRelativePosition = (
    event: MouseEvent<HTMLElement>,
    parentElement: HTMLDivElement | null
  ): Position | null => {
    if (!parentElement) return null;

    const rect = event.currentTarget.getBoundingClientRect(); // Obtener las dimensiones del elemento actual
    const parentRect = parentElement.getBoundingClientRect(); // Obtener las dimensiones del elemento padre

    const xPos = event.clientX - parentRect.left; // Posición X relativa al borde izquierdo del elemento padre
    const yPos = rect.bottom - parentRect.top; // Posición Y relativa a la parte inferior del elemento padre

    return { top: yPos, left: xPos };
  };


  const handleRightClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    event.preventDefault();
    const draggableId = event.currentTarget.getAttribute('data-draggable-id'); // Obtener el ID arrastrable de la fila
    const parentElement = tableRef.current; // Obtener el elemento padre relativo

    if (draggableId && parentElement) {
      const position = calculateRelativePosition(event, parentElement);
      if (position === null) return;
      setContextMenuPosition(position);
      setShowContextMenu(true);
      setSelectRow(draggableId); // Establecer la fila como seleccionada
    }
  };

  const handleHoverModal = (event: React.MouseEvent<HTMLTableCellElement>, text: string) => {
    event.preventDefault();
    const parentElement = tableRef.current; // Obtener el elemento padre relativo
    if (parentElement) {
      const position = calculateRelativePosition(event, parentElement);
      if (position === null) return;
      setAltModalPosition({ ...{ ...position }, text });
      setShowAltModal(true);
    }
  };

  const handleCloseContextMenu = () => {
    setShowContextMenu(false);
  };

  return (
    <>
      <span className='tw-absolute -tw-top-1 tw-right-0 '>
        <IconButton onClick={handleCollapse} style={{ color: 'white' }}>
          {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      </span>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        <div className='table-content tw-h-full'>
          <Droppable droppableId={droppableId}>
            {(provided) => (
              <table
                {...getTableProps()}
                className="tw-table tw-min-w-full tw-w-full tw-h-full tw-overflow-y-auto tw-border tw-border-collapse tw-border-[#F0F0F2]"
                ref={(el) => {
                  provided.innerRef(el); // Asigna la referencia proporcionada
                  tableRef.current = el; // Asigna la referencia al useRef
                }}
                {...provided.droppableProps}
                style={{ display: 'block', width: '100%' }}
              >
                {tableData.length === 0 ? (
                  <tbody {...getTableBodyProps()}>
                    <tr>
                      <td colSpan={headerGroups[0].headers.length} className='tw-bg-white tw-text-center tw-p-4'>
                        {t('No hay datos disponibles')}
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    <thead className='table-header tw-flex tw-items-center tw-bg-[#CDE8E8]'>
                      {headerGroups.map((headerGroup, headerIndex) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          <th
                            style={{ minWidth: `27px`, maxWidth: `27px` }}
                          >
                          </th>
                          {headerGroup.headers.map((column, columnIndex) => (
                            <th
                              className={`tw-text-left tw-font-normal tw-text-sm tw-bg-[#CDE8E8] ${columnIndex === headerGroup.headers.length - 1
                                ? 'tw-text-center tw-border-l tw-border-[#F0F0F2] '
                                : ''
                                }`}
                              {...column.getHeaderProps()}
                              style={{ minWidth: `${column.width}px`, width: `auto` }}
                            >
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                          <Draggable key={row.id} draggableId={`${droppableId.replace('droppable', 'draggable')}-${row.id}`} index={index}>
                            {(provided, snapshot) => (
                              <tr
                                {...row.getRowProps()}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={`tw-hover:tw-bg-gray-100 ${snapshot.isDragging ? 'tw-opacity-80' : ''} ${selectRow === provided.draggableProps['data-rbd-draggable-id'] ? 'tw-bg-[#CDE8E8]' : 'tw-bg-white '
                                  }`}
                                onClick={() => {
                                  let selectedRow: string | null = provided.draggableProps['data-rbd-draggable-id']
                                  if (selectRow === selectedRow) selectedRow = null;
                                  setSelectRow(selectedRow)
                                }}
                                onContextMenu={handleRightClick} // Manejador de evento de clic derecho
                                data-draggable-id={`${droppableId.replace('droppable', 'draggable')}-${row.id}`} // Añadir el draggableId como atributo de datos
                              >
                                <td {...provided.dragHandleProps} className={`tw-text-center tw-border-r ${index !== rows.length - 1 ? 'tw-border-b' : ''} tw-border-[#F0F0F2]`}>
                                  <div style={{ cursor: 'grab', display: 'inline-block' }}>
                                    <DragHandleIcon />
                                  </div>
                                </td>
                                {row.cells.map((cell, cellIndex) => (

                                  <td
                                    {...cell.getCellProps()}
                                    style={{
                                      minWidth: `${headerGroups[0].headers[cellIndex].width}px`,
                                      width: `auto`,
                                    }}
                                    className={`tw-font-semibold tw-text-sm ${cellIndex === headerGroups[0].headers.length - 1
                                      ? `tw-text-center ${index !== rows.length - 1 ? 'tw-border-b' : ''} tw-border-l tw-border-[#F0F0F2]`
                                      : `${index !== rows.length - 1 ? 'tw-border-b' : ''} tw-border-[#F0F0F2]`
                                      }`}

                                    onMouseOver={(event) => {
                                      const shouldShowText =
                                        (cellIndex === 1 && tableData[index]) ||
                                        (cellIndex === 7 && tableData[index]?.updated);

                                      if (shouldShowText) {
                                        const textToShow = cellIndex === 1 ? tableData[index].partnerName : 'Parada modificada';
                                        handleHoverModal(event, textToShow);
                                      }
                                    }}

                                    onMouseOut={() => setShowAltModal(false)}
                                  >
                                    {cellIndex === 7 && Boolean(cell.value)
                                      ? <CompareArrowsIcon className='tw-text-[#AF1685]' />
                                      : cell.render('Cell')}

                                  </td>
                                ))}
                              </tr>
                            )}
                          </Draggable>
                        );
                      })}
                    </tbody>
                    {
                      showContextMenu && (
                        <ContextMenu
                          top={contextMenuPosition.top}
                          left={contextMenuPosition.left}
                          data={data}
                          onClose={handleCloseContextMenu}
                          selectRow={selectRow}
                          actionRows={actionRows}
                        />
                      )
                    }
                    {
                      showAltModal && (
                        <AltModal
                          top={altModalPosition.top}
                          left={altModalPosition.left}
                          text={altModalPosition.text}
                        />
                      )
                    }
                  </>
                )}
              </table>
            )}
          </Droppable>
        </div>
      </Collapse>
    </>
  );
}

export default DataTable;
