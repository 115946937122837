import { useSelector } from 'react-redux'
import { Navigate, RouteProps } from 'react-router-dom'
import FedeBi from '../../modules/FedeBI'
import { RootState } from '../store'

const ProtectedRoutes: any = (props: RouteProps) => {
  const userAuth = useSelector((state: RootState) => state.auth)
  const token = userAuth?.token || localStorage.getItem('token')

  return userAuth.isAuthenticated || token ? (
    <FedeBi />
  ) : (
    <Navigate to="/login" />
  )
}

export default ProtectedRoutes
