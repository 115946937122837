import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useUserFilters } from '../../../../infrastructure/hooks/useUserFilters'
import { filterService } from '../../../../infrastructure/services/filterServices'
import { RootState } from '../../../../infrastructure/store'
import Button from '../../Button'
import Paragraphs from '../../Paragraphs/Paragraphs'
import {
  setFilters as setStoreFilters,
  setTags as setStoreTags,
  setCompanies as setStoreCompanies,
} from '../../../../infrastructure/store/tableFilters/tableFiltersSlice'
import { userTypeGuard } from '../../../../infrastructure/utils/common'
import { checkPermissions } from '../../../../infrastructure/utils/permissions'
import { UserRoles } from '../../../../infrastructure/interfaces/users'
import { IToolPanelParams } from 'ag-grid-community'
import { icons } from 'eva-icons'
import { TertiaryButton } from '../../TertiaryButton/TertiaryButton'

/**
 * Saved filters tool panel
 */
export default (props: IToolPanelParams) => {
  const filters = useSelector((state: RootState) => state.tableFilters.filters)

  const { t } = useTranslation()
  const { setFilters, deleteAFilter } = useUserFilters()
  const user = useSelector((state: RootState) => state.auth.user)
  const dispatch = useDispatch()

  let callingApi = false
  useEffect(() => {
    if (!callingApi) {
      callingApi = true
      if (
        (userTypeGuard(user) &&
          checkPermissions(
            ['filters.*', 'filters.list', 'filters.view'],
            user?.permissions
          )) ||
        user?.role.name === UserRoles.ADMIN
      ) {
        getFilters().then((response) => {
          if (response.data.items) {
            setFilters(response.data.items)
            dispatch(setStoreFilters(response.data.items))
          }
        })
      } else {
        setFilters([])
      }
    }
  }, [])

  const onDeleteFilter = (id: string) => {
    deleteFilter(id).then((response) => {
      if (response.data) {
        deleteAFilter(parseInt(id))
      }
    })
    if (filters.length === 0) {
      clearData()
    }
  }

  const applyFilters = async ({ filter }: any) => {
    if (filter.tags) {
      localStorage.setItem('tags', JSON.stringify(filter.tags.values))
      dispatch(setStoreTags(filter.tags.values))
    } else {
      localStorage.removeItem('tags')
      dispatch(setStoreTags([]))
    }
    if (filter.companies) {
      localStorage.setItem('companies', JSON.stringify(filter.companies.values))
      dispatch(setStoreCompanies(filter.companies.values))
    } else {
      localStorage.removeItem('companies')
      dispatch(setStoreCompanies([]))
    }

    props.api.setFilterModel(filter)
    props.api.onFilterChanged()
  }

  const clearData = () => {
    props.api.setFilterModel(null)
    dispatch(setStoreTags([]))
    dispatch(setStoreCompanies([]))
    localStorage.removeItem('tags')
    localStorage.removeItem('companies')
  }

  return (
    <div className="ag-filter-tool-panel tw-p-2 tw-w-100">
      <div className="ag-filter-tool-panel__filters tw-flex tw-flex-col tw-w-100">
        {filters?.length > 0 ? (
          <Fragment>
            <Paragraphs className="tw-text-sm tw-font-bold tw-mb-2 tw-text-center tw-mt-2">
              {t('titles.Filtros guardados')}
            </Paragraphs>
            {filters?.map((item: any) => (
              <div
                className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-2"
                key={item.id}
              >
                <Paragraphs
                  className="tw-text-sm tw-font-bold tw-text-left tw-mt-2 tw-cursor-pointer"
                  size="xs"
                >
                  <span
                    className="tw-cursor-pointer tw-font-bold tw-text-xs tw-text-gray-700t w-p-2"
                    onClick={() => applyFilters(item)}
                    title={t('titles.Aplicar filtro')}
                  >
                    {' '}
                    {item.name.toUpperCase()}
                  </span>
                </Paragraphs>

                <span
                  className="tw-text-sm tw-text-red-300 hover:tw-text-red-500 tw-transition tw-duration-300 tw-ease-in-out tw-cursor-pointer"
                  onClick={() => {
                    onDeleteFilter(String(item.id))
                  }}
                  dangerouslySetInnerHTML={{
                    __html: icons['trash-2-outline']?.toSvg({ width: 24, height: 24 }) || '',
                  }}
                />
              </div>
            ))}
            {/* reset filter button */}
          </Fragment>
        ) : (
          <div className="ag-filter-tool-panel__filters tw-flex tw-flex-col tw-w-100">
            <Paragraphs className="tw-text-sm tw-font-bold tw-mb-2 tw-text-center tw-mt-4">
              {t('titles.No hay filtros guardados')}
            </Paragraphs>
          </div>
        )}
        {/* button on bottom reset filters*/}
        <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-mb-2 tw-absolute tw-bottom-0 tw-left-7">
          <div className="tw-w-[200px] tw-h-[44px]">
            <TertiaryButton text="titles.Limpiar filtros" type="button" onClick={clearData} />
          </div>
        </div>
      </div>
    </div>
  )
}

async function getFilters() {
  return await filterService.getFilters({ table: 'users' })
}

async function deleteFilter(id: string) {
  return await filterService.deleteFilter(id)
}
