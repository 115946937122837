import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { defaultColDef } from '../../../infrastructure/constant/columns/aggridColDef'
import customTags from './CustomTags'
import { useTranslation } from 'react-i18next'
import aggridLocale from '../../../infrastructure/constant/aggrid/aggridLocale'
import {
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  RowDoubleClickedEvent,
  RowSelectedEvent,
  SideBarDef,
} from 'ag-grid-community'
import companyFilter from '../../../infrastructure/constant/filters/companyFilter'
import userFilterToolPanel from '../../../components/commons/AgGrid/toolPanels/UserFilterToolPanel'
import userSavedFiltersToolPanel from '../../../components/commons/AgGrid/toolPanels/UserSavedFiltersToolPanel'
import customTagFilter from './floatingFilters/customTagFilter'
import customCompaniesFilter from './floatingFilters/customCompaniesFilter'
import customSelectFilter from './floatingFilters/customSelectFilter'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { AgGridContainer } from './styles'
import './styles.css'
import { Paginator } from './components/CustomPagination/CustomPagination'

interface IAgListProps {
  className?: string
  columns: any[]
  columnDefs?: {}
  onGridReady?: (params: any) => void
  getContextMenu?: (params: GetContextMenuItemsParams) => void
  pagination?: boolean
  paginationPageSize?: number
  onRowDoubleClicked?: (event: RowDoubleClickedEvent) => void
  cacheBlockSize?: number
  sideBar?: SideBarDef | string | boolean
  gridApi?: GridApi
  onRowSelected?: (event: RowSelectedEvent) => void
  rowSelection?: 'single' | 'multiple' | undefined
  rowDragManaged?: boolean
  rowData?: any[]
  animateRows?: boolean
  onRowDragEnd?: (event: any) => void
  height?: string
  loading?: boolean
  currentPage?: number
  totalPages?: number
  rowModelType?: 'serverSide' | 'infinite' | 'clientSide'
  onFilterChanged?: (params: any) => void
  title?: string
  onCellEditingStopped?: (params: any) => void
}

const AgList = ({
  className,
  columns,
  columnDefs,
  loading,
  onGridReady,
  getContextMenu,
  pagination,
  paginationPageSize,
  height,
  onRowDoubleClicked,
  cacheBlockSize,
  sideBar,
  gridApi,
  onRowSelected,
  rowSelection,
  currentPage = 1,
  totalPages = 1,
  animateRows = true,
  rowData,
  onRowDragEnd,
  rowDragManaged,
  title,
  onCellEditingStopped,
  onFilterChanged,
  rowModelType = 'serverSide',
}: IAgListProps) => {
  const { t } = useTranslation()
  const gridRef = useRef<AgGridReact>(null)
  const [current, setCurrent] = useState(currentPage || 1)
  const [total, setTotal] = useState(totalPages || 1)

  // actualizar currentpage y totalpages cuando cambie la paginacion
  useEffect(() => {
    setCurrent(currentPage)
    setTotal(totalPages)
  }, [currentPage, totalPages])

  const onPageChange = (page: number) => {
    setCurrent(page)
    if (gridApi) {
      gridApi.paginationGoToPage(page - 1)
    }
  }

  const getContextMenuItems = (row: any) => {
    if (typeof getContextMenu === 'function') return getContextMenu(row)
    else return row.defaultItems
  }

  const onGridReadyHandler = (params: GridReadyEvent) => {
    if (onGridReady) {
      onGridReady(params)
    }
    if (params.api) {
      setTotal(params.api.paginationGetTotalPages())
    }
  }

  useEffect(() => {
    function handleResize() {
      gridApi?.sizeColumnsToFit()
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <AgGridContainer
      style={{
        height: height || '90%',
        minHeight: '400px',
      }}
      className={`ag-theme-alpine   ag-theme-fedefarma tw-overflow-x-auto tw-w-full  ${
        className ? '' : 'tw-px-8 tw-py-5'
      }`}
      id="user-list"
    >
      <div className="tw-relative tw-h-full">
        {/* {title && (
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
            <h4 className="tw-text-md tw-font-bold tw-text-gray-800">
              {title}
            </h4>
          </div>
        )} */}
        <AgGridReact
          ref={gridRef}
          // quitar los floating filters
          rowModelType={rowModelType}
          onCellEditingStopped={onCellEditingStopped}
          suppressMoveWhenRowDragging={true}
          onFilterChanged={onFilterChanged}
          // suppressPaginationPanel={true}
          columnDefs={columns}
          getContextMenuItems={(row) => getContextMenuItems(row)}
          defaultColDef={columnDefs || defaultColDef}
          animateRows={animateRows}
          rowData={rowData}
          onRowDragEnd={onRowDragEnd}
          rowDragManaged={rowDragManaged}
          legacyComponentRendering={true}
          components={{
            customTags,
            companyFilter,
            userFilterToolPanel,
            userSavedFiltersToolPanel,
            customTagFilter,
            customCompaniesFilter,
            customSelectFilter,
          }}
          paginationPageSize={paginationPageSize || 24}
          pagination={pagination}
          localeText={aggridLocale(t)}
          onGridReady={onGridReadyHandler}
          containerStyle={{
            boxShadow: '0 3px 10px rgb(0,0,0,0.1)',
            borderRadius: '5px',
            border: '1px solid #e5e7eb',
          }}
          onRowDoubleClicked={onRowDoubleClicked}
          cacheBlockSize={cacheBlockSize || 24}
          blockLoadDebounceMillis={500}
          maxConcurrentDatasourceRequests={2}
          sideBar={sideBar}
          onRowSelected={onRowSelected}
          rowSelection={rowSelection}
          suppressServerSideFullWidthLoadingRow={true}
          gridOptions={{
            defaultColDef: {
              wrapText: true,
              autoHeight: true,
            },
          }}
        />
        {pagination && (
          <div className="tw-absolute tw-h-[48px] tw-flex tw-items-center tw-justify-center tw-bottom-0 -tw-translate-x-1/2 tw-left-1/2 ">
            <Paginator
              currentPage={current}
              totalPages={total}
              hasNextPage={current < total}
              hasPreviousPage={current > 1}
              handleFetchNextPage={() => onPageChange(current + 1)}
              handleFetchPreviousPage={() => onPageChange(current - 1)}
              isLoading={loading || false}
              handleFetchPage={onPageChange}
            />
          </div>
        )}
      </div>
    </AgGridContainer>
  )
}

export default AgList
