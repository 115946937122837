import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const useCustomToast = () => {
  const { t } = useTranslation()

  const responseOK = () => {
    toast(t('messages.el registro se ha guardado') as string, {
      type: 'success',
      className: 'toast-success tw-p-4',
      position: 'top-center',
    })
  }

  const responseError = (error: any) => {
    toast(t('messages.error al guardar el registro') as string, {
      type: 'error',
      className: 'toast-error tw-p-4',
      position: 'top-center',
    })
  }

  const noPermissionsToast = () => {
    toast(
      t(
        'messages.No tiene suficientes permisos, consulte con el administrador'
      ) as string,
      {
        type: 'error',
        className: 'toast-error tw-p-4',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      }
    )
  }

  return { responseOK, responseError, noPermissionsToast }
}

export default useCustomToast
