import React from 'react'
import { Navigate, BrowserRouterProps, Outlet } from 'react-router-dom'

const PublicRoutes: React.FC = (props: BrowserRouterProps) => {
  const token = localStorage.getItem('token')

  return !token ? <Outlet /> : <Navigate to="/home" />
}

export default PublicRoutes
