import React, { CSSProperties } from 'react';
import Paragraphs from '../Paragraphs/Paragraphs';

interface CustomProgressBarProps {
  percentage: number;
  color: string;
  width?: string; // Debería ser un valor entre 0 y 100 para representar el porcentaje
  height?: string;
  className?: string;
}

const CustomProgressBar: React.FC<CustomProgressBarProps> = ({
  percentage,
  color,
  width = 'full',
  height = '4',
  className = ''
}) => {
  const progressBarStyle: CSSProperties = {
    width: width === 'full' ? 'full' : `tw-w-${width}`,
    height: `tw-h-${height}`,
  };

  const fillBarStyle: CSSProperties = {
    width: `${percentage}%`,  // Utilizar el porcentaje proporcionado
    backgroundColor: color,
  };

  return (
    <div className={`tw-relative tw-rounded ${progressBarStyle.height} ${progressBarStyle.width} ${className}`}>
      <div className={`tw-absolute tw-top-0 tw-left-0 tw-h-full tw-rounded`} style={fillBarStyle}></div>
      <Paragraphs size="xs" weight="bold" color='black' className='tw-absolute  tw-top-0.5 tw-left-1'>
        {Math.round(percentage)} %
      </Paragraphs>
    </div>
  );
};

export default CustomProgressBar;
