import { icons } from 'eva-icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Checkbox from '../../../../components/commons/Checkbox/Checkbox'
import Label from '../../../../components/commons/Label'
import StickySettingsBtnBottom from '../../../../components/commons/Layouts/settingsPages/StickySettingsBtnBottom'
import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import TableFilters from '../../../../components/commons/ReportFilters/TableFilters'
import TagInput from '../../../../components/commons/TagInput/TagInput'
import {
  ICMS,
  ICompanyItem,
  IReportItem,
  IWorkspace,
} from '../../../../infrastructure/interfaces/settings'
import { UserRoles } from '../../../../infrastructure/interfaces/users'
import { settingsService } from '../../../../infrastructure/services/settingsService'
import storageService from '../../../../infrastructure/services/storageService'
import { setMenuSignal } from '../../../../infrastructure/store/sidebar/sidebarSlice'
import {
  handleInputErrors,
  slugify,
} from '../../../../infrastructure/utils/common'
import { checkPermissions } from '../../../../infrastructure/utils/permissions'
import { CommonContainer, Input } from '../../styles'
import { OptionType } from '../../CMS/CreatePage/CreatePage'

const CreateReport = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const sidebarMenu = useSelector((state: any) => state.sidebar.menu)
  const user =
    useSelector((state: any) => state.auth?.user) || storageService.getUser()
  const [companies, setCompanies] = useState<ICompanyItem[]>([])
  const [workspaces, setWorkspaces] = useState<IWorkspace[]>([])
  const [data, setData] = useState<IReportItem | any>({
    name: '',
    description: '',
    company_id: '',
    visible: false,
    tenant: '',
    report_id: '',
    workspace_id: '',
    report_section: '',
    icon: '',
    slug: '',
  })
  const [tags, setTags] = useState<any[]>([])
  const [type, setType] = useState<string>('create')
  const [selectedIcon, setSelectedIcon] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const evaIconOptions = Object.keys(icons).map((icon) => ({
    label: icon,
    value: icon,
  }))

  useEffect(() => {
    if (location && location['state']) {
      const state: any = location['state']
      state && setData(state.data)
      state && setSelectedIcon(state.data.icon)
      setType('update')
      setLoading(false)
    }
  }, [location])

  useEffect(() => {
    if (data && data.tags && !tags.length) {
      return setTags(data.tags)
    }
  }, [data])

  let calling = false
  useEffect(() => {
    if (calling) return
    if (
      user &&
      (checkPermissions(
        ['companies.*', 'companies.list', 'companies.view'],
        user.permissions
      ) ||
        user.role.name === UserRoles.ADMIN)
    ) {
      settingsService.getCompanies({ per_page: 500 }).then((res) => {
        setCompanies(res.data.items)
        setLoading(false)
      })
    }
    if (
      user &&
      (checkPermissions(
        ['workspaces.*', 'workspaces.list', 'workspaces.view'],
        user.permissions
      ) ||
        user.role.name === UserRoles.ADMIN)
    ) {
      settingsService
        .getWorkspaces({
          per_page: 500,
        })
        .then((res) => {
          setWorkspaces(res.data.items)
          setLoading(false)
        })
    }
    calling = true
  }, [])

  const onSave = () => {
    setLoading(true)
    if (tags.length) {
      data.tags = tags
    }
    if (selectedIcon) {
      data.icon = selectedIcon
    }

    Object.keys(data).forEach((key) => {
      if (data[key] === '' || data[key] === undefined || data[key] === null) {
        delete data[key]
      }
    })

    if (type === 'update') {
      settingsService
        .updateReport(data)
        .then((res) => {
          if (res.status === 200) {
            toast(t('messages.el registro se ha guardado') as string, {
              type: 'success',
              className: 'toast-success tw-p-4',
              position: 'top-center',
            })

            dispatch(setMenuSignal(true))
            navigate(-1)
          }
        })
        .catch((err) => {
          let errors = handleInputErrors(err)
          handleSelectErrors(errors)
        })
    } else {
      settingsService
        .createReport(data)
        .then((res) => {
          if (res.status === 200) {
            toast(t('messages.el registro se ha guardado') as string, {
              type: 'success',
              className: 'toast-success tw-p-4',
              position: 'top-center',
            })
          }
          dispatch(setMenuSignal(true))
          navigate(-1)
        })
        .catch((err) => {
          let errors = handleInputErrors(err)
          handleSelectErrors(errors)
        })
    }

    setLoading(false)
  }

  const onDeleteTag = (tags: string[]) => {
    setTags(tags)

    setData((prevState: IReportItem) => {
      prevState.tags = tags
      return prevState
    })
  }

  const handleIconChange = (selectedOption: OptionType | null) => {
    setData((prevData: ICMS) => ({
      ...prevData,
      icon: selectedOption ? selectedOption.value : null,
    }))
    setSelectedIcon(selectedOption ? selectedOption.value : null)
    document.getElementById('icon')?.classList.remove('tw-border-red-500')
  }

  const handleSelectErrors = (errors: any[]) => {
    //convert array of object to object with key as name
    let errorObject: any = {}
    errors.forEach((error) => {
      Object.keys(error)?.forEach((key) => {
        errorObject[key] = error[key]
      })
    })

    if (errorObject.icon) {
      document
        .getElementsByClassName('icon__control')[0]
        ?.setAttribute('style', 'border: 2px solid #e53e3e')
    }
    if (errorObject.workspace_id) {
      document
        .getElementsByClassName('workspace__control')[0]
        ?.setAttribute('style', 'border: 2px solid #e53e3e')
    }
    if (errorObject.company_id) {
      document
        .getElementsByClassName('company__control')[0]
        ?.setAttribute('style', 'border: 2px solid #e53e3e')
    }
  }

  return (
    <div className="tw-py-8 tw-px-8 tw-h-full">
      <StickySettingsBtnBottom onSave={onSave} loading={loading}>
        <div className="tw-w-full tw-pb-12">
          {/* top section inputs */}
          <CommonContainer className="tw-py-8 tw-px-8">
            <div className="tw-flex tw-w-full tw-h-full tw-pt-4">
              {/* left container */}
              <div className="tw-w-2/5">
                <div className="tw-container">
                  <div className="tw-w-full">
                    <div className="tw-w-full tw-mb-8">
                      <div className="tw-grid tw-grid-cols-3 tw-py-2">
                        <Label
                          label="Nombre del informe"
                          className="tw-mr-2 tw-text-right"
                          align="right"
                          justify="end"
                        />
                        <div className="tw-col-span-2">
                          <Input
                            className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                            type="text"
                            required
                            id="name"
                            name="name"
                            onChange={(e) => {
                              setData({
                                ...data,
                                label: e.target.value,
                                name: e.target.value,
                              })
                              if (
                                e.target.classList.contains('tw-border-red-500')
                              ) {
                                e.target.classList.remove('tw-border-red-500')
                              }
                              e.target.parentElement
                                ?.querySelector('.tw-text-red-500')
                                ?.remove()
                            }}
                            defaultValue={data && data.name}
                          />
                        </div>
                      </div>
                      <div className="tw-grid tw-grid-cols-3 tw-py-2">
                        <Label
                          label="Descripcion del informe"
                          className="tw-mr-2 tw-text-right"
                          align="right"
                          justify="end"
                        />
                        <Input
                          className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                          type="text"
                          required
                          id="description"
                          onChange={(e) => {
                            setData({ ...data, description: e.target.value })
                            if (
                              e.target.classList.contains('tw-border-red-500')
                            ) {
                              e.target.classList.remove('tw-border-red-500')
                            }
                          }}
                          value={data && data.description}
                        />
                        <Label
                          label="Slug de la página"
                          className="tw-mr-2 tw-text-right tw-mt-4"
                          align="right"
                          justify="end"
                        />
                        <Input
                          className="tw-appearance-none tw-border-2 tw-mt-4 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                          type="text"
                          required
                          id="slug"
                          onChange={(e) => {
                            if (
                              e.target.classList.contains('tw-border-red-500')
                            ) {
                              e.target.classList.remove('tw-border-red-500')
                            }
                            setData({
                              ...data,
                              slug: e.target.value,
                            })
                          }}
                          value={data?.slug || ''}
                        />
                        {data &&
                          data.name &&
                          slugify(data.name) !== data?.slug && (
                            <div className="tw-col-span-3 tw-text-right tw-mt-2">
                              <span className="tw-text-xs tw-text-gray-500">
                                {t(
                                  'messages.el slug se generará automáticamente'
                                )}
                              </span>

                              <button
                                className="tw-ml-1 tw-text-xs tw-text-gray-500 tw-underline"
                                onClick={() => {
                                  setData({
                                    ...data,
                                    slug: slugify(data?.name || ''),
                                  })
                                }}
                              >
                                {t('actions.generar slug')}
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* right container*/}
              <div className="tw-w-1/3">
                <div className="tw-w-full tw-mb-8">
                  <div className="tw-grid tw-grid-cols-4 tw-py-2">
                    <Label
                      label="Icono"
                      className="tw-mr-2 tw-text-right"
                      align="right"
                      justify="end"
                    />
                    <Select
                      className="tw-grid tw-z-[50] tw-appearance-none tw-w-full tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2 tw-italic tw-cursor-pointer"
                      value={
                        selectedIcon
                          ? { value: selectedIcon, label: selectedIcon }
                          : null
                      }
                      onChange={(selectedOption) =>
                        handleIconChange(selectedOption as OptionType | null)
                      }
                      options={evaIconOptions}
                      id="icon"
                      name="icon"
                      components={{
                        Option: (props: any) => {
                          const iconName = props?.data?.value
                          return (
                            <div
                              {...props.innerProps}
                              className="tw-p-1 material-icons hover:tw-bg-secondary hover:tw-bg-opacity-30"
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: icons[iconName]?.toSvg({
                                    width: 24,
                                    height: 24,
                                  }),
                                }}
                              />
                            </div>
                          )
                        },
                        SingleValue: (props: any) => {
                          const iconName = props?.data?.value
                          return (
                            <div
                              className="tw-flex tw-items-center tw-cursor-text"
                              title="Empezar a buscar"
                            >
                              {iconName && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: icons[iconName]?.toSvg({
                                      width: 24,
                                      height: 24,
                                    }),
                                  }}
                                />
                              )}
                              <span className="tw-ml-2">
                                {props.data.label}
                              </span>
                            </div>
                          )
                        },
                      }}
                      styles={{
                        valueContainer: (base: any) => ({
                          ...base,
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </CommonContainer>

          {/* content section */}
          <CommonContainer className="tw-py-8 tw-px-8">
            <div className="tw-flex tw-w-full tw-h-full tw-pt-4">
              {/* left container */}
              <div className="tw-w-1/2">
                <div className="tw-container">
                  <div className="tw-w-4/5">
                    <div className="tw-w-full tw-mb-8">
                      <div className="tw-grid tw-grid-cols-3 tw-py-2">
                        <Label
                          label="Asociar a entidad"
                          className="tw-mr-2 tw-text-right"
                          align="right"
                          justify="end"
                          translate
                        />
                        {user &&
                          (checkPermissions(
                            ['companies.*', 'companies.list', 'companies.view'],
                            user.permissions
                          ) ||
                            user.role.name === UserRoles.ADMIN) ? (
                          <Select
                            value={
                              data && companies.length && 'company_id' in data
                                ? {
                                  value: data.company_id,
                                  label: companies.find(
                                    (company) =>
                                      company.id === data.company_id
                                  )?.name,
                                }
                                : data && data.company
                                  ? {
                                    value: data.company.id,
                                    label: data.company.name,
                                  }
                                  : null
                            }
                            className="tw-appearance-none tw-w-full tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2 tw-italic tw-cursor-pointer"
                            onChange={(e: any) => {
                              setData({
                                ...data,
                                company_id: e.value,
                              })
                              document
                                .getElementById('company_id')
                                ?.classList.remove('tw-border-red-500')
                              document
                                .getElementById('company_id')
                                ?.parentElement?.querySelector(
                                  '.tw-text-red-500'
                                )
                                ?.remove()
                              document
                                .getElementsByClassName('company__control')[0]
                                .removeAttribute('style')
                            }}
                            placeholder="Seleccione una empresa"
                            options={companies.map((company) => ({
                              label: company.name,
                              value: company.id,
                            }))}
                            id="company_id"
                            name="company_id"
                            classNamePrefix={'company'}
                          />
                        ) : (
                          <Input
                            className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                            type="text"
                            required
                            readOnly
                            disabled
                            value={data && data.company?.name}
                          />
                        )}
                      </div>
                      <div className="tw-grid tw-grid-cols-3 tw-py-2">
                        <Label
                          label="TenantId"
                          className="tw-mr-2 tw-text-right"
                          align="right"
                          justify="end"
                        />
                        <div className="tw-col-span-2">
                          <Input
                            className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                            type="text"
                            onChange={(e) => {
                              setData({ ...data, tenant: e.target.value })
                              if (
                                e.target.classList.contains('tw-border-red-500')
                              ) {
                                e.target.classList.remove('tw-border-red-500')
                              }
                              e.target.parentElement
                                ?.querySelector('.tw-text-red-500')
                                ?.remove()
                            }}
                            value={data && data.tenant}
                            required
                            id="tenant"
                            name="tenant"
                          />
                        </div>
                      </div>
                      <div className="tw-grid tw-grid-cols-3 tw-py-2">
                        <Label
                          label="Workspace ID"
                          className="tw-mr-2 tw-text-right"
                          align="right"
                          justify="end"
                        />
                        {user &&
                          (checkPermissions(
                            [
                              'workspaces.*',
                              'workspaces.list',
                              'workspaces.view',
                            ],
                            user.permissions
                          ) ||
                            user.role.name === UserRoles.ADMIN) ? (
                          <Select
                            value={
                              data &&
                                workspaces.length &&
                                'workspace_id' in data
                                ? {
                                  value: data.workspace_id,
                                  label: workspaces.find(
                                    (workspace) =>
                                      workspace.id === data.workspace_id
                                  )?.name,
                                }
                                : data && data.workspace
                                  ? {
                                    value: data.workspace.id,
                                    label: data.workspace.name,
                                  }
                                  : null
                            }
                            className="tw-appearance-none tw-w-full tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2 tw-italic tw-cursor-pointer"
                            onChange={(e: any) => {
                              setData({
                                ...data,
                                workspace_id: e.value,
                              })
                              let element =
                                document.getElementById('workspace_id')
                              if (element) {
                                element.classList.remove('tw-border-red-500')
                                element.parentElement
                                  ?.querySelector('.tw-text-red-500')
                                  ?.remove()
                              }
                              document
                                .getElementsByClassName('workspace__control')[0]
                                .removeAttribute('style')
                            }}
                            placeholder="Seleccione una empresa"
                            options={workspaces.map((workspace) => ({
                              label: workspace.name,
                              value: workspace.id,
                            }))}
                            id="workspace_id"
                            name="workspace_id"
                            classNamePrefix={'workspace'}
                          />
                        ) : (
                          <Input
                            className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                            type="text"
                            required
                            readOnly
                            disabled
                            value={data && data.workspace?.name}
                          />
                        )}
                      </div>
                      <div className="tw-grid tw-grid-cols-3 tw-py-2">
                        {/* tags */}
                        <TagInput
                          tags={tags || []}
                          setTags={setTags}
                          onDeletedTag={onDeleteTag}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* right container*/}
              <div className="tw-w-1/2 tw-pr-8">
                <div className="tw-w-full tw-mb-8">
                  <div className="tw-grid tw-grid-cols-3 tw-py-2">
                    <Label
                      label="Reportid"
                      className="tw-mr-2 tw-text-right"
                      align="right"
                      justify="start"
                    />
                    <div className="tw-col-span-2">
                      <Input
                        className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                        type="text"
                        name="report_id"
                        onChange={(e) => {
                          setData({ ...data, report_id: e.target.value })
                          if (
                            e.target.classList.contains('tw-border-red-500')
                          ) {
                            e.target.classList.remove('tw-border-red-500')
                          }
                          e.target.parentElement
                            ?.querySelector('.tw-text-red-500')
                            ?.remove()
                        }}
                        value={data && data.report_id}
                        required
                        id="report_id"
                      />
                    </div>
                  </div>
                  <div className="tw-grid tw-grid-cols-3 tw-py-2">
                    <Label
                      label="ReportSection"
                      className="tw-mr-2 tw-text-right"
                      align="right"
                      justify="start"
                    />
                    <div className="tw-col-span-2">
                      <Input
                        name="report_section"
                        className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-w-full tw-py-2 tw-px-4 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-800 tw-col-span-2"
                        type="text"
                        required
                        onChange={(e) => {
                          setData({ ...data, report_section: e.target.value })
                          if (
                            e.target.classList.contains('tw-border-red-500')
                          ) {
                            e.target.classList.remove('tw-border-red-500')
                          }
                          e.target.parentElement
                            ?.querySelector('.tw-text-red-500')
                            ?.remove()
                        }}
                        value={data && data.report_section}
                        id="report_section"
                      />
                    </div>
                  </div>
                </div>
                <TableFilters setData={setData} data={data} />
              </div>
            </div>
          </CommonContainer>
        </div>
      </StickySettingsBtnBottom>
    </div>
  )
}

export default CreateReport
