import { IPagination } from './common'

export interface ICompany {
  items: ICompanyItem[]
  meta?: IPagination
}

export interface ICompanyItem {
  id: number
  name: string
  primary_color: string
  secondary_color?: string
  tertiary_color?: string
  quaternary_color?: string
  logo: string
  domain: string
  total_reports?: number
  roles?: string[]
  chat_active?: boolean
  chatbot?: string
  menus_count?: number
  domain_api?: string
  reports?: IReportItem[]
  pages?: ICMS[]
  sso_client_id?: string
  sso_client_secret?: string
  default?: boolean
  ms_sso?: boolean
}

export interface IRoles {
  items: IRoleItem[]
  meta?: IPagination
}

export interface IRoleItem {
  id: number
  name: string
  permissions?: string[]
  default?: boolean
}

export interface IReport {
  items: IReportItem[]
  meta?: IPagination
}

export interface IReportItem {
  id: number
  name: string
  description: string
  workspace_id: string
  tenant: string
  report_id: string
  report_section: string
  label?: string
  slug: string
  icon?: string
  visible?: boolean
  tags: string[]
  embed_token?: string
  embed_url?: string
  filter?: {
    table: string
    column: string
    values: string[]
  }
}

export interface ICapability {
  items: ICapabilityItem[]
  meta?: IPagination
}

export interface ICapabilityItem {}

export interface ICMS {
  id: number
  title: string
  company?: number
  visible: boolean
  icon?: string | null
  slug: string
  updated_at: string
  widgets?: IWidget[]
  is_home: boolean
  has_widgets?: boolean
}

export interface IWidget {
  id: number
  content: string
  type: WidgetType | string
  position: number
  page: ICMS
  name?: string
  report?: IReportItem
  report_id?: number // only to update the item
}

export enum WidgetType {
  HTML = 'html',
  BI = 'bi',
}

export interface IWorkspace {
  id?: number
  name: string
  workspace_id: string
  application_id: string
  application_secret: string
}

export interface IStatus {
  id: number
  name: string
  init_date: string
  end_date: string
  status: string
}

export interface IMenuItem {
  company_id: number
  label?: string
  icon?: string
  parent_menu_id?: number
  slug?: string
  entity_id?: number
  weight?: number
  type: string
  id?: number | string
}
