import RefreshIcon from '@mui/icons-material/Refresh';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import iconTrash from '../../../infrastructure/assets/svg/feather-trash.svg';
import { HeaderGlobalTable, IRoutes, RouteData, TableRow } from '../../../infrastructure/interfaces/routes';
import EventBusService, { EventData } from '../../../infrastructure/services/events';
import CustomProgressBar from '../../commons/CustomProgressBar/CustomProgressBar';
import Paragraphs from '../../commons/Paragraphs/Paragraphs';
import DataTable from './DataTable';
import ModifyDepeatureModal from './ModifyDepeatureModal';
import { icons } from 'eva-icons';

export interface EditField {
  tableIndex: number,
  header: string;
  field: string;
  value: string;
}
interface DynamicTablesProps {
  data: IRoutes[];
  setData: (routes: IRoutes[]) => void;
  routeData: RouteData[];
  setRouteData: (routes: RouteData[]) => void;
  showBtnRecalcMap: boolean;
  setShowBtnRecalcMap: (show: boolean) => void;
  removeRoute: (codrut: string) => void;
  resetRoute: (codrut: string) => void;
}

const DynamicTables = (props: DynamicTablesProps) => {
  const {
    data,
    setData,
    routeData,
    setRouteData,
    showBtnRecalcMap,
    setShowBtnRecalcMap,
    removeRoute,
    resetRoute
  } = props
  const [tableData, setTableData] = useState<TableRow[][]>([]);
  const [selectRow, setSelectRow] = useState<string | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editField, setEditField] = useState<EditField>({
    tableIndex: -1,
    header: '',
    field: '',
    value: ''
  });
  const { t } = useTranslation()
  const columns: Column<TableRow>[] = [
    { Header: 'Par.', accessor: 'stop', width: 40 }, // Ancho de 50 unidades
    { Header: 'Socio', accessor: 'partnerCode', width: 90 }, // Ancho de 100 unidades
    { Header: 'Cub.', accessor: 'buckets', width: 50 }, // Ancho de 75 unidades
    { Header: 'Uni.', accessor: 'units', width: 50 }, // Ancho de 75 unidades
    { Header: 'Llegada', accessor: 'arrival', width: 100 }, // Ancho de 120 unidades
    { Header: 'CPM', accessor: 'cpm', width: 50 }, // Ancho de 80 unidades
    { Header: 'Ded.', accessor: 'dedication', width: 40 }, // Ancho de 100 unidades
    { Header: '', accessor: 'updated', width: 30 }, // Ancho de 100 unidades
    { Header: 'Hora estimada', accessor: 'realTimeEstimatedDuration', width: 130 }, // Ancho de 150 unidades
  ];

  const columnsTwo: Column<HeaderGlobalTable>[] = [
    { Header: 'Paradas', accessor: 'totalStops' },
    { Header: '% Llenado', accessor: 'fill' },
    { Header: 'Cubetas', accessor: 'totalBuckets' },
    { Header: 'Unidades', accessor: 'totalUnits' },
    { Header: 'Salida', accessor: 'departure' },
    { Header: 'Dur. Real', accessor: 'realTimeDuration' },
    { Header: 'km BBDD', accessor: 'kmBBDD' },
    { Header: 'km Google', accessor: 'kmGoogle' }
  ];

  const openModal = (editData: EditField) => {
    setEditField(editData)
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSave = (newValue: any) => {
    const newData: any = [...data];
    newData[editField.tableIndex].data[editField.field] = newValue
    setEditField(prev => ({ ...prev, value: newValue }));
  };


  useEffect(() => {
    const formatData: TableRow[][] = Object.values(data).map((route: IRoutes) => {
      return route.stops.map((stop, index) => {
        return {
          stop: index + 1,
          partnerCode: stop.partnerCode,
          partnerName: stop.partnerName,
          buckets: stop.buckets,
          units: stop.units,
          arrival: stop.arrival,
          cpm: '20k',
          dedication: '80%',
          updated: stop.updated ?? false,
          realTimeEstimatedDuration: '2:30h - 2:57h',
          kmBBDD: 42,
          kmGoogle: 42
        };
      })
    });
    if (formatData.length === 0) return;

    setTableData(formatData);
  }, [data]);

  const actionRows = useCallback((props: any) => {
    const { row, action, tableIndex } = props;

    if (row === null) return;
    const div = row.split('-');
    const table = Number(div[1]);
    const position = Number(div[2]);

    if (action === 'deleteStop') {
      actionRemove(table, position);

      return;
    }

    if (action === 'moveStop') {
      actionMoveItemToOtherTable(table, tableIndex, position, tableData[tableIndex].length);

      return;
    }
    let newPosition = Number(div[2]);

    switch (action) {
      case 'moveToStart':
        newPosition = 0;
        break;
      case 'moveToEnd':
        newPosition = tableData[table].length - 1;
        break;
      case 'moveUp':
        newPosition = position - 1;
        break;
      case 'moveDown':
        newPosition = position + 1;
        break;
      default:
        break;
    }


    actionMoveItem(table, position, newPosition);
  }, [tableData]);

  useEffect(() => {
    const event = EventBusService.subscribe('rowAction', actionRows);

    return () => {
      event.unsubscribe();
    };
  }, [actionRows]);

  const actionRemove = (tableIndex: number, position: number) => {
    if (tableData.length === 0 || data.length === 0 || routeData.length === 0) return;

    const copyTableData = [...tableData];
    const copyData = [...data];
    const copyRouteData = [...routeData];

    // Borrar el elemento en la posición indicada de cada matriz
    copyTableData[tableIndex].splice(position, 1);
    copyData[tableIndex].stops.splice(position, 1);
    copyRouteData[tableIndex].destinations.splice(position, 1);

    // Actualizar estados
    setShowBtnRecalcMap(true);
    setData(copyData);
    setRouteData(copyRouteData);
    setTableData(copyTableData);
    handleSelectRow(`draggable-${tableIndex}-${position}`);
  };

  const actionMoveItem = (tableIndex: number, rowIndex: number, position: number) => {
    if (tableData.length === 0 || data.length === 0 || routeData.length === 0) return
    const copyTableData = [...tableData];
    const copyData = [...data];
    const copyRouteData = [...routeData];

    const sourceTable = copyTableData[tableIndex];
    const sourceData = copyData[tableIndex];
    const sourceRouteData = copyRouteData[tableIndex];

    moveItemBetweenArrays(sourceTable, copyTableData[tableIndex], rowIndex, position);
    moveItemBetweenArrays(sourceData.stops, copyData[tableIndex].stops, rowIndex, position);
    moveItemBetweenArrays(sourceRouteData.destinations, copyRouteData[tableIndex].destinations, rowIndex, position);

    setShowBtnRecalcMap(true);
    setData(copyData);
    setRouteData(copyRouteData);
    setTableData(copyTableData);
    handleSelectRow(`draggable-${tableIndex}-${position}`)
  };

  const actionMoveItemToOtherTable = (sourceTableIndex: number, destinationTableIndex: number, rowIndex: number, position: number) => {
    if (tableData.length === 0 || data.length === 0 || routeData.length === 0) return;

    // Copiar los datos de las tablas
    const copyTableData = [...tableData];
    const copyData = [...data];
    const copyRouteData = [...routeData];

    // Obtener los datos de la tabla de origen y la tabla de destino
    const sourceTable = copyTableData[sourceTableIndex];
    const destinationTable = copyTableData[destinationTableIndex];

    const sourceData = copyData[sourceTableIndex];
    const destinationData = copyData[destinationTableIndex];

    const sourceRouteData = copyRouteData[sourceTableIndex];
    const destinationRouteData = copyRouteData[destinationTableIndex];

    // Mover el elemento entre arrays
    moveItemBetweenArrays(sourceTable, destinationTable, rowIndex, position);
    moveItemBetweenArrays(sourceData.stops, destinationData.stops, rowIndex, position);
    moveItemBetweenArrays(sourceRouteData.destinations, destinationRouteData.destinations, rowIndex, position);

    // Actualizar los estados con los datos modificados
    setShowBtnRecalcMap(true);
    setData(copyData);
    setRouteData(copyRouteData);
    setTableData(copyTableData);
    handleSelectRow(`draggable-${destinationTableIndex}-${position}`);
  };

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    const sourceDroppableId = Number(source.droppableId.split('-')[1]);
    const destDroppableId = Number(destination.droppableId.split('-')[1]);

    const copyTableData = [...tableData];
    const copyData = [...data];
    const copyRouteData = [...routeData];

    const moveItem = (sourceId: number, destId: number) => {
      const sourceTable = copyTableData[sourceId];
      const sourceData = copyData[sourceId];
      const sourceRouteData = copyRouteData[sourceId];
      const destinationTable = copyTableData[destId];
      const destinationData = copyData[destId];
      const destinationRouteData = copyRouteData[destId];

      moveItemBetweenArrays(sourceTable, destinationTable, source.index, destination.index);
      moveItemBetweenArrays(sourceData.stops, destinationData.stops, source.index, destination.index);
      moveItemBetweenArrays(sourceRouteData.destinations, destinationRouteData.destinations, source.index, destination.index);
      sourceData.edited = true;
      destinationData.edited = true;
    };

    moveItem(sourceDroppableId, destDroppableId);

    setShowBtnRecalcMap(true);
    setData(copyData);
    setRouteData(copyRouteData);
    setTableData(copyTableData);
  };

  const moveItemBetweenArrays = (sourceArray: any[], destArray: any[], index: number, destinationIndex: number) => {
    const [removed] = sourceArray.splice(index, 1);
    if (!removed) return;
    removed.updated = true;
    destArray.splice(destinationIndex, 0, removed);
  };

  const handleSelectRow = (row: string | null) => {
    let params: EventData = { selectRow: row }
    if (row) {
      const div = row.split('-');
      const table = Number(div[1]);
      const position = Number(div[2]);
      const maxPosition = tableData[table].length - 1;
      params = {
        ...params,
        disabledUp: position === 0,
        disabledDown: maxPosition <= position
      }
    }
    EventBusService.publish('tableSelectRow', params)

    setSelectRow(row)
  }

  const renderGlobalCols = (data: IRoutes, tableIndex: number) => {
    return columnsTwo.map((column: Column<HeaderGlobalTable>, index) => (
      <Paragraphs size="xs" weight="regular" className='tw-block tw-w-full tw-items-center tw-mr-2' key={index}>
        {t(String(column.Header))}
        <Paragraphs size="xs" weight="bold">
          {column.accessor === 'fill'
            ? <CustomProgressBar
              percentage={data.data.fill}
              color="rgb(186, 215, 215)"
              width="full" height="5"
              className='tw-bg-white tw-border tw-border-gray-200'
            />
            : column.accessor === 'departure' ?

              <button
                onClick={() =>
                  openModal({
                    tableIndex,
                    header: String(column.Header),
                    field: String(column.accessor),
                    value: String(data.data[column.accessor as keyof typeof data.data]).replace('h', '')
                  })}
                className='tw-relative'
              >
                {data.data[column.accessor as keyof typeof data.data]}
                <span
                  className="tw-absolute -tw-top-0 -tw-right-6 tw-fill-white"
                  dangerouslySetInnerHTML={{
                    __html: icons['edit-2-outline']?.toSvg({ width: 16, height: 18 }) || '',
                  }}
                />
              </button>
              : data.data[column.accessor as keyof typeof data.data]}

        </Paragraphs>
      </Paragraphs>
    ));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='tw-w-[610px] tw-h-full'>
        {data.map((route, index) => (
          <div key={index} className='table-route tw-w-full tw-relative tw-mb-2'>
            <div className='table-header tw-h-8 tw-flex tw-items-center tw-text-white tw-px-2' style={{ backgroundColor: 'rgb(0, 48, 56)' }}>
              <div className="tw-w-[20px] tw-h-[20px] tw-rounded-full tw-border-2 tw-border-black tw-border-opacity-30 tw-mr-2" style={{ backgroundColor: route.color }}></div>
              <Paragraphs size="sm" weight="bold" className='tw-flex tw-flex-1 tw-items-center'>
                {route.name}
                <button className='tw-flex tw-items-start -tw-mt-1' onClick={() => removeRoute(route.name)}>
                  <span
                    className='tw-fill-white tw-ml-2 hover:tw-scale-125 tw-transition-all tw-mt-2'
                    dangerouslySetInnerHTML={{
                      __html: icons['trash-2-outline']?.toSvg({ width: 16, height: 16 }) || '',
                    }}
                  />
                </button>
              </Paragraphs>
              {route.edited && <div className='tw-flex tw-items-center tw-justify-center '>
                <button
                  className='tw-mr-6 tw-bg-[#76AFAF] tw-rounded-md tw-text-sm tw-px-1 tw-pt-1'
                  onClick={() => {
                    handleSelectRow(null)
                    resetRoute(route.name)
                  }}>
                  <span>Reiniciar</span>
                  <RefreshIcon className='tw-ml-2 -tw-mt-1 tw-transition-all hover:tw-animate-spin' fontSize='small' />
                </button>
              </div>}
            </div>

            <div className='tw-flex tw-w-full tw-text-white tw-border-t-2' style={{ borderTopColor: 'rgb(75,124, 132)' }}>
              <div className='tw-w-full tw-h-16 tw-flex tw-items-center tw-text-white tw-px-4' style={{ backgroundColor: 'rgb(0, 48, 56)' }}>
                {renderGlobalCols(route, index)}
              </div>
            </div>
            {tableData[index] &&
              <DataTable
                columns={columns}
                data={data}
                tableData={tableData[index]}
                droppableId={`droppable-${index}`}
                onDragEnd={onDragEnd}
                selectRow={selectRow}
                setSelectRow={handleSelectRow}
                actionRows={actionRows}
              />}


          </div>
        ))}
      </div>
      {modalIsOpen && <ModifyDepeatureModal
        {...editField}
        onSave={handleSave}
        onClose={closeModal}
      />}
    </DragDropContext >
  );
}

export default DynamicTables;
