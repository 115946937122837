import { Route, Routes } from 'react-router-dom'
import CMSList from '../../modules/Settings/CMS/CmsList'
import CreatePage from '../../modules/Settings/CMS/CreatePage/CreatePage'
import EditWidget from '../../modules/Settings/CMS/EditWidget/EditWidget'
import CapabilityList from '../../modules/Settings/Capabilities/CapabilityList'
import CreateCapability from '../../modules/Settings/Capabilities/CreateCapability/CreateCapability'
import CompanyList from '../../modules/Settings/Companies/CompanyList'
import CreateCompany from '../../modules/Settings/Companies/CreateCompany/CreateCompany'
import CreateReport from '../../modules/Settings/Reports/CreateReport/CreateReport'
import ReportList from '../../modules/Settings/Reports/ReportList'
import Settings from '../../modules/Settings/Settings'
import StatusList from '../../modules/Settings/Status/StatusList'
import CreateUser from '../../modules/Settings/Users/CreateUser/CreateUser'
import UserList from '../../modules/Settings/Users/UserList'
import CreateWorkspace from '../../modules/Settings/Workspaces/CreateWorkspace/CreateWorkspace'
import WorkspaceList from '../../modules/Settings/Workspaces/WorkspaceList'
import NavigationList from '../../modules/Settings/Navigation/NavigationList'
import NavReportList from '../../modules/Settings/Navigation/NavReports/NavReportList'
import FolderList from '../../modules/Settings/Navigation/NavReports/FolderList/FolderList'
import { SettingsProvider } from '../../modules/Settings/context/SettingsContext'
const SettingsRoutes: any = () => {
  return (
    <SettingsProvider>
      <Routes>
        {/* Common Layout - SettingLayout */}
        <Route element={<Settings />}>
          <Route path="users" element={<UserList />} />
          <Route path="users/create-user" element={<CreateUser />} />
          <Route path="users/edit-user/:id" element={<CreateUser />} />
          <Route path="reports" element={<ReportList />} />
          <Route path="reports/create-report" element={<CreateReport />} />
          <Route path="companies" element={<CompanyList />} />
          <Route path="companies/create-company" element={<CreateCompany />} />
          <Route path="roles" element={<CapabilityList />} />
          <Route path="roles/create-roles" element={<CreateCapability />} />
          <Route path="cms" element={<CMSList />} />
          <Route path="cms/create-page" element={<CreatePage />} />
          <Route path="workspaces" element={<WorkspaceList />} />
          <Route path="navigation" element={<NavigationList />} />
          <Route
            path="navigation/menu/:companyId"
            element={<NavReportList />}
          />
          <Route
            path="navigation/menu/:companyId/folder/:folderId"
            element={<FolderList />}
          />
          {/* <Route
            path="navigation/reports/:id/create-report"
            element={<CreateReport />}
          /> */}
          {/* <Route
            path="navigation/reports/:id/folder/:id/create-report"
            element={<CreateReport />}
          /> */}
          <Route
            path="workspaces/create-workspace"
            element={<CreateWorkspace />}
          />
          <Route path="status" element={<StatusList />} />
        </Route>
        {/* Different Layout */}
        <Route path="cms/edit-widget/:id" element={<EditWidget />} />
      </Routes>
    </SettingsProvider>
  )
}

export default SettingsRoutes
