import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { FieldOkMark } from './styles'

import greenTick from '../../../infrastructure/assets/svg/green_tick.svg'
import Paragraphs from '../../commons/Paragraphs/Paragraphs'
import TextInput from '../../commons/TextInput'
import Button from '../../commons/Button'
import { authService } from '../../../infrastructure/services/authService'
import { AxiosError, AxiosResponse } from 'axios'
import {
  setToken as setTokenStore,
  setUser,
} from '../../../infrastructure/store/user/userSlice'

interface passwordsForm {
  password: string
  confirmPwd: string
}

const CreatePasswordForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [showGreenTick, setShowGreenTick] = useState(false)
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (location && location.state) {
      const { token: tokenLocation } = location.state as any
      if (tokenLocation) {
        setToken(tokenLocation)
        return setTitle(t('login.Revisa tu correo electronico'))
      }
    } else {
      let params = new URLSearchParams(location.search)
      let tokenParams = params.get('token')
      if (typeof tokenParams === 'string') {
        setToken(String(tokenParams))
        return setTitle(t('login.Crea tu contraseña'))
      }

      navigate('/login')
    }
  }, [location])

  useEffect(() => {
    () => {
      setToken('')
      setTitle('')
    }
  })

  // Password validations
  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required('Campo obligatorio')
      .min(8, t('Mínimo 8 caracteres'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[/\\ª~!¡@€#$%^&*¨`[\]()\-_=+·{};º¬:'"´|<,>.¿?\/]).{8,}$/,
        t('errors.Revisa la contraseña')
      ),
    confirmPwd: Yup.string()
      .required('Campo obligatorio')
      .oneOf([Yup.ref('password')], t('Las contraseñas no coinciden')),
  })


  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm({ resolver: yupResolver(formSchema), mode: 'onTouched' })

  const onSubmit = async (passwords: passwordsForm) => {
    if (passwords) {
      setLoading(true)
      authService
        .resetPassword(token, passwords)
        .then((res: AxiosResponse) => {
          if (res.status === 200) {
            setLoading(false)
            setShowGreenTick(true)
            if (res.data) {
              const { access_token, user } = res.data
              if (access_token && user) {
                setLoading(false)
                setTokenStore(access_token)
                setUser(user)
                localStorage.setItem('token', access_token)
                localStorage.setItem('user', JSON.stringify(user))
                return navigate('/home')
              }
            }
          }
        })
        .catch((err: AxiosError | any) => {
          const { errors } = err.response?.data || {}
          Object.keys(errors).forEach((key: string) => {
            const error = errors[key]
            if (error && error !== 'token') {
              return setError(key.split('.')[1], {
                type: 'manual',
                message: error[0],
              })
            }
            if (error === 'token') {
              return navigate('/login')
            }
          })
          setLoading(false)
        })
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit as any)}
      className="tw-flex tw-flex-col tw-items-center tw-flex-1 tw-h-full tw-w-86"
    >
      <div className="tw-p-6 tw-w-full">
        <div className="tw-p-4 tw-text-center">
          <Paragraphs weight="bold" size="2xl">
            {title}
          </Paragraphs>
        </div>
        <div className="tw-w-80 tw-text-center tw-mb-4">
          <Paragraphs>{t('login.Introduce nueva contraseña')}</Paragraphs>
        </div>
        <div>
          <div className="tw-relative">
            <TextInput
              name={'password'}
              type={'password'}
              id={'password'}
              label={'Introduce tu nueva contraseña'}
              register={register('password')}
              error={errors['password']?.message}
            />
            <FieldOkMark>
              {Boolean(
                errors && Object.keys(errors).length === 0 && showGreenTick
              ) && <img src={greenTick} alt="tick" className="tick-icon" />}
            </FieldOkMark>
          </div>
          <div className="tw-relative">
            <TextInput
              name={'confirmPwd'}
              type={'password'}
              id={'confirmPwd'}
              label={'Reescribe tu nueva contraseña'}
              register={register('confirmPwd')}
              error={errors['confirmPwd']?.message}
            />
            <FieldOkMark>
              {Boolean(
                errors && Object.keys(errors).length === 0 && showGreenTick
              ) && <img src={greenTick} alt="tick" className="tick-icon" />}
            </FieldOkMark>
          </div>
        </div>
      </div>
      <Button
        label={t('actions.Cambiar')}
        uppercase
        type="submit"
        disabled={loading}
      />
    </form>
  )
}

export default CreatePasswordForm
