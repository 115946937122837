import { ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
export const reportsColumns = [
  {
    field: 'name',
    headerName: 'Nombre',
    filter: 'agTextColumnFilter',
    sortable: true,
  },
  {
    field: 'description',
    headerName: 'Descripción',
    filter: 'agTextColumnFilter',
    sortable: true,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    filter: 'agTextColumnFilter',
    cellRenderer: 'customTags',
    sortable: false,
  },
  {
    field: 'company',
    headerName: 'Entidad',
    filter: 'agTextColumnFilter',
    valueGetter: (params: ValueGetterParams) => {
      const { data } = params
      return data && data.company ? data.company.name : ''
    },
    width: 250,
    maxWidth: 250,
  },
  // {
  //   field: 'visible',
  //   headerName: 'En menú',
  //   filter: true,
  //   sortable: true,
  //   cellRenderer: (params: ICellRendererParams) => {
  //     const { data } = params
  //     return data.visible ? 'Si' : 'No'
  //   },
  //   cellClass: 'tw-text-center',
  //   width: 100,
  //   maxWidth: 100,
  //   filterParams: {
  //     values: ['Si', 'No'],
  //   },
  // },
  {
    field: 'in_cms',
    headerName: 'En CMS',
    filter: true,
    sortable: true,
    cellRenderer: (params: ICellRendererParams) => {
      const { data } = params
      return data.in_cms ? 'Si' : 'No'
    },
    cellClass: 'tw-text-center',
    width: 100,
    maxWidth: 100,
    filterParams: {
      values: ['Si', 'No'],
    },
  },
]
