import { useEffect, useRef } from 'react'

const useListenerClose = (onClose: any, esc?: any) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const toggle = () => {
    onClose()
  }

  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      toggle()
    }
  }

  const handleKey = (e: any) => {
    if (esc && e.key === 'Escape') {
      toggle()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, { passive: true })
    if (esc) {
      document.addEventListener('keydown', handleKey, { passive: true })
    }

    return () => {
      document.removeEventListener('mousedown', handleClick)
      if (esc) {
        document.removeEventListener('keydown', handleKey)
      }
    }
  }, [])

  return [ref, toggle]
}

export default useListenerClose
