// En el archivo donde defines reportsColumns, ajusta lo siguiente:
export const navigationColumns = [
  {
    headerName: 'Entidad',
    field: 'name',
    sortable: true,
    filter: true,
  },
  {
    headerName: 'Dominio',
    field: 'domain',
    sortable: true,
    filter: true,
  },
  {
    headerName: 'Nº de informes',
    field: 'count_menus',
    sortable: true,
    filter: true,
  },
]
