import { useTranslation } from 'react-i18next'
import { WidgetType } from '../../../../infrastructure/interfaces/settings'
import Paragraphs from '../../Paragraphs/Paragraphs'

const CMSModal = ({ children, ...props }: any) => {
  const { t } = useTranslation()
  return (
    <div
      className="tw-fixed tw-overflow-y-auto tw-top-1/2 tw--translate-y-10 tw-z-50 tw-p-8"
      style={{
        left: '42%',
      }}
    >
      <div className="tw-flex sm:tw-items-center tw-justify-center tw-min-h-full tw-p-4 tw-text-center sm:tw-p-0">
        {/* cancel button */}
        <div
          className="tw-fixed tw-inset-0 tw-transition-opacity"
          aria-hidden="true"
        >
          <div className="tw-absolute tw-inset-0 tw-bg-gray-500 tw-opacity-50" />
        </div>

        <div className="tw-relative tw-bg-white tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all tw-ease-in-out tw-duration-150 sm:tw-my-8 sm:tw-max-w-lg sm:tw-w-full">
          <div
            className="tw-p-6"
            style={{
              backgroundColor: '#e5e7eb',
            }}
          >
            <div className="sm:tw-flex sm:tw-items-start">
              <div className="tw-absolute tw-top-0 tw-right-0 tw-p-2">
                <button
                  type="button"
                  className="tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-text-gray-500 tw-transition tw-ease-in-out tw-duration-150"
                  aria-label="Close"
                  onClick={props.onClose}
                >
                  <svg
                    className="tw-h-6 tw-w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="tw-pt-4 tw-text-center sm:tw-mt-0 sm:tw-mx-4 sm:tw-text-left">
                <Paragraphs size="lg" color="gray-900">
                  {t('titles.Selecciona el tipo de bloque que deseas agregar')}
                </Paragraphs>
              </div>
            </div>
            <div className="tw-flex">
              <div className="tw-flex  tw-items-center tw-justify-center tw-w-full">
                {/* button */}
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-m-4 tw-w-1/2">
                  <button
                    className="tw-bg-white hover:tw-bg-gray-100 tw-text-gray-800 tw-py-2 tw-px-6 tw-border-2 tw-border-gray-400 tw-rounded-lg tw-shadow-lg tw-h-full"
                    onClick={() => props.onClick(WidgetType.HTML)}
                  >
                    HTML
                  </button>
                </div>
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-m-4 tw-w-1/2">
                  <button
                    className="tw-bg-white hover:tw-bg-gray-100 tw-text-gray-800 tw-py-2 tw-px-6 tw-border-2 tw-border-gray-400 tw-rounded-lg tw-shadow-lg tw-h-full"
                    onClick={() => props.onClick(WidgetType.BI)}
                  >
                    Bi
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CMSModal
