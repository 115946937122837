import { IMenuFixItem } from '../../interfaces/sidebar'
import { UserRoles } from '../../interfaces/users'

export const settingsMenu: IMenuFixItem[] = [
  {
    label: 'Configuración',
    icon: 'settings',
    slug: '#',
    role: [UserRoles.ADMIN, UserRoles.MODERATOR],
    submenu: [
      {
        label: 'Usuarios',
        slug: 'settings/users',
        permissions: ['users.*', 'users.view'],
      },
      {
        label: 'Navegación',
        slug: 'settings/navigation',
        permissions: ['navigation.*', 'navigation.view'],
      },
      {
        label: 'Informes',
        slug: 'settings/reports',
        permissions: ['reports.*', 'reports.view'],
      },
      {
        label: 'ETL',
        slug: 'settings/status',
        permissions: ['etl.*', 'etl.view'],
      },
      {
        label: 'CMS',
        slug: 'settings/cms',
        permissions: ['pages.*', 'pages.view'],
      },
      {
        label: 'Entidades',
        slug: 'settings/companies',
        permissions: ['companies.*', 'companies.view'],
      },
      {
        label: 'Workspaces',
        slug: 'settings/workspaces',
        permissions: ['workspaces.*', 'workspaces.view'],
      },
      {
        label: 'Roles/Permisos',
        slug: 'settings/roles',
        permissions: ['roles.*', 'roles.view'],
      },
    ],
  },
]
