/**
 * To add any Ag grid locale (label), add a new entry to the agGridLocale return object.
 */
export default function aggridLocale(t: any) {
  return {
    page: t('aggrid.page'),
    of: t('aggrid.of'),
    to: t('aggrid.to'),
    nextPage: t('aggrid.nextPage'),
    previousPage: t('aggrid.previousPage'),
    firstPage: t('aggrid.firstPage'),
    lastPage: t('aggrid.lastPage'),
    searchOoo: t('aggrid.search'),
    selectAll: t('aggrid.selectAll'),
    loadingOoo: t('aggrid.loading'),
    noRowsToShow: t('aggrid.noRowsToShow'),
    filterOoo: t('aggrid.filters'),
    applyFilter: t('aggrid.applyFilter'),
    equals: t('aggrid.equals'),
    notEqual: t('aggrid.notEqual'),
    lessThan: t('aggrid.lessThan'),
    greaterThan: t('aggrid.greaterThan'),
    lessThanOrEqual: t('aggrid.lessThanOrEqual'),
    greaterThanOrEqual: t('aggrid.greaterThanOrEqual'),
    inRange: t('aggrid.inRange'),
    contains: t('aggrid.contains'),
    notContains: t('aggrid.notContains'),
    startsWith: t('aggrid.startsWith'),
    endsWith: t('aggrid.endsWith'),
    noMatches: t('aggrid.noMatches'),
  }
}
