import styled from 'styled-components'

export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #c4c4c4;
  margin: 5px 0;
  cursor: default;
  position: relative;
`
export const MoveLine = styled.div`
  display: flex;
  align-items: center;
  width: 30px;
  height: 10px;
  cursor: move;
  svg {
    width: 20px;
    height: 20px;
    fill: #787878;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #787878;
  }
`

export const WidgetBi = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`
