/**
 * Validate email function
 * @param {string} email
 * @returns boolean
 */

import i18next from 'i18next'
import { toast } from 'react-toastify'
import { IUserItem } from '../interfaces/users'

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const actionContextMenu = (
  action: any,
  row: any,
  deleteModal?: any,
  location?: any,
  navigate?: any
) => {
  if (!row) return null

  const { node } = row
  if (node !== null) {
    const { data } = node

    if (!data?.id) {
      return null
    }

    switch (action) {
      case 'DELETE':
        return deleteModal(data)

      case 'EDIT':
        navigate(location, { state: { data: data, type: 'update' } })
        return data

      default: {
        return null
      }
    }
  }
}

export const slugify = (text: string) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const userTypeGuard = (user: any): user is IUserItem => {
  return user?.role !== undefined
}

/**
 * Handle validations errors function
 * @param {object} error - {AxiosError}
 * @returns
 */
export const handleInputErrors = (error: any) => {
  const t = i18next.t
  let messages = new Array()
  if (error.response && error.response.data && error.response.data.errors) {
    Object.keys(error.response.data.errors)?.forEach((key) => {
      if (
        document.getElementsByName(key) &&
        document.getElementsByName(key)[0]
      ) {
        document.getElementsByName(key)[0].classList.add('tw-border-red-500')
        //does it have a a child with the class tw-text-red-500?
        if (
          document
            .getElementsByName(key)[0]
            .parentElement?.querySelector('.tw-text-red-500')
        ) {
          //if it has a child with the class tw-text-red-500, then remove it
          document
            .getElementsByName(key)[0]
            .parentElement?.querySelector('.tw-text-red-500')
            ?.remove()
        }

        let span = document.createElement('span')
        span.classList.add('tw-text-red-500')
        span.innerHTML = t(`errors.${error.response.data.errors[key][0]}`)
        document.getElementsByName(key)[0].parentElement?.appendChild(span)

        messages.push({
          [key]: t(`errors.${error.response.data.errors[key][0]}`),
        })
      }
    })
  }
  return messages
}

/**
 * Format filter array to object
 * @param params: Array<{filter: string}>
 * @returns Object [key: string]: string
 */
export const formattedFilters = (params: any) => {
  return Object.keys(params).reduce((obj: any, key: any) => {
    if (params[key].filterType === 'text') {
      obj[key] = params[key].filter
    }
    if (params[key].filterType === 'date') {
      obj[key] = params[key].dateFrom
    }
    if (params[key].filterType === 'set') {
      obj[key] = params[key].values.map((item: any) => item).join(',')
    }
    if (['no', 'si'].indexOf(obj[key].toLowerCase()) !== -1) {
      obj[key] = obj[key].toLowerCase() === 'si' ? true : false
    }
    return obj
  }, {})
}

/**
 * Format dates to ES format
 * @param date: Date | string
 */

export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('es-ES', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}

/**
 * Debounce function
 * @param {function} func
 * @param {number} wait
 * returns {function}
 */
export const debounce = (func: any, wait: number) => {
  let timeout: any
  return function (this: any, ...args: any) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}
