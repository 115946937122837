import { useTranslation } from 'react-i18next'

interface ButtonProps {
  text: string
  onClick?: () => void
  disabled?: boolean
  small?: boolean
  className?: string
  type?: 'button' | 'submit' | 'reset'
}

export function PrimaryButton({
  text,
  onClick = () => null,
  disabled,
  small,
  className,
  type = 'button',
}: ButtonProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <button
      className={`tw-flex tw-w-full tw-min-w-max tw-h-10 tw-items-center tw-justify-center tw-rounded-sm tw-px-[35px] tw-py-[12px] tw-font-medium tw-text-white tw-focus:tw-outline-none ${
        !disabled ? 'tw-bg-brand hover:tw-bg-brandMedium' : 'tw-bg-brandSoft'
      } ${!small ? 'tw-h-10' : 'tw-h-8'} ${className}`}
      disabled={disabled}
      onClick={!disabled ? onClick : () => null}
      type={type}
    >
      <h6 style={{ fontWeight: 500 }}>{t(text as string)}</h6>
    </button>
  )
}
