import Paragraphs from "../../commons/Paragraphs/Paragraphs";
import routeImage from '../../../infrastructure/assets/svg/ruta.svg';
import fullscreen from '../../../infrastructure/assets/svg/open-fullscreen-enter.svg';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from "react-i18next";
import SearchIcon from '@material-ui/icons/Search';
import Collapse from '@mui/material/Collapse';
import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { mockData } from "../../../modules/RoutePlanner/mocks";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AutocompleteSuggestions from "./AutocompleteSuggestions";
import { IRoutes, RouteData } from "../../../infrastructure/interfaces/routes";

interface MapButtonsProps {
  data: IRoutes[];
  type: string;
  handleBtn: () => void;
  showOriginalMap: boolean;
  setShowOriginalMap: (value: boolean) => void;
  setShowBtnLeft: (value: boolean) => void;
  center: boolean;
  handleCenterMap: () => void;
  showBtnLeft: boolean;
  setRoutesData?: (routesData: RouteData[]) => void;
  onClose: () => void;
}

const MapButtons = (props: MapButtonsProps) => {
  const {
    data,
    type,
    handleBtn,
    showOriginalMap,
    setShowOriginalMap,
    center,
    handleCenterMap,
    showBtnLeft,
    setShowBtnLeft,
    setRoutesData,
    onClose
  } = props
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [collapsedRoutes, setCollapsedRoutes] = useState<boolean>(true);
  const [partners, setPartners] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[] | string[]>([]);
  const [selectedDirection, setSelectedDirection] = useState<google.maps.places.AutocompletePrediction[] | string | null>(null)
  const [selectedRoute, setSelectedRoute] = useState<string | null>(null)
  const directionsRef = useRef<HTMLInputElement>(null);
  const isSimulator = type === 'simulator';
  const isCreate = type === 'create';
  const topMargin = showOriginalMap ? 'tw-top-11' : 'tw-top-2';
  const { t } = useTranslation()

  const onAdd = async (index: number) => {
    if (!directionsRef.current) return;

    // Obtén la descripción del lugar seleccionado
    const selectedPlaceDescription =
      typeof suggestions[index] === 'string' ?
        suggestions[index] :
        //@ts-ignore
        suggestions[index].description;

    try {
      // Realiza una solicitud para obtener los detalles completos del lugar
      const placeDetails = await getPlaceDetails(selectedPlaceDescription);

      // Verifica si se obtuvieron los detalles del lugar y si tiene coordenadas
      if (placeDetails?.geometry?.location) {
        const coordinates = {
          lat: placeDetails.geometry.location.lat(),
          lng: placeDetails.geometry.location.lng(),
        };

        // Actualiza el estado o realiza otras operaciones con las coordenadas
        setRoutesData && setRoutesData([
          {
            origin: coordinates,
            destinations: [],
            color: ''
          },
        ]);

        directionsRef.current.value = selectedPlaceDescription
        setCollapsed(true);
      }
    } catch (error) {
      console.error("Error al obtener detalles del lugar:", error);
    }
  };

  // Función para obtener los detalles completos del lugar utilizando la API de Places
  const getPlaceDetails = async (placeDescription: string): Promise<any> => {
    if (!directionsRef.current) return;

    const service = new google.maps.places.PlacesService(directionsRef.current);
    return new Promise((resolve, reject) => {
      service.textSearch({ query: placeDescription }, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results && results[0]) {
          resolve(results[0]);
        } else {
          reject(new Error(`No se pudieron obtener los detalles del lugar: ${status}`));
        }
      });
    });
  };


  const handleCollapseRoutes = () => {
    setCollapsedRoutes(!collapsedRoutes);
  };

  const handleInput = async () => {
    if (!directionsRef.current) return;
    const transformedPartners = mockData[250033].stops.map(stop => stop.partnerName);
    setPartners(transformedPartners);
    setSuggestions([]);

    const inputText = directionsRef.current.value.trim();
    setCollapsed(false)

    if (inputText.length < 4) return;

    if (showBtnLeft) {
      try {
        const predictions = await getPlacePredictions(inputText);
        setSuggestions(predictions);
      } catch (error) {
        console.error('Error fetching place predictions:', error);
      }
    } else {
      setSuggestions(filterPartners(transformedPartners, inputText));
    }
  };


  const getPlacePredictions = (inputText: string): Promise<google.maps.places.AutocompletePrediction[]> => {
    return new Promise((resolve, reject) => {
      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions({ input: inputText }, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(predictions ?? []);
        } else {
          reject(`Error fetching place predictions with status: ${status}`);
        }
      });
    });
  };

  const filterPartners = (partners: string[], inputText: string) => {
    const lowercaseInput = inputText.toLowerCase();
    return partners.filter(partner => partner.toLowerCase().includes(lowercaseInput)) ?? [];
  };

  return (
    <>
      <div className='tw-relative'>
        {showOriginalMap && (
          <div className="tw-absolute tw-top-2 tw-right-2">
            <Paragraphs
              className="tw-flex tw-bg-white tw-opacity-90 tw-text-black tw-py-1 tw-px-6 tw-text-center tw-rounded tw-shadow tw-text-md"
            >
              {isSimulator && 'Vista Simulada'}
              {!isSimulator && 'Vista Original'}
            </Paragraphs>
          </div>
        )}

        {isSimulator && (
          <>
            {showBtnLeft && (
              <div className="tw-absolute tw-top-2 tw-left-2">
                <button
                  className="tw-flex tw-bg-[#003038] tw-text-white tw-py-2 tw-px-4 tw-rounded tw-shadow tw-uppercase tw-text-md"
                  onClick={handleBtn}
                >
                  <img src={routeImage} alt="Recalcular mapa" className='tw-mr-2' />
                  Recalcular Mapa
                </button>
              </div>
            )}

            <div className={`tw-absolute ${topMargin} tw-right-2`}>
              <button
                className="tw-flex tw-bg-[#003038] tw-text-white tw-py-2 tw-px-2 tw-rounded tw-shadow hover:tw-scale-110 tw-transition-all"
                onClick={() => setShowOriginalMap(!showOriginalMap)}
              >
                <img src={fullscreen} alt="fullscreen" />
              </button>
            </div>
          </>
        )}

        {isCreate && (
          <>

            <div className="tw-flex tw-space-x-2 tw-absolute tw-top-2 tw-left-2 tw-w-full tw-h-full">
              <div className={`tw-relative tw-w-full ${collapsed ? 'tw-space-y-10' : 'tw-space-y-2'}`}>
                <div className="tw-w-full">
                  <div className={`tw-relative tw-w-1/4`}>
                    <input
                      type="text"
                      placeholder={!showBtnLeft ? t('Codigo de socio') : t('Buscar dirección')}
                      ref={directionsRef}
                      className='tw-absolute tw-border tw-z-10 tw-border-gray-300 tw-rounded-md tw-p-2 focus:tw-outline-none tw-w-full tw-h-8'
                      style={{ boxShadow: '0px 5px 15px 0px rgba(0,0,0,0.29)' }}
                    />
                    <div onClick={handleInput}>
                      <SearchIcon
                        className='tw-absolute tw-z-10 tw-right-1 tw-top-1 tw-w-4 tw-h-4 tw-text-gray-300 tw-cursor-pointer'
                      />
                    </div>
                  </div>
                  <Collapse
                    in={!collapsed}
                    timeout="auto"
                    unmountOnExit
                    className="tw-block tw-pt-12 tw-p-2 tw-bg-white tw-border tw-border-gray-300 tw-border-opacity-50 tw-rounded-b-md tw-w-1/4 tw-max-h-96 tw-overflow-y-auto"
                  >
                    <AutocompleteSuggestions suggestions={suggestions} onAdd={onAdd} />
                  </Collapse>
                </div>

                <div className="tw-relative tw-bg-white tw-p-2 tw-w-1/4 tw-rounded-md tw-space-y-2">
                  <div
                    className="tw-block tw-items-center tw-border tw-border-gray-300 tw-rounded-md tw-px-2 tw-py-1 focus:tw-outline-none tw-w-full tw-h-8 tw-cursor-pointer"
                    onClick={handleCollapseRoutes}
                  >
                    <span>{selectedRoute ?? t('Selecciona una ruta')}</span>
                    {collapsedRoutes ?
                      <ExpandMoreIcon className="tw-absolute tw-right-4 tw-top-3" /> :
                      <ExpandLessIcon className="tw-absolute tw-right-4 tw-top-3" />
                    }
                  </div>

                  <Collapse
                    in={!collapsedRoutes}
                    timeout="auto"
                    unmountOnExit
                    className="tw-absolute tw-top-9 tw-bg-white tw-rounded-md tw-border tw-border-gray-300"
                    style={{ width: 'calc(100% - 17px)' }}
                  >
                    <div>
                      <ul >
                        {['250033 - ruta1', '250163 - ruta3', '250263 - ruta2'].map((route) => (
                          <li
                            className="tw-w-full hover:tw-bg-gray-50 tw-pt-2 tw-px-2 tw-cursor-pointer"
                            key={route}
                            onClick={() => {
                              setSelectedRoute(route)
                              setCollapsedRoutes(true)
                            }}
                          >
                            {route}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Collapse>


                  <input
                    type="text"
                    placeholder={t('Añadir el código de parada')}
                    className="tw-block tw-items-center tw-bg-gray-50 tw-border tw-border-gray-300 tw-rounded-md tw-px-2 tw-py-1 focus:tw-outline-none tw-w-full tw-h-8"
                  />
                  <button
                    className="tw-block tw-items-center tw-bg-[#004753] tw-text-white tw-rounded-md tw-px-2 tw-py-1 focus:tw-outline-none tw-w-full tw-h-8"
                    onClick={() => {
                      setShowBtnLeft(!showBtnLeft)
                      setCollapsed(true)
                    }}
                  >
                    {t('Aplicar')}
                  </button>
                </div>

              </div>
              <button
                className="tw-absolute tw-left-1/4 tw-bg-[#004753] tw-text-white tw-p-2 tw-rounded tw-shadow tw-uppercase tw-text-xs tw-w-64"
                onClick={() => {
                  if (directionsRef.current) {
                    directionsRef.current.value = ''
                    setSuggestions([])
                    setPartners([])
                    setCollapsedRoutes(true)
                    setRoutesData && setRoutesData([])
                  }
                  setShowBtnLeft(!showBtnLeft)
                  setCollapsed(true)
                }}
              >
                {showBtnLeft ? t('Buscar por codigo de socio') : t('Buscar por dirección')}
              </button>
            </div>

            <div className={`tw-absolute ${topMargin} tw-right-2`}>
              <button
                className="tw-flex tw-bg-[#003038] tw-text-white tw-py-1 tw-px-1 tw-rounded tw-shadow hover:tw-scale-110 tw-transition-all"
                onClick={onClose}
              >
                <CloseIcon />
              </button>
            </div>

          </>
        )}

      </div>
      {
        !isCreate && !center && (
          <button
            className="tw-absolute tw-bottom-5 tw-left-1/2 tw-transform tw--translate-x-1/2 tw-bg-white tw-px-4 tw-py-2 tw-rounded-full"
            onClick={handleCenterMap}
          >
            Centrar
          </button>
        )
      }
    </>
  );
};

export default MapButtons;