import Select from 'react-select'
import { IWidget } from '../../../../infrastructure/interfaces/settings'
import { Input } from '../../TextInput/styles'
import Paragraphs from '../../Paragraphs/Paragraphs'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { settingsService } from '../../../../infrastructure/services/settingsService'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { MoveLine } from './styles'
import { icons } from 'eva-icons'

interface IWidgetListProps {
  widgets: any
  setWidgets: (widgets: any) => void
  reportList: any
  widgetsCopy: any
  pageId: number
  data: any
}

const WidgetList = ({
  widgets,
  setWidgets,
  reportList,
  pageId,
  data,
}: IWidgetListProps) => {
  const { t } = useTranslation()
  const inputRef = useRef<any>()
  const navigate = useNavigate()

  // const [widgetList, setWidgetList] = useState<any>({})
  const [selectedReports, setSelectedReports] = useState<any>(null)
  const [widgetList, updateList] = useState<any>(widgets.children || [])

  useEffect(() => {
    if (widgets && widgets.children) {
      // setWidgetList(widgets)
      if (widgets.children?.length > 0) {
        widgets.children.forEach((widget: any) => {
          if (widget.report) {
            setSelectedReports((prev: any) => {
              return {
                ...prev,
                [widget.id]: {
                  value: widget.report.id,
                  label: widget.report.name,
                },
              }
            })
          }
        })
      }
    }
  }, [widgets])

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return
    const items = Array.from(widgetList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    updateList(items)

    let data = items.map((item: any, index: any) => {
      return {
        ...item,
        position: index,
      }
    })

    settingsService
      .reorderWidgets({
        page_id: pageId,
        widgets: data.map((item: any) => {
          return {
            id: item.id,
            position: item.position,
          }
        }),
      })
      .then((res) => {
        if (res.status === 200) {
          toast(t('Widgets actualizados') as string, {
            type: 'success',
            position: 'top-center',
          })
        }
      })
      .catch((err) => {
        toast(t('messages.Error al actualizar los widgets') as string, {
          type: 'error',
          position: 'top-center',
        })
      })
  }

  const renderWidget = (widget: IWidget, index: any) => {
    if (widget.type === 'bi') {
      const savedReport = reportList.find(
        (report: any) => report.id === widget.report?.id
      )
      return (
        <Draggable
          key={widget.id}
          draggableId={String(widget.id)}
          index={index}
        >
          {(provided: any) => (
            <li
              id="widget"
              className="tw-flex tw-border tw-p-5 tw-border-l-zinc-900 tw-items-center tw-rounded-md tw-w-full tw-h-[70px] tw-cursor-pointer tw-relative"
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              key={widget.id}
            >
              <div className="tw-block tw-mr-10">
                <MoveLine />
                <MoveLine />
              </div>
              <div className="tw-flex tw-w-full tw-justify-start tw-gap-5 tw-items-center">
                <Paragraphs size="sm">Informe</Paragraphs>
                <Select
                  options={reportList.map((report: any) => {
                    return { value: report.id, label: report.name }
                  })}
                  value={
                    selectedReports?.hasOwnProperty(widget.id)
                      ? {
                        value: selectedReports[widget.id].value,
                        label: selectedReports[widget.id].label,
                      }
                      : {
                        value: savedReport?.id,
                        label: savedReport?.name,
                      }
                  }
                  onChange={(e: any) => {
                    handleUpdateWidgetReport(widget.id, e)
                    const newReports = { ...selectedReports }
                    newReports[widget.id] = {
                      value: e.value,
                      label: e.label,
                    }
                    setSelectedReports(newReports)
                    setWidgets((prev: any) => {
                      return {
                        ...prev,
                        children: prev.children.map((item: any) => {
                          if (item.id === widget.id) {
                            return {
                              ...item,
                              report: {
                                id: e.value,
                                name: e.label,
                              },
                            }
                          }
                          return item
                        }),
                      }
                    })
                  }}
                  className="tw-w-80"
                  classNamePrefix="select"
                  menuPortalTarget={document.querySelector('body')}
                />
                <div
                  className="tw-absolute tw-right-0"
                  onClick={(e) => handleDeleteWidget(widget.id)}
                >
                  <span
                    className="tw-text-red-500 tw-cursor-pointer tw-mr-2 tw-z-10"
                    dangerouslySetInnerHTML={{
                      __html: icons['trash-2-outline']?.toSvg({ width: 24, height: 24 }) || '',
                    }}
                  />
                </div>
              </div>
            </li>
          )}
        </Draggable>
      )
    }
    if (widget.type === 'html') {
      return (
        <Draggable
          key={widget.id}
          draggableId={String(widget.id)}
          index={index}
        >
          {(provided: any) => (
            <li
              id="widget"
              className="tw-flex tw-border tw-p-5 tw-border-l-zinc-900 tw-items-center tw-rounded-md tw-w-full tw-h-[70px] tw-cursor-pointer tw-relative"
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <div className="tw-block tw-mr-10">
                <MoveLine />
                <MoveLine />
              </div>
              <div className="tw-flex tw-w-full tw-justify-start tw-gap-5 tw-items-center">
                <Paragraphs size="sm" className="tw-w-34 tw-inline">
                  Nombre del bloque
                </Paragraphs>
                <Input
                  ref={inputRef}
                  type="text"
                  className="tw-appearance-none tw-border-2 tw-border-gray-200 tw-rounded tw-py-2 tw-px-2 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-bg-white focus:tw-border-gray-800 tw-z-2"
                  name="name"
                  width="20rem"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleNameChange(widget.id, e)
                  }
                  value={widget.name}
                />

                <div
                  className="tw-absolute tw-right-8"
                  onClick={(e) => handleEditWidget(widget.id)}
                >
                  <span
                    className="tw-text-gray-500 tw-cursor-pointer tw-mr-2 tw-z-10" dangerouslySetInnerHTML={{
                      __html: icons['edit-outline']?.toSvg({ width: 24, height: 24 }) || '',
                    }}
                  />
                </div>
                <div
                  className="tw-absolute tw-right-0"
                  onClick={(e) => handleDeleteWidget(widget.id)}
                >
                  <span
                    className="tw-text-red-500 tw-cursor-pointer tw-mr-2 tw-z-10"
                    dangerouslySetInnerHTML={{
                      __html: icons['log-out-outline']?.toSvg({ width: 24, height: 24 }) || '',
                    }}
                  />
                </div>
              </div>
            </li>
          )}
        </Draggable>
      )
    }
  }

  async function handleNameChange(
    id: number,
    e: ChangeEvent<HTMLInputElement>
  ) {
    let temp = [...widgets.children]
    temp = temp.map((item: any) => {
      if (id === item.id) {
        item.name = e.target.value
      }
      return item
    })
    setWidgets({
      ...widgets,
      children: temp,
    })
    let index = temp.findIndex((item: any) => item.id === id)
    if (inputRef && inputRef.current) {
      //debounce update
      clearTimeout(inputRef.current['timer'])
      inputRef.current['timer'] = setTimeout(async () => {
        const response = await settingsService.updateWidget({
          id: id,
          name: e.target.value,
          page_id: pageId,
          type: widgets.children[index].type,
        })
        if (response.status === 200) {
          toast(t('Nombre actualizado') as string, {
            type: 'success',
            position: 'top-center',
          })
        } else {
          toast(t('Error al actualizar el nombre') as string, {
            type: 'error',
            position: 'top-center',
          })
        }
      }, 1000)
    }
  }

  function handleDeleteWidget(id: number) {
    settingsService.deleteWidget(id).then((res) => {
      if (res.status === 200) {
        updateList(widgetList.filter((item: any) => item.id !== id))
        toast(t('messages.Widget eliminado') as string, {
          type: 'success',
          position: 'top-center',
        })
      } else {
        toast(t('messages.Error al eliminar el widget') as string, {
          type: 'error',
          position: 'top-center',
        })
      }
    })
  }

  function handleEditWidget(id: number) {
    navigate(`/settings/cms/edit-widget/${id}`, { state: { data } })
  }

  async function handleUpdateWidgetReport(id: number, e: any) {
    await settingsService.updateWidget({
      id: id,
      report_id: e.value,
      page_id: pageId,
      type: 'bi',
    })
  }

  return (
    <div style={{ minHeight: '100%', height: '100%', padding: '10px' }}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable-widget">
          {(provided: any) => (
            <ul
              className="tw-flex tw-flex-col tw-gap-5"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {widgetList &&
                widgetList?.map((item: any, index: any) => {
                  return renderWidget(item, index)
                })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      {/* <Tree
        paddingLeft={0}
        tree={JSON.parse(JSON.stringify(widgets))}
        onChange={handlePositionChange}
        renderNode={renderWidget}
      />  */}
    </div>
  )
}

export default WidgetList
