import { useEffect, useState } from 'react'
import Select, { MultiValue } from 'react-select'
import Label from '../../../../components/commons/Label'
import { settingsService } from '../../../../infrastructure/services/settingsService'

interface PermissionProps {
  onChange: (value: string[]) => void
  blockedPermissions: string[]
  userPermissions?: string[]
}

const PermissionsSelect = ({
  onChange,
  blockedPermissions,
  userPermissions,
}: PermissionProps): JSX.Element => {
  const [permissions, setPermissions] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedPermissions, setSelectedPermissions] = useState<any[]>([])

  useEffect(() => {
    getPermissions()
  }, [])

  useEffect(() => {
    if (
      userPermissions &&
      userPermissions.length &&
      blockedPermissions &&
      blockedPermissions.length
    ) {
      // merge userPermissions and blockedPermissions to get the selectedPermissions, no repeat values
      const mergedPermissions = [...userPermissions, ...blockedPermissions]
      const selectedPermissions = mergedPermissions.reduce(
        (acc: string[], item: string) => {
          if (!acc.includes(item)) {
            acc.push(item)
          }
          return acc
        },
        []
      )
      setSelectedPermissions(
        selectedPermissions.map((p) => {
          if (blockedPermissions.includes(p)) {
            return { label: p, value: p, isDisabled: true }
          }
          return { label: p, value: p }
        })
      )
    } else if (
      blockedPermissions.length === 0 &&
      userPermissions &&
      userPermissions.length > 0
    ) {
      setSelectedPermissions(
        userPermissions.map((p) => ({ label: p, value: p }))
      )
      return
    }
  }, [userPermissions, blockedPermissions])

  const getPermissions = async () => {
    await settingsService.getPermissions().then((res) => {
      if (userPermissions?.length) {
        const filteredPermissions = res.data.filter((p: string) => {
          return !userPermissions.includes(p)
        })
        setPermissions(
          filteredPermissions.map((p: string) => ({ label: p, value: p }))
        )
      } else {
        setPermissions(res.data.map((p: string) => ({ label: p, value: p })))
      }
    })
    setLoading(false)
  }

  const handleChange = (
    selectedOption: MultiValue<{ label: string; value: string }>
  ) => {
    const updatedPermissions = selectedOption.reduce(
      (acc: string[], item: { label: string; value: string }) => {
        if (!blockedPermissions.includes(item.value)) {
          acc.push(item.value)
        }
        return acc
      },
      []
    )
    // check if there are permission missings from blockedPermissions, if so, add them to updatedPermissions
    blockedPermissions.forEach((p) => {
      if (!updatedPermissions.includes(p)) {
        updatedPermissions.push(p)
      }
    })

    setSelectedPermissions(
      updatedPermissions.map((p) => {
        if (blockedPermissions.includes(p)) {
          return { label: p, value: p, isDisabled: true }
        }
        return { label: p, value: p }
      })
    )
    onChange(updatedPermissions)
  }

  return (
    <div className="tw-flex tw-items-center tw-flex-row 2xl:tw-w-10/12 lg:tw-w-full tw-mb-2">
      <div className="tw-w-7/12 lg:tw-w-4/12">
        <Label
          label="Permisos"
          translate
          className="tw-mb-1 tw-md:mb-0 tw-whitespace-nowrap tw-mr-2"
        />
      </div>
      <div className="tw-w-full">
        <Select
          name="permissions"
          options={permissions}
          className="basic-multi-select tw-w-full"
          classNamePrefix="permissions"
          tabIndex={4}
          id="permissions"
          isMulti
          onChange={handleChange}
          isLoading={loading}
          value={
            selectedPermissions.length
              ? selectedPermissions
              : blockedPermissions.map((item) => ({
                  label: item,
                  value: item,
                  isDisabled: true,
                }))
          }
          isClearable={false}
          styles={{
            multiValueRemove: (base, state) => ({
              ...base,
              display: state.data.isDisabled ? 'none' : base.display,
            }),
          }}
        />
      </div>
    </div>
  )
}

export default PermissionsSelect
