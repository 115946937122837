import CreatePasswordForm from '../../../components/Auth/CreatePassword'
import Layout from '../Layout'

const CreatePasswordScreen = () => {
  return (
    <Layout>
      <CreatePasswordForm />
    </Layout>
  )
}

export default CreatePasswordScreen
