import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { ReactElement, useEffect, useRef, useState } from 'react';
import addIcon from '../../../infrastructure/assets/svg/add.svg';
import arrowAltCircleDown from '../../../infrastructure/assets/svg/arrow-alt-circle-down.svg';
import arrowAltCircleEnd from '../../../infrastructure/assets/svg/arrow-alt-circle-end.svg';
import arrowAltCircleStart from '../../../infrastructure/assets/svg/arrow-alt-circle-start.svg';
import arrowAltCircleUp from '../../../infrastructure/assets/svg/arrow-alt-circle-up.svg';
import Paragraphs from '../../commons/Paragraphs/Paragraphs';
import { ActionRow, IRoutes } from '../../../infrastructure/interfaces/routes';

interface ContextMenuItem {
  label?: string;
  action?: string;
  type?: 'divider';
  icon?: string | ReactElement;
  submenu?: boolean;
}

interface ContextMenuProps {
  top: number;
  left: number;
  selectRow: string | null;
  data: IRoutes[];
  onClose: () => void;
  actionRows: (props: ActionRow) => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ top, left, data, selectRow, onClose, actionRows }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [showChangeRouteSubMenu, setShowChangeRouteSubMenu] = useState<boolean>(false);
  const contextMenuButtons: ContextMenuItem[] = [
    {
      label: 'Añadir parada',
      action: 'addStop',
      icon: addIcon
    },
    { type: 'divider' },
    {
      label: 'Cambiar de ruta',
      action: 'changeRoute',
      icon: <CompareArrowsIcon
        className='tw-text-[#AF1685] tw-mr-2'
        style={{ width: '1rem', height: '1rem' }}
      />,
      submenu: true,
    },
    { type: 'divider' },
    { label: 'Mover al principio', action: 'moveToStart', icon: arrowAltCircleStart },
    { label: 'Mover hacia arriba', action: 'moveUp', icon: arrowAltCircleUp },
    { label: 'Mover hacia abajo', action: 'moveDown', icon: arrowAltCircleDown },
    { label: 'Mover al final', action: 'moveToEnd', icon: arrowAltCircleEnd },
    { type: 'divider' },
    {
      label: 'Eliminar parada', action: 'deleteStop', icon:
        <svg xmlns="http://www.w3.org/2000/svg" className="tw-mr-2 tw-w-4 tw-h-4" width="11.994" height="13.216" viewBox="0 0 11.994 13.216">
          <g id="Icon_feather-trash-2" data-name="Icon feather-trash-2" transform="translate(0.5 0.5)">
            <path id="Trazado_181" data-name="Trazado 181" d="M4.5,9H15.494" transform="translate(-4.5 -6.557)" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            <path id="Trazado_183" data-name="Trazado 183" d="M15,16.5v3.665" transform="translate(-10.725 -11.003)" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            <path id="Trazado_184" data-name="Trazado 184" d="M21,16.5v3.665" transform="translate(-14.281 -11.003)" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            <path id="Trazado_182" data-name="Trazado 182" d="M16.051,5.443v8.551a1.222,1.222,0,0,1-1.222,1.222H8.722A1.222,1.222,0,0,1,7.5,13.994V5.443m1.832,0V4.222A1.222,1.222,0,0,1,10.554,3H13a1.222,1.222,0,0,1,1.222,1.222V5.443" transform="translate(-6.278 -3)" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          </g>
        </svg>
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      const menuNode = menuRef.current;

      if (menuNode && !menuNode.contains(target)) {
        onClose(); // Cierra el menú solo si se hace clic fuera del menú contextual
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleItemClick = (action: string) => {
    onClose(); // Cierra el menú al hacer clic en una acción
    // Aquí puedes añadir la lógica correspondiente a la acción seleccionada
    actionRows({ row: selectRow, action });
  };

  return (
    <div
      ref={menuRef}
      className="tw-absolute tw-bg-white tw-shadow-[0_3px_10px_rgb(0,0,0,0.2)] tw-rounded-md tw-z-[50]"
      style={{ top: `${top}px`, left: `${left}px`, minWidth: '200px' }}
    >
      <div className="tw-p-4 tw-flex tw-flex-col">
        {contextMenuButtons.map((button, index) => {
          if (button.type === 'divider') {
            return <hr key={index} className="tw-my-1 tw-border-t tw-border-gray-300 tw-border-opacity-30" />;
          } else {
            return (
              <button
                key={index}
                onClick={() => button.action && handleItemClick(button.action)}
                className="tw-w-full tw-py-2 tw-pl-1 tw-pr-4 tw-cursor-pointer tw-flex tw-items-start tw-rounded hover:tw-bg-gray-200 hover:tw-bg-opacity-30"
                onMouseOver={() => button.submenu && setShowChangeRouteSubMenu(true)}
                onMouseOut={() => button.submenu && setShowChangeRouteSubMenu(false)}
              >
                <Paragraphs
                  size='sm'
                  className="tw-flex"
                  color={`${button.action === 'deleteStop' ? 'red' : ''}`}
                >
                  {button.icon && (
                    (typeof button.icon === 'string') ?
                      <img
                        src={button.icon}
                        alt={button.label}
                        className="tw-mr-2 tw-w-4 tw-h-4"
                        style={{ color: 'red' }}
                      />
                      : button.icon
                  )}

                  {button.label}
                  {button.submenu && <div className="tw-absolute tw-right-3">
                    <ChevronRightIcon className='-tw-mt-1' />
                    {showChangeRouteSubMenu && (
                      <div className="tw-absolute -tw-top-2  tw-left-full tw-bg-white tw-shadow-[0_3px_10px_rgb(0,0,0,0.2)] tw-rounded-md">
                        <div className="tw-p-4 tw-flex tw-flex-col tw-w-full">
                          {data.map((data, index) => (
                            <div key={index} className='tw-w-64 tw-relative z-10'>
                              {index > 0 && <hr key={index} className="tw-h-1 tw-my-1 tw-border-t tw-border-gray-300 tw-border-opacity-30" />}
                              <button
                                onClick={() => actionRows({ row: selectRow, tableIndex: index, action: 'moveStop' })}
                                className="tw-w-full tw-pl-1 tw-pr-4 tw-cursor-pointer tw-flex tw-items-start tw-rounded hover:tw-bg-gray-200 hover:tw-bg-opacity-30"

                              >
                                <div className='tw-h-6 tw-flex tw-items-center  tw-px-2'>
                                  <div className="tw-w-4 tw-h-4 tw-rounded-full tw-border-2 tw-border-black tw-border-opacity-30 tw-mr-2" style={{ backgroundColor: data.color }}></div>
                                  <Paragraphs size="sm" weight="regular" color='black' className='tw-flex tw-flex-1 tw-items-center tw-mt-1'>
                                    {data.name}
                                  </Paragraphs>
                                </div>
                              </button>
                            </div>

                          ))}

                        </div>
                      </div>

                    )}
                  </div>}

                </Paragraphs>
              </button>
            );
          }
        })}

      </div>
    </div>
  );
};

export default ContextMenu;
