import { Fragment, useEffect, useState } from 'react'
import Button from '../../commons/Button'
import Paragraphs from '../../commons/Paragraphs/Paragraphs'
import TextInput from '../../commons/TextInput'

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import {
  setToken,
  setUser,
  setExpiresAt,
} from '../../../infrastructure/store/user/userSlice'
import { ErrorBox, LinkWrapper, MsWrapper } from './styles'
import { validateEmail } from '../../../infrastructure/utils/common'
import { authService } from '../../../infrastructure/services/authService'
import MicrosoftBtn from './MicrosoftBtn'
import { api } from '../../../infrastructure/services/api'
import { ICompanyItem } from '../../../infrastructure/interfaces/settings'
import Logo from '../../commons/Logo'
import { AxiosError } from 'axios'

export const INPUTS = {
  login: [
    {
      id: 'email',
      type: 'email',
      label: 'Usuario',
      name: 'email',
    },
    {
      id: 'password',
      type: 'password',
      label: 'Contraseña',
      name: 'password',
    },
  ],
}

interface IProps {
  entity: ICompanyItem
}

const FormLogin = ({ entity }: IProps) => {
  const searchParams = useLocation()
  const [error, setError] = useState<Boolean>(false)
  const [loading, setLoading] = useState<Boolean>(false)
  const [disabled, setDisabled] = useState<Boolean>(false)
  const [loadingOverlay, setLoadingOverlay] = useState<Boolean>(false)
  const [showPasswordExpiredMessage, setShowPasswordExpiredMessage] =
    useState<boolean>(false)
  const [showForm, setShowForm] = useState<Boolean>(
    window.location.hostname.includes('localhost') ? true : false
  )

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  const { t } = useTranslation()

  const onLogin = authService.login
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onSubmit = async (user: any) => {
    setLoading(true)
    setLoadingOverlay(true)

    if (!validateEmail(user.indentificacion)) {
      let payload = { ...user, remember_me: false }
      try {
        const { data } = await onLogin(payload)
        if (data && data.access_token) {
          dispatch(setUser(data.user))
          dispatch(setToken(data.access_token))
          dispatch(setExpiresAt(data.expires_at))
          navigate('/home')
          return
        }
      } catch (error: any) {
        const { response } = error as AxiosError
        if (response && response.status === 403) {
          setShowPasswordExpiredMessage(true)
          return setTimeout(() => {
            setShowPasswordExpiredMessage(false)
            setLoadingOverlay(false)
            setLoading(false)
            setDisabled(false)
          }, 8000)
        } else if (response && response.status === 401) {
          setError(true)
          setBorders()
        }
        setLoading(false)
        setDisabled(false)
        return setLoadingOverlay(false)
      }
    } else {
      setError(true)
      setBorders()
    }
  }

  useEffect(() => {
    if (searchParams && searchParams.search) {
      //split from code= until &
      const code = searchParams.search.split('code=')[1].split('&')[0]
      if (code) {
        postMsLogin(code)
      }
      const error = searchParams.search.split('error=')[1]
      if (error) {
        setError(true)
        setLoadingOverlay(false)
      }
    }
  }, [searchParams])

  function postMsLogin(code: string) {
    setLoadingOverlay(true)
    api
      .post('/login-ms', { code })
      .then((res) => {
        if (res.data && res.data.access_token) {
          dispatch(setUser(res.data.user))
          dispatch(setToken(res.data.access_token))
          dispatch(setExpiresAt(res.data.expires_at))
          return navigate('/home')
        } else {
          setLoadingOverlay(false)
        }
      })
      .catch((err) => {
        setLoadingOverlay(false)
      })
  }

  function handleLogin() {
    setLoadingOverlay(true)
    api
      .get('/login-ms')
      .then((response) => {
        const { data } = response
        if (data.url) {
          return (window.location.href = data.url)
        }
      })
      .catch((error) => {
        setLoadingOverlay(false)
      })
  }

  function setBorders() {
    INPUTS.login.forEach((input) => {
      if (document.getElementById(input.id)) {
        document
          .getElementById(input.id)
          ?.setAttribute('style', 'border:1px solid red;')
      }
    })
  }

  return (
    <Fragment>
      {loadingOverlay ? (
        <div className="tw-flex tw-flex-col tw-absolute tw-top-0 tw-left-0 tw-bg-white tw-bg-opacity-100 tw-h-full tw-w-full tw-z-10 tw-items-center tw-justify-center">
          <Logo width={!showPasswordExpiredMessage ? 100 : 200} />
          {!showPasswordExpiredMessage ? (
            <Paragraphs>{t('messages.Cargando')}</Paragraphs>
          ) : (
            <Paragraphs size="xl" className="tw-w-1/3 tw-text-center tw-mt-8">
              {t(
                'messages.Su contraseña ha expirado, por ello, hemos enviado un correo con un link para que pueda cambiarla, por favor revise su bandeja de entrada.'
              )}
            </Paragraphs>
          )}
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '500px' }}
          className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-flex-1 tw-h-full"
        >
          <div className="tw-p-6 tw-w-full">
            <div className="tw-p-4 tw-text-center">
              <Paragraphs weight="bold" size="xl">
                {t('login.Bienvenido a BI')}
              </Paragraphs>
            </div>

            <div>
              {entity && entity.ms_sso ? (
                <MsWrapper className="tw-p-8 tw-mt-[10px] tw-w-full tw-items-center tw-text-center tw-flex tw-flex-col tw-border-2 tw-border-t-gray-800">
                  <Paragraphs className="tw-text-center tw-w-60">
                    {t(
                      'login.Si estás dado de alta en BI accede con tu correo de Microsoft de fedefarma'
                    )}
                  </Paragraphs>
                  <MicrosoftBtn onClick={() => handleLogin()} />
                  <LinkWrapper>
                    <div
                      onClick={() => setShowForm(!showForm)}
                      className="tw-cursor-pointer tw-text-center tw-mt-2 hover:tw-text-blue-500 tw-underline"
                    >
                      <Paragraphs className="tw-text-gray-500" size="xs">
                        {t('login.Si eres un colaborador inicia sesión aquí')}
                      </Paragraphs>
                    </div>
                  </LinkWrapper>
                </MsWrapper>
              ) : null}

              {(showForm || !entity?.ms_sso) && (
                <div className="tw-bg-white tw-mt-4 tw-flex tw-flex-col tw-w-full tw-gap-2 tw-p-10">
                  <div className="tw-text-center tw-mt-2">
                    <Paragraphs size="2xl" weight="bold">
                      {t('login.Acceso usuarios externos')}
                    </Paragraphs>
                  </div>

                  {INPUTS.login.map((i: any) => (
                    <Fragment key={i.id}>
                      <TextInput
                        type={i.type}
                        disabled={i.type === 'password' ? disabled : null}
                        id={i.id}
                        label={i.label}
                        register={register(i.name, {
                          // validate regex
                          onChange: (e: any) => {
                            // detect if email includes @fedefarma.com
                            if (i.name === 'email') {
                              if (e.target.value.includes('@fedefarma.com')) {
                                setDisabled(true)
                              } else {
                                setDisabled(false)
                              }
                            }
                          },
                          required: {
                            value: true,
                            message: 'Campo requerido',
                          },
                        })}
                        error={errors[i.name]?.message}
                      />
                      {disabled && entity?.ms_sso && i.type === 'email' && (
                        <Paragraphs weight="bold">
                          Para este usuario identificate con microsoft
                        </Paragraphs>
                      )}
                    </Fragment>
                  ))}

                  <ErrorBox>
                    {Boolean(error) && (
                      <Paragraphs size="xs" className="tw-py-2">
                        {t('login.Correo electronico o contraseña incorrectos')}
                      </Paragraphs>
                    )}
                  </ErrorBox>
                  <Button
                    label={t('actions.Entrar')}
                    uppercase
                    type="submit"
                    size="full"
                    disabled={loading || disabled ? true : false}
                  />
                  <Paragraphs size="xs">
                    {!disabled ? (
                      <LinkWrapper>
                        <Link to={'/forgot-password'}>
                          {t('login.¿Has olvidado tu contraseña?')}
                        </Link>
                      </LinkWrapper>
                    ) : (
                      <LinkWrapper>
                        <Paragraphs className="tw-text-gray-500" size="xs">
                          {t('login.¿Has olvidado tu contraseña?')}
                        </Paragraphs>
                      </LinkWrapper>
                    )}
                  </Paragraphs>
                </div>
              )}
            </div>
          </div>
        </form>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state: any) => ({
  entity: state.multitenant.entity,
})

export default connect(mapStateToProps)(FormLogin)
