import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'

export default forwardRef((props: any, ref: any) => {
  const columnName = props.column.userProvidedColDef.field
  const items = useSelector((state: any) => state.tableFilters[columnName])
  const [currentValue, setCurrentValue] = useState<any | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel: any) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          setCurrentValue(null)
        } else {
          setCurrentValue(parentModel.filter)
        }
      },
    }
  })

  useEffect(() => {
    setTimeout(() => {
      props.api.onFilterChanged()
    }, 500)
  }, [items])

  return (
    <div
      className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper"
      onClick={() => {
        props.api.openToolPanel('filters')
      }}
    >
      <input
        ref={inputRef}
        type="text"
        className="ag-input-field-input ag-text-field-input tw-cursor-pointer"
        disabled
        value={items?.map((item: any) => item).join(', ')}
      />
    </div>
  )
})
