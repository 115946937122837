interface InputProps {
  disabled?: boolean
  readOnly?: boolean
  backgroundColorInput: string
  flex: boolean
}

export const useInput = (
  disabled = false,
  readOnly = false,
  backgroundColorInput: string,
  flex: string
) => {
  const setGenericClass = (
    { uppercase, transparent, textAlign, mode, weight }: any,
    element?: any
  ) => {
    let classes = ''
    if (uppercase) {
      classes += 'tw-uppercase '
    }
    if (weight) {
      classes += `tw-font-${weight} `
    }
    if (transparent) {
      classes += `tw-${element}-transparent `
    }

    if (textAlign) {
      classes += `tw-text-${textAlign} `
    }

    if (mode !== 'normal') {
      classes += ` tw-text-${mode} `
    }

    if (mode === 'clickable') {
      classes += ` tw-cursor-pointer `
    }
    return classes
  }

  const classSelection = (customClassName: any) => {
    if (customClassName) {
      return customClassName
    }

    if (disabled || readOnly) {
      return 'tw-flex tw-border tw-bg-transparent'
    }

    return `${
      !backgroundColorInput && ' tw-bg-gray-100'
    } tw-flex tw-flex-row tw-align-right`
  }

  const modeFlex = () => {
    switch (flex) {
      case 'row':
        return 'tw-flex tw-row tw-items-center tw-w-full'
      case 'between':
        return 'tw-flex tw-row tw-items-center tw-justify-between tw-w-full'
    }
  }

  return { setGenericClass, classSelection, modeFlex }
}
